import axios from 'axios';
import {AdditionalFormsURLEnum} from '../utils/utility';
import CONSTANTS from '../constants/constants';

export const getAllClientNames = async (city, jobCategory) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/form-io/getClientNames?city=${encodeURIComponent(city)}&jobCategory=${encodeURIComponent(jobCategory)}`;
  return axios.get(url, {withCredentials: true});
};

export const getRecommendedJobs = async (company, location, jobCategory, phoneNumber, mitra) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/getRecommendedJobs`;
  return await axios.get(url, {
    params: {companypreference: company, locationPreference: location, jobCategory, phoneNumber, mitraId: mitra.id}, withCredentials: true
  });
};

export const submitApplicationForBasicForm = async (submittedJob, basicFormData, mitra, uniquenessCheck=true) => {
  const requestData = {
    submission: {
      data: {
        sourceName: "mitra-leader",
        sourceId: mitra.id,
        name: basicFormData.name,
        phoneNumber: basicFormData.phoneNumber,
        createApplication: true,
        uniqueness: true,
        jobId: submittedJob.jobId,
        jobDemandId: submittedJob.jobDemandId,
        city: basicFormData.cityPreference,
        clientName: submittedJob.companyName,
        managerMitraId: mitra.managerMitraID ? mitra.managerMitraID : mitra.id,    
      },
      currentPageData: {
        createApplication: true,
        ...(uniquenessCheck ? { uniqueness: true } : {}),
      }
    }
  }

  const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
  return axios.post(url, requestData, {withCredentials: true});
}


export const submitJobs = async (submittedJob, basicFormData, mitra) => {
  if (submittedJob.companyName.toLowerCase() in AdditionalFormsURLEnum)
  {
    if(CONSTANTS.APPLICATION_CREATION_STAFFING_CLIENTS.includes(submittedJob.companyName.toLowerCase()))
    {
      submitApplicationForBasicForm(submittedJob, basicFormData, mitra, false);
    }

    if(submittedJob.companyName.toLowerCase() === 'zomato') {
      return submitApplicationForBasicForm(submittedJob, basicFormData, mitra);
    }
    return {
      checkAdditionalForm: true,
    }
  }

  return submitApplicationForBasicForm(submittedJob, basicFormData, mitra);
}

export const uniquenessCheck = (submittedJob, basicFormData, mitra) => {
  const requestData = {
    submission: {
      data: {
        ...submittedJob,
        sourceName: "mitra-leader",
        sourceId: mitra.id,
        name: basicFormData.name,
        phoneNumber: basicFormData.phoneNumber,
        clientName: submittedJob.companyName,
        jobId: submittedJob.jobid,
        jobDemandId: submittedJob.jobdemandid,
        uniqueness: true,
        city: basicFormData.cityPreference ,
        cityPreference: basicFormData.cityPreference ,
      },
      currentPageData: {
        uniqueness: true
      }
    }
  }

  const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
  return axios.post(url, requestData, {withCredentials: true});
}

export const createJobRecommendationAction = (submittedJob, basicFormData, mitra) => {
  try {
    const requestData = {
      submission: {
        data: {
          sourceName: "mitra-leader",
          sourceId: mitra.id,
          name: basicFormData.name,
          phoneNumber: basicFormData.phoneNumber,
          clientName: submittedJob.companyName,
          jobId: submittedJob.jobId,
          jobDemandId: submittedJob.jobDemandId,
          createJobRecommendation: true
        },
        currentPageData: {
          createJobRecommendation: true
        }
      }
    }
  
    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
  } catch(err) {
    console.error(err)
  }
}
