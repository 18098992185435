import axios from 'axios';

export const getDistinctDateForBulkFeature = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getDistinctDateForBulkFeature`;
    return axios.get(url, {params: data, withCredentials: true});
};


export const getBulkUniquenessList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/listBulkUploadCandidates`;
    return axios.get(url, {params: data, withCredentials: true});
};

export const uploadBulkUniquenessList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/postBulkUniquenessData`;
    return axios.post(url, data, {withCredentials: true});
};

export const getpopularCities = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/form-io/getPremiumJobsCities`;
    return axios.get(url, {withCredentials: true});
};
export const bulkIVRUpload = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/bulkIVRUpload`;
    return axios.post(url, data, {withCredentials: true});
}
export const bulkWhatsappUpload = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/bulkWhatsappUpload`;
    return axios.post(url, data, {withCredentials: true});
}
export const getAudioFile = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getIVRCallAudio`;
    return axios.get(url, {withCredentials: true, params: data});
}
export const getIvrCampaignList = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getBulkIVRCampaignListForMitra`;
    return axios.get(url, {withCredentials: true});
}
export const getIvrResponsesForCampaign = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getIvrResponsesForCampaign`;
    return axios.get(url, {withCredentials: true, params: data});
}

export const getWhatsappLeads = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getBulkWhatsapLeads`;
    return axios.get(url, {withCredentials: true, params: data});

}

export const getScheduledIVRList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getScheduledIVRCallsByMitra`;
    return axios.get(url, {withCredentials: true, params: data});
}

export  const displayBulkUniqueStatus = (status, companyName, scheduledInterviewId) => {
    if (status){
        if(companyName.toLowerCase().indexOf('shadowfax') !== -1){
            if(status.toLowerCase() === 'created'){
                return 'Unique'
            }
        }
        else if(status.toLowerCase() === 'uploaded'){
            return 'Unique'
        }
        if(status.toLowerCase() === 'not uploaded'){
            return 'Not Unique'
        }
    }
    if((companyName.toLowerCase().indexOf('shadowfax') !== -1 || companyName.toLowerCase() === 'zomato') && (scheduledInterviewId)) {
            return 'Pending'
    }
    if(scheduledInterviewId){
        return "Application Created"
    }
    else{
        return 'Incomplete Application'
    }
}

export const getClientsForBulkReferral = async (city) => {
        const data = new FormData();
        data.append('city', city);
        let url = process.env.REACT_APP_BACKEND_URL + '/getClientsAndCompaniesForBulkUpload';
        return axios.post(url, data, {withCredentials: true});
};

export const uploadReferralList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/postBulkReferralData`;
    return axios.post(url, data, {withCredentials: true});
};

export const recruiterDashboardCandidateCount = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/recruiterDashboardCandidateCount`;
    return axios.get(url, {withCredentials: true});
};

export const downloadBulkIvrResponses = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/download/bulkIvrLeadsWithResponse`;
    return axios.get(url, {withCredentials: true, params: data});
}

export const downloadBulkUniquenessList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/download/listBulkUploadCandidates`;
    return axios.get(url, {params: data, withCredentials: true});
};

export const downloadBulkWhatsappMessage = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/download/bulkWhatsappMessage`;
    return axios.get(url, {params: data, withCredentials: true});
};
