import { Modal, Typography,Card,Space,Button } from "antd";
import {PhoneOutlined} from '@ant-design/icons';
import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import './incomingCallsPopup.scss'
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";

const { Text} = Typography;

const IncomingCallsPopup = ({visible,closeIncomingCallsModal, incomingCallDetails}) => {
    const history = useHistory();
    const closeModal = () => {
        closeIncomingCallsModal(false);
    };

    const viewCallLogs = () => {
        trackEvent('viewed_incoming_calls_login', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        history.push('/recruiter');
    }

    return (
        <Modal
        title={incomingCallDetails.length < 10 ?  "0"+incomingCallDetails.length+ " Incoming Calls": incomingCallDetails.length+ " Incoming Calls"}
        style={{ textAlign: 'left'}}
        bodyStyle={{padding:'10px'}}
        className='incoming-calls-modal-style'
        visible={visible}
        onCancel={closeModal}
        footer={
            [
                <Button onClick={viewCallLogs} key="1" style={{background:'#2F54EB',color:'white', height:'38px', width:'358px'}}>
                    View Call Logs
                </Button>
            ]
        }
        >
            
            {
                incomingCallDetails.map((item,index) => {
                    return (
                        <Card key={index} bodyStyle={{padding:'8px'}} style={{marginBottom:'5px'}}>
                        <Space direction='vertical' size={8} style={{ width:"100%"}}>
                            <div className="incoming-calls-primary-details">
                                <Text style={{display:'flex', alignItems:'center', gap:'5px'}} className='missed-call-text'><PhoneOutlined style={{color:'blue'}}/>{item.firstName}</Text>
                                <Text>{moment(item.createdAt).format('DD MMM hh:mm A')}</Text>
                            </div>
                            <div className="incoming-call-other-details">
                                <Text>{item.receiverNumber}</Text>
                                {
                                    item.billedDuration ? <Text>Duration: {item.billedDuration} sec</Text>: <Text style={{color: '#2F54EB'}} className="missed-call-text">Missed Call</Text>
                                }
                                
                            </div>
                        </Space>
                        </Card>
                    )
                })
            }
            
        </Modal>
    );
};

export default IncomingCallsPopup;
