import React, { useReducer } from "react";
import Moment from 'react-moment';
import { Button, Divider, Tag, Space, Typography, Checkbox, Card, Image, List, Empty, Table, Steps } from 'antd';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import { DownOutlined, CloseOutlined, FilterOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { disabledDate } from "../../service/commonService";
import moment from "moment";
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";
import { getDisplayableClientName } from "../../utils/commonFunction";


const { Text } = Typography;

const TabularView = (props) => {
    const { selection, leads, ivrLeads, displayBulkUniqueStatus, mitraDispatch, startDate, endDate,
        clientSelected, bulkStatusesSelected, history, mapIVRStatus, bulkIVRStatusesSelected,
        statusColorMapping, bulkResponseSelected, mitraReducer, getBulkUniquenessList, setLeads,
        setPrevLeads, setShowBanner, getScheduledIVRList, setIVRLeads, setStartDate, setEndDate, setShowDateChip,
        setCategoryShowFilterPopup, setStatusShowFilterPopup, setIVRStatusShowFilterPopup, setResponseShowFilterPopup,
        loading, deleteChip, downloadSheet, setfilterSider, handleBulkApis, showBanner, setBulkWhatsappMessages, bulkWhatsappSelected } = props;

    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const BulkActionGAEventsTracker = useGAEventsTracker("bulkActions")
    const { Step } = Steps;
    const messageStatusesMap = {
        submitted: 'Message Queued',
        sent: 'Message Sent',
        delivered: 'Message Delivered',
        read: 'Message Read',
        failed: 'Message Failed'

    }
    const currentStepOfMessage = (item) => {
        if(item.status.includes('failed')){
            return 0
        }
        if(item.status.includes('read') || item.response != '-'){
            return 3
        }
        if(item.status.includes('delivered')){
            return 2
        }
        if(item.status.includes('sent')){
            return 1
        }
        return item.status.length>= 3 ? 3 : item.length
    }
    const applyFilterCondition = () => {
        let result = false;
        let arr = [bulkStatusesSelected, bulkIVRStatusesSelected, clientSelected, bulkStatusesSelected, startDate, endDate];
        arr.map(item => {
            if (item) {
                for (let i = 0; i < item.length; i++) {
                    if (item[i].value === true) {
                        result = true;
                        break;
                    }
                }
            }
            if (moment(startDate).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY") || moment(endDate).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")){
                result = true;
            }
        })
        return result;
    }

    const seeAll = () => {
        if (selection == 1) {
            mitraDispatch({ type: 'paramPass', value: { startDate: startDate, endDate: endDate, client: clientSelected, status: bulkStatusesSelected } });
            history.push("/lead-management/bulk-uniqueness");
        }
        else if (selection == 2) {
            mitraDispatch({ type: 'paramPass', value: { startDate: startDate, endDate: endDate, bulkIVRStatus: bulkIVRStatusesSelected, bulkResponse: bulkResponseSelected } });
            history.push("/lead-management/bulk-IVR");
        } else {
            mitraDispatch({ type: 'paramPass', value: { startDate: startDate, endDate: endDate, bulkIVRStatus: bulkIVRStatusesSelected, bulkResponse: bulkResponseSelected } });
            history.push("/lead-management/bulk-whatsapp");
        }
    }

    const preventDefault = (e, label, item) => {
        e.preventDefault()
        deleteChip(label, item)
        handleBulkApis()
        console.log('Clicked! But prevent default.');
    }

    const onFilterClicked = () => {
        setfilterSider(true);
        trackEvent('clicked_filters_home_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    return (
        <React.Fragment>
            <>
                <div className="filter-container">
                    <div className="filter-set-container" style={{ justifyContent: 'space-between' }}>
                        <div className="date-client-status">
                            <div className="Chips-container">
                                <Button style={applyFilterCondition() ? { color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : null} onClick={() => onFilterClicked()} icon={<FilterOutlined />}>Apply Filters</Button>
                                {
                                    selection == 1 &&
                                    <>
                                        {
                                            clientSelected.map((ele, index1) => {
                                                if (ele.value) {
                                                    return <Tag key={index1} className="single-chip" closable onClose={(e) => preventDefault(e, 'client', ele.label)}>{getDisplayableClientName(ele.label)} </Tag>
                                                }
                                            })
                                        }

                                        {
                                            bulkStatusesSelected.map((ele, index2) => {
                                                if (ele.value) {
                                                    return <Tag key={index2} className="single-chip" closable onClose={(e) => preventDefault(e, 'status', ele.label)}>{ele.label} </Tag>
                                                }
                                            })
                                        }
                                    </>
                                }
                                {
                                    selection == 2 &&
                                    <>
                                        {
                                            bulkIVRStatusesSelected.map((ele, index3) => {
                                                if (ele.value) {
                                                    return <Tag key={index3} className="single-chip" closable onClose={(e) => preventDefault(e, 'IVRStatus', ele.label)}>{ele.label} </Tag>
                                                }
                                            })
                                        }

                                        {
                                            bulkResponseSelected.map((ele, index4) => {
                                                if (ele.value) {
                                                    return <Tag key={index4} className="single-chip" closable onClose={(e) => preventDefault(e, 'response', ele.label)}>{ele.label} </Tag>
                                                }
                                            })
                                        }
                                    </>
                                }
                                {
                                    selection == 3 && <>
                                        {
                                            setBulkWhatsappMessages.map((ele, index3) => {
                                                if (ele.value) {
                                                    return <Tag key={index3} className="single-chip" closable onClose={(e) => preventDefault(e, 'Bulk_Whatsapp', ele.label)}>{ele.label} </Tag>
                                                }
                                            })
                                        }
                                    </>

                                }
                            </div>
                        </div>
                        {
                            showBanner ?
                                <div className="warning-message-at-risk-container">
                                    <div className="warning-message-atrisk">
                                        <InfoCircleOutlined style={{ color: '#D34612', margin: '5px 10px 0px 10px' }} />
                                        It may take upto 2 hours for the uniqueness status to get updated for bulk referred leads.
                                        <CloseOutlined onClick={() => { setShowBanner(false) }} style={{ margin: '5px 10px 0px auto' }} />
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </>
            <div className="display-session">
                <Space className="date-download">
                {
                    (startDate !== null && endDate !== null) 
                    ?
                        <div>
                            <Tag className="single-chip"  onClose={(e)=>preventDefault(e,'date',startDate)}> <Moment format="DD MMM YYYY" withTitle>{startDate}</Moment> </Tag>
                            {/* <Text style={{marginRight: '3px'}}>to</Text>
                            <Tag className="single-chip"  onClose={(e)=>preventDefault(e,'date',endDate)}> <Moment format="DD MMM YYYY" withTitle>{endDate}</Moment> </Tag> */}
                        </div>
                    : 	
                        null
                }
                <Button danger className="download-btn" onClick={() => { downloadSheet(); BulkActionGAEventsTracker("DownloadBulkActionSheet"); }}>
                    Download File
                </Button>
                </Space>
                <Divider style={{ margin: '15px 0' }} />
                <Space className="select-cardview">
                    <Space className="selectall-btns">
                        <Space>
                            <Tag className="total-leads">Total lead <span>{selection == 1 ? leads.length : (selection == 2 ? ivrLeads.length : bulkWhatsappSelected.length)}</span></Tag>
                        </Space>

                    </Space>
                    <List
                        itemLayout="horizontal"
                        dataSource={selection == 1 ? leads : (selection == 2 ? ivrLeads : bulkWhatsappSelected)}
                        loading={!loading}
                        className="list-of-candidates"
                        locale={{ emptyText: <Empty description={"No leads found for your selection. Try searching for something else"} /> }}
                        renderItem={(item, index) => (
                            <List.Item>
                                <Card className="cardview-wrapper" hoverable style={selection == 3 ? { background: item.response == 'Interested' ? '#f0fff0' : item.response == '-' ? '' : '#fff0f0' } : {}}>
                                    <Space className="cardview-space">
                                        <Space direction="vertical" >
                                            <div className="name-number">
                                                <Text className="name">
                                                    {selection == 1 ? item.candidateName : item.firstName}
                                                </Text>
                                                <Text copyable className="number">
                                                    {selection == 1 ? item.candidatePhoneNumber : item.phoneNumber}
                                                </Text>
                                            </div>
                                            {selection == 2 &&
                                                <>
                                                    <Text><Text style={{ marginRight: 20 }}>IVR Status -</Text>
                                                        <Tag style={{ color: statusColorMapping[mapIVRStatus(item.status)] ? statusColorMapping[mapIVRStatus(item.status)] : null }}>
                                                            {mapIVRStatus(item.status)}
                                                        </Tag>
                                                    </Text>
                                                </>
                                            }
                                            {selection == 2 && item.answer.length > 0 && item.answer[0] &&
                                                <Text>
                                                    <Text><Text style={{ marginRight: 20 }}>Response</Text>
                                                        {
                                                            item.answer.map((q, i) => (
                                                                <b key={i} style={{ marginRight: '15px' }}> {q}</b>
                                                            ))
                                                        }
                                                    </Text>
                                                </Text>
                                            }
                                        </Space>
                                        <Space>
                                            {selection == 1 && <Text
                                                className="text-status"
                                                style={{ color: displayBulkUniqueStatus(item.candidateStatus, item.preferredClient, item.scheduledInterviewId) === 'Unique' ? '#D34612' : displayBulkUniqueStatus(item.candidateStatus, item.preferredClient, item.scheduledInterviewId) === 'Not Unique' ? '#F5222D' : displayBulkUniqueStatus(item.candidateStatus, item.preferredClient, item.scheduledInterviewId) === 'Pending' ? '#2F54EB' : '#262626' }}
                                            >
                                                {displayBulkUniqueStatus(item.candidateStatus, item.preferredClient, item.scheduledInterviewId)}
                                            </Text>
                                            }
                                        </Space>

                                    </Space>
                                    {
                                        selection == 3 && <div style={{marginTop:'30px'}}>
                                            <Steps current={currentStepOfMessage(item)} size="small">
                                               { ['Message Scheduled', 'Message Sent', 'Message Delivered', 'Message Read'].map((ite, index) => (  

                                                <Step description={item.response && index  == 3 ? item.response: ''} key={index} title={ite} />

                                               ))
                                               
                                               }
                                            </Steps>
                                        </div>
                                    }

                                    {selection == 1 && <><Divider style={{ margin: '10px 0' }} />
                                        <div className="cardview-space">
                                            <Space>Location:
                                                <Text className="city" type="danger">{item?.city}</Text>
                                            </Space>
                                            <Image
                                                width={70}
                                                height={20}
                                                preview={false}
                                                src={"/image/" + item.preferredClient + ".png"}
                                                alt={item.preferredClient}
                                            />
                                        </div></>}
                                </Card>
                            </List.Item>
                        )}
                    />
                </Space>
            </div>
        </React.Fragment>
    )
}

export default TabularView