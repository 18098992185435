import React, { useState, useContext } from 'react';
import { notification, Button, message, Modal, Input, Typography } from 'antd';
import { loginForPayoutDashboard, sendPayoutDashboardPasswordResetUrl } from '../../service/commonService'
import { InfoCircleOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../src/App.js';

const { Text } = Typography;

const LockingComponent = (props) => {
    const { path, pathName, visibleAuth, setVisibleAuth } = props;

    const { mitraReducer } = useContext(AppContext);
    const [accessPasswordUsr, setAccessPasswordUsr] = useState('');
    const history = useHistory();

    const onResetPasswordClick = () => {
        notification.open({
            message: `A password ${mitraReducer?.mitraInfo?.password ? 'reset' : 'creation'} SMS has been sent to your phone number ${mitraReducer?.mitraInfo?.phoneNumber}`,
            duration: 15,
            icon: <InfoCircleOutlined style={{ color: '#52C41A' }} />,
            description: <Button type="primary" size="small" onClick={() => history.push(`/${path}`)}>Go to {pathName}</Button>
        });
        let isNewPassword = mitraReducer?.mitraInfo?.password ? false : true
        sendPayoutDashboardPasswordResetUrl(isNewPassword);
        history.push('/home')
    }

    const handleOkClick = () => {
        loginForPayoutDashboard({ password: accessPasswordUsr }).then(res => {
            if (res.data.success) {
                localStorage.setItem("payoutAccessToken", res.headers.payoutaccesstoken)
                setVisibleAuth(false);
            } else {
                message.error("Invalid credentials!")
            }
        }).catch((e) => {
            message.error("Invalid credentials!")
        })
    }

    const getButtons = () => {
        let buttonsArr = [];
        if (mitraReducer?.mitraInfo?.password) {
            buttonsArr.push(
                <Button
                    onClick={onResetPasswordClick}
                    type="secondary">
                    <Text >Reset Password</Text>
                </Button>
            );
            buttonsArr.push(
                <Button
                    onClick={handleOkClick}
                    type="primary">
                    <Text style={{ color: '#fff' }}> OK </Text>
                </Button>
            )
        }
        return buttonsArr;
    }

    return (
        <>
            <Modal
                title={<Text style={{ display: 'flex', alignItems: 'center' }} strong>Authentication</Text>}
                visible={visibleAuth && !localStorage.getItem("payoutAccessToken")}
                onOk={() => {
                }}
                onCancel={() => {
                    history.goBack();
                }}
                footer={getButtons()}
            >
                {
                    mitraReducer?.mitraInfo?.password ?
                        <Input.Password
                            placeholder={"Input password to access " + pathName}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            onChange={(e) => {
                                setAccessPasswordUsr(e.target.value)
                            }}
                            defaultValue={accessPasswordUsr}
                        /> :
                        <Button
                            onClick={onResetPasswordClick}
                            type="secondary">
                            <Text > Create New Password</Text>
                        </Button>
                }
            </Modal>
        </>
    )
}

export default LockingComponent;
