import LogInPage from '../../components/login/LogInPage.component';
import LeadsPage from '../../components/leads/LeadsPage.component';
import Recruiter from '../../components/leads/recruiter.component';
import JobsPage from '../../components/jobs/AddJob.component';
import AdditionalInfoPage1 from '../../components/additionalInfo/AdditionalInfo1.component';
import JobDemandsPage from '../../components/JobDemandsPage.component';
import ConvertedLeadsPage from '../../components/convertedLeads.component';
import UploadPeriscopeDataPage from '../../components/UploadPeriscopeData.component';
import UploadMarketingDataPage from '../../components/UploadMarketingData.component';
import UploadOnBoardingDataPage from '../../components/UploadOnBoardingData.component';
import FileUploader from '../../components/FileUploader.component';
import SignupPage from '../../components/signup/SignupPage.component';
import AccountPage from '../../components/account/AccountPage.component';
import UploadDocumentsPage from '../../components/account/UploadMitraDocsPage.compoent';
import TeamManagement from '../../components/teamManagement/TeamManagement.component';
import TermsAndConditions from '../../components/TermsAndConditions';
import TrainingSessionPage from '../../components/account/TrainingSessionPage.component';
import ReportPage from '../../components/report/ReportPage.component';
import Incentives from '../../components/Incentives/Incentive.component';
import IncentiveTermsAndConditions from '../../components/Incentives/TermsAndCondition.component';
import ReportLeadPage from '../../components/report/ReportLeadPage.component';
import TeamLeaderBoard from '../../components/report/TeamLeaderBoardPage.component';
import TeamLeadspage from '../../components/report/TeamLeadsPage.component';
import EarningPage from '../../components/report/EarningPage.component';
import LeadManagement from '../../components/bulk-action/Lead-management.component';
import BulkAction from '../../components/bulk-action/Bulk-action.component';
import JobPostingsManagement from '../../components/jobs/Job-postings.component';
import HelpComponent from '../../components/help/help.component';
import MorePage from '../../components/account/MoreMenu.component.js';
import HomePage from '../../components/Home';
import SearchPage from '../../components/SearchPage';
import SearchTicket from '../../components/SearchTicket';
import NotificationTabs from '../../components/notification/NotificationTabs';
import trainingHubView from '../../components/trainingHub/trainingHubView.component'
import ConnectingCallScreen from '../../components/kaleyraCallComponents/connectingCall';
import CallStatusScreen from '../../components/kaleyraCallComponents/callStatus';
import ResetPayoutDashboardPasswordScreen from '../../components/common/resetPayoutDashboardPassword.component';
import ReferAndEarn from '../../components/refer-and-earn/referAndEarn';
import CustomCallNotes from '../../components/customCallNotes';
import NewOnboardingQuestions from '../../components/onboarding/onboardingQuestions';
import MLOnboarding from '../mlOnboarding/mlOnboarding.component';
import BasicInfoForm from '../../components/BasicInfoForm';
import NotFound from '../../components/404Page';
import TelecallerDashboard from '../leads/TelecallerDashboard.component';
import MissingDocuments from '../leads/MissingDocumets.component';
import AdditionalInfoScreen from '../additionalInfo/ZomatoAdditionalInfo/AdditionalInfoScreen.jsx';
import HotLead from '../leads/hotleads.component';

export const ROLES = {
  DC: 'dc',
  ML: 'ml',
};

export const ROUTES = {
  [ROLES.ML]: [
    {
      path: '/',
      component: LogInPage,
      exact: true,
    },
    {
      path: '/home',
      component: HomePage,
      exact: true,
    },
    {
      path: '/add-single-candidate',
      component: BasicInfoForm,
      exact: true,
    },
    {
      path: '/leads/:tab',
      component: LeadsPage,
      exact: true,
    },
    {
      path: '/hot-leads-candidates',
      component: HotLead,
      exact: true,
    },
    {
      path: '/hot-leads',
      component: HotLead,
      exact: true,
    },
    {
      path: '/recruiter',
      component: Recruiter,
      exact: true,
    },
    {
      path: '/recruiter-candidates',
      component: Recruiter,
      exact: true,
    },
    {
      path: '/telecallerDashboard',
      component: TelecallerDashboard,
      exact: true,
    },
    {
      path: '/lead-management/:tab',
      component: LeadManagement,
      exact: true,
    },
    {
      path: '/search-page',
      component: SearchPage,
      exact: true,
    },
    {
      path: '/notification',
      component: NotificationTabs,
      exact: true,
    },
    {
      path: '/search-ticket',
      component: SearchTicket,
      exact: true,
    },
    {
      path: '/report/:tab',
      component: ReportPage,
      exact: true,
    },
    {
      path: '/leads-page',
      component: ReportLeadPage,
      exact: true,
    },
    {
      path: '/account/team-management',
      component: TeamManagement,
      exact: true,
    },
    {
      path: '/earnings',
      component: EarningPage,
      exact: true,
    },
    {
      path: '/account',
      component: AccountPage,
      exact: true,
    },
    {
      path: '/more',
      component: MorePage,
      exact: true,
    },
    {
      path: '/jobs',
      component: JobsPage,
      exact: true,
    },
    {
      path: '/moreInfo',
      component: AdditionalInfoPage1,
      exact: true,
    },
    {
      path: '/zomato-additional-form',
      component: AdditionalInfoScreen,
      exact: true,
    },
    {
      path: '/converted-leads',
      component: ConvertedLeadsPage,
      exact: true,
    },
    {
      path: '/job-demands/:tabType',
      component: JobDemandsPage,
      exact: true,
    },
    {
      path: '/upload-periscope-data',
      component: UploadPeriscopeDataPage,
      exact: true,
    },
    {
      path: '/upload-marketing-data',
      component: UploadMarketingDataPage,
      exact: true,
    },
    {
      path: '/upload-onboarding-data',
      component: UploadOnBoardingDataPage,
      exact: true,
    },
    {
      path: '/file-uploader',
      component: FileUploader,
      exact: true,
    },
    {
      path: '/signup',
      component: SignupPage,
      exact: false,
    },
    {
      path: '/account/upload-documents',
      component: UploadDocumentsPage,
      exact: true,
    },
    {
      path: '/terms-and-conditions',
      component: TermsAndConditions,
      exact: true,
    },
    {
      path: '/account/training-session',
      component: TrainingSessionPage,
      exact: true,
    },
    {
      path: '/bulk-action',
      component: BulkAction,
      exact: true,
    },
    {
      path: '/job-postings/:client/:id',
      component: JobPostingsManagement,
      exact: true,
    },
    {
      path: '/job-postings/:mitraName/:city/:salary/:client/:id',
      component: JobPostingsManagement,
      exact: true,
    },
    {
      path: '/team-leader-board',
      component: TeamLeaderBoard,
      exact: true,
    },
    {
      path: '/team-leads-page',
      component: TeamLeadspage,
      exact: true,
    },
    {
      path: '/incentives',
      component: Incentives,
      exact: true,
    },
    {
      path: '/incentives/termsAndConditions',
      component: IncentiveTermsAndConditions,
      exact: true,
    },
    {
      path: '/training-hub',
      component: trainingHubView,
      exact: true,
    },
    {
      path: '/help',
      component: HelpComponent,
      exact: true,
    },
    {
      path: '/connecting-call',
      component: ConnectingCallScreen,
      exact: true,
    },
    {
      path: '/call-status',
      component: CallStatusScreen,
      exact: true,
    },
    {
      path: '/refer-and-earn',
      component: ReferAndEarn,
      exact: true,
    },
    {
      path: '/custom-call-notes',
      component: CustomCallNotes,
      exact: true,
    },
    {
      path: '/onboarding-questions',
      component: NewOnboardingQuestions,
      exact: true,
    },
    {
      path: '/ml-onboarding',
      component: MLOnboarding,
      exact: true,
    },
    {
      path: '/rp/:tab',
      component: ResetPayoutDashboardPasswordScreen,
      exact: true,
    },
    {
      path: '/other-docs',
      component: MissingDocuments,
      exact: true,
    },
    {
      path: '',
      component: NotFound,
      exact: false,
    }
  ],
  [ROLES.DC]: [
    {
      path: '/',
      component: LogInPage,
      exact: true,
    },
    {
      path: '/search-page',
      component: SearchPage,
      exact: true,
    },
    {
      path: '/moreInfo',
      component: AdditionalInfoPage1,
      exact: true,
    },
    {
      path: '/zomato-additional-form',
      component: AdditionalInfoScreen,
      exact: true,
    },
    {
      path: '/other-docs',
      component: MissingDocuments,
      exact: true,
    },
    {
      path: '',
      component: SearchPage,
      exact: false,
    }
  ]
};
