import React, {useState, useEffect, useContext, useReducer, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import '../css/home.scss'
import {logEvent, setTrackingUser} from '../service/tracking';
import trackingConstants from '../constants/trackingConstants';
import BasicInfoForm from './BasicInfoForm';
import useGAEventsTracker from '../service/useGSEventsTracker';
import FilterPopup from './leads/Filter-popup.component';
// import useMoengage from './../hooks/useMoengage';
import BulkActionSidesheet from './bulk-action/Bulk-action-sidesheet.component'
import {getBulkUniquenessList, getDistinctDateForBulkFeature, getScheduledIVRList, displayBulkUniqueStatus,recruiterDashboardCandidateCount, getIvrCampaignList, getIvrResponsesForCampaign, getWhatsappLeads, bulkWhatsappUpload} from './../service/bulkActionService'
import Excel from 'exceljs';
import {saveAs} from 'file-saver';
import {AppContext} from '../App';
import { Button , Tabs, DatePicker ,Tag, PageHeader,message, Card, Select, Space , Input , Typography, Timeline, Collapse, Spin, Modal,notification, Carousel } from 'antd';
import { RightCircleFilled, UserAddOutlined, UsergroupAddOutlined, CopyFilled, SearchOutlined, PlusCircleFilled, RightCircleOutlined, TeamOutlined, TableOutlined, PercentageOutlined , DashboardOutlined, BookOutlined, IssuesCloseOutlined, InfoCircleFilled, FileAddOutlined} from '@ant-design/icons';
import TabularView from './common/TabularView';
import {getActiveClientsForFilter, disabledDateForBulkFeature, fetchActivatedAndReferredLeads, updateLeadsBasedEvents, additionalMitraInfo, checkBronzeML} from './../service/commonService'
import moment from 'moment';
import * as loginService from './../service/loginService';
import SideSheet from "./common/SideSheet";
import PageHeaderComponent from './common/PageHeader';
import { trackEvent } from '../service/engagementMonitoringService';
import CONSTANTS from '../constants/constants';
import * as queryString from 'query-string';
import ProgressBar from './progress-bar/ProgressBar';
import RoadToBronze from './progress-bar/RoadToBronze';
import RoadToSilver from './progress-bar/RoadToSilver';
import ProgressBarDetails from './progress-bar/ProgressBarDetails';
import { getCallNotification } from '../service/kaleyraCallService';
import IncomingCallsPopup from './kaleyraCallComponents/incomingCallsPopup';
import { getMitraInfo } from '../service/mlOnboardingService';
import { rollOutConversationHistory ,whatsappFeatureEnabledMitras} from '../../src/constants/utils';
import { Badge } from 'antd';
import {getDisplayableClientName} from './../utils/commonFunction';
import {downloadBulkIvrResponses, downloadBulkUniquenessList,downloadBulkWhatsappMessage} from './../service/bulkActionService';
import VerifyEmailPopup from './common/VerifyEmailPopup.component';


const { TabPane } = Tabs;
const { Option }  = Select;
const { Search }  = Input;
const { Text }    = Typography;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';


const DEFAULT = 'DEFAULT';
let deferredPrompt;

const Home = ({comingFromLoginPage}) => {
    const {mitraReducer, mitraDispatch , setAlert, setSpin} = useContext(AppContext);
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	const [loading,setLoading]=useState(false);
	const history = useHistory();
	const [breadcrumb,setBreadcrumb] = useState([{link: '/', label: 'Home'},{link: '/', label: 'Bulk Referrals'}]);
	const [category, setCategory] = useState(DEFAULT);
	const [city, setCity] = useState(DEFAULT);
	const [header, setHeader] = useState('Bulk Actions')
	const [company, setCompany] = useState(DEFAULT);
	const [jobLocation, setJobLocation] = useState(DEFAULT);
	const [leadAlreadyExistsMsg, setLeadAlreadyExistsMsg] = useState('');
	const [clients, setClients] = useState([]);
	const [jobs, setJobs] = useState([]);
	const [jobId, setJobId] = useState('');
	const [type, setType] = useState("date");
	const [jobDemandId, setJobDemandId] = useState([]);
	const [candidateForm, setCandidateForm] = useState(false);
	const [homeScreenModal, sethomeScreenModal] = useState(true);
	const [prevLeads, setPrevLeads] = useState([]);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [filterSider, setfilterSider] = useState(false);
	const [errorsFile, setErrorsFile] = useState([]);
	const [bulkCandidateUploadForm, setBulkCandidateUploadForm] = useState(false);
	const [bulkCandidateFile, setBulkCandidateFile] = useState('');
	const [dlNumber, setDlNumber] = useState(null);
	const [selection, setSelection] = useState(1)
	const [bulkAction, setBulkAction] = useState(false)
	const [bulkActionType, setBulkActionType] = useState('')
	const [isAdmin, setIsAdmin] = useState(false);
	const [startDate, setStartDate] = useState(moment());
	const [endDate, setEndDate] = useState(moment());
	const [showCategoryFilterPopup, setCategoryShowFilterPopup] = useState(false);
	const [showStatusFilterPopup, setStatusShowFilterPopup] = useState(false);
	const [showIVRStatusFilterPopup, setIVRStatusShowFilterPopup] = useState(false);
	const [showResponseFilterPopup, setResponseShowFilterPopup] = useState(false);
	const [showSideSheet, setShowSideSheet] = useState(false);
	const [ivrLeads, setIVRLeads] = useState([])
	const [showDateChip, setShowDateChip] = useState(false)
	const [showBanner, setShowBanner] = useState(true);
	const [clientSelected, setClientSelcted] = useState([])
	const [onClearTrigger, setonClearTrigger] = useState({})
	const [leads, setLeads] = useState([]);
	const [recruiterCount, setRecruiterCount] = useState(0);
	const [progressBarState, changeProgressBarState] = useState(null);
	const [progressBarSpinner, setProgressBarSpinner] = useState(true);
	const [showIncomingCallsPopup, setShowIncomingCallsPopup] = useState(false);
	const [incomingCallsPopupData, setIncomingCallsPopupData] = useState([]);
	const [showBankInfo, setShowBankInfo] = useState(false);
	const [ticketInfo, setTicketInfo] = useState([]);
	const [badgeCount, setBadgeCount] = useState('');
	const [bulkWhatsappSelected, setBulkWhatsappSelected] = useState([])
	const [conversationHistoryNotificationModal, setConversationHistoryNotificationModal] = useState(false);
	const [quickLinks, setQuickLinks] = useState(CONSTANTS.HOME_PAGE_QUICK_LINKS);
	const [availableDateList, setAvailableDateList] = useState([]);
	const highIntentEvents = useGAEventsTracker('LeadStatus');
	const louToursHomeSequence = {
		'Onboarding Video':452960672179,
		'Incentives':271200083666,
		'Refer a candidate':509828131192,
	}
	const louTours = {
		'Lead help?':785689618530,
		'Performance dash':203895845576
	}
	
	//Put everyselector in file
	const [bulkStatusesSelected, setBulkStatusesSelected] = useState([
		{label: 'Pending', value: false, backendValue: 'pendingStatus'},
        {label: 'Unique', value: false, backendValue: 'uniqueStatus'},
        {label: 'Not Unique', value: false, backendValue: 'nonUniqueStatus'},
        {label: 'Application Created', value: false, backendValue: 'completeApplication'},
        {label: 'Incomplete Application', value: false, backendValue: 'incompleteApplication'}
	]);
	const [setBulkWhatsappMessages, setWhatsappMessages] = useState([
		{label: 'Hot Leads', value: false, backendValue: 'hot_leads'},
		{label: 'Cold Leads', value: false, backendValue: 'cold_leads'},

	]);
	const [bulkIVRStatusesSelected, setBulkIVRStatusesSelected] = useState([
		{label: 'Call Connected', value: false, backendValue: 'Completed'},
		{label: 'Call Disconnected(Hangup)', value: false, backendValue: 'Hangup'},
		{label: 'IVR Scheduled(Queued)', value: false, backendValue: 'Initiated'},
		{label: 'IVR Scheduled', value: false, backendValue: 'Created'},
		{label: 'Call Disconnected(DND)', value: false, backendValue: 'DND User'},
		{label: 'Call Disconnected(Failed)', value: false, backendValue: 'Failed'},
		{label: 'No Answer', value: false, backendValue: 'No Answer'},
		{label: 'Rejected', value: false, backendValue: 'Rejected'},
		{label: 'Other', value: false, backendValue: 'Other'},
		{label: 'Busy', value: false, backendValue: 'Busy'},

	])
	const [bulkResponseSelected, setBulkResponseSelected] = useState([])
	const [bulkIvrCampaignList, setBulkIvrCampaignList] = useState([]);
	const [IvrCampaignSelected, setIvrCampaignSelected] = useState('');
	//Put everyselector in file

	const statusColorMapping = {
		"Call Connected": "rgb(75 170 90)",
		"Call Disconnected(Hangup)": "rgb(168 35 35)",
		"IVR Scheduled(Queued)": "rgb(32 184 170)",
		"IVR Scheduled": '#000',
		"Call Disconnected(DND)": 'rgb(255 0 0)',
		"Call Disconnected(Failed)": 'rgb(244 116 55)',
		"No Answer": 'rgb(146 124 247)',
		"Rejected": 'rgb(251 18 18);',
		"Busy": 'red'
	};
	const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);


	const GAEventsTracker = useGAEventsTracker("Referral Flow")
	const BulkActionGAEventsTracker = useGAEventsTracker("bulkActions")
	
	const conversationHistoryNotificationModalCancel = () => {
		setConversationHistoryNotificationModal(false);
	};
	const trackMoengageEvent = (event,obj) => {
		trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}
	useEffect(() => {
		if (!mitraReducer?.mitraInfo) {
            loginService
                .validateSession()
                .then((response) => {
					if(response.data.mitra.role === 'admin'){
						setIsAdmin(true);
					}
                    mitraDispatch({type: 'LoginInfo', value: response.data.mitra});
					if (response.data.features) {
						mitraDispatch({type:'EXPERIMENTAL_FEATURES', value: response.data.features});
					}
					changeProgressBarState(response.data.mitra.tier);
					setProgressBarSpinner(false);
					if(!window.LOU.user()){
						window.LOU.identify(response.data.mitra.id, {
							mitraPhoneNumber: response.data.mitra.phoneNumber,
							mitraName: response.data.mitra.name,
							city: response.data.mitra.homeCity,
							managerMitraID: response.data.mitra.managerMitraID,
							email: response.data.mitra.email,
							signUpDate: response.data.mitra.createdAt
						});
					}
					if(response.data?.mitra?.managerMitraID == null && new Date(response.data?.mitra?.createdAt) > new Date('2022-11-18') && window.LOU.user() && window.location.href.includes('/home')){
						louTour();
					}
					if(response?.data?.mitra?.id && response?.data?.mitra?.role === 'admin')
					{
						additionalMitraInfo().then(res => {
							if(res?.data?.hasPlacedLead) {
								trackEvent('nps_web', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
							}
						}).catch(err => {
						})
					}
					if (response?.data?.mitra?.role === 'admin' && !response.data.mitra.isBankInfoFilled && window.location.pathname !== '/ml-onboarding' && !localStorage.getItem('isLogin')){
						localStorage.setItem('isLogin', true);
						history.replace('/ml-onboarding');
						notification.info({
							message: 'Please fill the required details to get Onboarded with VAHAN and start working',
							placement: 'topRight',
							duration: 5
						});			
					}
                })
        } else {
            setTrackingUser(mitraReducer?.mitraInfo);
			setIsAdmin(mitraReducer?.mitraInfo.role === 'admin')	
			// to check if mitra has already added bank details
			if (mitraReducer?.mitraInfo.role === 'admin') {
				getMitraInfo(mitraReducer?.mitraInfo?.id).then(res => {
					res.data.length > 0 && (res.data.findIndex(element => element.type === "haveGstNumber") !== -1) ? setShowBankInfo(false) : setShowBankInfo(true)
				}).catch((error) => {
					console.log(error);
				})
			}
			checkBronzeML(mitraReducer?.mitraInfo).then(res => {
				if(res.data.success && res.data.result){
					localStorage.setItem('isBronzeML', true)
				}
			}).catch((error) => {
				console.log(error);
			});
			if(mitraReducer?.mitraInfo?.managerMitraID == null && new Date(mitraReducer?.mitraInfo?.createdAt) > new Date('2022-11-18') && window.LOU.user() &&  window.location.href.includes('/home')){
				louTour();
			}

			loginService.getConversationNotification(mitraReducer?.mitraInfo?.phoneNumber).then((response) => {
				const DateObj = new Date();
				let openTickets = response?.data?.results?.filter((ticket) => ticket.status === 6 &&
				((DateObj.getTime() - Date.parse(ticket.updated_at)) / (1000 * 3600 * 24) < 4));
				if (openTickets.length > 0) {
					setBadgeCount(openTickets.length);
				}
			})
			
			if (rollOutConversationHistory(mitraReducer?.mitraInfo)) {
				loginService.getConversationNotification(mitraReducer?.mitraInfo?.phoneNumber).then((response) => {
					let openTickets = response?.data?.results?.filter((ticket) => ticket.status === 6);
					if (openTickets.length > 0) {
						setTicketInfo(openTickets);
						// setTicketInfo([1,2]);
					}
				}).catch((error) => {
					console.log(error);
				})
			}
		}
		getActiveClientsForFilter().then(data => {
			setClientSelcted(data.data.activeClients)
		});
		recruiterDashboardCandidateCount().then(data => {
			setRecruiterCount(data.data.count)
		})
		
		logEvent('OpenHomeTab', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);

		window.addEventListener("beforeinstallprompt", (e) => {
			// Prevent the mini-infobar from appearing on mobile
			e.preventDefault();
			// Stash the event so it can be triggered later.
			deferredPrompt = e;
			// Update UI notify the user they can install the PWA
			sethomeScreenModal(false);
		});
		
	}, [mitraReducer]);

	useEffect(() => {
		let notifiedTickets;

		if (localStorage.getItem('notifiedTickets')) {
			notifiedTickets = JSON.parse(localStorage.getItem('notifiedTickets'));
		}

		if (notifiedTickets) {
			Object.keys(notifiedTickets).forEach(ticket => {
				if (notifiedTickets[ticket].notifiedAt) {
					let ticketDate = moment(notifiedTickets[ticket].notifiedAt).format('YYYY-MM-DD');
					let yesterdayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
					if (ticketDate !== yesterdayDate) {
						const indexOfTicket = ticketInfo.findIndex(object => {
							return object.id === +ticket;
						});
						if (indexOfTicket !== -1) {
							ticketInfo.splice(indexOfTicket, 1);
						}
					}
				}
			})
		}
		if (ticketInfo.length > 0) {
			let tickets = {}
			ticketInfo.forEach((ticket) => {	
				tickets[ticket.id] = {
					"notifiedAt": moment().format()
				}
			});
			localStorage.setItem('notifiedTickets', JSON.stringify(tickets));
			setConversationHistoryNotificationModal(true);
		}
	}, [ticketInfo.length > 0]);

	useEffect(()=>{
		loginService
                .validateSession()
                .then((response) => {
					changeProgressBarState(response.data.mitra.tier);
                    mitraDispatch({type: 'LoginInfo', value: response.data.mitra});
					setProgressBarSpinner(false);
                }).catch(error => {
					setProgressBarSpinner(false);
				})
	},[progressBarState])
	
	useEffect(() => {
		handleBulkApis();
		getDistinctDateForBulkFeature({feature: selection === 1 ? 'Bulk Referral' : selection === 2 ? 'IVR' : 'Bulk Whatsapp Message'}).then((response) => {
			if(response.data.data){
				setAvailableDateList([...response.data.data, moment().format('YYYY-MM-DD')])
			}
		}
		).catch((error) => {
			console.log(error);
		})

	}, [selection]);

	useEffect(() => {
		if(IvrCampaignSelected){
			getIvrResponsesForCampaign({campaign: IvrCampaignSelected}).then((response) => {
				setBulkResponseSelected(response.data.data);
			}).catch((error) => {
				console.log(error);
			})
		}}, [IvrCampaignSelected]);
	
	const showNotificationForBankInfo = () => {
		notification['info']({
			message: <div>
				<p>Please fill the required details to get Onboarded with VAHAN and start working</p>
				<Button type="primary" onClick={() => {
					history.push({
						pathname: '/ml-onboarding'
					})
					notification.destroy();
				}}>Fill Onboarding Details</Button>
			</div>,
			duration: 0
		});
	}

	const handleGetPhoneNumberClick = async () => {
		if (showBankInfo) {
			showNotificationForBankInfo();
		} else {
			setBulkAction(false);
			// setCandidateForm(true);
			logEvent('AddLead', trackingConstants.EVENT_GROUP_HOME, trackingConstants.EVENT_SCREEN_HOME_TAB);
			trackEvent('add_single_candidate_button_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			history.push('/add-single-candidate');
		}
	};
	const triggerTour = (data) =>{
		switch (parseInt(data.TOUR_ID)) {
			case louToursHomeSequence['Onboarding Video']:
				window.LOU.startTour(louToursHomeSequence['Incentives']);
				break;
			case louToursHomeSequence['Incentives']:
				window.LOU.startTour(louToursHomeSequence['Refer a candidate']);
				break;
			case louToursHomeSequence['Refer a candidate']:
				setTimeout(() => {
					if(document.getElementsByClassName('ant-page-header-heading-title')[0]?.innerHTML =='Add Candidate'){
						window.LOU.startTour(louTours['Lead help?']);
					}
				}, 4000);
				break;
			default:
				break;
		}
	}
	const handleBackArrowClick = () => {
		setCandidateForm(false);
		setBulkCandidateUploadForm(false);
		setLeadAlreadyExistsMsg('');
		setPhoneNumber('');
		setCity(DEFAULT);
		setCompany(DEFAULT);
		setCategory(DEFAULT);
		setJobLocation(DEFAULT);
		setJobs([]);
		setClients([]);
		setBulkCandidateFile('');
		setErrorsFile([]);
		setBulkAction(false)
		setBulkActionType('');
	};	
	
	const mapIVRStatus = (status) => {
		let return_value;
		bulkIVRStatusesSelected.map(ele => {
			if (status == ele.backendValue) {
				return_value = ele.label
			}
		})
		return return_value
	}


	const searchCandidate = (e) => {
		if (selection) {
			if (prevLeads.length === 0) {
				setPrevLeads(leads)
			}
			if (e.length === 0) {
				setLeads(prevLeads);
			}
			const searchResult = prevLeads.filter(ele => {
				return ele.candidatePhoneNumber.includes(e) || ele.candidateName.toLowerCase().includes(e.toLowerCase());
			})
			setLeads(searchResult);
		}
		else {
			if (prevLeads.length === 0) {
				setPrevLeads(leads)
			}
			if (e.length === 0) {
				setIVRLeads(prevLeads);
			}
			const searchResult = prevLeads.filter(ele => {
				return ele.phoneNumber.includes(e) || ele.firstName.toLowerCase().includes(e.toLowerCase());
			})
			setIVRLeads(searchResult);
		}

	}

	const onClickDownloadFile = () => {
		setShowVerifyEmailPopup(true);
	}

	const downloadSheet = async (email) => {
		let clientArray = [], statusArray = [],IVRStatusArray = [], responseArray = []; let filterArray = [];
		clientSelected.map(ele => {
			if (ele.value) {
				clientArray.push(ele.key.toLowerCase())
			}
		})
		bulkStatusesSelected.map(ele => {
			if (ele.value) {
				statusArray.push(ele.backendValue)
			}
		})
		bulkIVRStatusesSelected.map(ele => {
			if (ele.value) {
				IVRStatusArray.push(ele.label)
			}
		})
		bulkResponseSelected.map(ele => {
			if (ele.value) {
				responseArray.push(ele.backendValue)
			}
		})
		setBulkWhatsappMessages.map(ele => {
			if (ele.value) {
				filterArray.push(ele.backendValue)
			}
		})
		if (selection == 1) {
			const data = {
				startDate: moment(startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
				endDate: moment(endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss"),
				client: JSON.stringify(clientArray),
				status: JSON.stringify(statusArray),
				response: JSON.stringify(responseArray),
				mitraManagerId: mitraReducer.mitraInfo.id,
				email: email,
				changeEmail: changeEmail
			}
			downloadBulkUniquenessList(data).then((response) => {
				setAlert({
					display: true,
					text: response.data.message,
					type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
				});
			})
			.catch((error) => {
				console.log(error);
			})
		}
		else if(selection == 2) {
			const data = {
				startDate: moment(startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
				endDate: moment(endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss"),
				response: JSON.stringify(responseArray),
				mitraManagerId: mitraReducer.mitraInfo.id,
				status: JSON.stringify(IVRStatusArray),
				email,
				changeEmail
			}
			downloadBulkIvrResponses(data).then((response) => {
				setAlert({
					display: true,
					text: response.data.message,
					type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
				});
			})
			.catch((error) => {
				console.log(error);
			})
		}else {
			const data = {
				startDate: moment(startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
				endDate: moment(endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss"),
				whatsappStatus: JSON.stringify(filterArray),
				email: email,
				changeEmail: changeEmail
			}
			downloadBulkWhatsappMessage(data).then((response) => {
				setAlert({
					display: true,
					text: response.data.message,
					type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
				});
			})
			.catch((error) => {
				console.log(error);
			})
		}
	}

	const deleteChip = (name, item) => {
		if (name === 'response') {
			const currenArray = [...bulkResponseSelected]
			currenArray.map(ele => {
				if (ele.label === item) {
					ele.value = false;
				}
			})
			setBulkResponseSelected(currenArray)
		}
		else if (name === 'IVRStatus') {
			const currenArray = [...bulkIVRStatusesSelected]
			currenArray.map(ele => {
				if (ele.label === item) {
					ele.value = false;
				}
			})
			setBulkIVRStatusesSelected(currenArray)
		}
		else if (name === 'status') {
			const currenArray = [...bulkStatusesSelected]
			currenArray.map(ele => {
				if (ele.label === item) {
					ele.value = false;
				}
			})
			setBulkStatusesSelected(currenArray)
		}
		else if (name === 'client') {
			const currenArray = [...clientSelected]
			currenArray.map(ele => {
				if (ele.label === item) {
					console.log(name, item)
					ele.value = false;
				}
			})
			setClientSelcted(currenArray)
		}
		else if (name == 'Bulk_Whatsapp') {
			const currenArray = [...setBulkWhatsappMessages]
			currenArray.map(ele => {
				if (ele.label === item) {
					ele.value = false;
				}
			})
			setWhatsappMessages(setBulkWhatsappMessages)
		}
	}

	const onClickTags=(label,index)=>{
		if(label === 'Status'){
			bulkStatusesSelected[index].value=!bulkStatusesSelected[index].value;
		}
		if(label === 'Clients'){
			clientSelected[index].value=!clientSelected[index].value;
		}
		if(label === 'IVR Status'){
			bulkIVRStatusesSelected[index].value=!bulkIVRStatusesSelected[index].value;
		}
		if(label === 'Response'){
			bulkResponseSelected[index].value=!bulkResponseSelected[index].value;
		}
		if(label == 'Bulk Message'){
			setBulkWhatsappMessages[index].value = !setBulkWhatsappMessages[index].value;
		}
		forceUpdate()
	}

	const handleBulkApis=async()=>{
	setLoading(true)
	let clientArray = [], statusArray = [],IVRStatusArray = [], responseArray = [],filterArray=[];
            clientSelected.map(ele => {
                if (ele.value) {
                    clientArray.push(ele.key.toLowerCase())
                }
            })
            bulkStatusesSelected.map(ele => {
                if (ele.value) {
                    statusArray.push(ele.backendValue)
                }
            })
            bulkIVRStatusesSelected.map(ele => {
                if (ele.value) {
                    IVRStatusArray.push(ele.label)
                }
            })
            bulkResponseSelected.map(ele => {
                if (ele.value) {
                    responseArray.push(ele.backendValue)
                }
            })
			setBulkWhatsappMessages.map(ele => {
				if (ele.value) {
					filterArray.push(ele.backendValue)
				}
			})
            if (mitraReducer?.mitraInfo) {
                const data = {
                    startDate: moment(startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
					endDate: moment(endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss"),
                    client: JSON.stringify(clientArray),
                    status: JSON.stringify(statusArray),
					response: JSON.stringify(responseArray),
                    mitraManagerId: mitraReducer.mitraInfo.id,
					whatsappStatus: JSON.stringify(filterArray)
                }
				if(selection == 1){
					await getBulkUniquenessList(data).then((response) => {
						if (response.data.data.length > 0){
							setLeads(response.data.data)
							setPrevLeads(response.data.data)
						}else{
							setLeads([])
						}	
						setfilterSider(false)
					}).catch(e => {
						message.error(e);
					})
				}else if(selection == 2){
					await getIvrCampaignList().then((response) => {
						if(response.data.data.length > 0){
							setBulkIvrCampaignList(response.data.data)
						}
					}).catch(e => {
						message.error(e);
					})

					data.status= JSON.stringify(IVRStatusArray);
					await getScheduledIVRList(data).then((response) => {
						if (response.data.data.length > 0){
							setIVRLeads(response.data.data)
							setPrevLeads(response.data.data)
							forceUpdate()
						}else{
							setIVRLeads([])
						}
						setfilterSider(false)
					}).catch(e => {
						message.error(e);
					})
				}else{
					await getWhatsappLeads(data).then((response) => {
						if(response.data.data){
							setBulkWhatsappSelected(response.data.data)
						}
					}).catch(e => {
						// message.error(e);
					})
				}
                setLoading(false)
            }
	}

	const handleDateChange = (date) => {
		if(date === null){
            setStartDate(moment()).startOf('day');
			setEndDate(moment()).endOf('day');
        }
        else{
			setStartDate(moment(date).startOf('day'));
			setEndDate(moment(date).endOf('day'));
        }
	}

	const clearAll=  async()=>{
		if(selection == 1){
			await clientSelected.map(item => {
				item.value=false;
			})
			await bulkStatusesSelected.map(item => {
				item.value=false;
			})
		}else if(selection == 2){
			await bulkIVRStatusesSelected.map(item => {
				item.value=false;
			})
			await bulkResponseSelected.map(item => {
				item.value=false;
			})
		}else{
			await setBulkWhatsappMessages.map(item => {
				item.value=false;
			})
		}
		setStartDate(moment())
		setEndDate(moment())
		setfilterSider(false)
		setonClearTrigger(!onClearTrigger);
	}
	useEffect(() => {
		handleBulkApis()
	}, [onClearTrigger]);

	useEffect(()=> {
		// to check if there are any incoming notifications
		getCallNotification().then(res=> {
			console.log(res.data);
			setIncomingCallsPopupData(res.data);
			res.data.length ? setShowIncomingCallsPopup(true): setShowIncomingCallsPopup(false)
		}).catch((error) => {
			console.log(error);
		})

		// callNotificationData.length ? setShowIncomingCallsPopup(true):setShowIncomingCallsPopup(false);

		// to check if url contains navigateTo param(from jobs page)
		const parsedUrl = queryString.parse(window.location.search);
		if (parsedUrl?.navigateTo === "addSingleCandidate") {
			handleGetPhoneNumberClick();
		}

		// Adding high intent events
		fetchActivatedAndReferredLeads().then(res=> {
			// adding high intent events
			if (res.data.referredLeads > 2 && mitraReducer.mitraInfo.leadsReferred==='false') {
				highIntentEvents('LeadReferred');
				window.fbq('trackCustom', 'LeadReferred',{
					phone: mitraReducer?.mitraInfo?.phoneNumber,
					city: mitraReducer?.mitraInfo?.homeCity,
					name: mitraReducer?.mitraInfo?.name,
					email: mitraReducer?.mitraInfo?.email,
					fbclid: mitraReducer?.mitraInfo?.fbclid
				});
				updateLeadsBasedEvents('leadsReferred', 'true').then().catch();
			}
			if (res.data.activatedLeads > 0 && !mitraReducer.mitraInfo.leadsActivated==='false') {
				highIntentEvents('LeadActivated');
				window.fbq('trackCustom', 'LeadActivated',{
					phone: mitraReducer?.mitraInfo?.phoneNumber,
					city: mitraReducer?.mitraInfo?.homeCity,
					name: mitraReducer?.mitraInfo?.name,
					email: mitraReducer?.mitraInfo?.email,
					fbclid: mitraReducer?.mitraInfo?.fbclid
				});
				updateLeadsBasedEvents('leadsActivated', 'true').then().catch();
			}
			if(window.LOU.user() && res.data.referredLeads >= 2 && window.LOU.user().properties.tours_started_history[louTours['Lead help?']]) {
				window.LOU.startTour(louTours['Performance dash']);
			}
		}).catch(err => console.log(err));
		
		// clearing acquisition source, fbclid and gclid from localstorage once user comes to homepage post signup
		localStorage.removeItem('acquisitionSource');
		localStorage.removeItem('gclid');
		localStorage.removeItem('fbclid');
	}, []);
	
	const louTour = () =>{
		if(window.LOU && window.LOU.listen){
			window.LOU.listen('COMPLETE_TOUR', (event) => {
				const data = event.detail.data; // object containing relevant event data
				triggerTour(data);
			})
		}
		let LouUserStartedTours = window.LOU.user().properties.tours_started_history;
		if(!LouUserStartedTours){
			LouUserStartedTours={}
		}
		setTimeout(() => {
			if(!window.location.href.includes('/home') ){
				return;
			}
			if(!LouUserStartedTours[louToursHomeSequence['Onboarding Video']]){
				window.LOU.startTour(louToursHomeSequence['Onboarding Video']);
			}else {
				let data = Object.values(louToursHomeSequence).filter(ele=>{
					return LouUserStartedTours[ele]
				});
				if(data.length){
					triggerTour({TOUR_ID: data[data.length -1]});
				}
			}
		}, 4000);
	}
	const commonTag = (label,arrObj) =>{
			return <div className='tag-containers'>
				<div>{label}</div>
				{label === "Date" ?
					<Space>
						<DatePicker 
							size="small"
							picker={type}
							disabledDate={(current) => {return disabledDateForBulkFeature(current, availableDateList)}} 
							onChange={handleDateChange} 
							defaultValue={startDate} 
							value={startDate} 
							allowClear={false} />
					</Space> : 
					<div className='tag-wrapper-container'>
						{arrObj.map((item,index)=>{
							return <Tag className={!item.value ? 'tags':'tags tag-active'}
							key={index}
							// closable={item.value}
							onClick={()=>onClickTags(label,index)}
							>
								{label === "Clients" ? getDisplayableClientName(item.label) : item.label}
							</Tag>
						})}
					</div>
				}
				
			</div>
		
	}

	const onChangeTabs =(index)=>{
		if(index ==='1'){
			setSelection(1)
			setBreadcrumb([{link: '/', label: 'Home'},{link: '/', label: 'Bulk Referrals'}])
		}else if(index == 2){
			trackEvent('clicked_bulk_ivr', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			setSelection(2)
			setBreadcrumb([{link: '/', label: 'Home'},{link: '/', label: 'Bulk IVR Calls'}])
		}else{
			trackEvent('clicked_whatsap_message', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			setSelection(3)
			setBreadcrumb([{link: '/', label: 'Home'},{link: '/', label: 'Bulk Whatsapp Message'}])
		}
	}

	const quickAccessLinkClicked = (item) => {
		mitraDispatch({type: 'UTILITIES', value: {currentMenu: item.currentMenu}});
		trackEvent(`click_quick_access_${item.key}`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		history.push({pathname: item.link})
	}

	const handleIvrCampaignChange = (e) => {
		setIvrCampaignSelected(e.target.value);
	}

	const isHomePageBannerVisible = () => {
		//for Valentine week offer
		if(moment() < moment('2024-03-12').startOf('day') && mitraReducer && mitraReducer.mitraInfo && mitraReducer.mitraInfo.role === 'admin') {
			return true 
		}
		return false
	}

	if (!candidateForm) {
		return (
			<>
				{conversationHistoryNotificationModal && ticketInfo.length > 0 && 
				<Modal
					style={{ top: 20 }}
					title="Help Ticket Update"
					visible={conversationHistoryNotificationModal}
					onCancel={conversationHistoryNotificationModalCancel}
					footer={
						<div className="flex justify-end">
							<Button
								type="primary"
								onClick={() => {
									history.push(`/help`)
								}}
							>
								Go to Help & Support
							</Button>
						</div>
					}
				>
					<div>
						<h5>Please Reply to the {ticketInfo.length > 5? '':'Below'} Tickets for speedy Resolution</h5>
						{
							ticketInfo.length > 5 ? null :
							<div style={{flexDirection:'row',display:'flex', marginTop: '15px',}}>
								{
									ticketInfo.map((ticket, index) => {
										return (
											<div key={index} style={{margin:'0px 5px'}} >{ticket.id}</div>
										)
									})
								}
							</div>
						}
					</div>
				</Modal>
				}
				<IncomingCallsPopup
				visible={showIncomingCallsPopup}
				closeIncomingCallsModal={setShowIncomingCallsPopup}
				incomingCallDetails = {incomingCallsPopupData}
				></IncomingCallsPopup>
				<div className="Home mainContentZ" id="home">
					<div className="header">
						<div className='mitra-badge'>
							{
								mitraReducer?.mitraInfo?.tier === 'initiated' ? 
								<img src='/image/progress-bar-initial.png' alt='level' style={{height:'30px'}}></img> : 
								mitraReducer?.mitraInfo?.tier === 'bronze' ? 
								<img src='/image/progress-bar-bronze.png' alt='level' style={{height:'30px'}}></img> : 
								mitraReducer?.mitraInfo?.tier === 'silver' ? 
								<img src='/image/progress-bar-silver.png' alt='level' style={{height:'30px'}}></img> : null
							}
						{/* <img src='/image/progress-bar-bronze.png' alt='level' style={{height:'30px'}}></img> */}
						{/* <img src='/image/progress-bar-bronze.png' alt='level' style={{height:'30px'}}></img>
						<img src='/image/progress-bar-silver.png' alt='level' style={{height:'30px'}}></img> */}
						</div>

						<div className="header-text">
							<div className="welcome">Welcome</div>
							<span className="mitra-name">{mitraReducer?.mitraInfo?.name}</span>
						</div>
						{isAdmin && <><PageHeaderComponent breadcrumb={breadcrumb} />
							{/* <SearchComponent placeholder={"Search Candidates" } searchList={searchCandidate}/> */}
							<Button onClick={()=>{
								history.push("/search-page")
							}} style={{color:'#D34612',display:'flex',alignItems:'center'}} icon={<SearchOutlined />}  danger ghost size={'large'} >Search</Button>
							</>
						}
					</div>
					<div className="carousel-class">
						<Carousel autoplay speed={300}>
						{
							isHomePageBannerVisible() ? 
								<div className='home-page-banner-container'>
									<div className='banner-data'>
										<p>Due to consecutive bank holidays from March 8th to March 10th, we'll be able to process the payouts on March 11th.</p>
									</div>
								</div>
							: null
						}
							<div className='home-page-banner-container'>
								<div className='banner-data'>
									<p> 
										New stores have been added to Blinkit Job demand at NCR Locations.
										<a style={{color: 'yellow', margin: '0px 5px'}} rel="noreferrer" href="https://docs.google.com/spreadsheets/d/1DRcXn0RIl-GIo2ESpCt31OThkK3M_k9-aqLkaPDDTq0/edit?usp=sharing" target="_blank" > Click here</a>
										to check.
									</p>
								</div>
							</div>
						</Carousel>
					</div>
					<div className='btn-wrapper'>
						<Button className='btn-container' onClick={handleGetPhoneNumberClick}>
							<div className='CTA-context'>
								<UserAddOutlined />
								<span>
									Add Single Candidate
								</span>
							</div>
							<RightCircleFilled />
						</Button>
						{
							isAdmin &&
							<Button className='btn-container' onClick={() => 
								{
									if (showBankInfo)
										showNotificationForBankInfo();
									else {
										BulkActionGAEventsTracker("ClickOnBulkActions");
										setShowSideSheet(true);
										trackEvent('bulk_actions_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
									}
								}
							}>
								<div className='CTA-context'>
									<UsergroupAddOutlined />
									<span>
										Bulk Actions
									</span>
								</div>
								<PlusCircleFilled />
							</Button>
						}
					</div>
					{
						isAdmin && showBankInfo &&
						<div className='bank-detils-wrapper'>
							<div className='section-1'>
								<InfoCircleFilled style={{ color: 'blue' }}></InfoCircleFilled>
								<Text>Please fill the required details to get Onboarded with VAHAN and start working</Text>
							</div>
							<div className='section-2'>
								<button className='button-content' onClick={() => {
									trackEvent('click_on_add_ml_onboarding_details', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
									history.push({
										pathname: '/ml-onboarding'
									})
								}}>
									<FileAddOutlined />
									Onboarding Details
								</button>
							</div>
						</div>
					}
					<Card className='card-recruiter-dashboard' onClick={()=>{
						history.push({
							pathname: '/recruiter',
							state: {
								recruiterCounts: recruiterCount
							}
						})
						}} hoverable>
						<div className='card-text-view'>
							<CopyFilled  style={{color:"#D34612"}}/>
							<div className='copy-innertext'>
								<Text strong>Leads for follow ups</Text>
								<Text type="secondary" style={{fontSize:'0.8em'}}>Application which need urgent attention</Text>
							</div>
						</div>
						<div className="warning-message-at-risk-container message-container-update" >
							<div className="warning-message-atrisk">
									<Text strong> Start following up now </Text>
									<div className='lead-total'>
										<Text style={{color:'#D34612'}} underline>{recruiterCount} Leads</Text>
										<RightCircleOutlined  style={{color:"#D34612"}} />
									</div>
							</div>
                        </div>
					</Card>
					
					<Card style={{margin:'10px'}} bodyStyle={{padding:'12px'}}>
						<div className='quick-access-heading'>Quick Access</div>
						<div className='quick-access-item-container'>
							{
								quickLinks.map((item, index) => {
									if (!mitraReducer?.mitraInfo?.managerMitraID) {
										// if user is a manager, showing Incentives quick link

										if (item.visible === 'teamMembers') return null;
									} else {
										// if user is a team member. showing Action Neede quick link

										if (item.visible === 'managers') return null;
									}
									return <div key={index} onClick={() => {quickAccessLinkClicked(item)}}>
										<div className={`quick-link-icon-container ${item.key === 'help_and_support' && badgeCount > 0 ? "active-badge" : ""}`} style={{cursor: 'pointer' }}>
											{item.key === 'help_and_support' && badgeCount > 0 ?
												<Badge count={badgeCount} offset={[20, 0]}/>
											: null}
											{item.icon}
										</div>
										<Text style={{textAlign: 'left', fontWeight: 'bold', cursor: 'pointer'}}>{item.heading}</Text>
									</div>
								})
							}
						</div>	
					</Card>
					{
						!mitraReducer?.mitraInfo?.managerMitraID && mitraReducer?.experimentalFeatures?.["progress-bar"] === 'enable' ? (
							<Spin spinning={progressBarSpinner}>
							<div style={{margin:'10px'}}>
								{
									!mitraReducer?.mitraInfo?.tier ? 
									<ProgressBar setProgressBarState={changeProgressBarState} /> : <ProgressBarDetails  setProgressBarState={changeProgressBarState}/>
								}
							</div>
							</Spin>
						): null
					}
					{isAdmin && <Tabs className='tabClass' onChange={onChangeTabs}  defaultActiveKey="1" centered>
						<TabPane tab="Bulk Referrals" key="1">
							<TabularView selection={1} leads={leads} displayBulkUniqueStatus={displayBulkUniqueStatus} mitraReducer={mitraReducer}
							mitraDispatch={mitraDispatch} startDate={startDate} endDate={endDate} clientSelected={clientSelected} bulkStatusesSelected={bulkStatusesSelected} 
							history={history} getBulkUniquenessList={getBulkUniquenessList} setLeads={setLeads} setPrevLeads={setPrevLeads}
							setShowBanner={setShowBanner} setStartDate={setStartDate} setEndDate={setEndDate} setShowDateChip={setShowDateChip} setCategoryShowFilterPopup={setCategoryShowFilterPopup}
							setStatusShowFilterPopup={setStatusShowFilterPopup} setIVRStatusShowFilterPopup={setIVRStatusShowFilterPopup} setResponseShowFilterPopup={setResponseShowFilterPopup}
							loading={!loading} deleteChip={deleteChip} downloadSheet={onClickDownloadFile} setBulkStatusesSelected={setBulkStatusesSelected} setClientSelcted={setClientSelcted}
							setfilterSider={setfilterSider} handleBulkApis={handleBulkApis} showBanner={showBanner}/>
						</TabPane>

						<TabPane tab="Bulk IVR Calls" key="2">
							<TabularView  selection={2} ivrLeads={ivrLeads}  mitraDispatch={mitraDispatch} startDate={startDate} endDate={endDate} mitraReducer={mitraReducer}
							mapIVRStatus={mapIVRStatus} bulkIVRStatusesSelected={bulkIVRStatusesSelected} bulkResponseSelected={bulkResponseSelected} statusColorMapping={statusColorMapping} 
							history={history} setPrevLeads={setPrevLeads} getScheduledIVRList={getScheduledIVRList} setIVRLeads={setIVRLeads}
							setStartDate={setStartDate} setEndDate={setEndDate} setShowDateChip={setShowDateChip} setCategoryShowFilterPopup={setCategoryShowFilterPopup}
							setStatusShowFilterPopup={setStatusShowFilterPopup} setIVRStatusShowFilterPopup={setIVRStatusShowFilterPopup} setResponseShowFilterPopup={setResponseShowFilterPopup}
							loading={!loading} deleteChip={deleteChip} downloadSheet={onClickDownloadFile} 
							setfilterSider={setfilterSider} handleBulkApis={handleBulkApis} showBanner={false}/>
						</TabPane>
					{
						whatsappFeatureEnabledMitras(mitraReducer?.mitraInfo) && <TabPane tab="Bulk Whatsapp Message" key="3">
						<TabularView  selection={3} 
						ivrLeads={ivrLeads}  
						mitraDispatch={mitraDispatch} 
						startDate={startDate} endDate={endDate}
						mitraReducer={mitraReducer}
						bulkResponseSelected={bulkResponseSelected} 
						statusColorMapping={statusColorMapping} 
						history={history} 
						setPrevLeads={setPrevLeads} 
						setStartDate={setStartDate} 
						setEndDate={setEndDate}
						setShowDateChip={setShowDateChip} 
						setCategoryShowFilterPopup={setCategoryShowFilterPopup}
						setStatusShowFilterPopup={setStatusShowFilterPopup} 
						setResponseShowFilterPopup={setResponseShowFilterPopup}
						loading={!loading} 
						deleteChip={deleteChip} 
						downloadSheet={downloadSheet} 
						setfilterSider={setfilterSider} 
						handleBulkApis={handleBulkApis} 
						showBanner={false}
						setBulkWhatsappMessages={setBulkWhatsappMessages}
						bulkWhatsappSelected={bulkWhatsappSelected}
						/>
					</TabPane>

					}
						
					</Tabs>}
				</div>
				{showSideSheet && 
				<SideSheet sidername={header} onDismiss={() => {setShowSideSheet(false);setHeader("Bulk Actions")}}>
					<BulkActionSidesheet setHeader={setHeader}setOpen={setShowSideSheet} />
				</SideSheet>}
				{filterSider && 
				<SideSheet submit={{disabled:false,onClick:handleBulkApis}}
				clearAll={{disabled:false,onClick:clearAll}}
				sidername={"Filter Leads"} 
				onDismiss={() => {setfilterSider(false)}}>
					<div className='filter-home-wrapper'>
						{commonTag("Date",null)}
						{selection == 1 && commonTag("Status",bulkStatusesSelected)}
						{selection == 1 && commonTag("Clients",clientSelected)}
						{selection == 2 && commonTag("IVR Status",bulkIVRStatusesSelected)}
						{
							selection == 3 && commonTag("Bulk Message",setBulkWhatsappMessages)
						}
						{
							selection == 2 && <div className='tag-containers'>
							<div>Select Campaigns</div>
							<div className='tag-wrapper-container'>
							<select
                                name="ivrCampaign"
                                onChange={handleIvrCampaignChange}
                                value={IvrCampaignSelected}
								style={{marginBottom:'10px'}}
                            >
								<option value={DEFAULT}>
                                    Select Campaign
                                </option>
							{
								bulkIvrCampaignList.map((campaign, index) => (
									<option className="Dropdown-option" key={index} value={campaign}>
										{campaign}
									</option>
								))
							}
							</select>
							</div>
							{
								bulkResponseSelected &&  commonTag("Response",bulkResponseSelected)
							}
						</div>
						}
						
					</div>
				</SideSheet>}
				{
					showVerifyEmailPopup && 
					(
						<VerifyEmailPopup mitra={mitraReducer?.mitraInfo} visible={showVerifyEmailPopup} closePopup={setShowVerifyEmailPopup} downloadFunction={downloadSheet} changeEmail={changeEmail} setChangeEmail={setChangeEmail}></VerifyEmailPopup>
					)
				}
			</>
		)
	} else {
		return (
			<>
				{
					candidateForm && <div className="Home mainContentZ">
						<PageHeader
							className="site-page-header"
							onBack={(e) => handleBackArrowClick(e)}
							title="Add Candidate"
						/>
						<BasicInfoForm mitra={mitraReducer?.mitraInfo} mitraReducer={mitraReducer} mitraDispatch={mitraDispatch} setAlert={setAlert} />
					</div>
				}
			</>
		);
	}
};

export default Home;
