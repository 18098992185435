import React, {useState,useEffect,useContext} from 'react';
import { Link, useHistory } from 'react-router-dom';
import PageHeaderComponent from './common/PageHeader';
import SearchComponent from './common/globalSearch';
import {UserAddOutlined } from '@ant-design/icons';
import { PageHeader,Anchor,Card,Typography,Empty,Pagination, message, Button} from 'antd';
import {getCandidatesAndLeads} from './../service/commonService'
import Lead from './common/candidateApplication/CandidateApplicationCard.component';
import CandidateProfilePopup from './leads/candidateProfilePopup.component'
import {AppContext} from '../App';
import axios from 'axios';
import SideSheet from "./common/SideSheet"
import SideInput from "./common/SiderInput"
import moment from "moment";
import BasicInfoForm from './BasicInfoForm';
import RaiseIssueSider from './help/RaiseIssueSider.component';
import { aBTestingRollOut, jobseekerApplicationInML, suiteCrmMLView } from '../../src/constants/utils'
import * as loginService from '../service/loginService';

import '../css/searchPage.scss';
import CandidateProfilePopupV2 from './leads/candidateProfilePopupV2Components/candidateProfilePopupV2';
import { ROLES } from './common/roles';

const {Paragraph}=Typography;
const { Title , Text} = Typography;

const SearchPage = (props) => {
  const {mitraReducer,mitraDispatch, setAlert,setSpin, userRole } = useContext(AppContext);

  const [searchListArr, setsearchListArr] = useState([])
  const [searchElement, setsearchElement] = useState('')
  const [searchElementDuplicate, setsearchElementDuplicate] = useState('')
	const [selectedLead, setSelectedLead] = useState({});
	const [showDetail, setShowDetail] = useState(false);
	const [referAgain, setReferAgain] = useState(false);
	const [currentJob, setCurrentJob] = useState({});
  const [submitdisable, setSubmitdisable] = useState(true);
  const [alternateNumber, setAlternateNumber] = useState();
	const [candidateBasicInfo, setJobLeadsTab] = useState(false);
	const [basicInfo, setBasicInfo] = useState(null);
	const [totalApplicationCountCandidates, setTotalApplicationCountCandidates] = useState(0)
	const [totalApplicationCountLeads, setTotalApplicationCountLeads] = useState(0)
	const [currentPageCandidate, setCurrentPageCandidate] = useState(1)
	const [currentPageLeads, setCurrentPageLeads] = useState(1)
	const [pageLimit, setPageLimit] = useState(10);
  const [selectedScheduledInterviewId, setSelectedScheduledInterviewId] = useState([]);
  const [selectedRecommendationHistoryId, setSelectedRecommendationHistoryId] = useState([]);
  const [viewDetailLead, setViewDetailLead] = useState({});
  const [raiseIssueSider, setRaiseIssueSider] = useState(false);
  const history = useHistory();

  const searchApiCall =()=>{
    let offsetLeads=(searchElement !== searchElementDuplicate) ? 0 : currentPageLeads * pageLimit - pageLimit;
    let offsetCandidate=(searchElement !== searchElementDuplicate) ? 0 : currentPageCandidate * pageLimit - pageLimit;

    let data = {
      search:searchElement,
      widgetsToInclude:JSON.stringify([{"type":"leads","limit":pageLimit,"offset":offsetLeads},{"type":"candidates","limit":pageLimit,"offset":offsetCandidate}]),
      includeMitraAppApplications: jobseekerApplicationInML(mitraReducer?.mitraInfo),
      showAllApplications: userRole === ROLES.DC
    }
    if(searchElement !== ''){
      setSpin({
        loading: true, //false or true when actived
        delay: null,
        tip: 'Loading...'  //Loading copy here
      })
      getCandidatesAndLeads(data).then((response)=>{
        setSpin({
          loading: false, //false or true when actived
          delay: null,
          tip: 'Loading...'  //Loading copy here
        })
        setsearchListArr(response.data);
        setTotalApplicationCountCandidates(Number(response.data.candidates.count));
        setTotalApplicationCountLeads(Number(response.data.leads.count));
        setsearchElementDuplicate(searchElement);
      }).catch((error) => {
        setSpin({
          loading: false, //false or true when actived
          delay: null,
          tip: 'Loading...'  //Loading copy here
        })
        message.error(error.response.data.message);
      });
    }
  }

  useEffect(() => {
    if(history.location?.state?.candidatePhoneNumber || localStorage.getItem('currentPhoneNumber'))
    {
      const phoneNumber = history.location?.state?.candidatePhoneNumber || localStorage.getItem('currentPhoneNumber');
      setsearchElement(phoneNumber);
    }
  }, [])

  useEffect(() => {
    searchApiCall();
  }, [searchElement,currentPageCandidate,currentPageLeads])

  const onPhoneNumberChange = async (e) => {
		if(e.target.value.length > 10) {
			setAlert({
        display:true,
        text:'Please enter only the 10 digit mobile no. without any prefix (+91 or 0)',
        type:'error'
      });
			setSubmitdisable(true);
			return;	
		}
		else {
			const alternatePhone = e.target.value;
			const regex = new RegExp("^[0-9]{10}$");
			if(regex.test(alternatePhone)){
				setAlternateNumber(alternatePhone);
				setSubmitdisable(false);
			} else {
				setSubmitdisable(true);
			}
		}
    }

  const submitLeadFilter=(lead)=>{
		const data = new FormData();

		data.append('userId', selectedLead.id);
		data.append('oldPhoneNumber', currentJob.phoneNumber);
		data.append('userPhoneNumber', currentJob.phoneNumber);
		data.append('phoneNumber', alternateNumber);
		data.append('desiredRole', currentJob.jobType);
		data.append('jobId', currentJob.jobId);
		data.append('sourceName', 'mitra-leader');
		data.append('jobDemandId', currentJob.jobDemandId);
		data.append('sourceId',currentJob.mitraId);
		data.append('name', currentJob.name);
		data.append('clientPreference', currentJob.companyName);
		data.append('cityPreference', currentJob.companyCity);
		data.append('role', currentJob.role);
		data.append('companyCity', currentJob.companyCity);
		data.append('managerMitraID', mitraReducer?.mitraInfo.managerMitraID ?  mitraReducer?.mitraInfo.managerMitraID : currentJob.mitraId );
		let url = process.env.REACT_APP_BACKEND_URL + '/referAgain';
	
		let response = axios.post(url, data, {withCredentials: true});
		setAlert({
			display:true,
			text: 'Trying to refer with other number....'
		});
		response.then(function (response) {
			if (!response.data.status) {
				let message = response.data.message ? response.data.message : 'Sorry, try another number.';
				setAlert({
					display:true,
					text: message,
					type:'error'
				});	
			} else {
				setAlert({
					display:true,
					text:'successfully referred!!',
					type:'success'
				});
			
				setReferAgain(false);
			}
		});

        setSubmitdisable(true);
    }
  
  return<>
  {!basicInfo && <div className="SearchPage mainContentZ">
    <Anchor>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <PageHeader
            ghost={false}
            className="search-page-header"
            title={"Search by Candidate Name/Phone Number"}
            onBack={() => window.history.back()}
          />
          {suiteCrmMLView(mitraReducer?.mitraInfo?.id) && localStorage.getItem('isFromSuiteCrm') ?
            <Button type="primary" style={{ marginRight: '25px' }} onClick={() => {
              loginService
                .inValidateSession()
                .then((response) => {
                  localStorage.clear()
                  window.location.reload();          
                })
                .catch((error) => {
                  console.log('Error logging out: ', error);
                });
            }
            }>Logout</Button>
            : null
          }</div>
      <SearchComponent searchItem={setsearchElement} placeholder={"Search by Candidates Name/Phone Number" } disabled={userRole === ROLES.DC}/>
   </Anchor>
      {searchElement !== '' &&
      <>
          <div  key="leads-pagination-v2" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <Title style={{margin:10,textAlign:'left'}} level={5}>Candidates</Title>
            {
								totalApplicationCountCandidates > 0 ? 
									<Pagination
                  key="candidate-pagination"
									total={totalApplicationCountCandidates > 0 && totalApplicationCountCandidates}
									showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
									defaultPageSize={pageLimit}
									defaultCurrent={currentPageCandidate}
									responsive={true}
									showLessItems={true}
									onChange={(page, pagesize) => {setCurrentPageCandidate(page)}}
									showSizeChanger={false}
									/>
								: null
							}
          </div>
          <Card style={{maxHeight:'60vh',overflow:'scroll'}}> 
              {searchListArr?.candidates?.count > 0 ? searchListArr?.candidates?.data?.map((lead,index)=>{
              return  <Lead
                key={index}
                lead={lead}
                setShowDetail={setShowDetail}
                setSelectedLead={setSelectedLead}
                setReferAgain={setReferAgain}
                setCurrentJob = {setCurrentJob}
                setSpin={setSpin}
                mitra = {mitraReducer?.mitraInfo}
                setSelectedScheduledInterviewId={setSelectedScheduledInterviewId}
                setSelectedRecommendationHistoryId={setSelectedRecommendationHistoryId}
              />
            }) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
              <span>
                Sorry! No result found for <Text strong>{searchElement}</Text>
              </span>
            }/>}
          </Card>
          
     </>}
    <div className="bottom-detail-wrapper">
        {
        showDetail && (<CandidateProfilePopupV2 
          selectedScheduledInterviewId={selectedScheduledInterviewId} 
          selectedRecommendationHistoryId={selectedRecommendationHistoryId}
          visible={showDetail} 
          setShowDetail={setShowDetail} 
          lead={selectedLead}
          setRaiseIssueSider={setRaiseIssueSider}
					setViewDetailLead={setViewDetailLead}
          ></CandidateProfilePopupV2>)
        }
		</div>
  </div>
  }
  {referAgain &&
    <SideSheet submit={{disabled:submitdisable,onClick:submitLeadFilter}} sidername='Add New Number' onDismiss={() => setReferAgain(false)}>
      <div className="siderInnerContainer1">
        <div className="outerContainer">
        <SideInput onChange={onPhoneNumberChange} placeholder={'Example : 8884228005'}
          imgsrc={'phone.svg'} labelname={"* Add Alternate Number"}/>
          
        
        <SideInput readOnly={true} value={selectedLead.phoneNumber}
          imgsrc={'phone.svg'} labelname={"Contact Number"}/>
        </div>
      </div>
    </SideSheet>
  }
  {basicInfo &&
        <div>
          <PageHeader
            ghost={false}
            className="search-page-header"
            title={"Add Candidate"}
            onBack={() => setBasicInfo(null)}
      />
          <BasicInfoForm mitra={mitraReducer?.mitraInfo} name={basicInfo.name} phoneNumber={basicInfo.phoneNumber} />
        </div>
}
    {raiseIssueSider &&
      <RaiseIssueSider
        closeSider={() => setRaiseIssueSider(false)}
        mitraReducer={mitraReducer}
        selectedLead={viewDetailLead}
      />
    }
  </>
};

export default SearchPage;
