import React from "react";
import { DatePicker, Button, PageHeader, Modal } from 'antd';
import { DownOutlined, FileDoneOutlined, InfoCircleOutlined, GlobalOutlined, CloudDownloadOutlined, CloseOutlined, PhoneOutlined, UserOutlined, LinkOutlined, SearchOutlined, DownloadOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";
import ShareOnPlatform from "../common/shareOnPlatform.component";
import SubmitPopup from "../common/SubmitPopup.component";

const JobPosterPage = (props) => {
    const {
        setViewLocations,
        setJobPostingId,
        jobPostingClientSelected,
        jobPostingId,
        setShowShareOnPlatform,
        jobPostingData,
        showShareOnPlatform,
        nameError,
        register,
        handleNameChange,
        phoneNumberError,
        handleNumberChange,
        createJobPostingResponse,
        phoneNumber,
        showAPIError,
        APIError,
        showSubmitPopup,
        setShowSubmitPopup,
        submitText,
        name
    } = props;
    return (
        <div className="jobs-page">
            <div className="header">
                <div className="header-text">
                    <span className="small-size-screen" style={{cursor: 'pointer'}} onClick={(e) => {setViewLocations(true); setJobPostingId('');}} >
                    <ArrowLeftOutlined /></span>
                    <span className="big-screen">
                        <span onClick={(e) => {setViewLocations(true); setJobPostingId('');}} style={{cursor: 'pointer'}}>Jobs</span> &nbsp;&nbsp;
                    <ArrowRightOutlined style={{color: '#666565'}} />&nbsp;&nbsp;
                    </span>
                    <span className="requirements-title">Job Post for {jobPostingClientSelected}</span>
                </div>
            </div>
            <hr style={{color: '#000000', margin: '10px 30px'}} />
            <div className="row">
                <div className="jobPostingLink col-9">
                    <span>{process.env.REACT_APP_PUBLIC_URL}/job-postings/{encodeURIComponent(jobPostingClientSelected)}/{jobPostingId}</span>
                </div>
                <div className="col-2">
                    <button className="shareLink" onClick={() => {setShowShareOnPlatform(true); trackEvent('share_website_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);}}>
                        Share Website
                    </button>
                </div>
                <ShareOnPlatform jobPostingData={jobPostingData} visible={showShareOnPlatform} setShowPopup={setShowShareOnPlatform}
                    webLink={`${process.env.REACT_APP_PUBLIC_URL}/job-postings/${jobPostingClientSelected}/${jobPostingId}`}>
                </ShareOnPlatform>
            </div>
            <div title='Preview poster' className="preview">
                {jobPostingData && jobPostingData.imageUrl ? <img className="companyName" alt={jobPostingClientSelected} src={jobPostingData.imageUrl} /> : ''}

                <div className="input-fields">
                    {
                        nameError ?
                            <div className="error">Please enter Name</div>
                            : null
                    }
                    <input
                        className="dropdown-control"
                        ref={register({required: true, minLength: 2})}
                        type="text"
                        placeholder="Enter your full name"
                        name="name"
                        onChange={handleNameChange}
                        disabled
                        required
                    />
                    {
                        phoneNumberError ?
                            <div className="error">Please enter a Valid Phone Number</div>
                            : null
                    }
                    <input
                        className="dropdown-control"
                        ref={register({required: true, minLength: 10, maxLength: 10})}
                        type="tel"
                        placeholder="Your Mobile number"
                        name="phoneNumber"
                        onChange={handleNumberChange}
                        disabled
                        required
                    />
                    <button disabled className="createViewWeblink" onClick={(e) => {createJobPostingResponse(jobPostingId, name, phoneNumber);}} style={{width: '95%'}}>
                        Apply Now
                    </button>
                    {
                        showAPIError ?
                            <div className="error">{APIError}</div>
                            : null
                    }
                </div>
            </div>
            <SubmitPopup visible={showSubmitPopup} setShowPopup={setShowSubmitPopup} text={submitText}></SubmitPopup>
        </div>
    )
};

export default JobPosterPage;
