import axios from "axios";

export const referMitra = (name, phoneNumber,city) => {
    const URL = process.env.REACT_APP_BACKEND_URL + '/refer-and-earn/refer';

    const formData = new FormData();
    formData.append('name', name);
    formData.append('phoneNumber', phoneNumber);
    formData.append('city', city);

    return axios.post(URL, formData, { withCredentials: true });
};

export const getAllReferredMitras = () => {
    const URL = process.env.REACT_APP_BACKEND_URL + `/refer-and-earn/fetchReferrals`;
    return axios.get(URL, { withCredentials: true ,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    }});
};

