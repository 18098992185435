import React, {useState} from 'react'
import BulkAction from './Bulk-action.component';
// import { Drawer } from 'antd';

const BulkActionSidesheet = ({setHeader, setOpen}) => {

    const DEFAULT = 'default'
    const [bulkActionType, setBulkActionType] = useState('')
    const [fileUploaded, setFilUploaded] = useState([])
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [langSelected, setLangSelected] = useState('null');
    const [citySelected, setCitySelected] = useState(DEFAULT)
    const [clientSelected, setClientSelcted] = useState(DEFAULT);
    const [jobCategorySelected, setJobCategorySelected] = useState(DEFAULT)
    const [jobLocationSelected, setJobLocationSelected] = useState(DEFAULT)
    const [jobSelected, setJobSelcted] = useState(DEFAULT);
    const [jobDemandSelected, setJobDemandSelected] = useState(DEFAULT);
    const [jobs, setJobs] = useState([]);


    return (
            <BulkAction setParentHeader={setHeader} fileUploaded={fileUploaded} setFilUploaded={setFilUploaded} uploadSuccess={uploadSuccess} setUploadSuccess={setUploadSuccess} 
            langSelected={langSelected} setLangSelected={setLangSelected} citySelected={citySelected} setCitySelected={setCitySelected} clientSelected={clientSelected}
            setClientSelcted={setClientSelcted} jobCategorySelected={jobCategorySelected} setJobCategorySelected={setJobCategorySelected} jobLocationSelected={jobLocationSelected}
            setJobLocationSelected={setJobLocationSelected} jobSelected={jobSelected} setJobSelcted={setJobSelcted} jobDemandSelected={jobDemandSelected} setJobDemandSelected={setJobDemandSelected}
            bulkActionType={bulkActionType} setBulkActionType={setBulkActionType} jobs={jobs} setJobs={setJobs} />
    )
    }

export default BulkActionSidesheet;
