import React from "react";
import SideSheetComponent from "../common/SideSheet";
import { DatePicker, Button, PageHeader, Modal } from 'antd';
import { DownOutlined, FileDoneOutlined, InfoCircleOutlined, GlobalOutlined, CloudDownloadOutlined, CloseOutlined, PhoneOutlined, UserOutlined, LinkOutlined, SearchOutlined, DownloadOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";
import moment from "moment";

const JobPageSidebar = (props) => {
    const {
        client,
        setShowSideSheet,
        setViewCreateWebLink,
        jobPostingsByClient,
        setJobPostingId,
        setJobPostingResponseById,
        setJobPostingCity,
        setJobPostingData,
        setJobPostingClientSelected
    } = props;

    return (
        <SideSheetComponent submit={null} sidername={'Weblink for ' + client} onDismiss={() => setShowSideSheet(false)}>
            <div className="jobs-page">
                <button style={{padding: '10px'}} className="createWebLinks" onClick={() => {setShowSideSheet(false); setViewCreateWebLink(true); trackEvent('create_new_weblink_clicked', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);}}>
                    <GlobalOutlined />
                    &nbsp;&nbsp;Create New Poster
                </button>
            </div>

            {jobPostingsByClient.map((JobPosting, index) => (
                <div key={index} className="jobs-page job-posting-sidesheet">
                    <div className="detail-element-body">
                        <div className="card-container" style={{display: 'inline-block', textAlign: 'left'}} key={index}>
                            <div>
                                <h6 className="content"><b>Created on {moment(JobPosting.createdAt).format("DD MMM YYYY")}</b></h6>
                            </div>
                            <div className="row">
                                <div className="client-logo col-6">
                                    <img className="imgResponse" alt={JobPosting.companyName} src={"/image/" + JobPosting.companyName + ".png"} />
                                </div>
                                <div className="col-6">
                                    <br></br><h5 className="cityHeading"><i className="fas fa-map-marker-alt"></i> &nbsp;&nbsp;<b className="bold">{JobPosting.city}</b> - {JobPosting.localityCount} {JobPosting.localityCount > '1' ? 'Localities' : 'Locality'}</h5>
                                </div>
                            </div>
                            <button className="createViewWeblink" onClick={() => {setShowSideSheet(false); setJobPostingId(JobPosting.id); setJobPostingCity(JobPosting.city); setJobPostingClientSelected(JobPosting.companyName); setJobPostingData(JobPosting); trackEvent('view_weblink_clicked', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);}} >
                                <LinkOutlined />
                                &nbsp;&nbsp;View Poster
                            </button>
                            <button className="viewLocations" onClick={() => {setShowSideSheet(false); setJobPostingResponseById(JobPosting.id); trackEvent('view_job_leads_clicked', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);}}>
                                <i className="fas fa-thumbtack"></i>
                                &nbsp;&nbsp;View Leads ({JobPosting.jobPostingLeadsCount})
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </SideSheetComponent>
    )
};

export default JobPageSidebar;
