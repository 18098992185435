import React, {useEffect, useState} from 'react';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { InfoCircleOutlined,RightCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Card,Tooltip,Skeleton,Typography, Divider} from 'antd';
const { Text } = Typography;

const MitraAppDownloadsSummary = ({performanceDashboardData,mitraDispatch}) => {
    const history=useHistory();
    const routing = (title) => {
        trackEvent("viewed_candidates_list_overall_summary",{}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        history.push({
            pathname: '/leads-page',
            search: title
        })
        mitraDispatch()
    }

  return (
    <>
        {
            performanceDashboardData ? 
                <div className='overall-summary-box-mitra-app-downloads' >
                    <div className='remaining-milestone-container' >
                        {
                            performanceDashboardData.mitraAppDownloads && 
                            performanceDashboardData.mitraAppDownloads.milestones && 
                            performanceDashboardData.mitraAppDownloads.milestones.length  ? performanceDashboardData.mitraAppDownloads.milestones.map((item,index) => (
                                
                                <Card className='overall-card' key={index}>
                                    <div className='overall-header'>
                                        <div style={{backgroundColor:item.color}} className='summary-color'></div>
                                        <Text className='summary-header'>{item.label}</Text> 
                                        <Tooltip title={item.description}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </div>
                                    <div className='overall-number' onClick={()=>routing(item.label)}>
                                        <Text className='number'>{item.count}</Text>
                                        <RightCircleOutlined style={{fontSize:'20px', marginLeft:'10px',color:'#D34612'}} />
                                    </div>
                                </Card>
                            )): null
                        }
                    </div>
                    <div className='overall-summary-static-container'>
                    <div className='total-referral-box'  >
                        <div className='total-referral-container'>
                            <div style={{fontFamily: 'Nunito Sans Bold', display: 'flex', alignItems: 'center'}}>
                                <Text style={{marginRight:'5px'}}>{performanceDashboardData.mitraAppDownloads?.value === 'total_mitra_app_downloads' ? 'Total Vahan App Downloads': performanceDashboardData.mitraAppDownloads?.label}</Text>
                                <Tooltip title={performanceDashboardData.mitraAppDownloads?.description}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </div>
                            {/* <Text className='total-referral-number' style={{marginTop: '10px'}}>{performanceDashboardData.mitraAppDownloads?.count}</Text> */}
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}} onClick={()=>routing(performanceDashboardData.mitraAppDownloads?.value === 'total_mitra_app_downloads' ? 'Total Vahan App Downloads': performanceDashboardData.mitraAppDownloads?.label)}>
                                <Text className='total-referral-number'>{performanceDashboardData.mitraAppDownloads?.count}</Text>
                                <RightCircleOutlined style={{fontSize:'20px', marginLeft:'10px',color:'#D34612'}} />
                            </div>
                        </div>
                        
                        <Divider />
                        
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            {
                                performanceDashboardData.mitraAppDownloads && performanceDashboardData.mitraAppDownloads.children && performanceDashboardData.mitraAppDownloads.children.map((child, index) => {
                                    return  (
                                        <div style={{padding: '0px 5px', width: '50%', borderLeft: index!==0 ? '1px solid #f0f0f0' : ''}} key={index}>
                                            <div className='total-referral-container'>
                                                <div style={{fontFamily: 'Nunito Sans Bold', display: 'flex', alignItems: 'center', marginRight:'10px'}}>
                                                    <Text style={{marginRight: '5px'}}>{child.label}</Text>
                                                    <Tooltip title={child.description}>
                                                        <InfoCircleOutlined />
                                                    </Tooltip>
                                                </div>
                                                <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}} onClick={()=>routing(child.label)}>
                                                    <Text className='total-referral-number'>{child.count}</Text>
                                                    <RightCircleOutlined style={{fontSize:'20px', marginLeft:'10px',color:'#D34612'}} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })   
                            }
                        </div>
                    </div>  
                    </div>
                    
                </div>
            :
                <Skeleton active/>
        }
    </>
  )
}

export default MitraAppDownloadsSummary;
