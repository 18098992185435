import React, { useState, useContext } from 'react';
import '../../../css/lead.scss';
// import useMoengage from '../../../hooks/useMoengage';
import { trackEvent } from '../../../service/engagementMonitoringService';
import CONSTANTS from '../../../constants/constants';
import useGAEventsTracker from '../../../service/useGSEventsTracker';
import moment from "moment";
import { UploadOutlined, WhatsAppOutlined, UserOutlined, PhoneOutlined, CopyOutlined, EnvironmentOutlined, ArrowRightOutlined, ArrowUpOutlined, UserAddOutlined, ExclamationCircleOutlined, InfoCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Tag, Space, Divider, Typography, Image } from 'antd';
import { AdditionalFormsURLEnum } from '../../../utils/utility';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../App';
import { red } from '@material-ui/core/colors';
import { getDisplayableClientName } from '../../../utils/commonFunction';
import { claimMitraAppLeads } from '../../../service/additionalInfoService'
import { jobseekerApplicationInML } from '../../../constants/utils'
import { ROLES } from '../roles';
import WhatsappTemplateModal from '../../whatsappTemplateModal/WhatsappTemplateModal';
import SpinningLoader from '../../../static/images/loader-rolling.gif';
const { Text } = Typography;


function SingleApplicationContainer({ lead, leadDetails, index, setSpin, setShowDetail, showViewDetails, setSelectedLead, checkStatus, referagainBool, setReferAgain, setCurrentJob, mitra, setSelectedScheduledInterviewId, currentClientSiId, currentClientRhId, setSelectedRecommendationHistoryId, realtimeUniquenessStatusData, siToCheckUniquenessStatus, showLoaderForUniqueness, showCompleteApplicationForUniqueSi }) {
    const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
    const history = useHistory();
    const { mitraReducer, userRole } = useContext(AppContext);
    const [isBronzeML, setIsBronzeML] = React.useState(localStorage.getItem('isBronzeML'))
    const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
    const [selectedApplicationIndex, setSelectedApplicationIndex] = useState(null);
    // const [createMoengageUser, trackEvent] = useMoengage();

    const diffBetweenTwoDates = (statusTime, rhTime) => {
        const first = statusTime ? statusTime : rhTime
        return moment(first).format('DD MMMM YYYY')
    }
    const claimLeadClick = async (lead, index) => {
        const response = await claimMitraAppLeads(
            {
                phoneNumber: lead.phoneNumber,
                scheduledInterviewId: lead.scheduledInterviewId[index],
                siMetaData: { isTransferredToMl: true },
                mitraId: mitra.id
            },
            {
                updateSiMetaData: true
            }
        )
        if (response.data && response.data.updateSiMetaData == '200') {
            lead.siMetaData[index] = lead.siMetaData[index] ? { ...lead.siMetaData[index], isTransferredToMl: true } : { isTransferredToMl: true }
        }
    }

    const onClickViewDetails = (e) => {
        setSelectedScheduledInterviewId([currentClientSiId]);
        setSelectedRecommendationHistoryId([currentClientRhId]);
        trackEvent('view_details_button_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setSpin({
            loading: true, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
        setShowDetail(true);
        setSelectedLead(lead);
        GAEventsTracker('OpenViewDetails')
        setSpin({
            loading: false, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
        e.stopPropagation();

        // adding high intent events for google and meta pixel
        window.fbq('trackCustom', 'OpenViewDetails', {
            phone: mitraReducer?.mitraInfo?.phoneNumber,
            city: mitraReducer?.mitraInfo?.homeCity,
            name: mitraReducer?.mitraInfo?.name,
            email: mitraReducer?.mitraInfo?.email,
            fbclid: mitraReducer?.mitraInfo?.fbclid
        });
    }

    const viewAdditionalFormHandler = (lead, index) => {
        const paramObject = {
            name: lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName,
            phoneNumber: lead.phoneNumber,
            scheduledInterviewId: lead.scheduledInterviewId[index],
            companyName: lead.companies[index],
            jobId: lead.jobId[index],
            jobDemandId: lead.jobDemandId[index],
            companyCity: lead.companyCity[index],
            ...(lead.companies[index]?.toLowerCase().indexOf('rapido') !== -1 ? {
                candidateId: lead.siMetaData[index] ? lead.siMetaData[index].rapidoMilestone ? lead.siMetaData[index].rapidoMilestone.captainId : null : null
            } : {}),
            meta: JSON.stringify(lead.siMetaData[index]),
        }
        viewAdditionalForm(paramObject);
    }

    const viewAdditionalForm = (lead) => {
        if (lead?.companyName?.toLowerCase().indexOf('zomato') !== -1) {
            history.push({
                pathname: '/zomato-additional-form',
                route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
                search: new URLSearchParams(lead).toString()
            });
        } else {
            history.push({
                pathname: '/moreInfo',
                route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
                search: new URLSearchParams(lead).toString()
            })
        }

    }

    const onClickCompleteApplication = (e) => {
        viewAdditionalFormHandler(lead, index);
        trackEvent('complete_application_clicked_on_candidate_card', { candidate_phone_number: lead.phoneNumber, application_id: lead.scheduledInterviewId[index] }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    const onClickReferAgain = (e) => {
        setReferAgain(true);
        setCurrentJob({ scheduledInterviewId: lead.scheduledInterviewId[index], productSource: lead.productSource.length > 0 ? lead.productSource[index] : '', parentPhoneNumber: lead.parentPhoneNumber ? lead.parentPhoneNumber[index] : '', childPhoneNumber: lead.childPhoneNumber?.length > 0 ? lead.childPhoneNumber[index] : '', companyName: lead.companies[index], status: lead.latestMilestone[index], phoneNumber: lead.phoneNumber, name: lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName, mitraId: mitra.id, jobType: lead.jobType[index], jobId: lead.jobId[index], jobDemandId: lead.jobDemandId[index], companyCity: lead.companyCity[index], role: lead.role[index] });
        trackEvent('refer_again_clicked_on_candidate_card', { candidate_phone_number: lead.phoneNumber, application_id: lead.scheduledInterviewId[index] }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setSelectedLead(lead);
        e.stopPropagation();
    }

    const openWhatsAppPop = (event, index) => {
        event.stopPropagation();
        setSelectedApplicationIndex(index);
        setWhatsAppMessageModal(true)
    }
    const copyPhoneNumberFunc = (event, phoneNumber) => {
        event.stopPropagation();
        navigator.clipboard.writeText(phoneNumber);
    }

    const whatsAppMessageModalCancel = (e) => {
        e.stopPropagation();
        setWhatsAppMessageModal(false);
    };

    const getUniquenessLabel = () => {
        // lead.latestMilestone[index]
        const companiesToExcludeFromThisEvaluation = ['Zepto_3PL'];

        if (companiesToExcludeFromThisEvaluation.includes(lead.companies[index])) return lead.latestMilestone[index];

        if (
            lead.scheduledInterviewId.length >= index - 1 && // checking if SI id is created for corresponding rhId
            realtimeUniquenessStatusData && Object.keys(realtimeUniquenessStatusData)?.includes(lead.scheduledInterviewId[index]) // checking if siId exists in uniqueness polling api data
        ) {
            return realtimeUniquenessStatusData[lead.scheduledInterviewId[0]]?.isNonUnique ? 'Non Unique' : 'Unique'
        } else {
            // if current siId is included in evaluation then set/unset loader else send original response
            if (siToCheckUniquenessStatus?.includes(lead.scheduledInterviewId[index])) {
                if (showLoaderForUniqueness) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>{lead.latestMilestone[index]}</div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: '8px', alignSelf: 'center' }}>Checking Uniqueness</div>
                                <Image src={SpinningLoader} alt="loading" height={'auto'} width={18} />
                            </div>
                        </div>

                    )
                } else {
                    return (
                        <>
                            <div>{lead.latestMilestone[index]} <br /> Please check uniqueness status after some time</div>
                        </>
                    )
                }
            } else return (
                <div>{lead.latestMilestone[index]}</div>
            )
        }
    };

    console.log(mitraReducer);

    return (
        <>

            {whatsAppMessageModal ?
                <WhatsappTemplateModal
                    lead={lead}
                    leadDetails={leadDetails}
                    whatsAppMessageModal={whatsAppMessageModal}
                    whatsAppMessageModalCancel={whatsAppMessageModalCancel}
                    selectedApplicationIndex={selectedApplicationIndex}
                ></WhatsappTemplateModal>
                : null}
            <div className="single-application-container" onClick={onClickViewDetails}>
                {/* <Divider style={{margin:'8px 0'}}/> */}
                <div key={index} className={lead.latestMilestone[index] !== 'Upload Documents' ?
                    (checkStatus({
                        scheduledInterviewId: lead.scheduledInterviewId[index], productSource: lead.productSource.length > 0 ?
                            lead.productSource[index] : null, parentPhoneNumber: lead.parentPhoneNumber ?
                                lead.parentPhoneNumber[index] : null, childPhoneNumber: lead.childPhoneNumber?.length > 0 ? lead.childPhoneNumber[index] : null, companyName: lead.companies[index], status: lead.latestMilestone[index]
                    }) ?
                        'incompleteApplicationBG' : '') : (lead.referAgain ? '' : 'incompleteApplicationBG')} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                    <Space key={index} direction='vertical' style={{ width: '100%' }}>

                        <div className='d-flex justify-content-between'>
                            <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                <div className="single-job-container">
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="client-logo">
                                                <img className="imgResponse" style={{ width: 68, heigth: 32 }} alt={getDisplayableClientName(lead.companies[index])} src={lead?.logos[index]} />
                                            </div>
                                            <div style={{ marginLeft: '1em', color: '#000000D9', fontWeight: '500', fontSize: '16px' }}>
                                                {lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {lead.companyCity ?
                                    <div className='display-city-name'>
                                        <EnvironmentOutlined style={{ marginRight: '3px' }} /> {[...new Set(lead.companyCity)].toString()}
                                    </div> : null}
                                <Space direction='horizontal' className="onboarding-schedule">
                                    <div className="leadsCardStatus d-flex flex-row" style={lead.latestMilestone[index] === 'Upload Documents' ? { color: '#000', fontFamily: 'Nunito Sans Bold' } :
                                        (lead.latestMilestone[index] === 'F25 ~ First 25 Trips' || lead.latestMilestone[index] === 'F10 ~ First 10 Trips' || lead.latestMilestone[index] === 'F1 ~ First Trip') ? { color: '#52C41A' } : null}>
                                        {(
                                            (lead.ujfMetaData && lead.ujfMetaData.length && lead.companies[index] === 'Swiggy' && lead?.ujfMetaData[index]?.swiggy_uniqueness) ? lead.ujfMetaData[index].swiggy_uniqueness :
                                                getUniquenessLabel()
                                        )}
                                    </div>
                                    <div style={{ color: '#00000073', fontSize: '12px' }}>{'\u2022'}</div>
                                    <div className="diffInDate">
                                        {diffBetweenTwoDates(lead.ujfmCreatedAt[index], lead.rhCreatedAt[index])}
                                    </div>
                                </Space>
                                {lead.zomatoMilestones ?
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {JSON.parse(lead.zomatoMilestones).map((milestone, idx) => (
                                            milestone.milestoneName.includes('referral_date_si') || (milestone.milestoneRank >= 260 && milestone.milestoneRank <= 320) ? '' :
                                                <div key={idx} style={{ backgroundColor: '#d34612', color: '#FFF', marginRight: '5px', padding: '2px 5px', borderRadius: '3px' }}>
                                                    {milestone.milestoneName.replaceAll('_', ' ')}
                                                </div>
                                        ))}
                                    </div> :
                                    <div style={{ height: '10px', marginTop: '5px' }}></div>}
                                <div className='candidate-card-action-required-buttons-container'>
                                    {
                                        lead.companies[index].toLowerCase().includes('3pl') && ROLES.DC === userRole ?
                                            <>
                                                {
                                                    <div onClick={(e) => {
                                                        if (!lead.siMetaData[index] || (lead.siMetaData[index] && !lead.siMetaData[index]['isTransferredToMl'])) {
                                                            claimLeadClick(lead, index)
                                                        }
                                                    }}>
                                                        <Button icon={<RedoOutlined />} type="primary" style={{ marginRight: '5px', backgroundColor: !lead.siMetaData[index] || (lead.siMetaData[index] && !lead.siMetaData[index]['isTransferredToMl']) ? '#d34612' : '#03bd03' }}>{!lead.siMetaData[index] || (lead.siMetaData[index] && !lead.siMetaData[index]['isTransferredToMl']) ? 'Claim Application' : 'Claimed'}</Button>
                                                    </div>

                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        (lead?.siMetaData?.[index]?.rapidoMilestone?.value?.toString().includes('Please upload') || lead?.siMetaData?.[index]?.rapidoMilestone?.value?.toString().includes('Pending') || lead?.siMetaData?.[index]?.rapidoMilestone?.value?.toString().includes('Rejected') || (lead?.siMetaData?.[index]?.isApplicationCompleted === false && lead?.siMetaData?.[index]?.documentUploadStarted === true)) ?
                                            <>
                                                {
                                                    AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                                                        <div onClick={onClickCompleteApplication}>
                                                            <Button icon={<UploadOutlined />} type="primary" ghost>Upload Documents</Button>
                                                        </div>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        // console.log(lead.latestMilestone[index], !(JSON.stringify(lead?.siMetaData?.[index]).includes('formTwoCompleted')), lead.companies[index]?.toLowerCase().indexOf('zepto_3pl') !== -1, lead.latestMilestone[index] !== 'Incomplete Application' , lead.latestMilestone[index] !== 'Non Unique' , !(JSON.stringify(lead?.siMetaData?.[index]).includes('formThreeCompleted')) && (lead?.scheduledInterviewId) ) &&
                                        // console.log(lead?.latestMilestone[index]) &&
                                        !(JSON.stringify(lead?.siMetaData?.[index]).includes('formTwoCompleted')) && lead.companies[index]?.toLowerCase().indexOf('zepto_3pl') !== -1 && lead.latestMilestone[index] !== 'Incomplete Application' && lead.latestMilestone[index] !== 'Non Unique' && !(JSON.stringify(lead?.siMetaData?.[index]).includes('formThreeCompleted')) && (lead?.scheduledInterviewId) ?
                                            <>
                                                {
                                                    AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                                                        <div onClick={onClickCompleteApplication}>
                                                            {/* { lead?.latestMilestone[index] !== 'Non Unique' ? */}
                                                            <Button icon={<UploadOutlined />} type="primary" ghost>Complete Application</Button>
                                                            {/* : ''} */}
                                                        </div>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        lead.companies[index]?.toLowerCase().includes('rapido_3pl') && lead.bankDetailsVerified && lead.jobseekerDocsConfirmation && lead.mitraAppUserLoginAt && !lead?.siMetaData?.[index]?.rapidoMilestone?.captainId ?
                                            <>
                                                {
                                                    AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                                                        <div onClick={onClickCompleteApplication}>
                                                            <Button icon={<UploadOutlined />} type="primary" ghost>Verify OTP</Button>
                                                        </div>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        referagainBool(index)
                                            ?
                                            <div style={{ marginLeft: 3 }} onClick={onClickReferAgain}>
                                                <Button icon={<UserAddOutlined />} style={{ marginRight: 8 }} type="primary" ghost>Refer Again</Button>
                                            </div>
                                            : null
                                    }

                                </div>
                            </div>

                            <div className='d-flex' style={{ alignItems: 'center' }}>
                                <Button style={{ color: '#fce3e6', border: '2px solid #fce3e6', width: '60px', height: '60px' }} type="primary" size='large' ghost shape="circle" icon={<ArrowRightOutlined style={{ fontSize: '25px' }} />} />
                            </div>
                        </div>
                    </Space>
                </div>
                {
                    lead.campaignTags && lead.campaignTags.length
                        ?
                        <div className='campaign-tag-container'>
                            {
                                lead.campaignTags[index].map((campaignTag, index) => <Tag key={index} className='single-chip'>{campaignTag}</Tag>)
                            }
                        </div>
                        : null
                }
                {/* for banners */}
                <div className='application-card-banners'>
                    <div className="d-flex justify-content-between">
                        {
                            (lead.latestMilestone[index] === 'Incomplete Application')
                                ?
                                (lead.companies[index]?.toLowerCase().indexOf('rapido')) !== -1 ?
                                    <div className="d-flex flex-row">
                                        <Tag color="yellow" style={{ maxHeight: '25px', fontWeight: 'bold', color: '#FAAD14' }}>Action Required</Tag>
                                        <Text>{'\u2022'} Please complete the OTP verification by clicking on &quot;Complete Application&quot;</Text>
                                    </div>
                                    : <div className="d-flex flex-row"><Tag color="yellow" style={{ maxHeight: '25px', fontWeight: 'bold', color: '#FAAD14' }}>Action Required</Tag>
                                        <Text>{'\u2022'} Please complete the remaining steps by clicking on &quot;Complete Application&quot;</Text></div>
                                : null
                        }
                        {
                            !lead.companies[index]?.toLowerCase().includes('dominos') && (!lead.companies[index]?.toLowerCase().includes('3pl')) && !lead.mitraAppUserLoginAt && isBronzeML
                                ? (lead.latestMilestone[index] === 'Incomplete Application' ?
                                    <Text className='action-banner'>{'\u2022'} Vahan App Not Downloaded</Text>
                                    : <div className="d-flex flex-row">
                                        <Tag color="yellow" style={{ maxHeight: '25px', fontWeight: 'bold', color: '#FAAD14' }}>Action Required</Tag>
                                        <Text>{'\u2022'} Vahan App Not Downloaded</Text> </div>)
                                : null
                        }
                        {
                            lead.companies[index]?.toLowerCase().includes('dominos') && <div className="d-flex flex-row">
                                <Tag color="yellow" style={{maxHeight: '25px', fontWeight: 'bold', color: '#FAAD14'}}>Action Required</Tag>
                                <Text className='action-banner'>{'\u2022'} Please fill out the provided form if you haven't already.  <Tag color="blue" style={{marginLeft:'5px'}}> <a style={{color:'blue'}} target='_blank' href='https://360.idfy.com/signup/37646013-0184-42e9-8998-45ef9cf606c5/18e0cf22-81ea-40b3-88a1-b656086d5928'> Click here to fill</a></Tag></Text>
                            </div>
                        }

                        {
                            !(JSON.stringify(lead?.siMetaData?.[index]).includes('formTwoCompleted')) && lead.companies[index]?.toLowerCase().indexOf('zepto_3pl') !== -1 && lead.latestMilestone[index] !== 'Incomplete Application' && lead.latestMilestone[index] !== 'Non Unique' && !(JSON.stringify(lead?.siMetaData?.[index]).includes('formThreeCompleted')) && (lead?.scheduledInterviewId) ?
                                <Text className='action-banner'>{'\u2022'} Please complete the remaining steps by clicking on &quot;Complete Application&quot;</Text>
                                : null
                        }
                        {
                            (JSON.stringify(lead?.siMetaData?.[index]).includes('formTwoCompleted') && lead.companies[index]?.toLowerCase().indexOf('zepto_3pl') !== -1 && lead.latestMilestone[index] !== 'Incomplete Application' && lead.latestMilestone[index] !== 'Non Unique'
                                && JSON.stringify(lead?.siMetaData?.[index]).includes('googleFormFillErrorReason') && !(JSON.stringify(lead?.siMetaData?.[index]).includes('googleFormFilled'))) ? <Text className='action-banner'>{'\u2022'} Please Re-Upload the correct shift preference as per location using demands sheet: <u><b><a style={{ color: 'black' }} href='https://docs.google.com/spreadsheets/d/1tFVN1n8DLYrEZakIFdikcChghLdIE96YxbYfME9zOb4/edit#gid=1033278430' rel="noopener noreferrer" target="_blank">LINK</a></b></u>, selected shift is not avaiable with client! </Text> : null
                        }
                        {
                            (JSON.stringify(lead?.siMetaData?.[index]).includes('formTwoCompleted') && lead.companies[index]?.toLowerCase().indexOf('zepto_3pl') !== -1 && lead.latestMilestone[index] !== 'Incomplete Application' && lead.latestMilestone[index] !== 'Non Unique'
                                && JSON.stringify(lead?.siMetaData?.[index]).includes('zeptoRejectionReason')) ? <Tag className='action-banner' icon={<ArrowUpOutlined />} color="volcano">{'\u2022'} Client rejected the lead with Reason: ${lead?.siMetaData?.[index]?.zeptoRejectionReason}, please re-upload the required details by editing the application</Tag> : null
                        }
                        {
                            (lead.companies[index]?.toLowerCase().includes('3pl')) && !lead.mitraAppUserLoginAt && lead.latestMilestone[index] !== 'Non Unique' ?
                                <Text className='action-banner'>{'\u2022'} For Vahan activation, ask candidate to login to {`"Vahan App"`}</Text>
                                :
                                null
                        }
                        {
                            lead.companies[index]?.toLowerCase().includes('rapido_3pl') && lead.bankDetailsVerified && lead.jobseekerDocsConfirmation && lead.mitraAppUserLoginAt && !lead?.siMetaData?.[index]?.rapidoMilestone?.captainId ?
                                <Text className='action-banner'>{'\u2022'} Verify OTP to activate candidate.</Text>
                                :
                                null
                        }
                        {
                            lead.companies[index]?.toLowerCase().indexOf('uber') !== -1 && CONSTANTS.UBER_DL_CHECKER_CITIES.indexOf(lead.companyCity[index]?.toLowerCase()) === -1 ?
                                <Tag className='action-banner'>There is no uniqueness check for the city you have chosen. Please ask lead to proceed with next steps without uniqueness.</Tag>
                                : null
                        }
                        {
                            (!Array.isArray(lead?.siMetaData?.[index]?.rapidoMilestone?.value) && (lead?.siMetaData?.[index]?.rapidoMilestone?.value?.toString().includes('Please upload') || lead?.siMetaData?.[index]?.rapidoMilestone?.value?.toString().includes('Pending')))
                                ?
                                <Text className='action-banner'>{'\u2022'} Please complete the remaining steps by clicking on &quot;Upload Documents&quot;</Text>
                                : null
                        }
                        {
                            (Array.isArray(lead.siMetaData[index]?.rapidoMilestone?.value) && (lead.siMetaData[index]?.rapidoMilestone?.value.filter((ele) => (ele.includes('Review'))).length === lead.siMetaData[index]?.rapidoMilestone?.value.length)) ?
                                <Tag className='action-banner' icon={<ArrowUpOutlined />} color="volcano">Documents are being Reviewed please check after some time</Tag>
                                : null
                        }
                        {
                            (lead.companies[index]?.toLowerCase().indexOf('uber') !== -1 && lead.siMetaData[index] && lead.siMetaData[index].driverStatus)
                                ?
                                (lead.siMetaData[index].driverStatus === 'NOT_CONVERTED') ?
                                    <Tag className='action-banner' icon={<ArrowUpOutlined />}>Please ask the lead to create ID & Password using <a style={{ color: "blue", textDecoration: 'underline' }} href={lead.siMetaData[index]?.uberMilestone?.url} target="_blank" rel="noreferrer">Invite Link</a></Tag>
                                    :
                                    (lead.siMetaData[index].driverStatus === 'BLOCKED') ?
                                        <Tag className='action-banner' icon={<ArrowUpOutlined />} color="volcano">Lead Blocked By Uber </Tag>
                                        :
                                        (lead.siMetaData[index]?.reviewNotStartedUberDocArray?.length > 0) ?
                                            <Tag className='action-banner' icon={<ArrowUpOutlined />} color="volcano">Documents are being Reviewed please check after some time</Tag>
                                            :
                                            (lead.siMetaData[index]?.isApplicationCompleted !== true || lead.siMetaData[index]?.failedUberDocArray?.length > 0 || lead.siMetaData[index]?.notUploadedUberDocArray?.length > 0) ?
                                                <Text className='action-banner'>{'\u2022'} Please re-upload the Pending / Rejected Documents by clicking on &quot;Upload Documents&quot;</Text>
                                                :
                                                <Tag className='action-banner' icon={<InfoCircleOutlined />} color="green">Congrats! All the documents have been approved!</Tag>
                                : null
                        }
                        {
                            (Array.isArray(lead?.siMetaData?.[index]?.dunzoMilestone?.value) && lead?.siMetaData?.[index]?.dunzoMilestone?.value?.toString().includes('Under Review'))
                                ?
                                <Tag className='action-banner' icon={<ArrowUpOutlined />} color="gray">Documents are being reviewed please check after some time.</Tag>
                                : null
                        }

                        {
                            (Array.isArray(lead?.siMetaData?.[index]?.dunzoMilestone?.value) && !(lead?.siMetaData?.[index]?.dunzoMilestone?.value?.toString().includes('Under Review')))
                                ?
                                <Tag className='action-banner' icon={<InfoCircleOutlined />} color="green">Congrats! All the documents have been Approved!</Tag>
                                : null
                        }

                        {
                            (lead.siMetaData[index]?.rapidoMilestone?.value?.toString().includes('Please upload') || lead.siMetaData[index]?.rapidoMilestone?.value?.toString().includes('Rejected'))
                                ?
                                <Text className='action-banner'>{'\u2022'} Please re-upload the Pending / Rejected Documents by clicking on &quot;Upload Documents&quot;</Text>
                                : null
                        }
                        {
                            (Array.isArray(lead.siMetaData[index]?.rapidoMilestone?.value) && !(lead.siMetaData[index]?.rapidoMilestone?.value?.toString().includes('Please upload')) && !lead.siMetaData[index]?.rapidoMilestone?.value?.toString().includes('Pending') && lead.siMetaData[index]?.isApplicationCompleted)
                                ?
                                <Tag className='action-banner' icon={<InfoCircleOutlined />} color="green">Congrats! All the documents have been Approved!</Tag>
                                : null
                        }
                        {
                            lead.productSource[index] === 'CrossReferRapidoToZomato' || lead.productSource[index] === 'CrossReferShadowfaxToZomato'
                                ?
                                <Tag> Cross Referred by Vahan</Tag>
                                : null
                        }
                        {
                            lead.productSource[index] === 'CrossReferRapidoToZomato' || lead.productSource[index] === 'CrossReferShadowfaxToZomato' || lead.productSource[index] === 'CrossReferLSNToZomato' || lead.productSource[index] === 'CrossReferDunzoToZomato'
                                ?
                                <Text className='action-banner'>{'\u2022'} Refer with alternate contact number <strong> {lead.childPhoneNumber}</strong></Text>

                                // <Tag className='referredAgain' style={{display:'flex',alignItems:'center'}} icon={<ExclamationCircleOutlined />}>Refer with alternate contact number 
                                // <strong> {lead.childPhoneNumber}</strong>
                                // </Tag>
                                : null
                        }
                        {
                            lead?.siMetaData[index] && lead.siMetaData[index].rapidoMilestone && !Array.isArray(lead.siMetaData[index].rapidoMilestone.value) && lead.siMetaData[index].rapidoMilestone.value?.toLowerCase().indexOf('attributed') !== -1
                                ?
                                <div className='rapidoNotAttributed'> <InfoCircleOutlined style={{ marginRight: '10px', color: 'rgb(211, 70, 18)' }} />
                                    An error occurred {lead.siMetaData[index]?.rapidoMilestone?.value?.toString().includes('OTP') ? 'as OTP could not be verified' : ''}!! Vahan will share the lead details with the client for attribution. Please ask the candidate to download the client app using the referral code in the meantime.
                                </div>
                                : null
                        }
                        {
                            lead.siMetaData[index] && lead.siMetaData[index].rapidoMilestone && !Array.isArray(lead.siMetaData[index].rapidoMilestone.value) && lead.siMetaData[index].rapidoMilestone.value?.toLowerCase().includes('uploading')
                                ?
                                <Tag className='rapidoNotAttributed' color="orange"> <InfoCircleOutlined style={{ marginRight: '10px', color: 'rgb(211, 70, 18)' }} />
                                    Documents Uploading Please Check after some time
                                </Tag>
                                : null
                        }
                        {
                            (checkStatus({ scheduledInterviewId: lead.scheduledInterviewId[index], productSource: lead.productSource.length > 0 ? lead.productSource[index] : null, parentPhoneNumber: lead.parentPhoneNumber ? lead.parentPhoneNumber[index] : null, childPhoneNumber: lead.childPhoneNumber?.length > 0 ? lead.childPhoneNumber[index] : null, companyName: lead.companies[index], status: lead.latestMilestone[index] }))
                                ?
                                <Text className='action-banner'>{'\u2022'} Refer with alternate contact number <strong> {lead.childPhoneNumber}</strong></Text>
                                // <div className='referredAgain'> <img src="/image/icon_info.png" alt="" style={{marginRight: '3px'}} />Refer with alternate contact number 
                                // <strong> {lead.childPhoneNumber}</strong>
                                // </div>
                                : null
                        }
                        {
                            (lead.parentPhoneNumber[index] && lead.scheduledInterviewId[index])
                                ?
                                <Tag className='referredAgain' icon={<ExclamationCircleOutlined />}> Referred again with alternate contact no.</Tag>
                                : null
                        }

{
                            (
                                ( 
                                    lead.latestMilestone[index] === 'Incomplete Application' && 
                                    lead.companies[index] !== 'Zomato' // Excluding zomato out of this check as we donot allow non-unique to see additional info form
                                ) || 
                                (lead.companies[index] === 'Zomato' && lead.latestMilestone[index] === 'Unique') || 
                                (
                                    lead.companies[index] === 'Zomato' && realtimeUniquenessStatusData && 
                                    realtimeUniquenessStatusData[lead.scheduledInterviewId[0]]?.isNonUnique === false
                                )
                            )  ?
                                <>  
                                {
                                    AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()]  ? 
                                        <div onClick={onClickCompleteApplication} className='d-flex'>
                                            <Button icon={<UploadOutlined />} type="primary">Complete Application</Button>
                                            {
                                                lead.companies[index] === 'Zomato' ? <img src="/image/new-icon.gif" style={{height: '25px',width: 'auto'}} />: null
                                            }
                                        </div>
                                    : null
                                }
                                </>
                            : null
                        }
                    </div>

                    <Divider></Divider>
                    <div className='d-flex justify-content-between show-only-desktop'>
                        {lead.mitraName ? <div className='added-mitra-name'><UserOutlined style={{ marginRight: '3px' }} />Added by&nbsp;<span className='display-mitra-name'>{[...new Set(lead.mitraName)].toString()}</span></div> : null}
                        <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <div className="contact-buttons">
                                <Button onClick={(e) => copyPhoneNumberFunc(e, lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)` : lead.phoneNumber)}
                                    icon={<CopyOutlined />} type="primary" ghost>
                                    {lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)` : lead.phoneNumber}
                                </Button>
                                <Button onClick={(e) => openWhatsAppPop(e, index)} style={{ margin: 0 }} icon={<WhatsAppOutlined />} type="primary" ghost>Message Via WhatsApp</Button>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between show-only-mobile'>

                        <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center', marginTop: '-10px' }}>
                            <div className="contact-buttons">
                                <a
                                    href={`tel:+91-${lead.parentPhoneNumber[0] ? lead.parentPhoneNumber[0] : lead.phoneNumber}`}
                                    onClick={(e) => {
                                        GAEventsTracker("CallCandidate"); e.stopPropagation();
                                        trackEvent('call_now_option_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
                                    }}>
                                    <Button className='button-phone-number'
                                        icon={<PhoneOutlined />} type="primary" ghost>
                                        {lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)` : lead.phoneNumber}
                                    </Button>
                                </a>
                            </div>
                            <Button onClick={(e) => openWhatsAppPop(e, index)} className='button-whatsapp' icon={<WhatsAppOutlined />} type="primary">Message</Button>
                        </div>
                        <div style={{ alignItems: 'center' }}>
                            {lead.mitraName ? <div className='added-mitra-name'><UserOutlined style={{ marginRight: '3px' }} />Added by&nbsp;<span className='display-mitra-name'>{[...new Set(lead.mitraName)].toString()}</span></div> : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleApplicationContainer;
