import axios from 'axios';

export const getLeadsConvertedByVahan = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getLeadsConvertedByVahan`;
    return axios.get(url, {withCredentials: true});
};

export const checkMitraEligibilityForConvertedLeads = (mitraId) => {
    const mitraArray = [
        '85a35980-f853-4d7f-b10d-eb8694f0db62',
        '428c3f7d-dd80-45db-bb34-9d2c026084d1',
        '467b4433-f61b-4f1c-a43a-28c4d45d8a60',
        '2ffc690e-974d-41d3-befb-c8461485efc0',
        'aed6171b-6f9f-4a84-92d5-a3b0f7c2591b',
        '92206b52-3c8c-4adf-993c-ec9a1fd6224a',
        '2c40390b-300b-44d5-8d22-892742f7b9f4',
        '213e1795-be9e-4ae7-bec5-9854c054909e',
        '5df93202-d753-4363-9d0b-35ed0c7efea2',
        'a3168841-80b3-4a65-b141-c836772dfda2',
        'bcc9f103-a479-461e-8f44-aa00fb7babd6',
        '621b2dba-c1f4-452c-a9e5-ae8b684f3cbe',
        'aaef1dee-1b1e-4024-95f9-c720fb564dfe',
        'c4db335e-e68b-40ad-9c05-cd7acc466f84',
        '832b0a7f-2e6d-4b3e-a0cf-a84d897d2ed7',
        '444511c8-99b9-4e8d-8977-a694bc76954d',
        'c4fac155-4981-4cc6-8ec5-4880edd324ae',
        'd06d8122-f064-4eda-a12e-6eb4ae423294',
        '1efb4303-66dd-4ad5-ac5c-34e4f8647c9d',
        '949906f5-6316-4b5d-a658-14440b1c87d3',
        'fd976213-d4b3-4194-9a6f-87fa1a8124f7',
        '7b6a1e7d-c620-42b6-86b0-2c522cf5756e',
        'c457cecb-3ab7-4a89-8ad8-6540173f19a3',
        '22681cf9-f493-482d-b710-d8854a5d608d',
        '76df02ba-34f2-49f1-97d5-56cca88c416d',
        '71bdf8a0-3f8d-4d60-a647-e6edfde50216',
        '07aaa302-a8c6-42cf-8c4c-295354c925cf',
        '61fe5417-c223-4f06-9459-a23b28d632ff',
        '700ab148-f2e0-4e29-8425-6ee40df1dc55',
        '91b4aa49-7728-4be5-a0cf-ef61dfc67cd4',
        '075b8fdc-3ad7-4741-a393-9ffa6a6f98c2',
        '4c3fefc5-0957-4638-979b-e399191ebf85',
        '8efd7803-89fe-4221-9c7d-6fc0fa0d0c4c',
        '7de8a6ff-14f7-47f0-88a6-bac758030bbf',
        '734a6510-cc89-4dcc-a385-56d97fce7877',
        '68c9d3a0-a035-4cd8-a1a0-61ddf01b4884',
        'd1eafcdc-e56c-4ee5-baf4-4cbe2442aeef',
        '0125c6cb-3e77-453c-b19a-666765d99aef',
        '33f10043-bc08-4a71-b9aa-6eb828912209',
        '185bd827-c0fc-4473-bd0f-796e54ac42e0',
        '779d8e11-162b-47bc-9396-8aa1cb7ed245',
        '2ca0f33d-969e-45be-a9db-f7a7b9a4f239',
        '409c4878-cde5-4067-9500-237d91b069a8',
        '7a59e945-f057-4a1b-a16f-2afc11366070',
        '5884c908-572b-4887-ab95-574c718d32c9',
        'b3d8a33a-f400-4f38-8541-092686723e30',
        '80cce257-cecf-4c06-a6c8-4f2b996f1941',
        '8bd8c7f1-28c0-4a58-b449-4fe1870f0823',
        '0dcad01c-7c55-4243-8b44-f56ff9be3bc4',
        'a67e81fa-de0b-4567-a215-ad063001401c',
        'c3d75c21-b6a0-4c4f-8897-d9ea09329d22',
        '42316f72-7b41-47a0-b6c6-3a82f1c3de55',
        'a2d0d98d-dfab-46fc-a035-898176746a57',
        'a8bd79f0-4f3c-4623-a225-b4e2488aac46',
        'acb71d75-d6a6-4321-9399-db9b9d8206e8',
        '8b650862-6f72-4888-abcf-ed72bc7e25ee',
        '4a22450f-f091-401a-b6f4-ade499588c68',
        '0629309a-4061-47f8-b597-3220a83a6e3c',
        'e37f2aa3-1e90-4838-83e8-8a11f2627e75',
        'ec13aa4b-574e-4f23-86ed-ef56dc3df0b5',
        '3904f9e3-764d-4d25-b5ca-c48a3ef0aa08',
        '6c05aeb4-0466-40ab-8fe7-cdb451590edb',
        '70ec5fe4-366f-42e4-870f-6c190b76ed62',
        '6a7fd905-286e-46c0-af2d-97fd4738dc5b',
        '136b76fe-f361-4dc6-8695-242bf29c8af3',
        'f0fcbf14-819d-4c0b-99f6-12b6cf815ae9',
        '815c73e7-eac4-4b1c-9309-81020c804f34',
        '1e758fcf-277b-45ca-8f74-27f08fd5ac78',
        'a3996b8a-7b5c-4c25-8282-3db0250e04c6',
        '28879ead-bcce-494b-a8ab-6a7d962baf42',
        '6fd091a0-202a-4c77-9d68-214e78c1534a',
        '2a365f40-919c-4ff1-9d7f-65808db616d3',
        '8989c09a-7f70-4b4a-9c43-e53e884ca52a',
        'b6fc77f9-9788-47a4-b6a9-434f240e04e0',
        '05732174-1a7f-4eaa-a115-869e46fc1413',
        '52757239-d18a-4b80-b820-2391af1c3a9c',
        '9b25c31e-df62-4fbf-ac44-ea72aa2ba848',
        '4560f8e4-f867-4110-ad2b-b8977e2af4f7',
        '7de8234f-0994-411d-ba2e-c937a2a0ec29',
        '929ed164-37f4-40dd-99ce-741feda7ab5c',
        'ccb97d59-0a9d-466d-ad6d-97af9bdc19c8',
        '09ecdfde-f6bb-46f9-9076-5347257387b8',
        'a92b6ec6-34a8-479e-832d-06b1af8e8715',
        '3b658cde-43e8-4439-97f7-8b2e92240c73',
        'c14ce908-ea9a-4d94-ac20-d67eb2397dd5',
        'a368279a-45cf-45b7-a032-b977ee431eba',
        '84b2a463-9b02-497d-a10f-30eb768d23ce',
        '1a2ac069-6aac-4285-8bc1-50ccd5885ff5',
        'e586ccec-1dcb-47e0-a2d1-6b6f81edc04d',
        '8c799495-53f1-41f5-97ac-d7290c8cf71e',
        '37805998-82c1-4772-81c8-0c068adf90d3',
        'ddbd6a5b-52c5-4d6a-9705-705736992126',
        'cd6ad065-bb59-4dbd-89b6-af9f5579f33c',
        'ec77bb3d-d2cb-4d6b-92b2-e6dd97801134',
        'ecbece64-51cb-457a-b73c-2ad908f0837f',
        'dc102ccf-4107-4152-9836-6afff030e63a',
        '292142ad-2488-4b54-bfec-4ba7a1106107',
        'ce1a4652-1b06-4f90-88f7-94cc0c7ea5bc',
        '76a2d0d8-34cf-4802-8c87-716db43cce6a',
        'e275b7bd-b762-423a-8a7d-6afc2a7f6131',
        '8b628b83-0cf8-45ac-a7cb-882b3aab93c1',
        '3ca018f6-6ad5-4471-b0f3-ab9b68cae60c',
        '80fa0a05-9272-4c5f-bec6-71dc22068a8c',
        '5160ad9e-9d06-461a-b3af-a5e7df1694ce',
        '713797e9-ba8c-44bf-9ab5-8e31b14f2c9b',
        'af4f26f9-f6d2-4cf7-a8ae-af0b470dcf54',
        '0217063b-39e0-4f93-9587-e25b35f32e92',
        '2c7b74db-1eb9-4e81-9f5f-c11902c52570',
        '7dd71eed-4477-446e-a9e3-9fab856373ca',
        '5fe7b3d5-7b06-4a6c-94f2-58c335c5437e',
        'fc3096ea-dc98-4488-b708-bf7149162892',
        'b5c704f3-0839-405c-9e65-4b83669b880c',
        '476c20a7-ce64-47e2-97d5-95709e0e5dee',
        '130cba22-a70d-4381-811f-29d922f01e2a',
        '0dadb417-cd2d-4884-a9ec-db3b402829c7',
        '77c89c66-d7d9-4ffd-a4d0-47876d9f1bbc',
        '30fb74d5-1e5a-4564-aaf5-2ed86a3974eb',
        '10bdda2e-5c47-41ab-851a-bb7924d35039',
        '2dbfebb5-0756-40c9-a41f-86b49858a084',
        '88a200d1-42fe-4493-a58d-7b98029473c9',
        '5bb356e2-41ca-4c88-968a-c6cfe1642f42',
        'ee7a03d3-a7b8-41fb-b00c-637fd2c03405',
        '0c376055-144c-443b-89d7-3f09d7956100',
        'f771c049-42a4-484f-b9ad-13a8f61b8beb',
        '09553e45-abf7-4af0-b3ad-19f5bad9465c',
        '1e758fcf-277b-45ca-8f74-27f08fd5ac78',
        '3f3dad47-9c42-46d9-b674-853894e351b7'
    ];
    let checkEligibility = false;
    mitraArray.map(mitra => {
        if(mitra === mitraId) {
            checkEligibility = true;
        }
    })
    return checkEligibility;
};
