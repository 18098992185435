



import React from 'react';
import '../../css/sidesheet.scss';
import { Button } from 'antd'; 

const SideSheetComponent = (props) => {    
    const {onDismiss,removeAccess,addAccess,containerStyle,submit,sidername,clearAll,hideDismissIcon,hideBottomButtons=false} = props;

    return (
		<>
            <div className='parentContainer'>
                <div onClick={onDismiss} className='emptyContainer'>
                </div>
                <div  style={containerStyle} className='containerSS'>
                    <div className='navigator'>
                        <div className='nav1'>
                            <img onClick={onDismiss} src={`/image/arrow-left.svg`}/>
                            <div className='sidername'>{sidername}</div>
                        </div>
                        {!hideDismissIcon && <img onClick={onDismiss} src={`/image/close.svg`}/>}
                    </div>
                    <div className='childContainer'>
                        {props.children}
                    </div>
                    {
                        hideBottomButtons ? null : (
                            <div className='btns'>
                                {clearAll && <Button  style={{marginRight:5}}disabled={clearAll.disabled} className='commonlayoutbtn' onClick={()=>clearAll.onClick('clearAll')}>Clear All</Button>}
                                {submit && <Button onClick={()=>{submit.onClick(); onDismiss()}} disabled={submit.disabled} className={submit.disabled ? "disabledSubmit" : "submit"} type="primary">Submit</Button>}
                                {addAccess && !addAccess.disabled && <Button onClick={()=>addAccess.onClick('addAccess')} className='addaccess' danger  type="primary">Re-activate Member</Button>}
                                {removeAccess && !removeAccess.disabled && <Button onClick={()=>removeAccess.onClick('removeAccess')} className='removeaccess' danger  type="primary">Remove Access</Button>}
                            </div>
                        )
                    }
                    
                </div>
            </div>
            
		</>
	);
};

export default SideSheetComponent;
