import axios from 'axios';
axios.defaults.timeout = 600000;

export const getDashboardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getPerformanceDashboardData` ;
    return axios.get(url, {
        params: data ,
        withCredentials: true
    }, );
}

export const getCandidatesData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getCandidates` ;
    return axios.get(url, {
        params: data ,
        withCredentials: true
    }, );
}

export const getAllStatuses = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getAllStatusOfLead` ;
    return axios.post(url, data ,{ withCredentials: true });
}

export const downloadPerformanceSheet = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/download/performanceDashboardData` ;
    return axios.get(url, { params: data, withCredentials: true });
}

export const downloadLeadStatusSheet = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/download/getCandidatesData` ;
    return axios.get(url, { params: data, withCredentials: true });
}

export const getPerformanceDashboardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getOverallSummaryData` ;
    return axios.get(url, { params: data, withCredentials: true });
}

export const getCLientPeferenceData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/clientWisePerformanceData` ;
    return axios.get(url, { params: data, withCredentials: true });
}

export const getTeamLeaderBoardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getTeamLeaderBoardData` ;
    return axios.get(url, { params: data, withCredentials: true });
}

export const getPerformanceHistory = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getPerformanceHistory` ;
    return axios.get(url, { params: data, withCredentials: true });
}

export const DownloadTeamLeaderBoardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/download/leaderBoardPageData` ;
    return axios.get(url, { params: data, withCredentials: true });
}

export const DownloadPerformanceDashboardCandidatePageData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/download/performanceDashboardCandidatePageData` ;
    return axios.get(url, { params: data, withCredentials: true }, {timeout: 2});
}

export const getPerformanceDashboardCandidateList = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getPerformanceDashboardCandidateList` ;
    return axios.get(url, { params: data, withCredentials: true });
}

export const DownloadReportsPageData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/download/PerformanceDashboardData` ;
    return axios.get(url, { params: data, withCredentials: true });
}

export const getMonthlyPayout = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/payout/monthlyPayout` ;
    return axios.get(url, { params: data, withCredentials: true,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
}

export const getMonthlyPayoutBreakup = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/payout/monthlyPayoutBreakup` ;
    return axios.get(url, { params: data, withCredentials: true,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    }  });
}

export const downloadMonthlyPayoutBreakup = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/download/payoutPageData` ;
    return axios.get(url, { params: data, withCredentials: true ,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
}

export const downloadPayoutLeads = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/download/payout/leads` ;
    return axios.get(url, { params: data, withCredentials: true ,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
}

export const getReportPayoutLeadsList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/payout/leads`;
    return axios.get(url, {withCredentials: true, params: data,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
};

export const showPayoutDashboard = (mitra) => {
    if(mitra && mitra.role === 'admin') {
        return true;
    }
    return false;
}

export const getRunRateCardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/fetchLeadReferralRatesForML`;
    return axios.get(url, { params: data, withCredentials: true });
}