import React, { useState, useEffect, useRef } from "react";
import Lottie from 'react-lottie';
import animationData from '../../../static/lottie/loader.json';
import vahanLogoOutline from '../../../static/images/vahan-logo-outline.svg'
import './NewLoader.css'



const NewLoader = ({ }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sentences = ['Bringing the best leads for you', 'Our AI systems keep data secure', 'Hang on tight! Almost there'];


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Increment currentIndex to display the next sentence
            setCurrentIndex((prevIndex) => (prevIndex + 1) % sentences.length);
        }, 1800); // Change text every 1.8 seconds

        return () => {
            clearInterval(intervalId); // Clean up the interval when component unmounts
        };
    }, [sentences]);

    return (
        <div style={styles.loaderParent}>
            <div style={styles.container}>
                <Lottie
                    options={defaultOptions}
                    height={200}
                    width={200}
                    speed={0.5}
                    style={styles.lottieLoader}
                />
                <img
                    src={vahanLogoOutline}
                    alt="Vahan Loader"
                    style={styles.loaderImage}
                />
            </div>
            <div className="sentenceTxt" style={styles.loaderText}>
                <span className="text-transition">{sentences[currentIndex]}</span>
            </div>
        </div>
    )
}

const styles = {
    loaderParent: {
        textAlign: 'center',
        height: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: "column"
    },
    container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    lottieLoader: {
        borderRadius: 100
    },
    loaderImage: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '75px', // Adjust width and height of the center image
        height: '75px',
        marginTop: '-37.5px', // Half of the image height
        marginLeft: '-37.5px', // Half of the image width
        zIndex: '1', // Ensure the image is displayed on top of the Lottie animation
    },
    loaderText: {
        marginTop: '20px',
        transition: 'opacity 0.5s',
        opacity: 1
    }
};

export default NewLoader