import axios from "axios"

export const getMitraProfileData = (phoneNumber, isAdmin) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/getMitraInfo?phoneNumber=${encodeURIComponent(phoneNumber)}`;
    return axios.get(url, {withCredentials: true,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken'),
        isAdmin: isAdmin
    }})
}

export const postMitraDocs = (data, mitra) => {
    const requestData = {
        submission: {
            data: {
                phoneNumber: mitra.phoneNumber,
                name: mitra.name,
                sourceName: mitra.name,
                sourceId: mitra.id,
            },
            currentPageData: {

            }
        }
    }

    for(let key in data){
        if(Array.isArray(data[key]) && data[key].length > 0){
            requestData.submission.currentPageData[key] = {url: data[key][0].data ? data[key][0].data.imageUrl: data[key][0].url, originalName: data[key][0].originalName, size: data[key][0].size}
            requestData.submission.data[key] = {url: data[key][0].data ? data[key][0].data.imageUrl: data[key][0].url, originalName: data[key][0].originalName, size: data[key][0].size}
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/addProfileData`
    return axios.post(url, requestData, {withCredentials: true});
}