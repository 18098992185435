import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as yup from "yup";
import { Form } from 'react-formio';
import { Button } from '@rmwc/button';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';


import { logEvent, setTrackingUser } from '../../service/tracking';
import trackingConstants from '../../constants/trackingConstants';
import * as loginService from '../../service/loginService';
import * as jobService from '../../service/jobService';

const schema = yup.object().shape({
	city: yup.string().required(),
	role: yup.string().required(),
	jobCategory: yup.string().required(),
	companyName: yup.string().required(),
});

const ADD_JOB_FORM_URL = 'https://sphnlcqnxxthwup.form.io/addJob';
const invalidJobMessage = 'We already have this job. Please try adding another job';

const AddJob = () => {
	const history = useHistory();
	const [ mitra, setMitra ] = useState({});
	const [ availableCategories, setAvailableCategories ] = useState({});
	const [ isValidJob, setIsValidJob ] = useState(false);
	const [ checkedValidity, setCheckedValidity ] = useState(false);
	const [ formSubmitted, setFormSubmitted ] = useState(false);
	const [ submitError, setSubmitError ] = useState(false);
	const [ addJobFormSchema, setAddJobFormSchema ] = useState({});
	const [ dataToPrefill, setDataToPrefill ] = useState({});
	const { register, handleSubmit, errors, reset } = useForm({
		resolver: yupResolver(schema)
	});
	const validateAndProceed = async job => {
		const result = await jobService.validateJob(job);
		setCheckedValidity(true);
		logEvent('ValidateJob', trackingConstants.EVENT_SCREEN_ADD_JOB_BASIC, trackingConstants.EVENT_GROUP_JOB);
		if (result.isValidJob) {
			setIsValidJob(true);
			logEvent('JobIsNew', trackingConstants.EVENT_SCREEN_ADD_JOB_BASIC, trackingConstants.EVENT_GROUP_JOB);
			setDataToPrefill({
				data: {
					role: job.role,
					jobCategory: job.jobCategory,
					companyName: job.companyName,  
					city: job.city
				}
			});
		} else {
			setIsValidJob(false);
			logEvent('JobIsDuplicate', trackingConstants.EVENT_SCREEN_ADD_JOB_BASIC, trackingConstants.EVENT_GROUP_JOB);
		}
	};

	useEffect(() => {
		const getJobCategories = async function() {
			const jobCategories = await jobService.getJobCategories();
			setAvailableCategories(jobCategories);
		}
		getJobCategories();
		async function fetchForm(){
			const theForm = await axios.get(ADD_JOB_FORM_URL);
			setAddJobFormSchema(theForm.data);
		}
		fetchForm();
		loginService
			.validateSession()
			.then((response) => {
				const loggedInMitra = response.data.mitra;
				setMitra(loggedInMitra);
				setTrackingUser(loggedInMitra);
				logEvent('OpenJobsPageBasic', trackingConstants.EVENT_SCREEN_ADD_JOB_BASIC, trackingConstants.EVENT_GROUP_JOB);
			})
			.catch((error) => {
				history.push({
					pathname: '/',
					state: {
						redirectTo: '/jobs'
					}
				})
		});
	}, [history]);

	const resetFields = (e) => {
		reset();
		setIsValidJob(false);
		setCheckedValidity(false);
		setFormSubmitted(false);
		setSubmitError(false);
	}

	const submitJob = async (job) => {
		job.data.mitraPhoneNumber = mitra.phoneNumber;
		const result = await jobService.addJob(job);
		setFormSubmitted(true);
		if (result.status === 'DONE'){
			logEvent('JobAdded', trackingConstants.EVENT_SCREEN_ADD_JOB_DETAIL, trackingConstants.EVENT_GROUP_JOB);
		} else {
			setSubmitError(true);
		}
	};

	return (
		<div className='mainContentZ'>
			<h2 className="heading_1 add-job">
				Vahan Jobs
			</h2> 
			{ !isValidJob ? 
				<div>
					<h4 className="heading_1 form-sub-heading">Basic Info</h4>
					<form onSubmit={handleSubmit(validateAndProceed)}>
						<div className="form-control">
							<div className="input-text-title field-required">City</div>
							<input
								label="City"
								name="city"
								type="text"
								placeholder="City"
								ref={register}
							/>
							<p className="errMsg">{errors.city?.message}</p>
						</div>
						<div className="form-control">
							<div className="input-text-title field-required">Company Name</div>
							<input
								label="Company Name"
								name="companyName"
								type="text"
								placeholder="Company Name"
								ref={register}
							/>
							<p className="errMsg">{errors.companyName?.message}</p>
						</div>
						<div className="form-control">
						<div className="input-text-title field-required">Job Category</div>
							<select
								label="Job Type"
								name="jobCategory"
								type="text"
								ref={register}
							>
								{Object.entries(availableCategories)
									.map((keyValue) => 
										(<option key={keyValue[0]} value={keyValue[0]}>{keyValue[1]}</option>)
									)}
							</select>
							<p className="errMsg">{errors.jobCategory?.message}</p>
						</div>
						<div className="form-control">
							<div className="input-text-title field-required">Role</div>
							<input
								label="Role"
								name="role"
								type="text"
								placeholder="Role"
								ref={register}
							/>
							<p className="errMsg">{errors.role?.message}</p>
						</div>
						<div className="form-control">
							<Button raised label="Check duplicate" type="submit" />
						</div>
						<div className="form-control">
							<p className="errMsg">{checkedValidity && !isValidJob && invalidJobMessage}</p>
						</div>
						{checkedValidity &&
							<div className="form-control">
								<Button raised label="Reset" type="reset" onClick={resetFields}/>
							</div>
						}
					</form>
				</div>
				: 
				<div>
					<h4 className="heading_1 form-sub-heading">More Info</h4>
					<Form submission={dataToPrefill} form={addJobFormSchema} onSubmit={submitJob}/>
					{formSubmitted && !submitError &&
						<div className="form-control">
							<p className="successMsg">You have successfully added the job! Our representative will reach out to you at +91-{mitra.phoneNumber} if required. </p>
							<Button raised type="reset" label="Add New Job" onClick={resetFields}/>
						</div>
					}
					{formSubmitted && submitError &&
						<div className="form-control">
							<p className="errMsg">Error submitting form </p>
							<Button raised type="reset" label="Add Another Job" onClick={resetFields}/>
						</div>
					}
				</div>
			} 
		</div>
	);

};

export default AddJob;
