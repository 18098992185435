import React, {useState, useEffect, useContext, useReducer} from 'react';
// import blitz from 'blitzllama-js';
import '../../css/report.scss'
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import PageHeaderComponent from '../common/PageHeader';
import SearchComponent from '../common/search.component';
import SectionLayout from '../common/SectionLayout.component';
import { getTeamLeaderBoardData, getPerformanceDashboardData, getPerformanceHistory, getCLientPeferenceData, DownloadPerformanceDashboardCandidatePageData, getMonthlyPayout, getMonthlyPayoutBreakup, downloadMonthlyPayoutBreakup, showPayoutDashboard} from './../../service/reportService'
import ClipLoader from 'react-spinners/ClipLoader';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import TeamLeadsCard from './ReportTeamMemberCard.component';
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import {getActiveClientsForFilter, getMitraTeamMembersList, disabledDate, disabledDateForPerformanceDashboard, loginForPayoutDashboard, disabledDateForPayoutDash, expiredLeadsDashboardInfo, sendPayoutDashboardPasswordResetUrl, getLocationForFilter, getMilestoneForFilter} from './../../service/commonService'
import {AppContext} from '../../App';
import {InfoCircleOutlined, InteractionTwoTone, ProjectFilled, RocketFilled, DashboardTwoTone, DollarCircleTwoTone, RetweetOutlined, CaretRightOutlined, CaretUpOutlined, CaretDownOutlined, RightCircleTwoTone,EyeTwoTone,EyeInvisibleOutlined,ReloadOutlined, DownOutlined, DownloadOutlined, FilterOutlined, MobileOutlined} from '@ant-design/icons';
import {DatePicker, Select, Tag, Card, Skeleton, List, Typography, Button, Empty, Tooltip, Table,Modal,Input, message, Dropdown, Space, Menu, notification} from 'antd';
import {Column, Line} from '@ant-design/plots';
import OverallSummary from './OverallSummary';
import ReportLeaderboard from './ReportLeaderBoard.component';
import ClientPerformance from './ClientPerformance';
import {useParams} from "react-router-dom";
import SideSheet from "../common/SideSheet"
import Moment from 'react-moment';
import VerifyEmailPopup from './../common/VerifyEmailPopup.component';
import LocationFilterComponent from '../common/LocationFilter.component';
import {getDisplayableClientName} from './../../utils/commonFunction'
import MitraAppDownloadsSummary from './MitraAppDownloadsSummary';
import RunRateComponent from './RunRate.Component';

const {Option} = Select;
const {Text} = Typography;
const {RangePicker} = DatePicker;
const dateFormat = 'DD-MMMM YYYY';


const ReportPage = () => {
    const {mitraReducer, setAlert, mitraDispatch} = useContext(AppContext);
    const [mitra, setMitra] = useState({})
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [isAdmin, setIsAdmin] = useState(mitraReducer?.mitraInfo?.role === 'admin' ? true : false);
    const history = useHistory();
    const {tab} = useParams();
    const [breadcrumb, setBreadcrumb] = useState([{link: `/report/${tab}`, label: 'Report'}, {link: `/report/${tab}`, label: `${tab === 'payout-dashboard' ? 'Payout Dashboard' : 'Performance Dashboard'} `}]);
    const [gridView, setGridView] = useState(false)
    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [endDate, setEndDate] = useState(moment());
    const [teamList, setTeamList] = useState([]);
    const [performanceDashboardData, setPerformanceDashboardData] = useState();
    const [performanceHistory, setPerformanceHistory] = useState();
    const [cientPerformance, setClientPerformance] = useState([]);
    const [selectedTeamMember, setSelectedTeamMember] = useState(0);
    const [teamLeaderBoardList, setTeamLeaderBoardList] = useState([]);
    const [topPerformerData, setTopPerformerData] = useState({})
    const [clientFilterList, setClientFilterList] = useState([])
    // const [createMoengageUser, trackEvent] = useMoengage();
    const [selectedClient, setSelectedClient] = useState('')
    const [selectedDate, setSelectedDate] = useState(moment().date() < 10 ? moment().subtract(1, "month").endOf("month") : moment())

    const [loaded, setLoaded] = useState(false);
    const ReportGAEventsTracker = useGAEventsTracker("Reports")
    const [showDateChip, setShowDateChip] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [monthlyPayoutData, setMonthlypayoutData] = useState([])
    const [monthlyPayoutDataBreakup, setMonthlypayoutDataBreakup] = useState({})
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [visibleAuth, setVisible] = useState(false);
    const [accessPasswordUsr, setAccessPasswordUsr] = useState('');
    const [payoutProcessingBanner, setPayoutProcessingBanner] = useState({})
    const [expiringLeads, setExpiringLeads] = useState([]);
    const [typeOfLead, setTypeOfLead] = useState('Eligible Leads');
    const [filterSider, setFilterSider] = useState(false);
    const [filterApplied, setFilterApplied] = useState(true);
    const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
    const [downloadType, setDownloadType] = useState('');
    const [changeEmail, setChangeEmail] = useState(false);
    const [payoutDashboardHeadersArray, setPayoutDashboardHeadersArray] = useState([]);
    const [locationList, setLocationList] = useState([])
    const [milestoneList, setMilestoneList] = useState([
        {
            label: 'F1 ~ First Trip',
            key: 'first_date_of_work',
            value: true,
        },
        {
            label: 'F10 ~ First 10 Trips',
            key: '10th_order_date',
            value: true,
        },
        {
            label: 'F20 ~ First 20 Trips',
            key: '20th_order_date',
            value: true,
        },
        {
            label: 'F50 ~ First 50 Trips',
            key: '50th_order_date',
            value: true,
        },
        {
            label: 'F80 ~ First 80 Trips',
            key: '80th_order_date',
            value: true,
        },
    ])
    const [isItemExpanded, setIsItemExpanded] = useState([
        {label: 'Base Pay', expanded: false},
        {label: 'Additions', expanded: false},
        {label: 'Deductions', expanded: false}
    ]);
    const [displayPayoutTopSection , setDisplayPayoutTopSection] = useState(true);

    const items = [
        {
          label: 'Payout Summary',
          key: '1',
          type: 'dashboard',
          icon: <DownloadOutlined />,
        },
        {
          label: 'Payout Summary and leads data',
          key: '2',
          type: 'leads',
          icon: <DownloadOutlined />,
        }
      ];

    const onClickTotalLead=(record)=>{
        if(tab === 'payout-dashboard'){
            trackEvent('viewed_candidates_list_from_payout_dash_main_page',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        }
        history.push({
            pathname: '/leads-page',
            state: {
                payoutname: record.campaignName || record.tag,
                expiringLeadsLabel: isNaN(record.key) ? record?.key: null,
                payload: {
                    limit: 50,
                    month: moment(selectedDate).format("MM"),
                    year: moment(selectedDate).format("YYYY"),
                    search: '',
                    offset: 0,
                    label: record.id || record.campaignName || record.tag,
                    tag: isNaN(record.key) ? record?.tag: null,
                    dashboard: tab,
                    client:selectedClient
                },
            },
        });
    }

    const expandedRowRender = (record, index, indent, expanded) => {
        trackEvent('viewed_breakdown_payout_dash_main_page',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        return (
            <Table
                className="payout-table"
                columns={monthlyPayoutDataBreakup.columns}
                dataSource={record.expandedRows}
                pagination={false}
                scroll={{x: 320}}
            />
        );
    };

    const getRequestData = () => {
        let selectedMitrasArray = [], selectedClientArray = [], selectLocationList=[], selectedMilestoneList = [];
          for(let mitra of teamList){
            if(mitra.value === true){
                selectedMitrasArray.push(mitra.id)
            }
          }
          for(let client of clientFilterList){
            if(client.value === true){
                selectedClientArray.push(client.label)
            }
          }
        locationList.map(status => {
            if (status.value) {
                selectLocationList.push(status.label)
            }
        })
        for(let milestone of milestoneList){
            if(milestone.value === true){
                selectedMilestoneList.push(milestone)
            }
        }
        if(selectLocationList.length > 0){
			trackEvent('clicked_city_filter_performance_main_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		}
        let managerId = isAdmin ?
            selectedMitrasArray.length === 0 ?
                mitra.id : null
            : mitra.id
        let dataReq = {
            mitraIdArray: selectedMitrasArray.length > 0 ? selectedMitrasArray : [],
            clientNamesArray: selectedClientArray.length > 0 ? selectedClientArray : [],
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            managerMitraID: managerId,
            graphView: gridView,
            typeOfLead: typeOfLead,
            locations: selectLocationList.length > 0 ? selectLocationList : [],
            milestones: selectedMilestoneList.length > 0 ? selectedMilestoneList : [],
        }
        return dataReq
    }

    const callAllPerformanceApis = async () => {
        const reqData = getRequestData()
         getTeamLeaderBoardData({...reqData, limit: 5, managerMitraID: isAdmin ? mitra.id : mitra.managerMitraID, mitraId: isAdmin ? reqData.mitraId : ''})
            .then(response => {
                setTeamLeaderBoardList(response.data.data.data);
                setTopPerformerData(response.data.data.topPerformerData)
            })

         getCLientPeferenceData(getRequestData()).then((response) => {
            setClientPerformance(response.data)
        }).catch(
            console.log("error clientwise")
        );
         getPerformanceHistory(getRequestData()).then((response) => {
            setPerformanceHistory(response.data);
        });

         getPerformanceDashboardData(getRequestData()).then((response) => {
            setPerformanceDashboardData(response.data)
        }).catch(err => {
        });
    }

    const callPayoutApi = () => {
        let selectedClientArray = []
        for(let client of clientFilterList){
            if(client.value === true){
                selectedClientArray.push(client.label)
            }
        }
        if(selectedClientArray.length > 0){
            setDisplayPayoutTopSection(false)
        }
        const data = {
            month: moment(selectedDate).format("MM"),
            year: moment(selectedDate).format("YYYY"),
            clientsArray: selectedClientArray
        }
        setMonthlypayoutData([]) 
        setMonthlypayoutDataBreakup({})
        if (selectedClientArray.length === 0) {
            getMonthlyPayout(data).then(res => {
                setMonthlypayoutData(res.data.data)
                setPayoutProcessingBanner(res.data.showPayoutBanner)
                setVisible(false)
            })
        }
        getMonthlyPayoutBreakup(data).then(res => {
            if(res.data.success){
                let foundTotalLeads = res.data.columns.findIndex(function (element) {
                    return element.dataIndex == "totalLeads";
                });
                let foundAmount = res.data.columns.findIndex(function (element) {
                    return element.dataIndex == "amount";
                });

                let foundcampaignName = res.data.columns.findIndex(function (element) {
                    return element.dataIndex == "campaignName";
                });

                res.data.columns[foundcampaignName] = {
                    "title": "Campaign Name & Details",
                    "dataIndex": "campaignName",
                    "key": "campaignName",
                    render(text, record) {
                        return {
                            children: <div className='campaign-name'>{record.displayName && record.displayName.trim() ? record.displayName : text}</div>
                        };
                    }
                }

                res.data.columns[foundTotalLeads] = {
                    "title": "Total Count",
                    "dataIndex": "totalLeads",
                    "key": "totalleads",
                    render(text, record) {
                        return {
                            children: (record.campaignName === 'Base Pay' || record.parentName === 'Base Pay') ?
                                <div onClick={() => {
                                    onClickTotalLead(record)
                                }} className='totallead-text'  style={{textDecoration:'underline',cursor:'pointer'}}>{text}<CaretRightOutlined style={{marginLeft: 6}} twoToneColor="#D34612" />
                                </div> : <div className='totallead-text'>{text}</div>
                        };
                    }
                }
                res.data.columns = [
                    res.data.columns[0],
                    Table.EXPAND_COLUMN,
                    ...res.data.columns.slice(1)
                ]
                setMonthlypayoutDataBreakup(res.data)
                const columnArray = res.data.columns.map((item) => {
                    return item.dataIndex
                })
                setPayoutDashboardHeadersArray(columnArray)
                
                setVisible(false)
            }else{
                setVisible(true)
            }
        }).catch(e=>{
            localStorage.removeItem('payoutAccessToken');
            setVisible(true);
        })
    };

    const fetchExcludedLeadsInformation = () => {
        
        const monthYear = moment(selectedDate).format("MM") + '-' + moment(selectedDate).format("YYYY");
        
        expiredLeadsDashboardInfo(monthYear).then(response => {
            if(response && response.data && response.data.data && response.data.data.length === 0){
                setExpiringLeads(['No Data']);
            }else{
                setExpiringLeads(response.data.data);
            }
        }).catch(error => {
            console.log(error, 'Expired Leads');
        })
    };

    useEffect(() => {
        getMilestoneForFilter().then(response=>{
            setMilestoneList(response.data.milestoneList)
        })
        if(sessionStorage.getItem('payoutSelectedDate')){
            setSelectedDate(moment(sessionStorage.getItem('payoutSelectedDate'),'MM-YYYY'));
        }   
        if (mitraReducer?.mitraInfo && !mitra.id) {
            setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
            setMitra(mitraReducer?.mitraInfo)
            trackEvent('reports_section_visited', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
            getMitraTeamMembersList().then(response => {
                let list = []
                list.push({label: mitraReducer?.mitraInfo.name, id: mitraReducer?.mitraInfo.id, value: false})
                for (let user in response.data.data) {
                    list.push({label: response.data.data[user].name, id: response.data.data[user].id, value: false});
                }
                setTeamList(list);
                getActiveClientsForFilter().then(data => {
                    setClientFilterList(data.data.activeClients)
                })
            });
            getLocationForFilter().then(response=>{
				setLocationList(response.data.locationList)
			})
            if(mitraReducer?.mitraInfo?.role === 'admin') {
                const data = {
                    mitraId: mitraReducer?.mitraInfo?.id
                }
            }
            // fetchExcludedLeadsInformation();
        }
    }, [mitraReducer]);

    useEffect(() => {
        if (teamList.length) {
            getCLientPeferenceData(getRequestData()).then((response) => {
                setClientPerformance(response.data)
            });
        }
    }, [gridView]);

    useEffect(() => {
        if(mitra && mitra.id && filterApplied) {
            setTeamLeaderBoardList([])
            setClientPerformance([])
            setPerformanceHistory()
            setPerformanceDashboardData()
            setLoaded(false)
            setBreadcrumb([{link: `/report/${tab}`, label: 'Report'}, {link: `/report/${tab}`, label: `${tab === 'payout-dashboard' ? 'Payout Dashboard' : 'Performance Dashboard'} `}]);
            if (tab === 'performance-dashboard') {
                // blitz.triggerEvent('viewed_performance_dash_main_page');
                trackEvent('viewed_performance_dash_main_page', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                callAllPerformanceApis();
            }
            if (tab === 'payout-dashboard') {
                callPayoutApi();
                // fetchExcludedLeadsInformation();
                // blitz.triggerEvent('viewed_payout_dash_main_page');
                trackEvent('viewed_payout_dash_main_page', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            }
            if (tab !== 'payout-dashboard') {
                ReportGAEventsTracker('ViewedPerformanceDashboard');
                window.fbq('trackCustom', 'ViewedPerformanceDashboard',{
					phone: mitraReducer?.mitraInfo?.phoneNumber,
					city: mitraReducer?.mitraInfo?.homeCity,
					name: mitraReducer?.mitraInfo?.name,
					email: mitraReducer?.mitraInfo?.email,
                    fbclid: mitraReducer?.mitraInfo?.fbclid
				});
            }
            setFilterApplied(false)
            setLoaded(true)
        }
    }, [mitra, tab, typeOfLead, filterApplied]); 
        // teamList, startDate, endDate, selectedClient, selectedTeamMember, selectedDate, 

    const handleSheet = async (email) => {
        setDownloadLoading(true)
        if(tab !== 'payout-dashboard') {
            trackEvent('downloaded_sheet_performance_dash_main_page',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);

            let selectedMitrasArray = [], selectedClientArray = [], selectedLocationList = [];
                for(let mitra of teamList){
                    if(mitra.value === true){
                        selectedMitrasArray.push(mitra.id)
                    }
                }

                for(let client of clientFilterList){
                    if(client.value === true){
                        selectedClientArray.push(client.label)
                    }
                }
                for(let location of locationList){
                    if(location.value === true){
                        selectedLocationList.push(location.label)
                    }
                }

            const requestParams = {
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                clients: selectedClientArray.length > 0 ? JSON.stringify(selectedClientArray) : JSON.stringify([]),
                statuses: JSON.stringify([]),
                teamMemberIds: selectedMitrasArray.length > 0 ? JSON.stringify(selectedMitrasArray) : JSON.stringify([]),
                latestMilestonesFilter: JSON.stringify([]),
                locationFilter: JSON.stringify([]),
                search: '',
                typeOfLead: typeOfLead,
                locations: selectedLocationList.length > 0 ? selectedLocationList : [],
                email: email,
                changeEmail: changeEmail
            }
            DownloadPerformanceDashboardCandidatePageData(requestParams).then(response => {
                if (response.data.responseCandidatesUrl) {
                    console.log(response)
                    window.open(response.data.responseCandidatesUrl, '_blank')
                }
                else {
                    setAlert({
                        display: true,
                        text: response.data.message,
                        type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
                    });
                }
                setDownloadLoading(false)
            })
        }
        else {
            let selectedClientArray = []
            for(let client of clientFilterList){
                if(client.value === true){
                    selectedClientArray.push(client.label)
                }
            }
            const data = {
                month: moment(selectedDate).format("MM"),
                year: moment(selectedDate).format("YYYY"),
                clientArray: selectedClientArray,
                type: downloadType,
                email: email,
                changeEmail: changeEmail
            }
            trackEvent('downloaded_sheet_payout_dash_main_page',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            downloadMonthlyPayoutBreakup(data).then(response => {
                console.log(response)
                if (response.data.responseCandidatesUrl) {
                    window.open(response.data.responseCandidatesUrl, '_blank')
                }
                else {
                    setAlert({
                        display: true,
                        text: response.data.message,
                        type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
                    });
                }
                setDownloadLoading(false)
            }).catch(e =>{
                localStorage.removeItem('payoutAccessToken');
                setVisible(true)
            })
        }
    }

    const downloadMenu = (
		<Menu onClick={(e) => {
            if(mitra.email == null || mitra.email === '') {
                setChangeEmail(true);
            }
            setShowVerifyEmailPopup(true)
            setDownloadType(items[e.key-1].type);
        }}>
			{items.map((item, index) => {
				return <Menu.Item key={item.key}>
					{item.label}
				</Menu.Item>
			})}
		</Menu>
	);

    const onclickDownloadPerformance = () => {
        if(mitra.email == null || mitra.email === '') {
            setChangeEmail(true);
        }
        setShowVerifyEmailPopup(true)
    }

    const deleteChip = (type, value) => {
        if (type === 'teamMember') {
            let teamListCopy = [...teamList];
            teamListCopy.forEach((teamMember) => {
                if (teamMember.label === value) {
                    teamMember.value = false;
                }
            });
            setTeamList(teamListCopy);
        }
        if (type === 'client') {
            let clientFilterListCopy = [...clientFilterList];
            clientFilterListCopy.forEach((client) => {
                if (client.key === value) {
                    client.value = false;
                }
            });
            setClientFilterList(clientFilterListCopy);
        }
        else if (type === 'location') {
			let newCategory = [...locationList]
			newCategory.map((single_category, index) => {
				if (single_category.label === value.label) {
					single_category.value = false
				}
			})
			setLocationList(newCategory)
		}
        else if (type === 'milestone') {
			let milestoneFilterListCopy = [...milestoneList];
            milestoneFilterListCopy.forEach((milestone) => {
                if (milestone.key === value) {
                    milestone.value = false;
                }
            });
            setMilestoneList(milestoneFilterListCopy);
		}
    }

    const handleDateRange = (dates) => {
        if (tab === 'payout-dashboard') {
            sessionStorage.setItem('payoutSelectedDate',moment(dates).format('MM-YYYY'))
            setSelectedDate(dates)
            trackEvent('used_month_selector_payout_dash_main_page',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS) 
        }else{
            if (dates === null) {
                setStartDate(moment().startOf('month'));
                setEndDate(moment());
            }
            else {
                setStartDate(dates[0]);
                setEndDate(dates[1]);
            }
            trackEvent('used_date_selector_performance_dash_main_page',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS) 
        }

    }

    // const handleClientChange = (e) => {
    //     setSelectedClient(e)
    //     ReportGAEventsTracker("ClickOnReportClientFilter")
    //     if(tab === 'payout-dashboard'){
    //         trackEvent('used_client_filter_payout_dash_main_page',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
    //     }
    // }
    const handleTypeOfLeadsChange = (e) => {
        setTypeOfLead(e)
        // ReportGAEventsTracker("ClickOnReportClientFilter")
        // if(tab === 'payout-dashboard'){
        //     trackEvent('used_client_filter_payout_dash_main_page',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        // }
    }
    // const handleTeamMemberChange = (e) => {
    //     setSelectedTeamMember(e)
    // }

    const handleViewAll = () => {
        history.push({
            pathname: '/team-leader-board',
            search: '?' + 'Team Leader Board',
        });
        mitraDispatch({
            type: 'reportParams', value: {
                startDate, endDate, selectedClient, selectedTeamMember, teamList, typeOfLead, locationList
            }
        })
    }

    const onResetPasswordClick = () => {
        notification.open({
            message: `A password ${mitraReducer?.mitraInfo?.password ? 'reset' : 'creation'} SMS has been sent to your phone number ${mitraReducer?.mitraInfo?.phoneNumber}`,
            duration: 15,
            icon: <InfoCircleOutlined style={{ color: '#52C41A' }} /> ,
            description: <Button type="primary" size="small" onClick={() => history.push(`/report/payout-dashboard`)}>Go to Payout Dashboard</Button>
          });
        let isNewPassword = mitraReducer?.mitraInfo?.password ? false : true
        sendPayoutDashboardPasswordResetUrl(isNewPassword);
        history.push('/home')
    }
    const handleOkClick = () => {
        setConfirmLoading(true);
        loginForPayoutDashboard({password:accessPasswordUsr}).then(res=>{
            if(res.data.success){
                localStorage.setItem("payoutAccessToken",res.headers.payoutaccesstoken)
                setTimeout(callPayoutApi(),5000)
                setVisible(false);
            } else {
                message.error("Invalid credentials!")
            }
            setConfirmLoading(false);
        }).catch((e)=>{
            message.error("Invalid credentials!")
            setConfirmLoading(false);
        })
    }

   const getButtons = () => {
    let buttonsArr = [];
    if(mitraReducer?.mitraInfo?.password) {
        buttonsArr.push(
            <Button  
                onClick={onResetPasswordClick}
                type="secondary">
                <Text >Reset Password</Text>
            </Button>
        );
        buttonsArr.push(
            <Button  
            onClick={handleOkClick}
            type="primary">
            <Text style={{ color: '#fff' }}> OK </Text>
            </Button>
        )
    }
    return buttonsArr;
   }
    const configLinePlot = {
        data: performanceHistory?.data?.values,
        xField: 'date',
        yField: 'value',
        seriesField: 'category',
        style: {backgroundColor: "#fff"},
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
        smooth: true,
        color: ['#FFEAE0', '#D34612', '#A0D911', '#52C41A', '#237804', '#13C2C2'],
    };

    const configColumnPlot = {
        data: cientPerformance?.data,
        xField: 'client',
        yField: 'value',
        seriesField: 'type',
        isGroup: 'true',
        columnStyle: {
            radius: [5, 5, 0, 0],
        },
        style: {backgroundColor: "#fff"},
        tooltip: {
            showMarkers: false,
            // enterable: true,
            domStyles: {
                'g2-tooltip': {
                    padding: '10px',
                    paddingTop: '0',
                    width: '216px'
                },
                'g2-tooltip-list': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    fontFamily: 'Nunito Sans Medium'
                },
                'g2-tooltip-list-item': {
                    margin: '3px'
                },
                'tooltip-color-shape': {
                    display: 'flex',
                    height: '8px',
                    width: '12px',
                    marginRight: '5px'
                },
                'tooltip-list-wrapper': {
                    padding: '10px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '0px'
                },
                'tooltip-value': {
                    fontFamily: 'Nunito Sans Bold'
                }
            },
            customContent: (title, items) => {
                return <div align='center'>
                    <img className="imgResponse" alt={title} src={"/image/" + title + ".png"} />
                    <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        dataSource={items}
                        renderItem={item => (
                            <div className='tooltip-list-wrapper'>
                                <span className='tooltip-list-wrapper'>
                                    <span className='tooltip-color-shape' style={{backgroundColor: item.color}}></span>
                                    <span>{item.name}</span>
                                </span>
                                <span className='tooltip-value'>{item.value}</span>
                            </div>
                        )}
                    />
                </div>
            },
        },
        color: ['#FFEAE0', '#D34612', '#A0D911', '#52C41A', '#237804', '#13C2C2'],
    };


    const submitFilter = () => {
		setFilterApplied(true)
	}

    const clearAll=()=>{
		teamList.map(item => {
			item.value=false;
		})
        clientFilterList.map(item => {
			item.value=false;
		})
		setStartDate(moment().startOf('month'));
		setEndDate(moment());
		setFilterApplied(true)
	}

    const applyFilterCondition = () =>{
        let result=false;
        let arr = [...teamList, ...clientFilterList];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].value === true) {
                result=true;
                break;
            }
            }
        return result;
    }

    const onClickTags=(label,index)=>{
        
		if(label === 'Clients'){
			clientFilterList[index].value = !clientFilterList[index].value;
		}
		if(label === 'Team Member'){
			teamList[index].value = !teamList[index].value;
		}
        if(label === 'Location'){
			locationList[index].value=!locationList[index].value;
		}
        if(label === 'Status (Choose up to 5 statuses)')
        {
            if (milestoneList.filter(item => item.value === true).length <= 4 || milestoneList[index].value === true)
                milestoneList[index].value = !milestoneList[index].value;
        }
		forceUpdate();
	}

    const  preventDefault=(e,label,item) =>{
        e.preventDefault()
        deleteChip(label, item)
        setFilterApplied(true)
    }

    const commonTag = (label, arrObj) =>{
		return <div className='tag-containers'>
			<div>{label}</div>
			{
                label === "Date Range" || label === "Month Selection" ?
                    <Space>
                        {
                            tab === "performance-dashboard" ?
                                <RangePicker
                                    defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                    format={dateFormat}
                                    onChange={handleDateRange}
                                    ranges={{
                                        Today: [moment().startOf('day'), moment().endOf('day')],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    }}
                                    disabledDate={(current) => {return disabledDateForPerformanceDashboard(current, moment(mitra.createdAt).valueOf())}}
                                    numberOfMonths={1}
                                    allowClear
                                />
                            :
                                <DatePicker
                                    onChange={handleDateRange}
                                    format="MMMM YYYY"
                                    allowClear={false}
                                    picker="month"
                                    value={selectedDate}
                                    disabledDate={disabledDateForPayoutDash}
                                    defaultValue={selectedDate}
                                    style={{width: 150}} 
                                />
                        }
                    </Space> 
                : 
                label === "Location" ? 
                <LocationFilterComponent
                    locationList={locationList}
                    onClickTags={onClickTags}
                    label={label}
                    setLocationList={setLocationList}
                />
                :
                    <div className='tag-wrapper-container'>
                        {arrObj.map((item,index)=>{
                            return <Tag className={!item.value ? 'tags':'tags tag-active'}
                                key={index}
                                // closable={item.value}
                                onClick={()=>{onClickTags(label, index)}}
                            >
                                {label === "Clients" ? getDisplayableClientName(item.label) : item.label}
                            </Tag>
                        })}
                    </div>
			}
		</div>
	}
    function onExpandPayoutDashItem(item) {
        const newExpendedArray = [...isItemExpanded]
        for(let i = 0; i < newExpendedArray.length; i++) {
            if(newExpendedArray[i].label === item) {
                newExpendedArray[i].expanded = !newExpendedArray[i].expanded
            }
        }
        setIsItemExpanded(newExpendedArray)
    }

    function showDownArrow(item) {
        for(let i = 0; i < isItemExpanded.length; i++) {
            if(isItemExpanded[i].label === item) {
                return isItemExpanded[i].expanded
            }
        }
    }
    



    return (
        <>
            <div className="ReportPage mainContentZ">
                <Modal
                    title={<Text style={{display:'flex',alignItems:'center'}} strong>Authentication</Text>}
                    visible={visibleAuth && tab === 'payout-dashboard' && !localStorage.getItem("payoutAccessToken")}
                    onOk={()=>{
                    }}
                    onCancel={()=>{
                        history.goBack();
                    }}
                    footer={getButtons()}
                >
                {
                    mitraReducer?.mitraInfo?.password ?
                    <Input.Password
                        placeholder="Input password to access payout dashboard"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined/>)}
                        onChange={(e)=>{
                            setAccessPasswordUsr(e.target.value)
                        }}
                        defaultValue={accessPasswordUsr}
                    /> : 
                    <Button  
                        onClick={onResetPasswordClick}
                        type="secondary">
                        <Text > Create New Password</Text>
                    </Button>
                }
                    {/* <div className='forgot-payout-password'>Get in touch with your Vahan Account Manager for password</div> */}
                </Modal>
                <div className="header">
                    <>
                        <PageHeaderComponent dropDownObj={{
                            dropDownArr: [
                                {
                                    value: 'performanceDashboard',
                                    route: '/report/performance-dashboard',
                                    label: 'Performance Dashboard ',
                                    disabled: false

                                },
                                {
                                    value: 'payoutDashboard',
                                    route: '/report/payout-dashboard',
                                    label: 'Payout Dashboard',
                                    disabled: !showPayoutDashboard(mitraReducer?.mitraInfo)
                                }
                            ],
                            defaultValue: tab
                        }} breadcrumb={breadcrumb} />
                    </>
                </div>

                <div className='containerMiddle'>

                    <div className="more-filters">

                        <div className="filter-set-container">

						<Button style={(applyFilterCondition()) ? {color:'#D34612',backgroundColor:'#FFEAE0',border:'1px solid #D34612'} :null} onClick={()=>setFilterSider(true)} icon={<FilterOutlined />}>Apply Filters</Button>

                            {/* <DatePicker value={selectedDate} picker="month" disabledDate={disabledDate} onChange={handleDateChange} defaultValue={selectedDate} format="MMM-YYYY" allowClear={false} /> */}

                            {/* {tab === "performance-dashboard" && <RangePicker
                                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                                format={dateFormat}
                                onChange={handleDateRange}
                                ranges={{
                                    Today: [moment(), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                }}
                                disabledDate={disabledDate}
                                numberOfMonths={1}
                                allowClear
                            />} */}

                            {/* {tab === "payout-dashboard" &&
                                <DatePicker
                                    onChange={handleDateRange}
                                    format="MMMM YYYY"
                                    allowClear={false}
                                    picker="month"
                                    value={selectedDate}
                                    disabledDate={disabledDateForPayoutDash}
                                    defaultValue={selectedDate}
                                    style={{width: 150}} />
                            } */}


                            {/* <Select
                                className="Dropdown-control filter-label"
                                value={selectedClient === '' ? "All Clients" : selectedClient}
                                placeholder="Select Client"
                                onChange={handleClientChange}
                            >
                                {clientFilterList.map((item, index) => {
                                    return <Option key={"clientFilter" + index} value={item.label}>{item.label}</Option>
                                })}
                            </Select> */}


                            {/* {tab !== 'payout-dashboard' ? <Select
                                className="Dropdown-control filter-label"
                                value={typeOfLead}
                                placeholder="Type of leads"
                                onChange={handleTypeOfLeadsChange}
                            >
                                <Option key={"Eligible Leads"} value={"Eligible Leads"}>Eligible Leads</Option>
                                <Option key={"All Leads"} value={"All Leads"}>All Leads</Option>
                            </Select> : null
                            } */}


                            {isAdmin ?
                                <>
                                    {/* {tab !== 'payout-dashboard' && <Select
                                        className="Dropdown-control filter-label"
                                        value={selectedTeamMember === '' ? null : selectedTeamMember}
                                        placeholder="Select Team Member"
                                        onChange={handleTeamMemberChange}
                                    >
                                        {teamList.map((item, index) => {
                                            return <Option key={"teamList" + index} value={index}>{item.name}</Option>
                                        })}
                                    </Select>*/}
                                    
                                    {
                                        tab !== 'payout-dashboard' && <Button danger loading={downloadLoading}  style={{margin: '0px 10px 0px auto'}}
                                            onClick={() => {
                                                onclickDownloadPerformance();
                                            }}>
                                            Download File
                                        </Button>
                                    }   
                                    {
                                        tab === 'payout-dashboard' && <div style={{margin: '0px 10px 0px auto'}}>
                                            <Dropdown overlay={downloadMenu} >
                                                <Button danger>
                                                    <Space>
                                                        Download
                                                    <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    }
                                    
                                    
                                </>
                                : null}

                        </div>
                        <div className="Chips-container">
							{
								(tab === 'performance-dashboard' && startDate !== null && endDate !== null) ?
                                    <>
                                        <Tag className="single-chip"  onClose={(e)=>preventDefault(e,'date',startDate)}> <Moment format="DD MMM YYYY" withTitle>{startDate}</Moment> </Tag>
                                        <Text style={{marginRight: '3px'}}>to</Text>
                                        <Tag className="single-chip"  onClose={(e)=>preventDefault(e,'date',endDate)}> <Moment format="DD MMM YYYY" withTitle>{endDate}</Moment> </Tag>
                                    </>
								:
                                    <>
                                        <Tag className="single-chip"  onClose={(e)=>preventDefault(e,'date',startDate)}> <Moment format="DD MMM YYYY" withTitle>{moment(selectedDate).startOf('month')}</Moment> </Tag>
                                        <Text style={{marginRight: '3px'}}>to</Text>
                                        <Tag className="single-chip"  onClose={(e)=>preventDefault(e,'date',endDate)}> <Moment format="DD MMM YYYY" withTitle>{selectedDate.endOf('month')}</Moment> </Tag>
                                    </>
							}	
							{
								clientFilterList?.map((single_category,index) => {
									if (single_category.value) {
                                        return <Tag key={'tagLead'+index} className="single-chip" closable onClose={(e)=>preventDefault(e,'client',single_category.key)}>{getDisplayableClientName(single_category.label)} </Tag>
                                    }
								})
							}

							{
								teamList?.map((teamMember,index) => {
									if (teamMember.value) {
										return <Tag  key={'tagLead'+index} className="single-chip" closable onClose={(e)=>preventDefault(e,'teamMember',teamMember.label)}>{teamMember.label} </Tag>
									}
								})
							}
                            {
								[...new Set(locationList)]?.map((single_status, index) => {
									if (single_status.value) {
										return <Tag key={index} className="single-chip" closable onClose={(e)=>preventDefault(e,'location',single_status)}>{single_status.label} </Tag>
									}
								})
							}
                            {(tab === 'performance-dashboard') &&
                                milestoneList?.map((single_category, index) => {
                                    if (single_category.value) {
                                        return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'milestone', single_category.key)}>{single_category.label} </Tag>
                                    }
                                })
                            }
					</div>
                    </div>

                    {
                        (selectedTeamMember !== 0 || selectedClient !== '' || showDateChip) ?
                            <div className="Chips-container" style={{padding: "0 20px"}}>
                                {/* {
                                <Tag className="single-chip" closable onClose={()=>deleteChip('month')}> {moment(startDate).format("DD-MMM-YYYY")-moment(endDate).format("DD-MMM-YYYY")}-</Tag>
                            } */}
                                {
                                    selectedTeamMember !== 0 ?
                                        <Tag className="single-chip" closable onClose={() => deleteChip('teamMember')}> {teamList[selectedTeamMember].name}</Tag>
                                        : null
                                }
                                {
                                    selectedClient !== '' ?
                                        <Tag className="single-chip" closable onClose={() => deleteChip('client')}> {selectedClient}</Tag>
                                        : null
                                }
                            </div>
                            : null
                    }

                    {tab === 'performance-dashboard' && <div className="main-container">
                    {
                        typeOfLead === 'Eligible Leads' ? <div style={{margin: '10px 20px'}}>
                            <div className="warning-message-at-risk-container">
                                <div className="warning-message-atrisk">
                                    <InfoCircleOutlined  style={{color: '#D34612', margin: '5px 10px 0px 10px'}} /> 
                                    This information is about leads that reached the milestone before expiring.
                                </div>
                            </div>
                        </div> : null
                    }

                    <SectionLayout themeWhite={true} icon={<DashboardTwoTone twoToneColor="#D34612" />} title={"Overall Summary"}>
                        <OverallSummary mitraDispatch={() => mitraDispatch({
                            type: 'reportParams', value: {
                                startDate, endDate,
                                clientList: clientFilterList,
                                selectedTeamMember,
                                teamList,
                                typeOfLead,
                                locationList,
                                selectedMilestoneList: Object.keys(milestoneList).filter(key => milestoneList[key].value)?.map(key => milestoneList[key].label),
                            }
                        })} performanceDashboardData={performanceDashboardData} />

                        {
                            mitraReducer?.mitraInfo?.role === 'admin' && <RunRateComponent />
                        }

                    </SectionLayout>

                    <SectionLayout themeWhite={true} icon={<MobileOutlined twoToneColor="#D34612" />} title={"Vahan App Download Summary"}>
                        <MitraAppDownloadsSummary mitraDispatch={() => mitraDispatch({
                            type: 'reportParams', value: {
                                startDate, endDate,
                                clientList: clientFilterList,
                                selectedTeamMember,
                                teamList,
                                typeOfLead,
                                locationList,
                                selectedMilestoneList: Object.keys(milestoneList).filter(key => milestoneList[key].value)?.map(key => milestoneList[key].label),
                            }
                        })} performanceDashboardData={performanceDashboardData} />
                    </SectionLayout>

                    <SectionLayout gridView={gridView} switchName={'Graph View'} onChangeGrid={() => {setGridView(!gridView)}} icon={<ProjectFilled />} title={"Client-wise Performance"}>
                        {
                            gridView && (cientPerformance?.data ?
                                cientPerformance?.data?.length > 0 ?
                                    <Column {...configColumnPlot} /> 
                                : <Empty />
                            : 
                                <Skeleton active />)
                        }
                        {
                            !gridView && (cientPerformance?.arrayFormat ?
                                (cientPerformance?.arrayFormat?.length > 0 ? 
                                    <ClientPerformance mitraDispatch={(client) => mitraDispatch({
                                        type: 'reportParams', value: {
                                            startDate, endDate, clientList: function () {
                                                clientFilterList.map(item => {
                                                    if (item.label.toLowerCase() === client.toLowerCase()) {
                                                        item.value = true
                                                    } else {
                                                        item.value = false
                                                    }
                                                })
                                                return clientFilterList;
                                            },
                                            selectedTeamMember, teamList,
                                            typeOfLead,
                                            locationList,
                                            selectedMilestoneList: Object.keys(milestoneList).filter(key => milestoneList[key].value)?.map(key => milestoneList[key].label),
                                        }
                                    })} cientPerformance={cientPerformance} /> 
                                : 
                                    <Empty />)
                            : <Skeleton active />)
                        }
                    </SectionLayout>
                    <SectionLayout themeWhite={true} icon={<RocketFilled />} title={"Team Leaderboard"}>
                        {
                            teamLeaderBoardList.length ?
                                teamLeaderBoardList.length > 0 ? 
                                    <ReportLeaderboard teamLeaderBoardList={teamLeaderBoardList} loading={loaded} topPerformerData={topPerformerData} startDate={startDate} endDate={endDate} handleViewAll={handleViewAll} />
                                : 
                                    <Empty />
                            : 
                                <Skeleton active />
                        }
                    </SectionLayout>
                    <SectionLayout icon={<InteractionTwoTone twoToneColor="#D34612" />} title={"Performance History"}>
                        {
                            performanceHistory ?
                                performanceHistory?.data?.values.length > 0 ? 
                                    <Line {...configLinePlot} /> 
                                : 
                                    <Empty />
                            : 
                                <Skeleton active /> 
                        }
                    </SectionLayout>
                    </div>}

                    {
                        !visibleAuth && localStorage.getItem('payoutAccessToken') && tab === 'payout-dashboard' &&
                        <div className="main-container">
                            {
                                displayPayoutTopSection ?
                                    <SectionLayout themeWhite={true} icon={<DollarCircleTwoTone twoToneColor="#D34612" />} title={`Total ${moment(selectedDate).format("MMMM")} Payouts`}>
                                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                            {monthlyPayoutData.length> 0 && monthlyPayoutData?.map((item, index) => {
                                                return <Card key={index} className='overall-card' style={{marginBottom: '20px', marginRight: '14px', flex: '1 1 0', backgroundColor: (index === 3 ? '#52C41A' : null)}}>
                                                    <div className='overall-header'>
                                                        <Text style={{color: (index === 3 ? 'white' : null)}} className='summary-header'>{item.name}</Text>
                                                        <Tooltip title={item.tooltip}>
                                                            <InfoCircleOutlined style={{color: (index === 3 ? 'white' : null)}} />
                                                        </Tooltip>
                                                    </div>
                                                    <div className='overall-number'>
                                                        <Text className='number' style={{fontFamily: 'Nunito Sans Bold', color: (index === 0 ? null : index === 1 ? '#52C41A' : index === 2 ? '#FF4D4F' : '#FFFFFF')}}>
                                                        {
                                                           item.amount === '--' ? null :  <Text style={{fontFamily: 'Nunito Sans Bold', color: (index === 0 ? null : index === 1 ? '#52C41A' : index === 2 ? '#FF4D4F' : '#FFFFFF')}}>{item.deduction ? '-' :(index === 3 ? '=' : null)} </Text>
                                                        }
                                                        {item.amount}
                                                        </Text>
                                                    </div>
                                                </Card>
                                            })}
                                        </div>
                                    </SectionLayout>
                                    : null
                            }

                            <SectionLayout themeWhite={true} icon={<RetweetOutlined twoToneColor="#D34612" />} title={`${moment(selectedDate).format("MMMM")} Payout Breakup`}>
                                {
                                    payoutProcessingBanner && payoutProcessingBanner.showBanner ? 
                                        <div className='payout-process-banner-container'>
                                            <div className='payout-banner-left'>
                                                <div>{payoutProcessingBanner.bannerText}</div>
                                                <div>Check back soon, Working on payout calculation.</div>
                                            </div>
                                            <div className='payout-banner-right'>
                                            <Button className="payout-banner-button" style={{margin: '0px 10px 0px auto'}}>
                                                Working on calculation
                                            </Button>
                                            </div>
                                        </div>
                                    :
                                        null
                                }

                                <div className='payout-breakup-container'>
                                    <div className='payout-breakup-header'>
                                    {
                                        monthlyPayoutDataBreakup.data && monthlyPayoutDataBreakup.data.length > 0 ? 
                                            <table style={{width: '100%',height: '55px'}}>
                                                <tr style={{background: '#f0f0f0'}}>
                                                    {monthlyPayoutDataBreakup?.columns?.map((item, index) => {return <th style={{padding: '16px'}} key={index}>{item.title}</th>})}
                                                </tr>
                                                <tr style={{height: '24px', background: 'white'}}></tr>
                                                {
                                                    monthlyPayoutDataBreakup?.data?.length > 0 ?
                                                        <>
                                                            {
                                                                monthlyPayoutDataBreakup.data.map((campaign, index) => {
                                                                    return <>
                                                                        {
                                                                            campaign.campaignName === 'Base Pay' ?
                                                                                <tr className='payout-breakup-basic-pay' >
                                                                                    {
                                                                                        payoutDashboardHeadersArray?.map((item, index) => {
                                                                                            return <th style={{padding: '16px'}} key={index}>
                                                                                                {item ? campaign[item] : <div onClick={() => {onExpandPayoutDashItem(campaign.campaignName)}}> { showDownArrow(campaign.campaignName) ? <CaretUpOutlined /> : <CaretDownOutlined  /> } </div> }
                                                                                                {item === 'totalLeads' ? <CaretRightOutlined onClick={() => onClickTotalLead(campaign)} /> : null} </th>})
                                                                                    }
                                                                                </tr>
                                                                            : null
                                                                        }
                                                                        {
                                                                            campaign.campaignName === 'Base Pay' && campaign.expandedRows.length > 0 && showDownArrow(campaign.campaignName) ?
                                                                                campaign.expandedRows.map((singleRow, index) => {
                                                                                    return <tr style={{background: '#ffeae0'}} key={index}>
                                                                                        {
                                                                                            payoutDashboardHeadersArray?.map((item, index) => {
                                                                                                return <th style={{padding: '16px'}} key={index+100}>
                                                                                                    {item != 'campaignName' ? singleRow[item] : ( singleRow['displayName'] ? singleRow['displayName'] : null || singleRow[item] ) }
                                                                                                    {item === 'totalLeads' ? <CaretRightOutlined onClick={() => onClickTotalLead(singleRow)} /> : null}
                                                                                                </th>
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                })
                                                                            : null
                                                                        }
                                                                        {
                                                                            campaign.campaignName === 'Additions' ?
                                                                                <tr className='payout-breakup-additions'>
                                                                                    {
                                                                                        payoutDashboardHeadersArray?.map((item, index) => {
                                                                                            return <th style={{padding: '16px'}} key={index}>{item ? campaign[item] : <div onClick={() => {onExpandPayoutDashItem(campaign.campaignName)}}> { showDownArrow(campaign.campaignName) ? <CaretUpOutlined /> : <CaretDownOutlined  /> } </div> }</th>
                                                                                        })
                                                                                    }
                                                                                </tr>
                                                                            : null
                                                                        }
                                                                        {
                                                                            campaign.campaignName === 'Additions' && campaign.expandedRows.length > 0 && showDownArrow(campaign.campaignName) ?
                                                                                campaign.expandedRows.map((singleRow, index) => {
                                                                                    return <tr style={{background: '#f6ffed'}} key={index}>
                                                                                        {
                                                                                            payoutDashboardHeadersArray?.map((item, index) => {
                                                                                                return <th style={{padding: '16px'}} key={index+100}>{item ? singleRow[item] : null }</th>
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                })
                                                                            : null
                                                                        }
                                                                        {   
                                                                            campaign.campaignName === 'Deductions' ?
                                                                                <tr className='payout-breakup-deductions'>
                                                                                    {
                                                                                        payoutDashboardHeadersArray?.map((item, index) => {
                                                                                            return <th style={{padding: '16px'}} key={index}>{item ? campaign[item] :  <div onClick={() => {onExpandPayoutDashItem(campaign.campaignName)}}>{ showDownArrow(campaign.campaignName) ? <CaretUpOutlined /> : <CaretDownOutlined  />} </div> }</th>
                                                                                        })
                                                                                    }
                                                                                </tr>
                                                                            : null
                                                                        }
                                                                        {
                                                                            campaign.campaignName === 'Deductions' && campaign.expandedRows.length > 0 && showDownArrow(campaign.campaignName) ?
                                                                                campaign.expandedRows.map((singleRow, index) => {
                                                                                    return <tr style={{background: '#fff1f0'}} key={index}>
                                                                                        {
                                                                                            payoutDashboardHeadersArray?.map((item, index) => {
                                                                                                return <th style={{padding: '16px'}} key={index+100}>{item ? singleRow[item] : null }</th>
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                })
                                                                            : null
                                                                        }
                                                                        {
                                                                            campaign.campaignName === 'Total Payout' ?
                                                                                <tr className='payout-breakup-total'>
                                                                                    {
                                                                                        payoutDashboardHeadersArray?.map((item, index) => {
                                                                                            return <th style={(item === 'campaignName' ? {padding: '16px', color: 'white', background: '#595959'} : {padding: '16px', color: 'back', background: 'white'})} key={index}>{item ? campaign[item] : null}</th>
                                                                                        })
                                                                                    }
                                                                                </tr>
                                                                            : null
                                                                        }
                                                                        <tr style={{height: '24px', background: 'white'}}></tr>
                                                                    </>
                                                                })
                                                            }
                                                        </>
                                                    : null
                                                }
                                            </table>
                                        : 
                                            <Empty active />
                                    }
                                    </div>   
                                </div>

                                {/* <Table
                                    className="components-table-demo-nested"
                                    columns={monthlyPayoutDataBreakup.columns}
                                    scroll={{x: 320}}
                                    rowClassName="row-layout"
                                    expandable={{
                                        expandedRowRender,
                                        expandIcon: ({expanded, onExpand, record}) =>
                                            record?.expandedRows?.length ? (
                                                expanded ? (
                                                    <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                                                ) : (
                                                    <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
                                                )
                                            ) : null,
                                    }}
                                    dataSource={monthlyPayoutDataBreakup.data}
                                    pagination={false}
                                /> */}

                            </SectionLayout>

                            {/* {
                                expiringLeads.length > 0 ?  (expiringLeads[0] !== 'No Data'? (
                                    <SectionLayout themeWhite={true} icon={<ReloadOutlined twoToneColor="#D34612" />} title={"Non-eligible Leads"}>
                                            {
                                                expiringLeads.map((lead, index)=> {
                                                    return (
                                                        <Tag 
                                                        key={index}
                                                        className='action-banner-payout-dash'>
                                                            <div className='tag-description'>
                                                                <InfoCircleOutlined /> {lead.key}
                                                            </div>
                                                            <div className='tag-leads-information'>
                                                                <div>{lead.value}</div> <div style={{display:'flex', cursor:'pointer'}} onClick={() => {
                                                                    onClickTotalLead(lead)
                                                                }}><RightCircleTwoTone style={{marginLeft: 6}} twoToneColor="#D34612" />
                                                                </div>
                                                            </div>
                                                            <div className='tag-amount' style={{flex:'1'}}>
                                                                ₹ 0
                                                            </div>
                                                        </Tag>
                                                    )
                                                    
                                                })
                                            }
                                            
                                    </SectionLayout>
                                ) : null) : <Skeleton style={{padding: '20px'}} active avatar />
                            } */}
                            
                        </div>
                    }
                </div>
            </div>

            {
                filterSider && 
                    <SideSheet submit={
                        {
                            disabled: false,
                            onClick: submitFilter
                        }}
                        clearAll={{disabled: false, onClick: clearAll}}
                        sidername={"Filter for Reports"} 
                        onDismiss={() => {setFilterSider(false)}}>
                        <div className='filter-home-wrapper'>
                            {
                                tab !== 'payout-dashboard' ? 
                                    <>
                                        {commonTag("Status (Choose up to 5 statuses)", milestoneList)}
                                        <hr style={{border: 'none',borderTop: '1px solid #ccc'}} />
                                        {commonTag("Date Range", null)}
                                        {commonTag("Team Member", teamList)}
                                        {commonTag("Clients", clientFilterList)}
                                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'justify', marginBottom: '20px'}}>
                                        <label style={{marginBottom: '4px'}}>Type Of Lead</label>
                                        <Select
                                            className="Dropdown-control filter-label"
                                            value={typeOfLead}
                                            placeholder="Type of leads"
                                            onChange={handleTypeOfLeadsChange}
                                        >
                                            <Option key={"Eligible Leads"} value={"Eligible Leads"}>Eligible Leads</Option>
                                            <Option key={"All Leads"} value={"All Leads"}>All Leads</Option>
                                        </Select> 
                                        </div>
                                        {commonTag("Location", locationList)}
                                        
                                    </>
                                :
                                    <>
                                        {commonTag("Month Selection", null)}
                                        {commonTag("Clients", clientFilterList)}
                                    </>
                            }
                        </div>
                    </SideSheet>
			}	
            {
                showVerifyEmailPopup && 
                (
                    <VerifyEmailPopup mitra={mitra} visible={showVerifyEmailPopup} closePopup={setShowVerifyEmailPopup} downloadFunction={handleSheet} changeEmail={changeEmail} setChangeEmail={setChangeEmail}></VerifyEmailPopup>
                )
            }
        </>
    )
}

export default ReportPage
