import React, {useEffect, useState , useContext , useRef , useReducer} from 'react';
import {Form} from "react-formio";
import NavigationTab from '../common/NavigationTab.component';
import PageHeaderComponent from '../common/PageHeader';
import { Button , Avatar, Tooltip , Modal} from 'antd';
import * as logoutService from '../../service/logoutService';
import {useHistory} from 'react-router-dom';
import useGAEventsTracker from '../../service/useGSEventsTracker';
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import SideSheet from "../common/SideSheet";
import SideInput from "../common/SiderInput";
import { AppContext } from "../../App";
import { CameraOutlined , DeleteOutlined , EyeOutlined } from '@ant-design/icons';

import '../../css/teammanagement.scss';

let updatedUser = null;
const initialState = null;

function teamManagementreducer(state, action) {
  switch (action.type) {
    case "photoUrl":
      return {...state , photoUrl:action.value}
    default:
      return state
  }
}

const TeamManagement = () => {
    const { setAlert , mitraReducer } = useContext(AppContext);
    const [filterSelected, setfilterSelected] = useState(false)
    const [submitdisable, setSubmitdisable] = useState(true);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [teamManagementReducerInfo, dispatchTeamManagement] = useReducer(teamManagementreducer, initialState);
    const [breadcrumb] = useState([{link:'/account',label:'Account'},{link:'',label:'Team Management'}]);
    const formRef = useRef(null);
    const [submissionData, setSubmissionData] = useState({
        data: {

        }
    });
    const [selectorRole] = useState([{label:'Team Member',value:'teamMember'},{label:'Team Leader',value:'teamLeader'}]);
    const memberName = useRef(null); 
    const memberPhoneNo = useRef(null); 
    const memberRole = useRef(null); 
    const memberEmail = useRef(null); 
    const [sider,setSider] = useState(false);
    const [viewAllUser, setViewUser] = useState([]); // Setting all member 
    const [userView, setUserView] = useState(null); //Single user on sider 
    const [filteredArr, setFilteredArr] = useState([]);
    // const [createMoengageUser, trackEvent] = useMoengage();
    const history = useHistory();
	const AccountGAEventsTracker = useGAEventsTracker("Accounts");

    useEffect(() => {
        setFilteredArr(viewAllUser.data);
    }, [viewAllUser])

    useEffect(() => {
        AccountGAEventsTracker("Open View User")
        fetchUsers();
    }, []);
    
    const fetchUsers = async () => {
        const users = await logoutService.getMitrasByManager();
        await setViewUser(users.data);
    }

    function siderSetting(label,info){
        deletePhotoUrl(info);
        setSider(label);
        setUserView(info);
    }

     //Modal confirm
     const { confirm } = Modal;
     function showPromiseConfirm(btnLabel) {
         confirm({
           title: btnLabel === 'removeAccess' ? 'Do you want to remove member?' : 'Do you want to re-activate member?',
           icon:  btnLabel === 'removeAccess' ? <DeleteOutlined style={{color: '#D34612'}}/> : null,
           content: btnLabel === 'removeAccess' ? 'Are you sure you want to remove access for this account ?'
                    : 'Are you sure you want to add access for this account ?',
           onOk() {
             new Promise((resolve,reject) => {
                 updatedUser={ ...userView};
                 submitBasicForm(btnLabel).then(()=>resolve).catch(()=>reject)
             }).catch(() => console.log('Oops errors!'));
           },
           onCancel() {},
         });
     }

    //Input changes -> Start
    function onChangeInput(e,obj){
        updatedUser={...userView};
        setSubmitdisable(false)
        updatedUser[obj]=e?.target?.value;
    }
    //Input changes -> End

    const isValidForm =()=>{
        var regex = new RegExp("^[0-9]{10}$");
        if(!regex.test(memberPhoneNo.current.input.value)){
            setAlert({
                display:true,
                text:'Please enter only the 10 digit mobile no without any prefix (+91 or 0) and without any spaces between the digits.',
                type:'error'
              });
        }else if(memberPhoneNo.current.input.value.trim() && memberName.current.input.value.trim()){
            return true;
        }
        else{
            setAlert({
                display:true,
                text:'Please fill the mandatory field',
                type:'error'
              });
              return false;
        }
         
    }

    const submitBasicForm = async (btnLabel) => {
        let memberPhoneNumber = memberPhoneNo.current.input.value.trim()
        if(sider === 'viewDetailActive' || sider === 'viewDetailInActive'){
            submitUpdate(btnLabel);
        }else if(isValidForm()){
            await logoutService.postUserInfoForm(memberName.current.input.value.trim() , memberPhoneNo.current.input.value.trim() , "Active" , memberRole.current.value ,teamManagementReducerInfo.photoUrl || '', memberEmail.current.input.value.trim() , mitraReducer.mitraInfo.id).then((response) => {
                AccountGAEventsTracker("Submit New Team Member")
                if (response && response.data) {
                    if (response.data.validLead) {
                        setAlert({
                            display:true,
                            text:response.data.message,
                            type:'success'
                          });
                        trackEvent('team_member_added', {team_member_phonenumber: memberPhoneNumber},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                        fetchUsers()
                        siderSetting(false,null);
                    }else{
                        setAlert({
                            display:true,
                            text:response.data.message,
                            type:'error'
                          });
                    }
                }
            });
        }
    };

    const submitUpdate = async (btnLabel) => {
        if(isValidForm()){
            AccountGAEventsTracker("Update User")
            if(btnLabel === 'removeAccess'){
                updatedUser.status='Inactive'
            }
            if(btnLabel === 'addAccess'){
                updatedUser.status='Active'
            }
            updatedUser.photoUrl=teamManagementReducerInfo?.photoUrl;
            const update = await logoutService.updateMitrasById(updatedUser)
            .catch(error => {
                setAlert({
                    display: true,
                    text: error.response.data ? error.response.data.message : error.message,
                    type: 'error'  //success , info , warning  or error
                });
              })
            if (update?.data.status) {
                setAlert({
                    display:true,
                    text:update.data.message,
                    type:'success'  //success , info , warning  or error
                  });
                fetchUsers();
                // setSider(false);
                siderSetting(false,null)
            }
        }
    }

    const onRenderForm=(e)=>{
        console.log('formRef',formRef.current);
        forceUpdate();
    }

    const onChange = (submission) => {
        console.log("submission",submission)
        if(submission.changed && submission.isValid){
            const key = submission.changed ? submission.changed.component.key : undefined;
            if(submission.data[key].length > 0){
                dispatchTeamManagement({type:'photoUrl',value:submission.data[key][0].data.imageUrl})
            }
        }
    }
    const onUpload =()=>{
        setSubmitdisable(false);
        updatedUser={...userView};
        formRef.current.element.getElementsByClassName('browse')[0].click();
    }

    const deletePhotoUrl= (info)=>{
        if(formRef.current?.element.getElementsByClassName('fa fa-remove').length > 0){
            formRef.current.element.getElementsByClassName('fa fa-remove')[0].click();
            dispatchTeamManagement({type:'photoUrl',value:null});
        }else if(info?.photoUrl){
            dispatchTeamManagement({type:'photoUrl',value:info.photoUrl});
        }else{
            dispatchTeamManagement({type:'photoUrl',value:null});
        }
    }

    const filterFunc = async (valueProps) =>{
        let newArray;
        if(valueProps === 'role'){
            if(filterSelected === 'role'){
                setfilterSelected(false)
            }else{
                setfilterSelected('role')
            }
        }else if(valueProps === 'status'){
            if(filterSelected === 'status'){
                setfilterSelected(false)
            }else{
                setfilterSelected('status')
            }
        }else{
            newArray = viewAllUser.data;
        }
        newArray = viewAllUser.data.sort((a, b) => (a[valueProps] > b[valueProps]) ? 1 : -1);
        setFilteredArr(newArray)       
    }                                        

    const onClickAddMember =() =>{
        setSubmitdisable(true)
        siderSetting(true,null)
    }

    const onDismiss =()=>{
        siderSetting(false,null)
    }
    
    const onClickViewDetail =(item)=>{
        setSubmitdisable(true)
        console.log(item);
        if(item.status === 'Active') {
            siderSetting("viewDetailActive",item);
        } else {
            siderSetting("viewDetailInActive",item);
        }
    }

    const onRemovePhotoUrl =()=>{
        deletePhotoUrl();
        setSubmitdisable(false);
        updatedUser ={ ...userView};
    }

    
    return (
        <>
                {/* <div className="navigation-container">
                    <NavigationTab currentTab={4} />
                </div> */}
                <div className='teamManagementContainer mainContentZ'>
                    <div className="containerTop">
                        <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb}/>
                    </div>
                    <div className="containerMiddle">
                        <div className="filterOps">
                            <div style={filterSelected === 'role' ? {border:'1px solid #d34612'} : null } onClickCapture={()=>filterFunc("role")}  className='selectionContainer'>
                                <img src='/image/black-user.svg'/>
                                <div>Role</div> 
                                <img style={filterSelected === 'role' ? {transform:'rotate(180deg)'} : null} src="/image/arrow-down.svg"/>
                                
                            </div>
                            <div style={filterSelected === 'status' ? {border:'1px solid #d34612'} : null } onClickCapture={()=>filterFunc("status")} className='selectionContainer'>
                                <img src='/image/filter.svg'/>
                                <div>Status</div> 
                                <img style={filterSelected === 'status' ? {transform:'rotate(180deg)'}:null} src="/image/arrow-down.svg"/>
                                
                            </div>
                        </div>
                        <div className='listViewMember'>
                                <div className="listview2">
                                    <Button onClick={()=>onClickAddMember()} className='add-btn' type="primary" icon={<img style={{marginBottom:4,marginRight:5}} src="/image/ppl-add.svg"/>}>Add New Team Member</Button>
                                </div>
                                <div className='scrollContainer'>
                                    {
                                       (filteredArr?.length > 0)  ? filteredArr.map(item=>{
                                           return <div className="listviewContainer">
                                           <div style={item.status ==="Active" ? null :{opacity:0.4} } className="listview">
                                               {item.photoUrl ? <img className="list-img" src={`${item.photoUrl}`}/> : 
                                                <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} gap={1}>
                                                    {item.name.substring(0,2).toUpperCase()}
                                                </Avatar>
                                               }
                                               <div className="listview-name-des">
                                                   <div className="name">{item.name}</div>
                                                   <div className="des"><span>{item.role.split(/(?=[A-Z])/).map(function(p) {return p.charAt(0).toUpperCase() + p.slice(1)}).join(' ')}</span>,<span className={item.status ==='Active'? 'active-status' : 'inactive-status'}> {item.status} </span></div>
                                               </div>
                                           </div>
                                           <Button onClick={()=>onClickViewDetail(item)} className={`viewDetail`}type="link" icon={<EyeOutlined />}>View Details</Button>
                                       </div>
                                       })  :  "No User Found"
                                    }
                                    
                                </div>
                            </div>
                    </div>
                </div>
                {sider && 
                        <SideSheet submit={{disabled:submitdisable,onClick:submitBasicForm}} removeAccess={sider === 'viewDetailActive' ? {disabled:false,onClick:showPromiseConfirm} : null}
                        addAccess={sider === 'viewDetailInActive' ? {disabled:false,onClick:showPromiseConfirm} : null}
                                    sidername={userView?.name || "Add Team Member"} onDismiss={() => onDismiss()}>
                            <div className="siderInnerContainer2">
                            {(teamManagementReducerInfo?.photoUrl) ? <div>
                                <img onClick={()=>window.open(teamManagementReducerInfo?.photoUrl)} className="avataarImg" src={`${teamManagementReducerInfo?.photoUrl}`}/>
                                <Tooltip title="Remove the profile pic">
                                    <DeleteOutlined onClick={()=>onRemovePhotoUrl()} style={{color:'red', fontSize:16,marginLeft:12}}/>
                                </Tooltip>
                            </div> : <Tooltip  title="Click to add profile pic" color={"red"}>
                                {formRef.current && 
                                    <Avatar onClick={onUpload} size={64} style={{ color: '#f56a00',marginBottom:20}} gap={1}>
                                        <CameraOutlined style={{fontSize:30,color:'#fff',cursor:'pointer'}}/>
                                    </Avatar>}
                            </Tooltip>}
                            <div style={{display:'none'}}>
                                <Form onRender={onRenderForm} ref={formRef} src={process.env.REACT_APP_FORM_IO_MITRA_DOCS_GENERAL} onChange={onChange} 
                                        submission={submissionData}/>
                            </div>
                        <div className="outerContainer">
                            <SideInput onChange={(e)=>onChangeInput(e,'name')} value={userView?.name}  ref={memberName}  placeholder={"Enter the name"} imgsrc={'black-user.svg'} labelname={"Team Member Name"} required={true}/>
                            <SideInput disabled={userView?.phoneNumber} onChange={(e)=>onChangeInput(e,'phoneNumber')} value={userView?.phoneNumber}  ref={memberPhoneNo} placeholder={"Example : 8884228005"} imgsrc={'phone.svg'} labelname={"Mobile Number"} required={true}/>
                            <SideInput onChange={(e)=>onChangeInput(e,'role')} value={userView?.role}  ref={memberRole} placeholder={"Enter the role"} imgsrc={'star.svg'} labelname={"Role"} required={true} selector={selectorRole}/>
                            <SideInput onChange={(e)=>onChangeInput(e,'email')} value={userView?.email} ref={memberEmail} placeholder={"Example@gmail.com"} imgsrc={'mail.svg'} labelname={"Email ID"} />
                        </div>                    
                            </div>
                        </SideSheet>
                }
        </>
    );
};
export default TeamManagement;
