import blitz from 'blitzllama-js';
import CONSTANTS from '../constants/constants';
import axios from 'axios';

export const trackEvent = async (eventName, payload, eventType) => {
    // function responsible for delegating events to each/specific engagement tool
    
    // checking if the user is set and is a god mode;
    let isAccountManager = false;
    const url = process.env.REACT_APP_BACKEND_URL + '/getAccountManager';
    await axios.get(url, {withCredentials: true}).then(function (response) {
      console.log(response, "------response-----")
      if(response && response.data){
        if(response.data.isAccountManager && response.data.isAccountManager === true){
          eventName = 'gm_' + eventName;
          isAccountManager = true;
        }
          // do nothing
      }
  })

    if (eventType === CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS) {
        triggerMoEngageEvent(eventName, payload);
        blitz.triggerEvent(eventName);
    } else if (eventType === CONSTANTS.ENGAGEMENT_TYPES.BLITZLLAMA && isAccountManager === false) blitz.triggerEvent(eventName);
    else if (eventType === CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE) triggerMoEngageEvent(eventName, payload);
};

export const createMoengageUser = obj => { // initialization of moengage
    const maxTry = {current: 1};

    if (window.Moengage) {
      window.Moengage.add_unique_user_id(obj.id);
      window.Moengage.add_user_attribute('Full Name',obj.name);
      window.Moengage.add_user_attribute('Email Id',obj.email);
      window.Moengage.add_user_attribute('Mobile Number',obj.phoneNumber);
      window.Moengage.add_user_attribute('userType','Vahan Leader');
      window.Moengage.add_user_attribute('Role',obj.role);
      window.Moengage.add_user_attribute('dateOfJoining',obj.createdAt);
      window.Moengage.add_user_attribute('acquisitionSource',obj.acquisitionSource);
      window.Moengage.add_user_attribute('homeCity',obj.homeCity);
      // window.Moengage.add_user_attribute('bankAccountDetailsAvailable',obj.createdAt);
      window.Moengage.add_user_attribute('mitraId',obj.id);
      window.Moengage.add_user_attribute('managerMitraId',obj.managerMitraID);
    } else if (maxTry.current < 3) {
      maxTry.current += 1;
      setTimeout(() => {
        createMoengageUser(obj.id);
      }, 1000);
    }
  };

export const triggerMoEngageEvent = (eventName, eventAttributes = {}) => { // tracking event for MoEngage
const maxTry = {current: 1};

if (window.Moengage) {
    window.Moengage.track_event(eventName, eventAttributes);
} else if (maxTry.current < 3) {
    maxTry.current += 1;
    setTimeout(() => {
        triggerMoEngageEvent(eventName, eventAttributes);
    }, 1000);
}
};
