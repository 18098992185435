import axios from "axios"
import React, { useEffect, useState , useContext} from "react"
import {Form} from "react-formio"
import { useHistory } from "react-router-dom"
import { postTrainingSessionDates } from "../../service/signupService"
import MainLogoHeader from "../header/MainLogoHeader"
import "./../../css/trainingSession.css"
import { AppContext } from "../../App";
import useGAEventsTracker from "../../service/useGSEventsTracker"


const TraingSessionForm = (props) => {
    const {setAlert} = useContext(AppContext);

    const [formSchema, setFormSchema] = useState(null)
    const history = useHistory();
    const signUpEventTracker = useGAEventsTracker('signUp');

    const fetchForm = async () => {
        const res = await axios.get("https://sphnlcqnxxthwup.form.io/trainingsession")
        if(props.mitraInfo){
            res.data.components.forEach(el => {
                if(el.datePicker) el.datePicker.minDate = null
            })
        }
        setFormSchema(res.data)
    }

    useEffect(() => {
        fetchForm();
        signUpEventTracker('OpenTrainingSlotScreen');
    }, [])

    const getSubmissionData = () => {
        signUpEventTracker('TrainingFormSubmitted');
        const submissionData = {
            data: {

            }
        }

        if(props.mitraInfo && props.mitraInfo.length > 0) {
            props.mitraInfo.forEach(el => {
                try {
                    submissionData.data[el.type] = JSON.parse(el.value)
                } catch (error) {
                   console.log(error); 
                }
            })
        }

        return submissionData

    }

    const options = {
        hooks: {
            beforeSubmit: async (submission, next) => {
                let errors = [];
                try {
                    const res = await postTrainingSessionDates(submission.data, props.mitra)
                    for(let key in res.data){
                        if(res.data[key]!== "200")
                            errors.push({message: `${key}: ${res.data[key]}`})
                    }
                } catch (error) {
                    if(error.response)
                        errors = error.response.data.message ? [{message: error.response.data.message}]: [{message: "something went wrong"}]
                    else
                        errors = [{message: "something went wrong"}]
                }

                if(errors.length > 0 ){
                    next(errors)
                }
                else{
                    setAlert({
                        display: true,
                        text: 'Successfully updated!!',
                        type: 'success'
                      })
                    history.replace(props.mitraInfo ? "/account" : "/")
                }

            }
        }
    }

    return (
        <div className="TrainingSession mainContentZ" id="OpenTrainingSlotScreen">
            <MainLogoHeader backButton={props.mitraInfo ? true : false} />
            <h1>Schedule training session</h1>
            <br />
            <p>Our team will set up a 1 hour training session with you on your preffered time to address your questions</p>
            <br /><br />
            <p>Please choose dates between Monday to Friday. We do not take trainings on Saturdays and Sundays</p>
            <div className="row">
                <div className="col" />
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4">
                    {/* <Form src="https://sphnlcqnxxthwup.form.io/trainingsession" submission={getSubmissionData()} options={options} /> */}
                    <Form src={formSchema} submission={getSubmissionData()} options={options} />
                </div>
                <div className="col" />
            </div>

            <p style={{background: "none"}}>If you do not choose any slot, our agent will call you to book a slot</p>
        </div>
    )
}

export default TraingSessionForm
