import React, {useEffect, useState} from 'react';
import { InfoCircleOutlined,RightCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Card,Tooltip,Skeleton,Typography} from 'antd';
import CONSTANTS from '../../constants/constants';
const { Text } = Typography;

const ClientPerformance = ({cientPerformance, mitraDispatch}) => {
    const history=useHistory();
    const statusToMilestoneMaping = CONSTANTS.statusToMilestoneMaping;
    const routing = (client, title) => {
        history.push({
            pathname: '/leads-page',
            search: statusToMilestoneMaping[title]
        })
        mitraDispatch(client)
    }

  return (
    
<div style={{display:'inline-flex',flexWrap:'wrap'}}>
            {cientPerformance?.arrayFormat.map((item, index)=>(
                <Card className='overall-card overall-card-clientperformance' hoverable key={index}>
                <img className="imgResponse" alt={item.client} src={item.logo} />
                <div className='card-view-tab'>
                { item && item.statusList && item.statusList.length > 0 && item.statusList.map((itemStatus, index2)=>(
                    <div className='card-items' key={index2}>
                        <div className='overall-header'>
                            <Text className='summary-header'>{statusToMilestoneMaping[itemStatus.type] ? statusToMilestoneMaping[itemStatus.type].replace(/(\w)(\d+)\s*~\s*(\w+\s*\d*\s*\w+)/, "$1-$2 Trips") : itemStatus.type}</Text>                                    
                        </div>
                        <div className='overall-number' >
                            {
                                itemStatus.type !== 'Bank + PAN Docs Verified' ?
                                    <div style={{display: 'flex', alignItems: 'center'}} onClick={(e) => routing(cientPerformance?.arrayFormat[index].client, cientPerformance?.arrayFormat[index].statusList[index2].type)}>
                                        <Text underline className='number'>{itemStatus.value}</Text>
                                        <RightCircleOutlined style={{fontSize:'12px', marginLeft:'10px'}} />
                                    </div>
                                :
                                <div>
                                    <Text underline className='number'>{itemStatus.value}</Text>
                                </div>
                            }
                        </div>
                    </div>
                ))}
                </div>
            </Card>
            ))}
        </div>
    )
}
export default ClientPerformance;