import axios from 'axios';

export const fetchIncentive = (data) => {
    const url = process.env.REACT_APP_BACKEND_URL + '/fetchIncentives';
    return axios.get(url, { withCredentials: true, params: data, headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
};

export const fetchMonthItemsForIncentive = () => {
    const url = process.env.REACT_APP_BACKEND_URL + '/fetchMonthItemsForIncentive';
    return axios.get(url, { withCredentials: true, headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
};

export const fetchTermsAndConditions = (data) => {
    const url = process.env.REACT_APP_BACKEND_URL + '/fetchTermsAndConditions';
    return axios.get(url, { withCredentials: true, params: data, headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken')
    } });
};


