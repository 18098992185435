import React, { useState } from "react";
import {Space, Typography, Avatar, Button, Card, Skeleton} from 'antd';
import { StarOutlined, ShareAltOutlined, RightCircleOutlined} from '@ant-design/icons';
import './../../css/teamLeaderboard.scss'
import { useHistory } from 'react-router-dom';
import ReportTeamMemberCard from './ReportTeamMemberCard.component'
import moment from "moment";



const ReportLeaderBoard = ({teamLeaderBoardList, loading, topPerformerData, startDate, endDate, handleViewAll}) => {

    const history = useHistory();
    const { Text } = Typography;

    return (
        <>
            <div className="leaderboard-container">
                    {
                        topPerformerData?.displayTopPerformer ? 
                            <Card className="left-card-container">
                                <div align="start" className="leaderboard-left-container">
                                    <div className="desktop-only">
                                        <div className="left-card-info">
                                            <Text className="margin-bottom-15px" style={{color: '#D34612'}}><StarOutlined /> Highest F10 Trips</Text>
                                            <Avatar className="margin-bottom-15px left-card-avatar" size={150}>{topPerformerData.topPerformer.mitraName[0].toUpperCase()}</Avatar>
                                            <Text style={{fontSize: '16px', fontWeight: 'bold'}}>{topPerformerData.topPerformer.rank} <Text style={{textTransform: 'capitalize'}}>{topPerformerData.topPerformer.mitraName}</Text></Text>
                                            <Text className="margin-bottom-15px" style={{fontSize: '14px', fontWeight: 'bold', color: '#595959'}}>{topPerformerData.topPerformer.mitraRole}</Text>
                                        </div>
                                    </div>
                                    <div className="mobile-only">
                                        <div className="left-card-info">
                                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                            <Text style={{color: '#D34612', marginBottom: '10px'}}><StarOutlined /> Highest F10 Trips</Text>
                                            <Text style={{fontSize: '16px', fontWeight: 'bold'}}>{topPerformerData.topPerformer.rank} <Text style={{textTransform: 'capitalize'}}>{topPerformerData.topPerformer.mitraName}</Text></Text>
                                            <Text style={{fontSize: '14px', fontWeight: 'bold', color: '#595959', marginTop:'5px'}}>{topPerformerData.topPerformer.mitraRole}</Text>
                                        </div>
                                        <Avatar className="left-card-avatar" size={100}>{topPerformerData.topPerformer.mitraName[0].toUpperCase()}</Avatar>
                                        </div>
                                    </div>
                                    <table className="table">
                                        <tr className="table-row">
                                            <td style={{textAlign: 'left', paddingLeft: '8px', paddingTop: '5px'}}>
                                                Total F10
                                            </td>
                                            <td style={{paddingTop: '5px'}}>{topPerformerData.totalF10Count}</td>
                                        </tr>
                                    {
                                        topPerformerData.f10Count.map((ele, index) => {
                                            return <tr className="table-row">
                                                <td style={{textAlign: 'left', paddingLeft: '8px', paddingTop: '5px'}}>
                                                    <img className="imgResponse" src={"/image/" + ele.companyName + ".png"} />
                                                </td>
                                                <td style={{paddingTop: '5px'}}>{ele.count}</td>
                                            </tr>
                                        })
                                    }
                                    </table>
                                    <a
                                        rel="noopener noreferrer"
                                        href={`https://wa.me?text=Hi, ${topPerformerData.topPerformer.mitraName} is on top of the leaderboard for the period: ${moment(startDate).format("DD-MMM-YYYY")}-${moment(endDate).format("DD-MMM-YYYY")} with a total F10 count of ${topPerformerData.totalF10Count}. Well Done ${topPerformerData.topPerformer.mitraName}`}
                                        target="_blank"
                                    >
                                        <Button className="share-button"> <ShareAltOutlined /> Share with Team </Button>                    
                                    </a>
                                </div>
                            </Card>
                        : null
                    }
                    
                <div className="leaderboard-right-container">
                {
                    teamLeaderBoardList.length ? 
                        teamLeaderBoardList.map((ele, index) => {
                            return <ReportTeamMemberCard member={ele} index={index} />
                        })
                    : 
                        <Skeleton loading={loading} avatar active />
                }
                <Button className="view-all" onClick={() => {handleViewAll()}}>View All</Button>
                </div>
            </div>
        </>
        
    )
}

export default ReportLeaderBoard