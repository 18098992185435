import React, {useState, useEffect, useReducer} from 'react';
import NavigationTab from "./components/common/NavigationTab.component";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';
import * as loginService from './service/loginService';
// import useMoengage from './hooks/useMoengage';
import { createMoengageUser } from './service/engagementMonitoringService';
import {reducer} from './reducers/mitraReducer';
import {Alert,notification,Spin} from 'antd';

import "bootstrap/dist/css/bootstrap.min.css";
import './main.scss';

import ProductFruits from 'react-product-fruits';
import { ROLES, ROUTES } from './components/common/roles';
import { suiteCrmMLView } from './constants/utils';

const {ErrorBoundary} = Alert;

window.dataLayer = window.dataLayer || [];
function gtag() { window.dataLayer.push(arguments); }
gtag('js', new Date());
gtag('config', 'G-S7YGR6V3MG');
ReactGA.initialize('UA-204663297-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export const AppContext = React.createContext();

function App() {
  const [userRole, setUserRole] = useState(ROLES.ML);
  const [mitraReducer, dispatchMitra] = useReducer(reducer, null);
  const [alert, setAlert] = useState({
    display: null, //false or true when actived
    text: 'Error Found!!',
    type: 'error'  //success , info , warning  or error
  })
  const [spin, setSpin] = useState({
    loading: false, //false or true when actived
    delay: null,
    tip: 'Loading...'  //Loading copy here
  })
  // const [createMoengageUser, trackEvent] = useMoengage();
  const [variant, setVariant] = useState(null);

  useEffect(() => {
    loginService
      .validateSession()
      .then((response) => {
        // setting user role
        if(localStorage.getItem('isFromSuiteCrm') && suiteCrmMLView(response.data.mitra.id))
          setUserRole(ROLES.DC);

        // if onboarding questions are not answered then take to onboarding screen
        if (!response.data.mitra.isFeedBackCompleted && window.location.pathname !== '/onboarding-questions'){
          window.location.pathname = '/onboarding-questions';
        }

        // LOU Assist
        window.LOU.identify(response.data.mitra.id, {
          mitraPhoneNumber: response.data.mitra.phoneNumber,
          mitraName: response.data.mitra.name,
          city: response.data.mitra.homeCity,
          managerMitraID: response.data.mitra.managerMitraID,
          email: response.data.mitra.email,
          signUpDate: response.data.mitra.createdAt
        });
        if(response && response.data && response.data.termsVersion > 0) {
          dispatchMitra({type: 'termsVersion', value: true})
        }else{
          dispatchMitra({type: 'termsVersion', value: false})
        }
        dispatchMitra({type: 'LoginInfo', value: response.data.mitra})
        if (response.data.features) {
          dispatchMitra({type:'EXPERIMENTAL_FEATURES', value: response.data.features})
        }
        if(process.env.REACT_APP_GOOGLE_OPTIMIZE_EXPERIMENT_ID){
        gtag('event', 'optimize.callback', {
            name: process.env.REACT_APP_GOOGLE_OPTIMIZE_EXPERIMENT_ID,
            callback: (_variant) => {       
              setVariant(_variant)
            }
        });
        }
        createMoengageUser(response.data.mitra);  
      })
      .catch(error => {
        console.error('Error:', error);
        if (
            window.location.pathname !== '/'
            && !window.location.pathname.includes('/job-postings')
            && !window.location.pathname.includes('/upload-onboarding-data')
            && !window.location.pathname.includes('/upload-periscope-data')
            && !window.location.pathname.includes('/upload-marketing-data')
            && !window.location.pathname.includes('/file-uploader')
            && !window.location.pathname.includes('/signup')
            && !window.location.pathname.includes('/terms-and-conditions')
            && !window.location.pathname.includes('/rp/')
        ) {
          window.location.pathname = '/';
        }
      });
  }, []);

  useEffect(() => {
    if (alert?.display === true) {
      setTimeout(() => {
        setAlert({
          display: false,
          text: '',
          type: ''
        })
      }, 3000);
    }
  }, [alert]);


  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log('Complete Cache Cleared')
  };

  clearCacheData();
  return (
    <>
      {alert?.display && <Alert size="large" banner message={alert.text} type={alert.type} afterClose={() => setAlert(null)} showIcon />}
      <div className="App">
        <ErrorBoundary>
          <AppContext.Provider value={{mitraReducer, mitraDispatch: dispatchMitra, setAlert,setSpin,userRole, setUserRole }}>
            <Router
            getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt */
            }}
            >
            <ProductFruits projectCode={process.env.REACT_APP_PRODUCT_FRUITS_PROJECT_CODE} language="en" { ... {username: mitraReducer?.mitraInfo?.id, props: { optVariant: variant }} } />
              {mitraReducer?.mitraInfo && mitraReducer.mitraInfo.isFeedBackCompleted && <div className="navigation-container"><NavigationTab /></div>}
              <Spin spinning={spin.loading} deplay={spin.delay} tip={spin.tip}>
                <Switch>
                  {
                    ROUTES[userRole].map((route, index) => {
                      return <Route key={index} path={route.path} component={route.component} exact={route.exact} />
                    })
                  }
                </Switch>
              </Spin>
            </Router>
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    </>
  );
}

export default App;
