import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Contact from '../leads/Contact.component';
import { getCandidatesData, downloadLeadStatusSheet } from './../../service/reportService'
import CandidateProfilePopup from '../leads/candidateProfilePopup.component'
import moment from "moment";
import '../../css/report.scss';
import { useLocation } from "react-router-dom";
import LeadCard from './LeadCard.component';
import ClipLoader from 'react-spinners/ClipLoader';
import {AppContext} from '../../App';
import {getActiveClientsForFilter, disabledDate } from './../../service/commonService'
import { DatePicker, Button, Input, Skeleton, Select, Tag} from 'antd';
import { DownloadOutlined, SearchOutlined, CloseOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import PageHeaderComponent from '../common/PageHeader';
import SearchComponent from '../common/search.component';
import CandidateProfilePopupV2 from '../leads/candidateProfilePopupV2Components/candidateProfilePopupV2';
import RaiseIssueSider from '../help/RaiseIssueSider.component';

const {Search} = Input;
const {Option} = Select;

const TeamLeadspage = () => {
    const {mitraReducer, mitraDispatch, setAlert , setSpin} = useContext(AppContext);
    const [isAdmin, setIsAdmin] = useState(mitraReducer?.mitraInfo?.role === 'admin' ? true : false);
    const [mitra, setMitra] = useState(mitraReducer?.mitraInfo ? mitraReducer.mitraInfo : {});
	const [selectedDate, setSelectedDate] = useState(mitraReducer?.reportParams?.selectedDate ? mitraReducer?.reportParams?.selectedDate : moment())
	const [clientSelected, setClientSeleted] = useState(mitraReducer?.reportParams?.clientSelected ? mitraReducer?.reportParams?.clientSelected : '')
	const [showDateChip, setShowDateChip] = useState(false)
    const [breadcrumb, setBreadcrumb] = useState('');
    const [extraSearch, setExtraSearch] = useState(false)
    const [leads, setLeads] = useState([]);
    const [prevLeads, setPrevLeads] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [selectedLead, setSelectedLead] = useState({});
    const [loaded, setLoaded] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const [clientFilterList, setClientFilterList] = useState([])
	const [tabParam, setTabParam] = useState(decodeURIComponent(location.search.substr(1)))
    const [viewDetailLead, setViewDetailLead] = useState({});
    const [raiseIssueSider, setRaiseIssueSider] = useState(false);  


    useEffect(() => {
        if(mitraReducer?.mitraInfo){
            setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
            setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})
        }
        getActiveClientsForFilter().then(data => {
            const clientArray = [{label: 'Select Client', value: true}, ...data.data.activeClients]
            setClientFilterList(data.data.activeClients)
            console.log(data.data.activeClients)
        })  
        let rank = new URLSearchParams(location.search).get("rank")
        let memberName = new URLSearchParams(location.search).get("memberName")
        setBreadcrumb('#' + rank + ' ' + memberName);
    }, [mitraReducer, mitra])

    useEffect(() => {
        setLoaded(true)
        if(mitra?.id){
            let data = {
                mitraId: new URLSearchParams(location.search).get("memberId"),
                month: moment(selectedDate).month()+1,
                year: moment().year(),
                clientName: clientSelected,
                status: 'unique'
            }
            getCandidatesData(data)
                .then(response => {
                    setLoaded(true)
                    setLeads(response.data.data)
                    setPrevLeads(response.data.data)
            })
        }
        if(moment(selectedDate).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")) {
            setShowDateChip(true)
        } else {
            setShowDateChip(false)
        }
        setLoaded(false)
    }, [mitra, selectedDate, clientSelected]);

    

    const searchCandidate = (e) => {
        if (e== '') {
            setLeads(prevLeads);
        }
        if (!prevLeads.length) {
            setPrevLeads(leads);
        }
        const searchResult = prevLeads.filter(ele => {
            return ele.phoneNumber.includes(e) || ele.firstName.toLowerCase().includes(e.toLowerCase());
        })
        setLeads(searchResult);
    }

    function handleSheet() {
        let data = {
            mitraId: new URLSearchParams(location.search).get("memberId"),
            month: moment(selectedDate).month()+1,
            year: moment().year(),
            clientName: clientSelected,
            status: 'unique'
        }
		downloadLeadStatusSheet(data).then(response => {
            if(response.data.responseCandidatesUrl){
				console.log(response)
                window.open(response.data.responseCandidatesUrl, '_blank')
            }
            else {
				setAlert({
					display: true,
					text: 'No data Present',
					type: 'error'
				});
			}
        })
	}

    const handleDateChange = (date) => {
        if(date === null){
            setSelectedDate(moment())
        }
        else{
            setSelectedDate(moment(date))
        }
    } 

    const handleClientChange = (e) => {
        setClientSeleted(e)
    }

    const deleteChip = (type, value) => {
		if(type === 'client') {
			setClientSeleted('')
		}
        if(type === 'month'){
            setSelectedDate(moment())
        }
}
    
    return (
        <>
            <div className="ReportPage mainContentZ">
            <div className="containerTop">
                {/* <div className="header-text">
                    <span className="small-size-screen" style={{ cursor: 'pointer' }} onClick={() => history.push('/report')}><ArrowLeftOutlined /></span>
                    <span className="big-screen">
                        <span onClick={() => history.push('/report')} style={{ cursor: 'pointer' }}>Report</span> &nbsp;&nbsp;
                        <ArrowRightOutlined style={{color: '#666565'}} />&nbsp;&nbsp;
                    </span>
                    <span style={{ fontWeight: '100', color: '#666565' }}><div className="teamMember-name">{breadcrumb}</div></span>
                </div> */}
                <PageHeaderComponent title={`Report - ${breadcrumb}`} extra={[<SearchComponent key={"searchCandidate"} placeholder={"Search Candidates"} searchList={searchCandidate}/>,
                        <Button key={"downloadFile"} icon={<DownloadOutlined />} danger style={{display:'flex',alignItems:'center'}} onClick={handleSheet}>
                            Download File
                        </Button>] }
                />
                {/* <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'right', marginLeft: 'auto' }}>
                    <div style={{ display: 'flex', marginRight: '5px' }}>
                        <div className="header-search">
                            <input className="search-input" onChange={searchCandidate} type="text" placeholder="Search Candidates" />
                        </div>
                        <div className="search-icon" onClick={() => { if (window.innerWidth < 840) setExtraSearch(!extraSearch) }}>
                            <SearchOutlined />
                        </div>
                    </div>
                    <Button icon={<DownloadOutlined />} danger style={{display:'flex',alignItems:'center'}} onClick={handleSheet}>
                            Download File
                    </Button>

                </div> */}

            </div>
            {/* {
                extraSearch ?
                    <div style={{ margin: '10px 0px', padding: '0px 30px' }}>
                        <input className="search-input" onChange={searchCandidate} type="text" placeholder="Search Candidates" />
                    </div>
                    : null
            } */}
            <div className="more-filters">
                <div className="filter-set-container">
                    <DatePicker value={selectedDate} format="MMM-YYYY" allowClear={false} picker="month" disabledDate={disabledDate} onChange={handleDateChange} defaultValue={selectedDate} />

                    {/* <select id="status-filter" className="Dropdown-control filter-label" style={{ width: '150px' }} value={clientSelected}
                        onChange={handleClientChange}>
                        {
                            clientFilterList.map((single_client, index) =>
                                <option key={index} className="filter-label" value={single_client.label}>{single_client.label}</option>
                            )
                        }
                        
                    </select> */}
                     <Select 
                        className="Dropdown-control filter-label"
                        value={clientSelected !='' ? clientSelected : null}
                        onChange={handleClientChange}   
                        placeholder="Select Client"  
                    >
                        {clientFilterList.map((single_client,index)=>{
                            return <Option key={"single_client"+index} value={single_client.label}>{single_client.label}</Option>
                            })}
                    </Select>
                </div>
            </div>
            <div className="Chips-container" style={{padding: "0 20px"}}>
                    {
						showDateChip &&
                        <Tag className="single-chip" closable onClose={()=>deleteChip('month')}> {moment(selectedDate).format("MMM-YYYY")}</Tag>

							// <div className="single-chip">
							// 	<span className="chip-text">{moment(selectedDate).format("MMM-YYYY")}</span>
							// 	<span className="chip-cross" onClick={() => {deleteChip('month')}}> <CloseOutlined /> </span>
							// </div>
					}
					{
                        clientSelected !== '' &&
                        <Tag className="single-chip" closable onClose={()=>deleteChip('client')}> {clientSelected}</Tag>
                    
                                    // <div className="single-chip">
                                    //     <span className="chip-text">{clientSelected}</span>
                                    //     <span className="chip-cross" onClick={() => {deleteChip('client')}}> 
                                    //         <CloseOutlined />
                                    //     </span>
                                    // </div>
					}
				</div>
            <div className="leads-wrapper">
                {loaded ? leads.length === 0 ? (
                    <div className="leads-error">No leads found</div>
                ) : (
                    leads.map((lead, index) => (
                        <LeadCard
                            key={index}
                            lead={lead}
                            setShowDetail={setShowDetail}
                            setSelectedLead={setSelectedLead}
                            setSpin={setSpin}
                        />
                    ))
                ) :  <Skeleton active />}
                <br />
            </div>
            <div className="bottom-detail-wrapper">
                <CandidateProfilePopupV2 visible={showDetail} setShowDetail={setShowDetail} lead={selectedLead} setRaiseIssueSider={setRaiseIssueSider} setViewDetailLead={setViewDetailLead}></CandidateProfilePopupV2>
            </div>
            </div>
            {raiseIssueSider &&
                <RaiseIssueSider
                    closeSider={() => setRaiseIssueSider(false)}
                    mitraReducer={mitraReducer}
                    selectedLead={viewDetailLead}
                />
            }
        </>
    );
};

export default TeamLeadspage;
