import React, {useContext, useEffect, useState} from "react";
import { Typography, Radio, Space,Input, Checkbox, Divider,Tag, Button, notification } from 'antd';
import {CheckCircleFilled,InfoCircleFilled} from '@ant-design/icons';
import './kaleyraCallComponents/callStatus.scss';
import PageHeaderComponent from "./common/PageHeader";
import CONSTANTS from "../constants/constants";
import * as queryString from 'query-string';
import { addCallRemark, addCustomCallNotes, getCallStatus } from "../service/kaleyraCallService";
import { useHistory } from "react-router-dom";
import { AppContext } from "../App";
import moment from "moment";
import { RouterPrompt } from "./RouterPrompt/RouterPrompt";

const { Text} = Typography;
const { TextArea } = Input;

const CustomCallNotes = () => {
    const [breadcrumb] = useState([{link: '/custom-call-notes', label: 'Add Call Notes'}]);
    const [callConnectedTags, setCallConnectedTags] = useState(CONSTANTS.CALL_TAGS.CONNECTED_TAGS);
    const [callNotConnectedTags, setCallNotConnectedTags] = useState(CONSTANTS.CALL_TAGS.NOT_CONNECTED_TAGS);
    const [ifCallConnected, setIfCallConnected] = useState(true);
    const [checkboxTicked, setCheckboxTicked] = useState(false);
    const [comment, setComment] = useState('');
    const [callId, setCallId] = useState('');
    const history = useHistory();
    const {mitraReducer} = useContext(AppContext);
    const [callStatus, setCallStatus] = useState({});
    const [loading, setLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [showPrompt, setShowPrompt] = useState(true);
    const [userName, setUserName] = useState({});
    const [userId, setUserId] = useState({});
    const [recommendationHistoryId, setRecommendationHistoryId] = useState({});
    const [phoneNumber, setPhoneNumber] = useState({});
    
    useEffect(()=>{
        // Adding these two lines to drop previous component values.
        setCallConnectedTags([...CONSTANTS.CALL_TAGS.CONNECTED_TAGS]);
        setCallNotConnectedTags([...CONSTANTS.CALL_TAGS.NOT_CONNECTED_TAGS]);
        const parsedUrl = queryString.parse(window.location.search);
        console.log(parsedUrl, 'ParsedUrl', parsedUrl.userName,parsedUrl.userId, parsedUrl.scheduledInterviewId);
        setUserName(parsedUrl.userName);
        setUserId(parsedUrl.userId);
        setPhoneNumber(parsedUrl.phoneNumber);
        setRecommendationHistoryId(parsedUrl.rhId);

    return () => {
        for (let i=0;i<callConnectedTags.length;i++) {
            if (callConnectedTags[i].isChecked) callConnectedTags[i].isChecked=false;
        }
        for (let i=0;i<callNotConnectedTags.length;i++) {
            if (callNotConnectedTags[i].isChecked) callNotConnectedTags[i].isChecked=false;
        }
        setCallConnectedTags([...callConnectedTags]);
        setCallConnectedTags([...callNotConnectedTags]);
    }
    }, []);

    const onTagChange = (tagList, tagId, tagType) => {
        setDisableSubmit(false);
        for (let i=0;i<tagList.length; i++) {
            if (tagList[i].id === tagId) {
                tagList[i].isChecked = true;
            } else {
                tagList[i].isChecked = false;
            }
        }
        if (tagType === 'CONNECTED') {
            setCallConnectedTags([...tagList])
            const newTagValue = [...callNotConnectedTags];
            for (let i=0;i<newTagValue.length; i++) {
                newTagValue[i].isChecked = false;
            }
        } else {
            setCallNotConnectedTags([...tagList]);
            const newTagValue = [...callConnectedTags];
            for (let i=0;i<newTagValue.length; i++) {
                newTagValue[i].isChecked = false;
            }
        }
    };

    const onRadioChange = (event) => {
        setDisableSubmit(true);
        setCheckboxTicked(false);
        setComment('');
       setIfCallConnected(!ifCallConnected);
    };

    const onCheckboxValueChange = () => {
        setCheckboxTicked(!checkboxTicked);
    }

    const onCommentChange = (event) => {
        setComment(event.target.value)
    }

    const openNotification = () => {
        const key = `open${Date.now()}`;
        const placement= 'bottomRight';
        notification.open({
          message: `Call log successfully recorded for: ${userName}`,
          key,
          duration: 5,
          placement,
          icon: <CheckCircleFilled style={{ color: '#52C41A' }} /> ,
        });
      };

    const submitRemarks = () => {
        setLoading(true);
        const tagGroup = ifCallConnected ? callConnectedTags: callNotConnectedTags;
        const selectedTag = tagGroup.filter((tag) => tag.isChecked);
        if (!selectedTag.length) {
            setLoading(false);
            notification['error']({
                message: 'Please select a status tag to continue !!'
              });
            return;
        }
        const apiParams = {
            mitraId: mitraReducer && mitraReducer.mitraInfo && mitraReducer.mitraInfo.id || null,
            managerMitraId: mitraReducer && mitraReducer.mitraInfo && mitraReducer.mitraInfo.managerMitraID || null,
            userId: userId,
            recommendationHistoryId: recommendationHistoryId || null,
            callerNumber: mitraReducer && mitraReducer.mitraInfo && mitraReducer.mitraInfo.phoneNumber || null,
            receiverNumber: phoneNumber,
            isCallConnected: ifCallConnected,
            isFollowUpNeeded: checkboxTicked,
            tag: selectedTag?.[0]?.value || "",
            comment: comment,
        };
        addCustomCallNotes(apiParams).then((response) => {
            setLoading(false);
            setShowPrompt(false);
            openNotification();
            setTimeout(()=> {
                history.goBack();
            }, 1000);
        }).catch((error)=> {
            setLoading(false);
            console.log(error);
        });
    }


    return (
        <div>
        <div className="header">
            <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb}></PageHeaderComponent>
        </div>
        <div className="call-status-main-container">

            <div className="call-feedback">
                <Space direction="vertical" size={24} style={{background:'#ffffff', width:"100%", padding:'20px'}}>
                    <Radio.Group onChange={onRadioChange} value={ifCallConnected}>
                        <Space direction="vertical" size={24}>
                            <div className="radio-section">
                                <Radio value={true}  className="radio-text">Connected</Radio>
                                {
                                    ifCallConnected && <Checkbox onChange={onCheckboxValueChange} checked={checkboxTicked}>Follow up again</Checkbox>
                                }
                            </div>
                            {
                                ifCallConnected && (
                                    <>
                                    <div className="tag-container">
                                    <Text style={{fontSize:'1rem',fontWeight:'700', display:'flex'}}>Select status (required) :</Text>
                                        <div className="tag-wrapper-container-connected">
                                            {
                                                callConnectedTags.map((tag)=> {
                                                    return (
                                                        <Tag.CheckableTag 
                                                        key={tag.id} 
                                                        className={!tag.isChecked ? 'tags':'tags tag-active'}
                                                        checked={tag.isChecked}
                                                        onChange={()=> onTagChange(callConnectedTags, tag.id, 'CONNECTED')}
                                                        >{tag.value}</Tag.CheckableTag>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <Divider></Divider>
                                    <TextArea
                                        placeholder="Add Comment"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        onChange={onCommentChange}
                                        value={comment}
                                    />
                                    </>
                                )
                            }
                            

                            <div className="radio-section">
                                <Radio value={false} className="radio-text">Not Connected</Radio>
                                {
                                    !ifCallConnected && <Checkbox onChange={onCheckboxValueChange} checked={checkboxTicked}>Follow up again</Checkbox>
                                }
                            </div>
                            {
                                !ifCallConnected && (
                                    <>
                                    <div className="tag-container">
                                    <Text style={{fontSize:'1rem',fontWeight:'700', display:'flex'}}>Select status (required) :</Text>
                                        <div className="tag-wrapper-container-not-connected">
                                        {
                                                callNotConnectedTags.map((tag)=> {
                                                    return (
                                                        <Tag.CheckableTag 
                                                        key={tag.id} 
                                                        className={!tag.isChecked ? 'tags':'tags tag-active'}
                                                        checked={tag.isChecked}
                                                        onChange={()=> onTagChange(callNotConnectedTags, tag.id, 'NOT_CONNECTED')}
                                                        >{tag.value}</Tag.CheckableTag>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <Divider></Divider>
                                    <TextArea
                                        placeholder="Add Comment"
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        onChange={onCommentChange}
                                        value={comment}
                                    />
                                    </>
                                )
                            }
                        
                        </Space>
                    </Radio.Group>
                    <Button 
                    style={{color: '#fff',background: ifCallConnected ? '#52C41A': '#F5222D'}} 
                    className="custom-submit-button"
                    loading={loading}
                    onClick={submitRemarks}>Submit</Button>
                </Space>
            </div>
            
        </div>	
    </div>
    );
};

export default CustomCallNotes
