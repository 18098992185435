import axios from 'axios';
import moment from 'moment';

export const getDataFromEnums = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getDataFromEnums`;
    return axios.get(url, {withCredentials: true});
};

export const createOrUpdate = async (data) => {
    const formData = new FormData();
    delete data.data.container.experienceLevel;
    delete data.data.container.englishLevels;
    delete data.data.container.educationLevel;
    delete data.data.container.salaryLevel;
    for (const [key, value] of Object.entries(data)) {
        formData.set(key, JSON.stringify(value));
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/lead/additionalInfo';
    return axios.post(url, formData, {withCredentials: true});
};

export const get = async (scheduledInterviewId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/lead/additionalInfo/${scheduledInterviewId}`;
    return axios.get(url, {withCredentials: true});
};

export const postAdditionalInfo = async (data, mitra, extra) => {
    const requestData = {
        submission: {
            data: {
                phoneNumber: extra.phoneNumber,
                name: extra.name,
                sourceName: mitra.name,
                sourceId: mitra.id,
                // ...((extra.companyName).toLowerCase().indexOf('rapido') !== -1 ? {
                //     clientName: extra.companyName,
                //     jobId: extra.jobId,
                //     jobDemandId: extra.jobDemandId
                // } : {})
            },
            currentPageData: {
                // ...((extra.companyName).toLowerCase().indexOf('rapido') !== -1 ?{createApplication:true,uniqueness:true}:{})
            }
        }
    }

    for (let key in data) {
        if (data[key] && !Array.isArray(data[key])) {
            requestData.submission.currentPageData[key] = data[key]
            requestData.submission.data[key] = data[key]
        }
    }
    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}
export const getScheduledInterviewIdForStaffingClients = async (submissionData) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/lead/additionalInfo/getScheduledInterviewIdForStaffingClients`;
    return axios.post(url, submissionData, {withCredentials: true});
}
export const fetchUserIdFromScheduledInterviewId = async (scheduledInterviewId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/lead/fetchUserIdFromScheduledInterviewId`;
    return axios.post(url, {scheduledInterviewId}, {withCredentials: true});
}
export const isAllRequiredDocumentsPresentForUserId = async (userId, scheduledInterviewId) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/lead/zepto/CheckIfAllRequiredDocumentsArePresentForUserId`;
    return axios.post(url, {'userId': userId, 'scheduledInterviewId': scheduledInterviewId}, {withCredentials: true});
}
export const isAllRequiredDocumentsPresent = async (scheduledInterviewId) => {
    const userIdResponse = await fetchUserIdFromScheduledInterviewId(scheduledInterviewId);
    //console.log("userIdResponse in isAllRequiredDocumentsPresent:>>",userIdResponse);
    const userId = userIdResponse.data[0].userId
    
    //console.log("userId in isAllRequiredDocumentsPresent:>>",userId);
    const docsPresentResponse = await isAllRequiredDocumentsPresentForUserId(userId, scheduledInterviewId);
    //console.log("docsPresentResponse in isAllRequiredDocumentsPresent:>>",docsPresentResponse);
    const isReadyForVendorFormFilling = docsPresentResponse.data.status;
    //console.log("isReadyForVendorFormFilling in isAllRequiredDocumentsPresent:>>",isReadyForVendorFormFilling)
    return isReadyForVendorFormFilling;
}


export const postCandidateDocuments = async (data, mitra, extra, allowDocUpload=null) => {
    let adhoc = false;
    if(extra.companyName.toLowerCase().indexOf('uber') != -1) adhoc = true;
    const requestData = {
        submission: {
            data: {
                phoneNumber: extra.phoneNumber,
                name: extra.name,
                sourceName: mitra.name,
                sourceId: mitra.id,
                adhoc: adhoc,
                ...(extra?.companyName?.toLowerCase().indexOf('3pl') !== -1 ? { allowDocUpload: allowDocUpload} : {}),
            },
            currentPageData: {}
        }
    }

    let docs = false;

    for (let key in data) {
        if (Array.isArray(data[key])) {
            docs = true
            break;
        }
    }

    if (docs) {
        for (let key in data) {
            if (Array.isArray(data[key]) && data[key].length > 0) {
                requestData.submission.data[key] = {url: data[key][0].data ? data[key][0].data.imageUrl : data[key][0].url, originalName: data[key][0].originalName, size: data[key][0].size}
                requestData.submission.currentPageData[key] = {url: data[key][0].data ? data[key][0].data.imageUrl : data[key][0].url, originalName: data[key][0].originalName, size: data[key][0].size}
            }
        }
    }
    else {
        for (let key in data) {
            if (data[key] && !Array.isArray(data[key])) {
                requestData.submission.data[key] = data[key]
                requestData.submission.currentPageData[key] = data[key]
            }
        }
    }

    if (data["aadhaarCard1"] && data["aadhaarCard1"].length > 0) {
        requestData.submission.data["aadhaarCard"] = {...requestData.submission.data["aadhaarCard"], otherSideImageUrl: data["aadhaarCard1"][0].data ? data["aadhaarCard1"][0].data.imageUrl : data["aadhaarCard1"][0].url}
        delete requestData.submission.data["aadhaarCard1"]
        delete requestData.submission.currentPageData["aadhaarCard1"]
    }

    if (data["drivingLicense1"] && data["drivingLicense1"].length > 0) {
        requestData.submission.data["drivingLicense"] = {...requestData.submission.data["drivingLicense"], otherSideImageUrl: data["drivingLicense1"][0].data ? data["drivingLicense1"][0].data.imageUrl : data["drivingLicense1"][0].url}
        delete requestData.submission.data["drivingLicense1"]
        delete requestData.submission.currentPageData["drivingLicense1"]
    }

    if (data["vehicleRc1"] && data["vehicleRc1"].length > 0) {
        requestData.submission.data["vehicleRc"] = {...requestData.submission.data["vehicleRc"], otherSideImageUrl: data["vehicleRc1"][0].data ? data["vehicleRc1"][0].data.imageUrl : data["vehicleRc1"][0].url}
        delete requestData.submission.data["vehicleRc1"]
        delete requestData.submission.currentPageData["vehicleRc1"]
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}

export const postCandidate = async (data, mitra, extra, adhocRequest = null) => {
    const requestData = {
        submission: {
            data: {
                phoneNumber: extra.phoneNumber,
                name: extra.name,
                sourceName: 'mitra-leader',
                sourceId: mitra.id
            },
            currentPageData: {
                ...(extra.companyName.toLowerCase().indexOf('zepto') !== -1 ? { updateZeptoStatus: true } : {})
            }
        }
    }

    let docs = false;

    for (let key in data) {
        if (Array.isArray(data[key])) {
            docs = true
            break;
        }
    }

    for (let key in data) {
        if (data[key] && !Array.isArray(data[key])) {
            requestData.submission.data[key] = data[key]
            requestData.submission.currentPageData[key] = data[key]
        }
    }
    if(adhocRequest) {
        requestData.submission.currentPageData = {
            "createApplication": true,
            ...(extra.companyName.toLowerCase().indexOf('uber') !== -1 ? { "isApplicationCompleteForUber": true } : {} ),
            "uniqueness": true
        }
        requestData.submission.data = {
            ...requestData.submission.data,
            jobId: extra.jobId,
            jobDemandId: extra.jobDemandId,
            setNonUnique: true,
            clientName: extra.companyName
        }
    }
    console.log(requestData)

    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}
export const createApplication = (data, mitra, extra) => {
    const requestData = {
        submission: {
            data: {
                ...extra,
                ...(extra.companyName.toLowerCase().indexOf('rapido') !== -1 ? {submissionType: data.uniqueness}:{...data}),
                sourceName: "mitra-leader",
                sourceId: mitra.id,
                createApplication: true,
                uniqueness: true,
                clientName: extra.companyName,
                city: extra.cityPreference,
                cityPreference: extra.companyCity,
                managerMitraId: mitra.managerMitraID ? mitra.managerMitraID : mitra.id,    
            },
            currentPageData: {
                createApplication: true,
                ...((extra.companyName).toLowerCase().includes('uber') ? {
                    isApplicationCompleteForUber: true
                } : {}),
                uniqueness: true
            }
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}

export const uniquenessCheck = (data) => {
    const requestData = {
        submission: {
            data: {
                name: data.name,
                phoneNumber: data.phoneNumber,
                clientName: data.companyName,
                uniqueness: true,
                city: data.companyCity
            },
            currentPageData: {
                uniqueness: true
            }
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}


export const getCandidateInfo = async (phoneNumber) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/getCandidateInfo?phoneNumber=${encodeURIComponent(phoneNumber)}`, {withCredentials: true})
}

export const getStoreShiftDetails = async (firstLocalityPreference) => {
    const city = firstLocalityPreference ? firstLocalityPreference.city : null;
    const store = firstLocalityPreference ? firstLocalityPreference.firstLocalityPreference : null;
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/zeptoSsu/storeAndShiftDetails?city=${city}&store=${store}`, {withCredentials: true});
}

export const postAdditionalInfoForRapido = async (data, mitra, extra) => {
    const requestData = {
        submission: {
            data: {
                phoneNumber: extra.phoneNumber,
                name: extra.name,
                sourceName: mitra.name,
                sourceId: mitra.id,
                clientName: extra.companyName,
                jobId: extra.jobId,
                jobDemandId: extra.jobDemandId,
                email: data.email,
                dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
                gender: data.gender,
                dlExpiry: moment(data.dlExpiry).format('YYYY-MM-DD'),
                vehicleNumber: data.vehicleNumber,
                maskedAadharNumber: data.aadhaarNumber,
                docChoice: data.docChoice
            },
            currentPageData: {
                email: data.email,
                dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
                gender: data.gender,
                dlExpiry: moment(data.dlExpiry).format('YYYY-MM-DD'),
                vehicleNumber: data.vehicleNumber,
                maskedAadharNumber: data.aadhaarNumber,
                docChoice: data.docChoice  
            }
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}

export const verifyOtp = async (data, mitra, extra) => {
    //FIXME: to be fixed after upload doc
    const requestData = {
        data: {
            phoneNumber: extra.phoneNumber,
            name: extra.name,
            sourceName: mitra.name,
            sourceId: mitra.id,
            clientName: extra.companyName,
            jobId: extra.jobId,
            jobDemandId: extra.jobDemandId,
            otp: data.otp,
            submissionType: data.uniqueness? data.uniqueness : 'fullOnboarding',
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/rapido/verifyOtp`
    return axios.post(url, requestData, { withCredentials: true });
}

export const sendOtp = async (data, mitra, extra) => {
    const requestData = {
        arg: {
            scheduledInterviewId: extra.scheduledInterviewId,
            clientName: extra.companyName,
        },
        user: {
            phoneNumber: extra.phoneNumber,
        }
    }

    // console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/rapido/sendOtp`
    return axios.post(url, requestData, { withCredentials: true });
}
  

export const sendZomatoOtp = async (data, mitra, extra) => {
    const requestData = {
        arg: {
            scheduledInterviewId: extra.scheduledInterviewId,
            clientName: extra.companyName,
        },
        user: {
            phoneNumber: extra.phoneNumber,
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/sendOtp`
    return axios.post(url, requestData, { withCredentials: true });
}

export const verifyZomatoOtp = async (data, mitra, extra) => {
    const requestData = {
        data: {
            phoneNumber: extra.phoneNumber,
            name: extra.name,
            sourceName: mitra.name,
            sourceId: mitra.id,
            clientName: extra.companyName,
            jobId: extra.jobId,
            jobDemandId: extra.jobDemandId,
            otp: data.otp,
            submissionType: data.uniqueness? data.uniqueness : 'fullOnboarding',
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/verifyOtp`
    return axios.post(url, requestData, { withCredentials: true });
}

export const submitZomatoOtp = async (data) => {
    const requestData = {
        data: {
            phoneNumber: data.phoneNumber,
            otp: data.otp
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/verifyOtp`
    return axios.post(url, requestData, { withCredentials: true });
}

export const uploadImageToS3 = async(data) => {
    const formData = new FormData();
    formData.append('file', data);

    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/uploadFile`
    return axios.post(url, formData, { withCredentials: true });
}

export const validateZomatoUser = async (extra) => {
    //FIXME: to be fixed after upload doc
    const requestData = {
        data: {
            phoneNumber: extra.phoneNumber
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/validateUser`
    return axios.post(url, requestData, { withCredentials: true });
}

export const getZomatoCitiesData = async (lead) => {
    //FIXME: to be fixed after upload doc
    const requestData = {
        data: {
            phoneNumber: lead.phoneNumber
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/getZomatoCities`
    return axios.post(url, requestData, { withCredentials: true });
}

export const getZomatoZonesData = async (lead) => {
    //FIXME: to be fixed after upload doc
    const requestData = {
        data: {
            phoneNumber: lead.phoneNumber,
            city: lead.city
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/getZomatoZones`
    return axios.post(url, requestData, { withCredentials: true });
}

export const getZomatoVehicleData = async (lead) => {
    //FIXME: to be fixed after upload doc
    const requestData = {
        data: {
            phoneNumber: lead.phoneNumber,
            city: lead.city
        }
    }
    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/getZomatoVehicleData`
    return axios.post(url, requestData, { withCredentials: true });
}

export const getDocVerificationData = async (lead) => {
    //FIXME: to be fixed after upload doc
    const requestData = {
        data: {
            phoneNumber: lead.phoneNumber,
            city: lead.city
        }
    }
    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/getDocVerificationData`
    return axios.post(url, requestData, { withCredentials: true });
}



  
export const getZomatoDigiLockerVerificationUrl = async (lead) => {
    //FIXME: to be fixed after upload doc
    const requestData = {
        data: {
            phoneNumber: lead.phoneNumber
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/getZomatoDigiLockerVerificationUrl?phoneNumber=${lead.phoneNumber}`
    return axios.get(url, { withCredentials: true });
}
export const getKycStatus = async (lead) => {
    //FIXME: to be fixed after upload doc
    const requestData = {
        data: {
            phoneNumber: lead.phoneNumber
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/getKycStatus?phoneNumber=${lead.phoneNumber}`
    return axios.get(url, { withCredentials: true });
}

export const getZomatoObSubmissionData = async ({source, phoneNumber, mlName}) => {
    //FIXME: to be fixed after upload doc
    const url = `${process.env.REACT_APP_BACKEND_URL}/getZomatoSubmissionDetails?phoneNumber=${phoneNumber}&source=${source}&mlName=${mlName}`
    return axios.get(url, { withCredentials: true });
}


export const updateZomatoCity = async (lead) => {
    //FIXME: to be fixed after upload doc
    const requestData = {
        data: {
            phoneNumber: lead.phoneNumber,
            cityId: lead.cityId
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/updateZomatoCities`
    return axios.post(url, requestData, { withCredentials: true });
}


export const updateZomatoData = async (data) => {
    //FIXME: to be fixed after upload doc
    const requestData = {
        dataToUpdate: data.dataToUpdate,
        phoneNumber: data.phoneNumber
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/updateZomatoData`
    return axios.post(url, requestData, { withCredentials: true });
}
  
export const pendingRapidoDocuments = async (lead) => {
    const requestData = {
        data: {
            scheduledInterviewId: lead.scheduledInterviewId
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/rapido/pendingDocuments`;
    return axios.post(url, requestData, { withCredentials: true });
}

export const submitApplication = (data, lead, mitra, isBeforeNext = false) => {
    const submissionType = data.uniqueness ? data.uniqueness : 'fullOnboarding';
    const basicDetails = {
        phoneNumber: lead.phoneNumber,
        city: lead.companyCity,
        jobId: lead.jobId,
        jobDemandId: lead.jobDemandId,
        submissionType: submissionType,
        clientName: lead.companyName
    }
    let additionalInfo = {};
    if (submissionType === "fullOnboarding") {
        additionalInfo = {
            gender: data.gender,
            email: data.email,
            dateOfBirth: data.dateOfBirth,
            vehicleNumber: data.vehicleNumber,
            dlExpiry: data.dlExpiry,
            maskedAadharNumber: data.aadhaarNumber,
            docChoice: data.docChoice,
            isBeforeNext: isBeforeNext
        }
    }
                    
    const requestData = {
      data: {
        sourceId: mitra.id,
        ...basicDetails,
        additionalInfo: additionalInfo,
      }
    }
    
    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/rapido/submitApplication`
    return axios.post(url, requestData, {withCredentials: true});
  }
  export const claimMitraAppLeads = async (data, key) => {
    const requestData = {
        submission: {
            data:{...data,claimApplication:true},
            currentPageData:key
        }
    }

    console.log('requsetData :>> ', requestData);
    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}

export const updateSiMetaData = async (data, key) => {
    const requestData = {
        submission: {
            data: data,
            currentPageData: key
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}

export const genericPostToFormIO = async (data, key) => {
    const requestData = {
        submission: {
            data: data,
            currentPageData: key
        }
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}
