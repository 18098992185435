import React, {useEffect, useState} from 'react';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { InfoCircleOutlined,RightCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Card,Tooltip,Skeleton,Typography, Divider, Row} from 'antd';
const { Text } = Typography;

const OverallSummary = ({performanceDashboardData,mitraDispatch}) => {
    const history=useHistory();
    const routing = (title) => {
        trackEvent("viewed_candidates_list_overall_summary",{}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        history.push({
            pathname: '/leads-page',
            search: title
        })
        mitraDispatch()
    }
    let uniqueCandidatesChild = performanceDashboardData?.totalReferrals?.children
  .map((child, index) => ({ child, index })) // Map each element to an object containing both child and index
  .filter(({ child }) => child.label === 'Unique Candidates');
  return (
    <>
        {
            performanceDashboardData ? 
                <div className='overall-summary-box' >
                    {/* <div className='overall-summary-static-container'> */}
                    <div className='total-referral-box'>
                    <div style={{display: 'flex', flexDirection: 'row'}}> 
                        <div style={{padding: '0px 5px', width: '50%', borderRight: '1px solid #f0f0f0' }}>
                            <div className='total-referral-container'>
                                <div style={{fontFamily: 'Nunito Sans Bold', display: 'flex', alignItems: 'center'}}>
                                    <Text style={{marginRight:'5px'}}>{performanceDashboardData.totalReferrals?.label}</Text>
                                    <Tooltip title={performanceDashboardData.totalReferrals?.description}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                                    <Text className='total-referral-number'>{performanceDashboardData.totalReferrals?.count}</Text>
                                    {/* <RightCircleOutlined style={{fontSize:'20px', marginLeft:'10px',color:'#D34612'}} /> */}
                                </div>
                            </div>
                        </div>
                            {/* <Text className='total-referral-number' style={{marginTop: '10px'}}>{performanceDashboardData.totalReferrals?.count}</Text> */}
                        {uniqueCandidatesChild.length > 0 ?
                        <div style={{padding: '0px 5px', width: '50%'}} key={uniqueCandidatesChild[0].child.index}>
                            <div className='total-referral-container'>
                                <div style={{fontFamily: 'Nunito Sans Bold', display: 'flex', alignItems: 'center', marginRight:'10px'}}>
                                    <Text style={{marginRight: '5px'}}>{uniqueCandidatesChild[0].child.label}</Text>
                                    <Tooltip title={uniqueCandidatesChild[0].child.description}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}} onClick={()=>routing(uniqueCandidatesChild[0].child.label)}>
                                    <Text className='total-referral-number'>{uniqueCandidatesChild[0].child.count}</Text>
                                    <RightCircleOutlined style={{fontSize:'20px', marginLeft:'10px',color:'#D34612'}} />
                                </div>
                            </div>
                        </div> : <div> </div> }
                        {/* </div> */}
                     </div>
                        <Divider />
                        
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            {
                                performanceDashboardData.totalReferrals && performanceDashboardData.totalReferrals.children && performanceDashboardData.totalReferrals.children.map((child, index) => {
                                    if(child.label === 'Unique Candidates') return;
                                        return  (
                                            <div style={{padding: '0px 5px', width: '50%', borderRight: child.label === 'Action Needed Referrals' ? '1px solid #f0f0f0' : ''}} key={index}>
                                                <div className='total-referral-container'>
                                                    <div style={{fontFamily: 'Nunito Sans Bold', display: 'flex', alignItems: 'center', marginRight:'10px'}}>
                                                        <Text style={{marginRight: '5px'}}>{child.label}</Text>
                                                        <Tooltip title={child.description}>
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}} onClick={()=>routing(child.label)}>
                                                        <Text className='total-referral-number'>{child.count}</Text>
                                                        <RightCircleOutlined style={{fontSize:'20px', marginLeft:'10px',color:'#D34612'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                })   
                            }
                        </div>
                    </div>  
                    {/* <div className='total-referral-box'  >
                        <div className='total-referral-container'>
                            <div style={{fontFamily: 'Nunito Sans Bold', display: 'flex', alignItems: 'center'}}>
                                <Text style={{marginRight:'5px'}}>{performanceDashboardData.mitraAppDownloads?.label}</Text>
                                <Tooltip title={performanceDashboardData.mitraAppDownloads?.description}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </div>
                            <Text className='total-referral-number' style={{marginTop: '10px'}}>{performanceDashboardData.mitraAppDownloads?.count}</Text>
                        </div>
                        
                        <Divider />
                        
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            {
                                performanceDashboardData.mitraAppDownloads && performanceDashboardData.mitraAppDownloads.children && performanceDashboardData.mitraAppDownloads.children.map((child, index) => {
                                    return  (
                                        <div style={{padding: '0px 5px', width: '50%', borderLeft: index!==0 ? '1px solid #f0f0f0' : ''}} key={index}>
                                            <div className='total-referral-container'>
                                                <div style={{fontFamily: 'Nunito Sans Bold', display: 'flex', alignItems: 'center', marginRight:'10px'}}>
                                                    <Text style={{marginRight: '5px'}}>{child.label}</Text>
                                                    <Tooltip title={child.description}>
                                                        <InfoCircleOutlined />
                                                    </Tooltip>
                                                </div>
                                                <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}} onClick={()=>routing(child.label)}>
                                                    <Text className='total-referral-number'>{child.count}</Text>
                                                    <RightCircleOutlined style={{fontSize:'20px', marginLeft:'10px',color:'#D34612'}} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })   
                            }
                        </div>
                    </div>   */}
                    {/* </div> */}
                    <div className='remaining-milestone-container' >
                        {
                            performanceDashboardData.data && performanceDashboardData.data.length ? performanceDashboardData.data.map((item,index) => (
                                item.value === 'activation_without_app' ? null:
                                <Card className='overall-card' key={index}>
                                    <div className='overall-header'>
                                        <div style={{backgroundColor:item.color}} className='summary-color'></div>
                                        <Text className='summary-header'>{item.label}</Text> 
                                        <Tooltip title={item.description}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </div>
                                    <div className='overall-number' onClick={!item.label.includes('10 days') ? () => routing(item.label) : null}>
                                        <Text className='number'>{item.count} {item.label.includes('10 days') ? '%' : null}  </Text>
                                        {!item.label.includes('10 days') ? 
                                        <RightCircleOutlined style={{fontSize:'20px', marginLeft:'10px',color:'#D34612'}} /> : null }
                                    </div>
                                    {item.label.includes('10 days') && item.hasOwnProperty('noOfLeads') ? <p style={{fontSize: '12px', fontWeight: 'bold', display: 'block'}}>(Leads Count: {item.noOfLeads})</p>: null}
                                </Card>
                            )): null
                        }
                    </div>
                </div>
            :
                <Skeleton active/>
        }
    </>
  )
}

export default OverallSummary;
