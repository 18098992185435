import axios from 'axios';

export const getNotificationsContent = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/comms/getNotificationsContent`;
    return axios.get(url, { params: data, withCredentials: true });
};
export const getNotificationsForMitra = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/comms/getNotificationsForMitra`;
    return axios.get(url, { withCredentials: true,params: data ,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken'),
        isAdmin: data.isAdmin
    }});
};

export const getUnreadNotificationsCount = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/comms/getUnreadNotificationsCountForMitra`;
    return axios.get(url, { withCredentials: true });
}


