import React, { useEffect , useReducer ,useState , useRef ,  useContext} from "react";
import { useHistory } from "react-router-dom";
import { inValidateSession } from "../../service/loginService"
import NavigationTab from "../common/NavigationTab.component"
import SideSheet from "../common/SideSheet"
import SideInput from "../common/SiderInput"
import "./../../css/accountPage.scss"
import useGAEventsTracker from '../../service/useGSEventsTracker';
// import useMoengage from '../../hooks/useMoengage';
import {Form} from "react-formio";
import { AppContext } from "../../App";
import { getMitraProfileData, postMitraDocs  } from "../../service/uploadMitraDocsService";
import { Tooltip , PageHeader, Button} from 'antd';
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";
import LockingComponent from "../common/Locking.component";

const AccountPage = () => {
    const { mitraReducer , mitraDispatch , setAlert} = useContext(AppContext);
    const history = useHistory();
    const formRef = useRef(null);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [submitdisable, setSubmitdisable] = useState(true);
    const [successResponse, setSuccessResponse] = useState(null)
	const AccountGAEventsTracker = useGAEventsTracker("Accounts")
    // const [createMoengageUser, trackEvent] = useMoengage();
    const [userBankInfo]=useState(['Account Holder’s Name','Account Number','IFSC Code','Pan Number']);
    const [disabledFields, setDisabledFields] = useState({
        cancelledCheque: false,
        bankPassbook: false
    });
    const [listContent] = useState([
        {
            img:'card-id.svg',
            name:'My Profile',
            description:'Personal Details, Bank Account Details',
            routes:'upload-documents'
        },
        {
            img:'frameFlag.svg',
            name:'Training Sessions',
            description:'Schedule Training Session',
            routes:'training-session'
        },
        {
            img:'ppl.svg',
            name:'Team Management',
            description:'Add/Remove Member, Assign Leads',
            routes:'team-management'
        },
        {
            img:'leader.svg',
            name:'Lead Management',
            description:'Manage Source Leads, Bulk Uniqueness Check',
            routes:'lead-management/bulk-uniqueness'
        }
        // {
        //     img:'mitra-logo.png',
        //     name:'Refer Mitra Leader',
        //     description:'Refer Mitra Leders and Earn bouns ₹20,000 '
        // },
        // {
        //     img:'mitra-logo.png',
        //     name:'Help & Support',
        //     description:'FAQ, Call and Email Support'
        // }
    ])

    const [sider,setSider] = useState(false);
    const [submissionData, setSubmissionData] = useState({
        data: {

        }
    });
    const [visibleAuth, setVisibleAuth] = useState(false);

    const trackMoengageEvent = (event,obj) => {
        trackEvent(event, obj || {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }    

    useEffect(() => {
        trackMoengageEvent('account_hub_viewed');
        console.log(formRef)
    },[]);

    useEffect(() => {
        if(mitraReducer?.mitraInfo){
            getMitraProfileData(mitraReducer?.mitraInfo.phoneNumber, isAdmin).then((mitraInfoRes) => {
                for(let key in mitraInfoRes.data) {
                    if(mitraInfoRes.data[key].type === 'bankPassbook' || mitraInfoRes.data[key].type === 'cancelledCheque'){
                        setDisabledFields({
                            cancelledCheque: true,
                            bankPassbook: true,
                            submit: true
                        });
                        let imageUrl, size, originalName;
                        try {
                            const parsedData = JSON.parse(mitraInfoRes.data[key].value);
                            imageUrl = parsedData.url;
                            size = parsedData.size;
                            originalName = parsedData.url;
                        } catch (error) {
                            imageUrl = undefined
                            size= undefined
                            originalName = undefined
                        }
                        setSubmissionData({
                            data: {
                                [mitraInfoRes.data[key].type]: [{
                                    storage: "url",
                                    url: imageUrl,
                                    size,
                                    originalName
                                }]
                            }
                        })
                        break;
                    }
                }
            })
			.catch((error) => {
                // history.replace("/");
                setVisibleAuth(true);
                localStorage.removeItem("payoutAccessToken");
            });
        }
	}, [mitraReducer]);

    const options = {
        disabled: disabledFields,
        hooks: {
            beforeSubmit: async (submission, next) => {
                let errors = [];
                try {
                    const res = await postMitraDocs(submission.data, mitraReducer.mitraInfo)
                    for(let key in res.data){
                        if(res.data[key]!== "200"){
                            let statementCopy=`${key}: ${res.data[key].split("Error: ").join("").split("500: ").join("")}`;
                            setSuccessResponse(false);
                            errors.push({message: statementCopy});
                            setAlert({
                                display:true,
                                text:statementCopy,
                                type:'error'
                              });
                        }
                        else{
                            var value;
                            if (key === "bankPassbook")
                                 value = "Passbook"
                             else if (key === "cancelledCheque")
                                 value = "Cheque"
                             trackMoengageEvent('bank_document_uploaded',{documentType: value});
                             //await setTimeout(()=>window.location.reload(), 4000);
                             mitraDispatch({type: 'LoginInfo', value: mitraReducer.mitraInfo});
                             setAlert({
                                display:true,
                                text:"Successfully uploaded!!",
                                type:'success'
                              });
                            //  formRef.current.element.hidden=true;
                             setSuccessResponse(true);
                        }
                    }
                } catch (error) {
                    if(error.response)
                        errors = error.response.data.message ? [{message: error.response.data.message}]: [{message: "something went wrong"}]
                }

                if(errors.length > 0 ){
                    next(errors)
                }
                else{
                    history.replace("/account")
                }
            }
        }
    }

    const handleAccountPages = (sidername) => {
        if(sidername === "terms-and-conditions"){
            trackMoengageEvent('terms_conditions_opened');
            return window.open("/terms-and-conditions")
        }
        // if(accountPagesEnum[el] === "User Management"){
        //     AccountGAEventsTracker("Open User Management")
        // }
        // if (route === 'uploadDocuments') trackMoengageEvent('update_bank_document_viewed');
        // if (route === 'userManagement') trackMoengageEvent('user_management_section_viewed');
        // history.push(`/account/${route}`)
        if(sidername.name == 'My Profile'){
            setSider(sidername.name)
        } else if(sidername.name == 'Lead Management'){
            history.push(`/${sidername.routes}`)
        }
        else{
            history.push(`/account/${sidername.routes}`)
        }
    }
    

    const onRenderForm=(e)=>{
        console.log('formRef',formRef.current);
        if(formRef.current){
            // formRef.current.element.style.opacity=0;
            formRef.current.element.getElementsByClassName('formio-component-button')[0].style.opacity=0;
        }
    }

    const uploadedFileName =(submissiondata)=>{
         const url = new URL(submissiondata.changed?.value[0]?.url);
         return url.pathname.replace('/','');
    }

    const onUpload =(e)=>{
        console.log(formRef.current.element);
        console.log(formRef.current);
        formRef.current.element.getElementsByClassName('browse')[0].click();
    }

    const submitBasicForm=()=>{
        formRef.current.element.querySelector('.formio-component-button button').click();
        setSubmitdisable(true);
    }

    const handleLogout = () => {
		inValidateSession()
			.then((response) => {
                localStorage.clear()
				window.location.reload();
			})
			.catch((error) => {
				console.log('Error logging out: ', error);
			});
	};

    const isAdmin = mitraReducer?.mitraInfo?.role === 'admin';

    return (
        <React.Fragment>
            {/* <div className="navigation-container">
                <NavigationTab currentTab={4} />
            </div> */}
            <div className="AccountPage mainContentZ">
                <LockingComponent path="account" pathName="Account" visibleAuth={visibleAuth} setVisibleAuth={setVisibleAuth} />
                {
                    !visibleAuth && (!isAdmin || localStorage.getItem('payoutAccessToken')) ? 
                <>
                <PageHeader
                    className="containerTop"
                    onBack={() => history.push('/more')}
                    title="Account"
                />
                <>
                {
                    listContent.map((item, index) =>{
                        if(item.routes === 'team-management'){
                            if(isAdmin){
                                return <div key={index} className="containerMiddle">
                                    <div className="listview" onClick={()=>handleAccountPages(item)}>
                                        <img className="list-img" src={`/image/${item.img}`}/>
                                        <div className="listview-name-des">
                                            <div className="name">{item.name}</div>
                                            <div className="des">{item.description}</div>
                                        </div>
                                    </div>
                                </div>
                            }else{
                                return null
                            }
                            
                        }else{
                            return <div key={index} className="containerMiddle">
                                        <div className="listview" onClick={()=>handleAccountPages(item)}>
                                            <img className="list-img" src={`/image/${item.img}`}/>
                                            <div className="listview-name-des">
                                                <div className="name">{item.name}</div>
                                                <div className="des">{item.description}</div>
                                            </div>
                                        </div>
                                    </div>
                        }
                    })
                }
                </>
                <div className="containerBottom">
                    <span onClick={()=>handleAccountPages("terms-and-conditions")} className="mobileViewBottomBtn">Terms & Conditions</span>
                    <span onClick={()=>handleLogout()} className="AccoutLogout mobileViewBottomBtn">Logout</span>
                </div>
                </>
                : null
                }
            </div>
            {sider && 
            <SideSheet submit={isAdmin ? {disabled:submitdisable,onClick:submitBasicForm} : null} sidername={sider} onDismiss={() => setSider(false)}>
                <div className="siderInnerContainer1">
                    <div className="titleLabel">Personal Details</div>
                    <div className="outerContainer">
                        <SideInput value={mitraReducer?.mitraInfo.name || 'NIL'} readOnly={true} placeholder={"Enter your name"} 
                                    imgsrc={'black-user.svg'} labelname={"Your Name"}/>
                        <SideInput value={mitraReducer?.mitraInfo.phoneNumber || 'NIL'} readOnly={true}  placeholder={"Example : 8884228005"}
                                    imgsrc={'phone.svg'} labelname={"Your Phone Number"}/>
                        <SideInput value={mitraReducer?.mitraInfo.homeCity || 'NIL'} readOnly={true}  placeholder={"Where you stay?"} 
                                    imgsrc={'location.svg'} labelname={"Location"}/>
                    </div>
                    {isAdmin && <div className="titleLabel">Bank Account Details {false && <span>(Approval Pending)</span>}</div>}
                    {false && //Future implementation
                    <div className="uploadedBox">
                            <div>
                                <img src="/image/file.svg"/>
                                <span>{uploadedFileName(submissionData)}</span>
                            </div>
                            <img src='/image/green-done.svg'/>
                    </div>}
                    {false  &&
                         <div className="userInfoWrapper">
                            {userBankInfo.map((item,index)=>{
                                return <div key={index} className="userInfoContainer">
                                            <label>
                                                {item}
                                            </label>
                                            <div>
                                                Mr. Rinaldo Almediya
                                            </div>
                                        </div>
                            })}
                    </div>}

                    {
                        isAdmin && <>
                            <div className="view-bank-info" style={{width:'100%'}}>
                                <Button onClick={() => {
                                    setSider(false); history.push({
                                        pathname: '/ml-onboarding'
                                    })
                                }} className="view-bank-info-btn" style={{width:'100%'}} type="secodary" size="middle" shape="default">
                                    Edit / View Bank Account Details
                                </Button>
                            </div>
                        </>
                    }
                    {/* {isAdmin && <p className="paraTag">You can not modify your documents from the app for security reasons. To modify your documents please create a ticket from the Help Section.</p>} */}
                </div>
            </SideSheet>}
        </React.Fragment>
    )
}

export default AccountPage
