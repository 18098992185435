import React, {useState, useEffect, useContext} from 'react';
import { CalendarOutlined, LineChartOutlined } from '@ant-design/icons';
import { Switch, Segmented, Skeleton } from 'antd';
import '../../css/runRate.scss';
import moment from 'moment';
import { Line } from '@ant-design/plots';
import { getRunRateCardData } from './../../service/reportService';
import {AppContext} from '../../App';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

const RunRateComponent = () => {
    const {mitraReducer, setAlert, mitraDispatch} = useContext(AppContext);
    const [isGraphVsible, setIsGraphVisible] = useState(false);
    const [cardData, setCardData] = useState([
        {
            title: "FT's completed this month",
            value: 0,
            color: '#d34712'
        },
        {
            title: "Projected FT's for this month",
            value: 0,
            color: '#d34712'
        },
        {
            title: "Target FT's per month",
            value: 0,
            color: '#1990ff'
        },
        {
            title: "Current Average FT's per day",
            value: 0,
            color: '#d34712'
        },
        {
            title: "Required FT's per day",
            value: 0,
            color: '#1990ff'
        }
    ]);
    const [dailyGraphData, setDailyGraphData] = useState();
    const [cumulativeGraphData, setCumulativeGraphData] = useState();
    const [loading, setLoading] = useState(false);
    const [graphViewType, setGraphViewType] = useState('Daily');
	const trackMoengageEvent = (event,obj) => {
		trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}
    const [displayRunRateSection, setDisplayRunRateSection] = useState(false);
    
    
    useEffect(() => {
        getRunRateCardData({mitraId: mitraReducer?.mitraInfo?.id}).then((res) => {
            if(res.data.status) {
                setLoading(false);
                setDisplayRunRateSection(true);
                setDailyGraphData(res.data.data.dateWiseRates);
                generateDailyGraphData(res.data.data.dateWiseRates, res.data.data.requiredRates.commitment);
                generateCumulativeGraphData(res.data.data.dateWiseRates, res.data.data.targets.commitment, res.data.data.totalFTDoneTillNow);
                const cardsData = [
                    {
                        title: "FT's completed this month",
                        value: res.data.data.totalFTDoneTillNow,
                        color: '#d34712'
                    },
                    {
                        title: "Projected FT's for this month",
                        value: res.data.data.projected,
                        color: '#d34712'
                    },
                    {
                        title: "Target FT's per month",
                        value: res.data.data.targets.commitment,
                        color: '#1990ff'
                    },
                    {
                        title: "Current Average FT's per day",
                        value: res.data.data.currentRate,
                        color: '#d34712'
                    },
                    {
                        title: "Required FT's per day",
                        value: res.data.data.requiredRates.commitment,
                        color: '#1990ff'
                    }
                ]
                setCardData(cardsData);
            }
        });
    }, []);

    const onChangeRunRateView = (checked) => {
		trackMoengageEvent('run-rate-daily-graph-viewed');
        setIsGraphVisible(checked);
    }

    const onChangeGraphView = (value) => {
		trackMoengageEvent('run-rate-cumulative-graph-viewed');
        setGraphViewType(value);
    }

    const configLinePlot = {
        data: graphViewType === 'Daily' ? dailyGraphData : cumulativeGraphData,
        xField: 'date',
        yField: 'value',
        seriesField: 'category',
        style: {backgroundColor: "#fff"},
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
        smooth: true,
        color: ['#D34612', '#52abff']
    };

    const generateDailyGraphData = (dataArray, requiredFtPerDay) => {
        const daysInMonth = moment().daysInMonth();
        const currentDate = moment().format('DD');
        const DaysRemaining = daysInMonth - currentDate;
        for(let day = 1; day <= DaysRemaining; day++) {
            const newObj = {
                date: moment().date(parseInt(currentDate)+day).format('DD'),
                value: requiredFtPerDay,
                category: 'Required FTs on this day'
            }
            dataArray.push(newObj);
        }
        setDailyGraphData(dataArray);
    }

    const generateCumulativeGraphData = (dataArray, targetFt, totalFt) => {
        let overallValue = 0;
        let ftCountValue = 0;
        const cumulativeArray = []
        const daysInMonth = moment().daysInMonth();
        const currentDate = moment().format('DD');
        dataArray.map((data, index) => {
            if(data.category === 'Actual FTs on this day') {
                ftCountValue += data.value;
                const newObj = {
                    date: data.date,
                    value: ftCountValue,
                    category: 'Actual FTs till this day'
                }
                cumulativeArray.push(newObj);
            } 
            else if(data.category === 'Required FTs on this day') {
                const newObj = {
                    date: data.date,
                    value: targetFt,
                    category: 'Required FTs for this month'
                }
                cumulativeArray.push(newObj);
            }
        })

        if((cumulativeArray.length/2) < daysInMonth) {
            for(let i = (cumulativeArray.length/2); i < daysInMonth; i++) {
                ftCountValue += Math.ceil(totalFt/currentDate);
                const newObj = {
                    date: moment().date(i+1).format('DD'),
                    value: ftCountValue,
                    category: 'Actual FTs till this day'
                }
                cumulativeArray.push(newObj);
                const newObj2 = {
                    date: moment().date(i+1).format('DD'),
                    value: targetFt,
                    category: 'Required FTs for this month'
                }
                cumulativeArray.push(newObj2);
            }
        }
        setCumulativeGraphData(cumulativeArray);
    }

    return (
        <>
        {
            displayRunRateSection ?
                <div className='run-rate-component-container'>
                    <div className='run-rate-header'>
                        <div className='run-rate-header-left'>
                            <CalendarOutlined className='overview-icon' />
                            <span className='run-rate-title'>{moment().format("MMM YYYY")} Overview</span>
                        </div>
                        <div className='run-rate-header-right'>
                            <LineChartOutlined className='graph-view-icon' />
                            <span className='run-rate-title'>Graph View</span>
                            <Switch onChange={onChangeRunRateView}/>
                        </div>
                    </div>
                    {
                        !isGraphVsible ? 
                            <div className='run-rate-card-container'>
                                {
                                    cardData ? cardData.length && cardData.map((data, index) => {
                                        return (
                                            <div key={index} className='run-rate-card'>
                                                <div className='run-rate-card-value' style={{color: data.color}}>{data.value}</div>
                                                <div className='run-rate-card-title'>{data.title}</div>
                                            </div>
                                        );
                                    })
                                    : <Skeleton active />
                                }
                            </div>
                            :
                            <div className='run-rate-graph-container'>
                                <Segmented className='graph-view-segmented'
                                    options={['Daily', 'Cumulative']}
                                    onChange={onChangeGraphView}
                                />
                                <div className='graph-card-continer'>
                                    <div className='graph-data'>
                                        <Line {...configLinePlot} /> 
                                    </div>
                                    <div className='card-data'>
                                        {
                                            cardData.map((data, index) => {
                                                if(index === 0) {
                                                    return (
                                                        <div key={index} className='run-rate-card-big'>
                                                            <div className='run-rate-card-value' style={{color: data.color}}>{data.value}</div>
                                                            <div className='run-rate-card-title'>{data.title}</div>
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <div key={index} className='run-rate-card-small'>
                                                        <div className='run-rate-card-value' style={{color: data.color}}>{data.value}</div>
                                                        <div className='run-rate-card-title'>{data.title}</div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            : null
        }
        </>
        
    );
};

export default RunRateComponent;
