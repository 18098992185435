import React, {useContext, useState} from 'react';
import {AppContext} from '../../App';
import './../../css/telecallerDashboard.scss';
import PageHeaderComponent from '../common/PageHeader';

const TelecallerDashboard = () => {
    const {mitraReducer } = useContext(AppContext);
	const [breadcrumb, setbreadcrumb] = useState([{link: '/recruiter', label: 'My Follow Up Leads'},{link: '/recruiter', label: 'Telecaller Dashboard'}])
  
  return (
    <div style={{}}>
        <div className='header'>
            <PageHeaderComponent breadcrumb={breadcrumb} backBtnBool={true}/>
        </div>
        {
            mitraReducer && mitraReducer.mitraInfo && mitraReducer.mitraInfo.id &&
            <>
                <div className='telecaller-dashboard-desktop'>
                    <iframe src={`https://admin.staffing.vahan.co/app/tc-recruiter-dashboard/page1-655725ea86020f74872fbae5?embed=true&logedInMitraId=${mitraReducer?.mitraInfo.id}`} style={{display: 'block', width: '100%', height: '100vh', marginLeft: '20px'}} ></iframe>
                </div>
                <div className='telecaller-dashboard-mobile'>
                    <iframe src={`https://admin.staffing.vahan.co/app/tc-recruiter-dashboard-mobile/page1-6567210b085b422018b824d9?embed=true&logedInMitraId=${mitraReducer?.mitraInfo.id}`} style={{display: 'block', width: '100%', height: '100vh'}} ></iframe>
                </div>          
            </>
            
        }
    </div>
  );
};
export default TelecallerDashboard;
