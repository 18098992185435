import React, {useState, useEffect} from 'react';
import * as trainingService from '../../service/trainingHubService';
import PageHeaderComponent from '../common/PageHeader';
import {Card, Button,Skeleton,Empty} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons';
import '../../css/trainingHub.scss';
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import ClipLoader from 'react-spinners/ClipLoader';
import { trackEventForProgressBar } from '../../service/progressBarService';

const {Meta} = Card;
const TrainingHubView = () => {
    const [trainingHubData, setTrainingHubData] = useState([])
    const [breadcrumb] = useState([{link: '/training-hub', label: 'Training Hub'}]);
    const [loader, setLoader] = useState(true);

    // const [createMoengageUser, trackEvent] = useMoengage();

    useEffect(() => {
        // progress bar event
		trackEventForProgressBar('training_page').then( response => {
        }).catch(error=> {
            console.log(error);
        });

        trainingService.getTrainingHubData().then(response => {
            if (response.data?.data) {
                setTrainingHubData(response.data.data.mapping)
            }
            setLoader(false)

        
        }).catch(e=>{
            setLoader(false)

        })
    }, []);
    const knowMoreClick = async (data) => {
        if (data.url) {
            trackMoengageEvent(data.eventName, {});
            window.open(data.url, '_blank').focus();
        }
    }
    const trackMoengageEvent = (event, obj) => {
        trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }
    return (
        <>
            <div className='trainingHubComponent mainContentZ'>
                <div className="containerTop">
                    <PageHeaderComponent breadcrumb={breadcrumb} />
                </div>

                <div className="containerMiddle">
                    {!trainingHubData.length && !loader ? <Empty></Empty>:null}

                    {loader && <Skeleton  active /> }



                    {
                        trainingHubData && trainingHubData.length ? trainingHubData.map((ele, index) => (
                            <div className="site-card-border-less-wrapper cardParent" key={index}>
                                <Card>
                                    <Meta

                                        title={ele.topicName ? ele.topicName : ''}
                                        description={ele.subTopic ? ele.subTopic : ''}
                                    />
                                    <p className='cardDescription'>
                                        {ele.topicDescription ? ele.topicDescription : ''}
                                    </p>
                                    {ele.url ? <Button className='cardButton' onClick={() => {knowMoreClick(ele)}} ><ArrowRightOutlined />Know More</Button> : null}


                                </Card>
                            </div>
                        )) : null
                    }

                </div>
            </div>
        </>
    );
};
export default TrainingHubView;
