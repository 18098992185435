import React, { useState, useContext, useEffect } from 'react';
import '../../../css/lead.scss';
// import useMoengage from '../../../hooks/useMoengage';
import { trackEvent } from '../../../service/engagementMonitoringService';
import CONSTANTS from '../../../constants/constants';
import useGAEventsTracker from '../../../service/useGSEventsTracker';
import moment from "moment";
import { CheckCircleOutlined, HistoryOutlined, UploadOutlined, WhatsAppOutlined, UserOutlined, PhoneOutlined, CopyOutlined, EnvironmentOutlined, ArrowRightOutlined, InfoCircleOutlined, CalendarOutlined, DashOutlined, LineOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Tag, Space, Divider, Typography, Image, AutoComplete } from 'antd';
import { AdditionalFormsURLEnum } from '../../../utils/utility';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../App';
import { red } from '@material-ui/core/colors';
import { getDisplayableClientName } from '../../../utils/commonFunction';
import { claimMitraAppLeads } from '../../../service/additionalInfoService'
import { jobseekerApplicationInML } from '../../../constants/utils'
import { ROLES } from '../roles';
import WhatsappTemplateModal from '../../whatsappTemplateModal/WhatsappTemplateModal';
import SpinningLoader from '../../../static/images/loader-rolling.gif';
const { Text } = Typography;


function SingleApplicationContainerForHotLead({ lead, leadDetails, index, setSpin, setShowDetail, showViewDetails, setSelectedLead, checkStatus, referagainBool, setReferAgain, setCurrentJob, mitra, setSelectedScheduledInterviewId, currentClientSiId, currentClientRhId, setSelectedRecommendationHistoryId, realtimeUniquenessStatusData, siToCheckUniquenessStatus, showLoaderForUniqueness }) {
    const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
    const history = useHistory();
    const { mitraReducer, userRole } = useContext(AppContext);
    const [isBronzeML, setIsBronzeML] = React.useState(localStorage.getItem('isBronzeML'))
    const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
    const [selectedApplicationIndex, setSelectedApplicationIndex] = useState(null);

    const [priorityShow, setPriorityShow] = useState(false);
    const [zomatoMilestonesKeyValue, setZomatoMilestonesKeyValue] = useState([
        { key: 'marked_unique', value: 'Unique Lead' },
        { key: 'client_app_downloaded', value: 'Zomato App Downloaded' },
        { key: 'p0_upto_5_sections_filled', value: 'All Sections Filled' },
        { key: 'ob_fees_paid', value: 'OB Fees Paid' },
        { key: 'pan_card_doc_submitted', value: 'PAN Verified' },
        { key: 'bank_doc_submitted', value: 'Bank Verified' },
        { key: 'activation_date', value: 'Activation Completed' },
        { key: 'first_gig', value: 'First Gig Booked' },
        { key: 'first_date_of_work', value: 'First Order Completed' },
        { key: '20th_order_date', value: 'F20 Completed' }
    ]);
    const [count, setCount] = useState(0);
    const [recentCompletedMilestone, setRecentCompletedMilestone] = useState();
    const [recentCompletedMilestoneAt, setRecentCompletedMilestoneAt] = useState();
    const [incompleteMilestone, setIncompleteMilestone] = useState();
    const [filteredMilestoneArray, setFilteredMilestoneArray] = useState([]);

    const diffBetweenTwoDates = (statusTime, rhTime) => {
        const first = statusTime ? statusTime : rhTime
        return moment(first).format('Do MMM YYYY')
    }
    useEffect(() => {
        getLeadsSorted(lead);
        console.log("LEADS_DATA", lead)
    }, [])

    const getLeadsSorted = (lead) => {
        let countMilestones = 0;
        let filteredArray = [];
        zomatoMilestonesKeyValue.filter((el) => {
            for (let milestone of JSON.parse(lead.zomatoMilestones)) {
                if (milestone.milestoneName.includes(el.key)) {
                    if (milestone.completedAt || milestone.completed) {
                        setRecentCompletedMilestone(el.value);
                        if (milestone.completedAt)
                            setRecentCompletedMilestoneAt(milestone.completedAt)
                        countMilestones = countMilestones + 1;
                        filteredArray.push({ key: true, value: el.value });
                        break;
                    }
                }
            }
        })
        let foundIncompleteMile = true;
        zomatoMilestonesKeyValue.filter((el) => {
            let foundMilestone = false;
            filteredArray.some(function (milestone) {
                if (milestone.value === el.value) {
                    foundMilestone = true;
                    return true;
                }
            })
            if (!foundMilestone) {
                if (foundIncompleteMile) {
                    setIncompleteMilestone(el.value);
                }
                foundIncompleteMile = false;
                filteredArray.push({ key: false, value: el.value });
            }
        })
        setCount(countMilestones);
        setFilteredMilestoneArray(filteredArray);
    }

    const claimLeadClick = async (lead, index) => {
        const response = await claimMitraAppLeads(
            {
                phoneNumber: lead.phoneNumber,
                scheduledInterviewId: lead.scheduledInterviewId[index],
                siMetaData: { isTransferredToMl: true },
                mitraId: mitra.id
            },
            {
                updateSiMetaData: true
            }
        )
        if (response.data && response.data.updateSiMetaData == '200') {
            lead.siMetaData[index] = lead.siMetaData[index] ? { ...lead.siMetaData[index], isTransferredToMl: true } : { isTransferredToMl: true }
        }
    }

    const onClickViewDetails = (e) => {
        if (priorityShow) {
            setPriorityShow(false);
        } else {
            setSelectedScheduledInterviewId([currentClientSiId]);
            setSelectedRecommendationHistoryId([currentClientRhId]);
            trackEvent('view_details_button_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            setSpin({
                loading: true, //false or true when actived
                delay: null,
                tip: 'Loading...'  //Loading copy here
            })
            setShowDetail(true);
            setSelectedLead(lead);
            GAEventsTracker('OpenViewDetails')
            setSpin({
                loading: false, //false or true when actived
                delay: null,
                tip: 'Loading...'  //Loading copy here
            })
            e.stopPropagation();

            // adding high intent events for google and meta pixel
            window.fbq('trackCustom', 'OpenViewDetails', {
                phone: mitraReducer?.mitraInfo?.phoneNumber,
                city: mitraReducer?.mitraInfo?.homeCity,
                name: mitraReducer?.mitraInfo?.name,
                email: mitraReducer?.mitraInfo?.email,
                fbclid: mitraReducer?.mitraInfo?.fbclid
            });
        }
    }

    const viewAdditionalFormHandler = (lead, index) => {
        const paramObject = {
            name: lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName,
            phoneNumber: lead.phoneNumber,
            scheduledInterviewId: lead.scheduledInterviewId[index],
            companyName: lead.companies[index],
            jobId: lead.jobId[index],
            jobDemandId: lead.jobDemandId[index],
            companyCity: lead.companyCity[index],
            ...(lead.companies[index]?.toLowerCase().indexOf('rapido') !== -1 ? {
                candidateId: lead.siMetaData[index] ? lead.siMetaData[index].rapidoMilestone ? lead.siMetaData[index].rapidoMilestone.captainId : null : null
            } : {}),
            meta: JSON.stringify(lead.siMetaData[index]),
        }
        viewAdditionalForm(paramObject);
    }

    const viewAdditionalForm = (lead) => {
        history.push({
            pathname: '/moreInfo',
            route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
            search: new URLSearchParams(lead).toString()
        })
    }

    const onClickCompleteApplication = (e) => {
        viewAdditionalFormHandler(lead, index);
        trackEvent('complete_application_clicked_on_candidate_card', { candidate_phone_number: lead.phoneNumber, application_id: lead.scheduledInterviewId[index] }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    const onClickReferAgain = (e) => {
        setReferAgain(true);
        setCurrentJob({ scheduledInterviewId: lead.scheduledInterviewId[index], productSource: lead.productSource.length > 0 ? lead.productSource[index] : '', parentPhoneNumber: lead.parentPhoneNumber ? lead.parentPhoneNumber[index] : '', childPhoneNumber: lead.childPhoneNumber?.length > 0 ? lead.childPhoneNumber[index] : '', companyName: lead.companies[index], status: lead.latestMilestone[index], phoneNumber: lead.phoneNumber, name: lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName, mitraId: mitra.id, jobType: lead.jobType[index], jobId: lead.jobId[index], jobDemandId: lead.jobDemandId[index], companyCity: lead.companyCity[index], role: lead.role[index] });
        trackEvent('refer_again_clicked_on_candidate_card', { candidate_phone_number: lead.phoneNumber, application_id: lead.scheduledInterviewId[index] }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setSelectedLead(lead);
        e.stopPropagation();
    }

    const openWhatsAppPop = (event, index) => {
        event.stopPropagation();
        setSelectedApplicationIndex(index);
        setWhatsAppMessageModal(true);
    }
    const copyPhoneNumberFunc = (event, phoneNumber) => {
        event.stopPropagation();
        navigator.clipboard.writeText(phoneNumber);
    }
    const onClickPriorityInfo = (event) => {
        event.stopPropagation();
        setPriorityShow(!priorityShow);
    }


    const whatsAppMessageModalCancel = (e) => {
        e.stopPropagation();
        setWhatsAppMessageModal(false);
    };

    const getUniquenessLabel = () => {
        // lead.latestMilestone[index]
        const companiesToExcludeFromThisEvaluation = ['Zepto_3PL'];

        if (companiesToExcludeFromThisEvaluation.includes(lead.companies[index])) return lead.latestMilestone[index];

        if (
            lead.scheduledInterviewId.length >= index - 1 && // checking if SI id is created for corresponding rhId
            realtimeUniquenessStatusData && Object.keys(realtimeUniquenessStatusData)?.includes(lead.scheduledInterviewId[index]) // checking if siId exists in uniqueness polling api data
        ) {
            return realtimeUniquenessStatusData[lead.scheduledInterviewId[0]]?.isNonUnique ? 'Non Unique' : 'Unique'
        } else {
            // if current siId is included in evaluation then set/unset loader else send original response
            if (siToCheckUniquenessStatus?.includes(lead.scheduledInterviewId[index])) {
                if (showLoaderForUniqueness) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>{lead.latestMilestone[index]}</div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: '8px', alignSelf: 'center' }}>Checking Uniqueness</div>
                                <Image src={SpinningLoader} alt="loading" height={'auto'} width={18} />
                            </div>
                        </div>

                    )
                } else {
                    return (
                        <>
                            <div>{lead.latestMilestone[index]} <br /> Please check uniqueness status after some time</div>
                        </>
                    )
                }
            } else return (
                <div>{lead.latestMilestone[index]}</div>
            )
        }
    };

    const returnLineImage = (lineLength = 0, dashed = false, isMobile = false) => {
        return (
            Array.from({ length: lineLength }).map(() => {
                return isMobile ? (
                    dashed ? <div style={{ width: "1px", height: "4px", backgroundColor: "rgba(0, 0, 0, 0.15)", marginLeft: "7px", marginTop: "2px" }}></div> : <div style={{ width: "1px", height: "4px", backgroundColor: "rgba(0, 0, 0, 0.15)", marginLeft: "7px" }}></div>
                )
                    : (
                        dashed ? <DashOutlined className='application-timeline-line-img' /> : <hr className='hr-no-margin' style={{ width: "4px", height: "1px", border: 'none', borderTop: '1px solid rgba(0, 0, 0, 0.15)' }} />
                        // <LineOutlined className='application-timeline-line-img' />
                    )
            })

        )
    }

    return (
        <>
            {whatsAppMessageModal ?
                <WhatsappTemplateModal
                    lead={lead}
                    leadDetails={leadDetails}
                    whatsAppMessageModal={whatsAppMessageModal}
                    whatsAppMessageModalCancel={whatsAppMessageModalCancel}
                    selectedApplicationIndex={selectedApplicationIndex}
                ></WhatsappTemplateModal>
                : null}
            <div className="single-application-container" style={{ width: "100%" }} onClick={onClickViewDetails}>
                {/* <Divider style={{margin:'8px 0'}}/> */}
                <div key={index} className={lead.latestMilestone[index] !== 'Upload Documents' ?
                    (checkStatus({
                        scheduledInterviewId: lead.scheduledInterviewId[index], productSource: lead.productSource.length > 0 ?
                            lead.productSource[index] : null, parentPhoneNumber: lead.parentPhoneNumber ?
                                lead.parentPhoneNumber[index] : null, childPhoneNumber: lead.childPhoneNumber?.length > 0 ? lead.childPhoneNumber[index] : null, companyName: lead.companies[index], status: lead.latestMilestone[index]
                    }) ?
                        'incompleteApplicationBG' : '') : (lead.referAgain ? '' : 'incompleteApplicationBG')} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                    <Space key={index} direction='vertical' style={{ width: '100%' }}>

                        <div className='d-flex justify-content-between' style={{ width: "100%" }}>
                            <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', width: "100%" }}>
                                <div className="single-job-container">
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="client-logo">
                                                <img className="imgResponse" style={{ width: 68, heigth: 32 }} alt={getDisplayableClientName(lead.companies[index])} src={lead?.logos[index]} />
                                            </div>
                                            <div style={{ marginLeft: '1em', color: '#000000D9', fontWeight: '500', fontSize: '16px' }}>
                                                {lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName}
                                            </div>
                                            <div className="client-logo click-to-top" style={{ display: 'flex', alignItems: 'center' }} onClick={(e) => onClickPriorityInfo(e)}>
                                                <img className="imgResponse" style={{ width: 60, heigth: 'auto', marginLeft: '2em' }} alt="priority" src={"/image/" + lead.priority + "-priority-hot-lead.png"} />
                                                <InfoCircleOutlined style={{ color: '#00000073', fontSize: '14px', marginLeft: '10px' }} />
                                                <div className='hover-priority' style={priorityShow ? { display: 'block' } : null}>
                                                    {lead.priority === 'high' ? <div>This indicates a <span>very high priority lead. </span>Our intelligence and data suggests that this lead has a <span>very high chance </span>to convert. Engage this lead to take his <span>first trip</span> as soon as possible.<br></br><br></br>Tap the card for more details</div> : null}
                                                    {lead.priority === 'medium' ? <div>This indicates a <span>high priority lead. </span>Our intelligence and data suggests that this lead has a <span>high chance </span>to convert. Engage this lead to take his <span>first trip</span> as soon as possible.<br></br><br></br>Tap the card for more details</div> : null}
                                                    {lead.priority === 'low' ? <div>This indicates a <span>medium priority lead. </span>Our intelligence and data suggests that this lead has a <span>medium chance </span>to convert. They <span>can be onboarded.</span><br></br><br></br>Tap the card for more details</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-row' style={styles.alignCenter}>
                                    <div className="leadsCardStatus d-flex flex-row" style={styles.alignCenter}>
                                        <Tag color="orange" style={{ fontWeight: 'bold', color: '#FAAD14' }}>Hot Lead</Tag>
                                    </div>
                                    {lead.companyCity ?
                                        <div className='display-city-name'>
                                            <EnvironmentOutlined style={{ marginRight: '3px' }} /> {[...new Set(lead.companyCity)].toString()}
                                        </div> : null}

                                    <div className="referred-by" style={styles.alignCenter}>
                                        <CalendarOutlined style={{ marginLeft: '12px', marginRight: '3px' }} /> Referred on {diffBetweenTwoDates(lead.ujfmCreatedAt[index], lead.rhCreatedAt[index])}
                                    </div>
                                </div>

                                {/* <Space direction='horizontal' className="onboarding-schedule">
                                    <div className="leadsCardStatus d-flex flex-row" style={lead.latestMilestone[index] === 'Upload Documents' ? { color: '#000', fontFamily: 'Nunito Sans Bold' } :
                                        (lead.latestMilestone[index] === 'F25 ~ First 25 Trips' || lead.latestMilestone[index] === 'F10 ~ First 10 Trips' || lead.latestMilestone[index] === 'F1 ~ First Trip') ? { color: '#52C41A' } : null}>
                                        {(
                                            (lead.ujfMetaData && lead.ujfMetaData.length && lead.companies[index] === 'Swiggy' && lead?.ujfMetaData[index]?.swiggy_uniqueness) ? lead.ujfMetaData[index].swiggy_uniqueness :
                                                getUniquenessLabel()
                                        )}
                                    </div>
                                    <div style={{ color: '#00000073', fontSize: '12px' }}>{'\u2022'}</div>
                                </Space> */}

                                <Space direction='horizontal' className='application-timeline-view show-only-desktop'>
                                    <div className='d-flex left-of-separator'>
                                        <div className='d-flex flex-column justify-content-center align-items-center recently-completed-milestone'>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                {returnLineImage(4, true, false)}
                                                <CheckCircleOutlined className='completed-step-icon' />
                                                <div className='d-flex' style={{ gap: 0 }}>
                                                    {returnLineImage(16, false, false)}
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                <Text className='recently-completed-milestone-txt'>
                                                    {recentCompletedMilestone}
                                                </Text>
                                                <Text className='next-txt'>
                                                    {moment(recentCompletedMilestoneAt).format('Do MMM YYYY')}
                                                </Text>
                                            </div>
                                        </div>

                                        {returnLineImage(24, false)}

                                        <div className='d-flex flex-column justify-content-center align-items-center next-milestone'>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                {returnLineImage(12, false, false)}
                                                <div className='orange-circle' />
                                                {returnLineImage(4, true, false)}
                                            </div>
                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                <Text className='next-milestone-txt'>
                                                    {incompleteMilestone}
                                                </Text>
                                                <Text className='next-txt'>
                                                    Next
                                                </Text>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='timeline-steps-completed-separator'>
                                        <div className='application-timeline-view-separator'>

                                        </div>
                                    </div>

                                    <div className='steps-completed d-flex justify-content-center align-items-center'>
                                        <svg width="70" height="70">
                                            <circle
                                                cx="35"
                                                cy="35"
                                                r={24}
                                                fill="none"
                                                stroke="rgba(245, 245, 245, 1)"
                                                strokeWidth="2.5"
                                            />
                                            <circle
                                                cx="35"
                                                cy="35"
                                                r={24}
                                                fill="transparent" // Grey circle
                                                stroke="rgba(75, 207, 63, 1)" // Green stroke
                                                strokeWidth="2.5" // Stroke width
                                                strokeDasharray={2 * Math.PI * 24}
                                                strokeDashoffset={2 * Math.PI * 24 * (1 - count / 10)}
                                                transform="rotate(-90 35 35)" // Rotate to start from the top
                                            />
                                            <text
                                                x="50%"
                                                y="50%"
                                                dominantBaseline="middle"
                                                textAnchor="middle"
                                                fontSize="14"
                                                fill="black"
                                            >
                                                {count * 10}%
                                            </text>
                                        </svg>

                                        <div className='steps-completed-txt'>
                                            Steps completed
                                        </div>

                                        <div className="click-for-hover-milestone hover-milestone-new-parent" onClick={(e) => onClickPriorityInfo(e)}>
                                            <EyeOutlined className='eye-hover-icon' />
                                            <div className='hover-milestones-new'>
                                                {filteredMilestoneArray.map((milestone, idx) => (
                                                    <div className="withIcon" key={'withIcon' + idx}>
                                                        {milestone.key === true ? <CheckCircleOutlined style={{ color: '#4BCF3F', fontSize: '12px', marginRight: '5px' }} />
                                                            : <HistoryOutlined style={{ color: '#D48806', fontSize: '12px', marginRight: '5px' }} />}
                                                        <Text style={{ color: '#00000073', fontSize: '12px' }}>
                                                            {milestone.value}
                                                        </Text>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Space>

                                <Space direction='horizontal' className='application-timeline-view show-only-mobile'>
                                    <div className='d-flex flex-row align-center'>
                                        <div className='d-flex flex-column left-of-separator-mobile'>
                                            <div className='d-flex flex-column justify-content-center align-items-center recently-completed-milestone'>
                                                <div className='d-flex flex-column justify-content-flex-start align-items-flex-start'>
                                                    <div style={{ marginBottom: "-6px" }}>
                                                        {returnLineImage(2, true, true)}
                                                    </div>
                                                    <div className='d-flex flex-row justify-content-center align-items-center'>
                                                        <CheckCircleOutlined className='completed-step-icon-mobile' />
                                                        <div className='d-flex flex-column justify-content-flex-start align-items-flex-start recently-completed-milestone-mobile'>
                                                            <Text className='recently-completed-milestone-txt recently-completed-milestone-txt-mobile'>
                                                                {recentCompletedMilestone}
                                                            </Text>
                                                            <Text className='next-txt'>
                                                                {moment(recentCompletedMilestoneAt).format('Do MMM YYYY')}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                    {returnLineImage(2, false, true)}
                                                </div>
                                            </div>

                                            <div style={{ marginTop: "-16px" }}>
                                                {returnLineImage(4, false, true)}
                                            </div>

                                            <div className='d-flex flex-row justify-content-flex-start align-items-flex-start next-milestone'>
                                                {/* <div className='d-flex justify-content-center align-items-center'> */}
                                                {/* {returnLineImage(4, false)} */}
                                                <div className='orange-circle-mobile' />
                                                {/* {returnLineImage(4, true)} */}
                                                {/* </div> */}
                                                <div className='d-flex flex-column justify-content-flex-start align-items-flex-start next-milestone-mobile'>
                                                    <Text className='next-milestone-txt-mobile'>
                                                        {incompleteMilestone}
                                                    </Text>
                                                    <Text className='next-txt'>
                                                        Next
                                                    </Text>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "-16px" }}>
                                                {returnLineImage(4, true, true)}
                                            </div>
                                        </div>

                                        <div className='timeline-steps-completed-separator-mobile'>
                                            <div className='application-timeline-view-separator-mobile'>

                                            </div>
                                        </div>

                                        <div className='steps-completed-mobile d-flex flex-column justify-content-center align-items-center'>
                                            <svg width="70" height="70">
                                                <circle
                                                    cx="35"
                                                    cy="35"
                                                    r={24}
                                                    fill="none"
                                                    stroke="rgba(245, 245, 245, 1)"
                                                    strokeWidth="2.5"
                                                />
                                                <circle
                                                    cx="35"
                                                    cy="35"
                                                    r={24}
                                                    fill="transparent" // Grey circle
                                                    stroke="rgba(75, 207, 63, 1)" // Green stroke
                                                    strokeWidth="2.5" // Stroke width
                                                    strokeDasharray={2 * Math.PI * 24}
                                                    strokeDashoffset={2 * Math.PI * 24 * (1 - count / 10)}
                                                    transform="rotate(-90 35 35)" // Rotate to start from the top
                                                />
                                                <text
                                                    x="50%"
                                                    y="50%"
                                                    dominantBaseline="middle"
                                                    textAnchor="middle"
                                                    fontSize="14"
                                                    fill="black"
                                                >
                                                    {count * 10}%
                                                </text>
                                            </svg>

                                            <div className='d-flex'>
                                                <div className='steps-completed-txt-mobile'>
                                                    Steps completed
                                                </div>

                                                <div className="click-for-hover-milestone hover-milestone-new-parent" onClick={(e) => onClickPriorityInfo(e)}>
                                                    <EyeOutlined className='eye-hover-icon' />
                                                    <div className='hover-milestones-new-mobile'>
                                                        {filteredMilestoneArray.map((milestone, idx) => (
                                                            <div className="withIcon" key={idx}>
                                                                {milestone.key === true ? <CheckCircleOutlined style={{ color: '#4BCF3F', fontSize: '12px', marginRight: '5px' }} />
                                                                    : <HistoryOutlined style={{ color: '#D48806', fontSize: '12px', marginRight: '5px' }} />}
                                                                <Text style={{ color: '#00000073', fontSize: '12px' }}>
                                                                    {milestone.value}
                                                                </Text>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Space>

                                {/* <Space direction='horizontal' className="onboarding-schedule milestones-mobile" style={{ marginTop: '1em' }}>
                                    {lead.zomatoMilestones ?
                                        <>
                                            <div className="withIcon">
                                                <CheckCircleOutlined style={{ color: '#4BCF3F', fontSize: '12px', marginRight: '5px' }} />
                                                <Text style={{ color: '#4BCF3F', fontSize: '12px' }}>
                                                    {recentCompletedMilestone}
                                                </Text>
                                            </div>
                                            <span className='show-only-desktop' style={{ margin: 5, color: '#00000073' }}>{'\u2022'}</span>
                                            <div className="withIcon">
                                                <HistoryOutlined style={{ color: '#D48806', fontSize: '12px', marginRight: '5px' }} />
                                                <Text style={{ color: '#D48806', fontSize: '12px' }}>
                                                    {incompleteMilestone}
                                                </Text>
                                            </div>
                                            <span className='show-only-desktop' style={{ margin: 5, color: '#00000073' }}>{'\u2022'}</span>
                                            <div className="click-for-hover-milestone" style={{ display: 'flex', alignItems: 'center' }} onClick={(e) => onClickPriorityInfo(e)}>
                                                <Text style={{ color: '#00000073', fontSize: '12px' }}>
                                                    <b style={{ fontWeight: 'bolder' }}>{count}/10 </b> Steps Completed
                                                </Text>
                                                <InfoCircleOutlined style={{ color: '#00000073', fontSize: '14px', marginLeft: '5px' }} />
                                                <div className='hover-milestones'>
                                                    {filteredMilestoneArray.map((milestone, idx) => (
                                                        <div className="withIcon">
                                                            {milestone.key === true ? <CheckCircleOutlined style={{ color: '#4BCF3F', fontSize: '12px', marginRight: '5px' }} />
                                                                : <HistoryOutlined style={{ color: '#D48806', fontSize: '12px', marginRight: '5px' }} />}
                                                            <Text style={{ color: '#00000073', fontSize: '12px' }}>
                                                                {milestone.value}
                                                            </Text>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </> : null}
                                </Space> */}
                            </div>
                            <div className='d-flex show-only-desktop' style={{ alignItems: 'center' }}>
                                <Button style={{ color: '#fce3e6', border: '2px solid #fce3e6', width: '60px', height: '60px' }} type="primary" size='large' ghost shape="circle" icon={<ArrowRightOutlined style={{ fontSize: '25px' }} />} />
                            </div>
                        </div>
                    </Space>
                </div>
                {/* for banners */}
                <div className='application-card-banners'>
                    <div style={{ alignItems: 'center' }} className='show-only-mobile'>
                        {lead.mitraName ? <div className='added-mitra-name'><UserOutlined style={{ marginRight: '3px' }} />Added by&nbsp;<span className='display-mitra-name'>{[...new Set(lead.mitraName)].toString()}</span></div> : null}
                    </div>
                    <Divider></Divider>
                    <div className='d-flex justify-content-between show-only-desktop'>
                        {lead.mitraName ? <div className='added-mitra-name'><UserOutlined style={{ marginRight: '3px' }} />Added by&nbsp;<span className='display-mitra-name'>{[...new Set(lead.mitraName)].toString()}</span></div> : null}
                        <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <div className="contact-buttons">
                                <Button onClick={(e) => copyPhoneNumberFunc(e, lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)` : lead.phoneNumber)}
                                    icon={<CopyOutlined />} type="primary" ghost>
                                    {lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)` : lead.phoneNumber}
                                </Button>
                                <Button onClick={(e) => openWhatsAppPop(e, index)} icon={<WhatsAppOutlined />} type="primary" ghost>Message Via WhatsApp</Button>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between show-only-mobile'>
                        <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center', marginTop: '-10px' }}>
                            <div className="contact-buttons">
                                <a
                                    href={`tel:+91-${lead.parentPhoneNumber[0] ? lead.parentPhoneNumber[0] : lead.phoneNumber}`}
                                    onClick={(e) => {
                                        GAEventsTracker("CallCandidate"); e.stopPropagation();
                                        trackEvent('call_now_option_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
                                    }}>
                                    <Button className='button-phone-number'
                                        icon={<PhoneOutlined />} type="primary" ghost>
                                        {lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)` : lead.phoneNumber}
                                    </Button>
                                </a>
                            </div>
                            <Button onClick={(e) => openWhatsAppPop(e, index)} className='button-whatsapp' icon={<WhatsAppOutlined />} type="primary" ghost>Message</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const styles = {
    alignCenter: {
        alignItems: "center"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    justifySb: {
        justifyContent: "space-between"
    }
}

export default SingleApplicationContainerForHotLead;
