import axios from 'axios';

export const getPremiumJobsCities = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getPremiumJobsCities`;
    return axios.get(url, {withCredentials: true});
};
export const getClientStatus = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getClientStatuses`;
    return axios.get(url, {withCredentials: true});
}
export const getWorkLocations = async (category, city, companyName) => {
    const data = new FormData();
    data.append('category', category);
    data.append('city', city);
    data.append('companyName', companyName);
    let url = process.env.REACT_APP_BACKEND_URL + '/getWorkLocations';
    return axios.post(url, data, {withCredentials: true});
};

export const getOnboardingLocations = async (category, city, companyName) => {
    const data = new FormData();
    data.append('category', category);
    data.append('city', city);
    data.append('companyName', companyName);
    let url = process.env.REACT_APP_BACKEND_URL + '/getOnboardingLocations';
    return axios.post(url, data, {withCredentials: true});
};

export const getClientsAndCompanies = async (city) => {
    const data = new FormData();
    data.append('city', city);
    let url = process.env.REACT_APP_BACKEND_URL + '/getClientsAndCompanies';
    return axios.post(url, data, {withCredentials: true});
};

// export const getClientsAndCompaniesForBulkUpload = async (city) => {
//     const data = new FormData();
//     data.append('city', city);
//     let url = process.env.REACT_APP_BACKEND_URL + '/getClientsAndCompaniesForBulkUpload';
//     return axios.post(url, data, {withCredentials: true});
// };


export const getJobs = async (category, city, companyName, jobLocation) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobs?category=${category}&city=${city}&companyName=${companyName}&jobLocation=${encodeURIComponent(jobLocation)}`;
    return axios.get(url, {withCredentials: true});
};

export const getJobLocations = async (city, company) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobLocations?city=${city}&company=${company}`;
    return axios.get(url, {withCredentials: true});
};
export const postBasicInfoForm = async (basicInfo) => {
    const data = new FormData();
    data.append('name', basicInfo.name);
    data.append('phoneNumber', basicInfo.phoneNumber);
    data.append('city', basicInfo.city);
    data.append('category', basicInfo.category);
    data.append('company', basicInfo.company);
    data.append('mitraId', basicInfo.mitraId);
    data.append('jobId', basicInfo.jobId);
    data.append('jobDemandId', basicInfo.jobDemandId);
    data.append('jobLocation', basicInfo.jobLocation);
    data.append('dlNumber', basicInfo.dlNumber);
    let url = process.env.REACT_APP_BACKEND_URL + '/postBasicInfoForm';
    return axios.post(url, data, {withCredentials: true});
};

export const postBulkUploadForm = async (bulkCandidateFile, city, category, company, jobId, jobLocation, jobDemandId) => {
    const data = new FormData();
    data.append('file', bulkCandidateFile);
    data.append('city', city);
    data.append('category', category);
    data.append('company', company);
    data.append('jobId', jobId);
    data.append('jobLocation', jobLocation);
    data.append('jobDemandId', jobDemandId);
    let url = process.env.REACT_APP_BACKEND_URL + '/postCandidateForm';
    return axios.post(url, data, {withCredentials: true});
}

export const getExtraRequiredFields = async (companyName) => {
    const url = process.env.REACT_APP_BACKEND_URL + '/company/extraRequiredFields?companyName=' + companyName;
    return axios.get(url, {withCredentials: true});
}
export const checkUberUniqueness = async (phoneNumber, city, clientName) => {
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/checkUberUniqueness', {withCredentials: true, params : { "phoneNumber" : phoneNumber, "city" : city, "clientName" : clientName }});
}

export const checkZeptoUniqueness = async (phoneNumbers) => {
    return axios.post(process.env.REACT_APP_BACKEND_URL + '/getZeptoLeadStatus', {"phoneNumbers" : phoneNumbers}, {withCredentials: true});
}

export const updateZeptoAPIUniqueness = async (jobDemandId, userPhone, uniqueness) => {
    return axios.post(process.env.REACT_APP_BACKEND_URL + '/updateZeptoAPIUniqueness', {jobDemandId, userPhone, uniqueness}, {withCredentials: true});
}

export const checkRapidoUniqueness = async (phoneNumber) => {
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/checkRapidoUniqueness', {withCredentials: true, params : { "phoneNumber" : phoneNumber }});
}       // if (key === "verticalPreference") {
export const submitBasicInfoForm = (data, mitra) => {
    const requestData = {
        submission: {
            data: {
                //sourceName: mitra.name,
                sourceName: "mitra-leader",
                sourceId: mitra.id,
                candidatePhoneNumber: data.phoneNumber,
                phoneNumber: data.phoneNumber,
                // verticalPreference: [],
                name: data.name,
                managerMitraID: mitra.managerMitraID || mitra.id
            },
            currentPageData: {
                duplicateLeadCheck: true,
                candidatePhoneNumber: data.phoneNumber,
                // verticalPreference: []
            }
        }
    }

    for (let key in data) {
        // if (key === "submit") {
            
        // }
        // else 
        if (key === "verticalPreference") {
            for (let vp in data[key])
                if (data[key][vp]) {
                    requestData.submission.currentPageData.verticalPreference.push(vp)
                    requestData.submission.data.verticalPreference.push(vp)
                }
        }
        else if (data[key] && typeof data[key] === "string") {
            requestData.submission.currentPageData[key] = data[key]
            requestData.submission.data[key] = data[key]
        }
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}/form-io/`
    return axios.post(url, requestData, {withCredentials: true});
}

export const getJobDetails = async (params) => {
    const url = process.env.REACT_APP_BACKEND_URL + '/getJobDetails';
    return axios.get(url, {params, withCredentials: true});
}
