import React, {useState, useEffect, useContext} from 'react';
import useGAEventsTracker from '../service/useGSEventsTracker';
import * as jobDemandsService from '../service/jobDemandsService';
import {useHistory} from 'react-router-dom';
import FilterPopup from './leads/Filter-popup.component';
import JobPostingSidesheet from './jobs/Job-posting-sidesheet.component'
import CreateJobPostPopUp from './jobs/createJobPostPopUp'
import Moment from 'react-moment';
import moment from "moment";
import 'react-dates/lib/css/_datepicker.css';
// import useMoengage from '../hooks/useMoengage';
import {useForm} from 'react-hook-form';
import BasicInfoForm from './BasicInfoForm';
import SideSheet from "../components/common/SideSheet"
import ShareOnPlatform from '../components/common/shareOnPlatform.component';
import SubmitPopup from "./common/SubmitPopup.component"
import {useParams} from "react-router-dom";
import './../css/jobDemands.css'
// import { disabledDate} from './../service/commonService'
import {getActiveClientsForFilter, disabledDate, getStatusListForFilters,fetchActivatedAndReferredLeads, getRapidoDocumentStatuses, getLocationForFilter} from './../service/commonService'
import {AppContext} from '../App';
import { DatePicker, Button, PageHeader, Modal, Alert } from 'antd';
import { DownOutlined, FileDoneOutlined, InfoCircleOutlined, GlobalOutlined, CloudDownloadOutlined, CloseOutlined, PhoneOutlined, UserOutlined, LinkOutlined, SearchOutlined, DownloadOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {trackEvent} from '../service/engagementMonitoringService';
import CONSTANTS from '../constants/constants';
import { trackEventForProgressBar } from '../service/progressBarService';
import JobLeads from './JobPageComponents/JobLeads';
import JobRequirements from './JobPageComponents/JobRequirements';
import JobPageSidebar from './JobPageComponents/JobPageSidebar';
import JobPosterPage from './JobPageComponents/JobPosterPage';
import JobLocations from './JobPageComponents/JobLocations';
import {FilterOutlined, WhatsAppOutlined} from '@ant-design/icons';

const DEFAULT = 'DEFAULT';


const JobDemandsPage = () => {
	//console.log("Job Demands Page refreshed:>>")
	const {tabType} = useParams();
	const {register} = useForm();
	const {mitraReducer,mitraDispatch, setAlert} = useContext(AppContext);
	const [mitra, setMitra] = useState();
	const [jobDemands, setJobDemands] = useState([]);
	const [validJobDemandsCities, setValidJobDemandsCities] = useState([]);
	const [validJobDemandsClients, setValidJobDemandsClients] = useState([]);
	const [validJobDemandsVerticals, setValidJobDemandsVerticals] = useState([]);
	const [client, setClient] = useState(DEFAULT);
	const [minSalary, setMinSalary] = useState(DEFAULT);
	const [maxSalary, setMaxSalary] = useState(DEFAULT);
	const [selected, setSelected] = useState({});
	const [selectedLocalities, setSelectedLocalities] = useState({});
	const [originalLocalities, setOriginalLocalities] = useState({});
	const [viewLocations, setViewLocations] = useState(true);
	const [jobLeadsTab, setJobLeadsTab] = useState(false);
	const [moreLocalities, setMoreLocalities] = useState(false);
	// const [createMoengageUser, trackEvent] = useMoengage();
	const [showClientFilterPopup, setClientShowFilterPopup] = useState(false);
	const [showBreadcrumb, setShowBreadcrumb] = useState(false);
	const [showCityFilterPopup, setCityShowFilterPopup] = useState(false);
	const [showVerticalFilterPopup, setVerticalShowFilterPopup] = useState(false);
	const [viewCreateWebLink, setViewCreateWebLink] = useState(false);
	const [jobPostingsByClient, setJobPostingsByClient] = useState([]);
	const [createJobPostingDropdown, setCreateJobPostingDropdown] = useState([]);
	const [validJobPostingsClients, setValidJobPostingsClients] = useState([]);
	const [validJobPostingsCities, setValidJobPostingsCities] = useState([]);
	const [jobPostings, setJobPostings] = useState([]);
	const [dateSelected, setDateSelected] = useState(new Date().toISOString().substr(0, 10))
	const [jobPostingResponseById, setJobPostingResponseById] = useState();
	const [showSideSheet, setShowSideSheet] = useState(false);
	const [showDateChip, setShowDateChip] = useState(false);
	const [startDate, setStartDate] = useState(moment().subtract(2, 'M'));
	const [endDate, setEndDate] = useState(moment());
	const [focusedInput, setFocusedInput] = useState(null);
	const [jobPostingId, setJobPostingId] = useState();
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [basicForm, setBasicForm] = useState(false);
	const [showShareOnPlatform, setShowShareOnPlatform] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [jobPostingCity, setJobPostingCity] = useState();
	const [jobPostingClientSelected, setJobPostingClientSelected] = useState('');
	const [basicInfo, setBasicInfo] = useState({});
	const [selectedClient, setSelectedClient] = useState({});
	const [selectedClientsOnJobPostingFilters, setSelectedClientsOnJobPostingFilters] = useState({});
	const [selectedCitiesOnJobPostingFilters, setSelectedCitiesOnJobPostingFilters] = useState({});
	const [showSubmitPopup, setShowSubmitPopup] = useState(false);
	const [submitText, setSubmitText] = useState('Your response has been submitted! One of our representatives will call you back shortly!')
	const [phoneNumberError, setPhoneNumberError] = useState(false)
	const [nameError, setNameError] = useState(false)
	const [APIError, setAPIError] = useState("")
	const [showAPIError, setShowAPIError] = useState(false)
	const [showBanner, setShowBanner] = useState(true)
	const [jobPostingData, setJobPostingData] = useState({});
	const history = useHistory();
	const JobsGAEventsTracker = useGAEventsTracker("jobs");
	const dateFormat = 'YYYY/MM/DD';
	const {RangePicker} = DatePicker;
	const [filterApplied, setFilterApplied] = React.useState(false);
	const [statusesSelected, setStatusesSelected] = React.useState([]);
	const [clientSelected, setClientSelcted] = React.useState([]);
	const [filterSider,setfilterSider] = React.useState(false);
	const [isValuePropModalVisible, setIsValuePropModalVisible] = useState(false);
	const [currentClient, setCurrentClient] = useState({});
	const [joiningBonusModal, setJoiningBonusModal] = useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
	const [imageElements, setImageElements] = useState([]);
	const [imageKeys, setImageKeys] = useState([]);
	const [highlightedHubName, setHighlightedHubName] = useState();
	const showJoiningBonusModal = () => {
		setJoiningBonusModal(true);
	}
	const hideJoiningBonusModal = () => {
		setJoiningBonusModal(false);
	}
	const showValuePropModal = (clientName) => {
		setIsValuePropModalVisible(true);
	};

	const handleReferCandidate = () => {
		// go to add single candidate page
		trackEvent(`clicked_value_prop_jobs_refer_${currentClient.companyName}`,{}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		history.push('/add-single-candidate');
	};

	const handleValuePropCancel = () => {
		setIsValuePropModalVisible(false);
	};
	const setImageContent = () => {

	}
	const setModalContent = (companyName) => {
		let valueProposition = [];
		switch(companyName) {
			case "Uber":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.uber;
				break;
			case "Uber_3PL":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.uber_3pl;
				break;
			case "Shadowfax":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.sfx;
				break;
			case "Rapido":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.rapido;
				break;
			case "Rapido_3PL":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.rapido_3pl;
				break;
			case "Dunzo":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.dunzo;
				break;
			case "Zomato":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.zomato;
				break;
			case "Zepto":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.zepto;
				break;
			case "Zepto_3PL":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.zepto;
				break;
			case "AmFlex":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.amflex;
				break;
			case "Swiggy":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.swiggy;
				break;
			case "Grab":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.grab;
				break;
			case "Delhivery_3PL":
				valueProposition = CONSTANTS.VALUE_PROPOSITION.delhivery_3pl;
				break;
			default:
				valueProposition = [];
		}
		return (
			<div>
				{valueProposition.length!==0 ? <div>
					<h6 style={{fontWeight: '700'}}>Reasons to join</h6>
					<ul>
					{
					valueProposition.map((data,index) => {
						return <li key={data.id}>
							{data.value}
							{ (data.hasChild && data.hasChild.length!==0) ? <ul>
								{
									data.hasChild.map((child,index) => {
										return <li key={child.id}>
											{child.value}
										</li>
									})
								}
							</ul>: null
							}
						</li>
					})
					}
					</ul>
				</div> : <h6 style={{fontWeight: '700'}}>Information coming soon..</h6>}
			</div>
		);
	}

	useEffect(() => {
		if (mitraReducer?.mitraInfo) {
			setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
			setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})
			const getJobDemandsData = async (e) => {
				try {
					jobDemandsService.getJobDemandsData().then((result) => {
						if (result && result.data.viewData) {
							if (result.data.viewData.allJobDemands) {
								setJobDemands(result.data.viewData.allJobDemands);
							}
							if (result.data.viewData.validJobDemandsCities) {
								setValidJobDemandsCities(result.data.viewData.validJobDemandsCities);
							}
							if (result.data.viewData.validJobDemandsClients) {
								setValidJobDemandsClients(result.data.viewData.validJobDemandsClients);
							}
							if (result.data.viewData.validJobDemandsVerticals) {
								setValidJobDemandsVerticals(result.data.viewData.validJobDemandsVerticals);
							}
						}
					});
				} catch (error) {
					console.log(error);
				}
			};
			getJobDemandsData();
			trackEvent('demand_hub_viewed', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		}
	}, [mitraReducer]);

	useEffect(() => {

		// progress bar event
		trackEventForProgressBar('jobs_page').then( response => {
        }).catch(error=> {
            console.log(error);
        });

		trackEvent('clicked_jobs_page', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		// if (tabType === 'jobRequirements') {
			history.push('/job-demands/job-requirements')
			setJobLeadsTab(false)
		// } 

		// adding high intent events for google and meta pixel
		JobsGAEventsTracker('ViewedJobsPage');
		window.fbq('trackCustom', 'ViewedJobsPage',{
			phone: mitraReducer?.mitraInfo?.phoneNumber,
			city: mitraReducer?.mitraInfo?.homeCity,
			name: mitraReducer?.mitraInfo?.name,
			email: mitraReducer?.mitraInfo?.email,
			fbclid: mitraReducer?.mitraInfo?.fbclid
		});
	}, []);

	useEffect(() => {
		let ClientList = [];
		let VerticalList = [];
		let CityList = [];
		validJobDemandsClients.map((client) => {
			if (client.value === true) {
				ClientList.push(client.label);
			}
			return null;
		});

		validJobDemandsVerticals.map((vertical) => {
			if (vertical.value === true) {
				VerticalList.push(vertical.label);
			}
			return null;
		});

		validJobDemandsCities.map((city) => {
			if (city.value === true) {
				CityList.push(city.label);
			}
			return null;
		});
		if (viewLocations) {
			validJobDemandsCities.map((city) => {
				if (city.value === true) {
					city.value = false;
				}
			});
			CityList = [];
		} else if (CityList.length > 0) {
			ClientList.push(client);
		}
		getJobDemandsOnFiltersChange(minSalary, maxSalary, CityList, ClientList, VerticalList);
	}, [minSalary, maxSalary, validJobDemandsCities, validJobDemandsClients, validJobDemandsVerticals]);

	useEffect(() => {
		let ClientList = [];
		let CityList = [];

		validJobPostingsClients.map((client) => {
			if (client.value === true) {
				ClientList.push(client.label);
			}
			return null;
		});
		validJobPostingsCities.map((city) => {
			if (city.value === true) {
				CityList.push(city.label);
			}
			return null;
		});
		setSelectedCitiesOnJobPostingFilters(CityList);
		setSelectedClientsOnJobPostingFilters(ClientList);
		getJobPostingResponsesOnFilterChange(startDate, endDate, CityList, ClientList);
		if (moment(startDate).format("DD-MM-YYYY") !== moment().subtract(2, 'M').format("DD-MM-YYYY") || (endDate).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")) {
			setShowDateChip(true)
		} else {
			setShowDateChip(false)
		}
	}, [startDate, endDate, validJobPostingsCities, validJobPostingsClients]);

	useEffect(() => {
		if (jobPostingResponseById) {
			getJobPostingResponseById(jobPostingResponseById);
		}
	}, [jobPostingResponseById]);

	useEffect(() => {
		if (jobPostingClientSelected !== '') {
			jobDemands.map(ele => {
				if (ele.companyName === jobPostingClientSelected) {
					setSelected(ele)
				}
			})
		}
	}, [jobPostingClientSelected])

	const getJobDemandsOnFiltersChange = async (minSalary, maxSalary, city, clientList, vertical) => {
		try {
			jobDemandsService.getJobDemandsOnFiltersChange(minSalary, maxSalary, city, clientList, vertical).then((result) => {
				if (result && result.data.viewData.allJobDemands) {
					const jobDemands = result.data.viewData.allJobDemands;
					setJobDemands(jobDemands);
					if (jobDemands.length === 1) {
						setSelected(jobDemands[0]);
					} else if (jobDemands.length === 0) {
						setSelected();
					} else {
						jobDemands.map(ele => {
							if (ele.companyName === client) {
								setSelected(ele);
							}
						})
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	};
	const createJobPostingResponse = async (jobPostingId, name, phoneNumber) => {
		try {
			if (name === undefined || name.trim() === '') {
				setNameError(true)
				return
			}
			else {
				setNameError(false)
			}
			if (phoneNumber === undefined || phoneNumber.trim() === '') {
				setPhoneNumberError(true);
				return;
			}
			else {
				setPhoneNumberError(false)
			}
			jobDemandsService.createJobPostingResponse(jobPostingId, name, phoneNumber).then((result) => {
				if (result && result.data.success) {
					handleTabSwitchtoJobLeads();
					setJobPostingId('');
				}
			})
				.catch(error => {
					console.log(error.response)
					if (error.response.data) {
						setShowAPIError(true)
						setAPIError(error.response.data.message)
					}
				})
		} catch (error) {
			console.log(error);
		}
	}
	const getJobPostingResponseById = async (jobPostingResponseById) => {
		try {
			jobDemandsService.getJobPostingResponseById(jobPostingResponseById).then((result) => {
				if (result && result.data.jobPostingResponse) {
					history.push('/job-demands/job-leads');
					setJobLeadsTab(true);
					setJobPostings(result.data.jobPostingResponse);
				}
			});
		} catch (error) {
			console.log(error);
		}
	}
	const getJobPostingResponses = async (dateSelected) => {
		try {
			jobDemandsService.getJobPostingResponses(dateSelected).then((result) => {
				if (result && result.data.viewData) {
					if (result.data.viewData.jobPostingResponse) {
						setJobPostings(result.data.viewData.jobPostingResponse);
					}
					if (result.data.viewData.cities) {
						setValidJobPostingsCities(result.data.viewData.cities);
					}
					if (result.data.viewData.client) {
						setValidJobPostingsClients(result.data.viewData.client);
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const getJobPostingResponsesOnFilterChange = async (startDate, endDate, city, client) => {
		try {
			jobDemandsService.getJobPostingResponsesOnFilterChange(startDate, endDate, city, client).then((result) => {
				if (result && result.data.jobPostingResponse) {
					setJobPostings(result.data.jobPostingResponse);
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const exportJobPostingResponseSheet = async (startDate, endDate, city, client) => {
		try {
			if (jobPostingResponseById) {
				jobDemandsService.exportJobPostingResponseSheetById(jobPostingResponseById).then((result) => {
					if (result && result.data.success) {
						window.open(result.data.fileURL);
					}
					else {
						setAlert({
							display: true,
							text: 'No data Present',
							type: 'error'
						});
					}
				});
			}
			else {
				jobDemandsService.exportJobPostingResponseSheetByFilters(startDate, endDate, city, client).then((result) => {
					if (result && result.data.success) {
						window.open(result.data.fileURL);
					}
					else {
						setAlert({
							display: true,
							text: 'No data Present',
							type: 'error'
						});
					}
				});
			}

		} catch (error) {
			console.log(error);
		}
	};

	const getJobPostingByClientName = async (client) => {
		try {
			jobDemandsService.getJobPostingByClientName(client).then((result) => {
				if (result && result.data.viewData && result.data.viewData.jobPostings) {
					setJobPostingsByClient(result.data.viewData.jobPostings);
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const getCreateJobPostingDropdown = async () => {
		try {
			jobDemandsService.getCreateJobPostingDropdown().then((result) => {
				if (result && result.data.viewData) {
					setCreateJobPostingDropdown(result.data.viewData);
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handleViewLocations = (selected) => {
		setClient(selected.companyName);
		// setVertical(selected.vertical);
		setViewLocations(false);
		setMoreLocalities(false);
		setSelected(selected);
	};

	const handleMoreLocalities = (selected) => {
		setMoreLocalities(true);
		setOriginalLocalities(selected);
		setSelectedLocalities(selected);
	};

	const deleteChip = (name, item) => {
		if (name === 'vertical') {
			let newVertical = [...validJobDemandsVerticals]
			newVertical.map((single_vertical, index) => {
				if (single_vertical.label === item.label) {
					single_vertical.value = false;
				}
			})
			setValidJobDemandsVerticals(newVertical)
		}
		else if (name === 'client') {
			let newClient = [...validJobDemandsClients]
			newClient.map((single_client, index) => {
				if (single_client.label === item.label) {
					single_client.value = false
				}
			})
			setValidJobDemandsClients(newClient)
		}
		else if (name === 'city') {
			let newCity = [...validJobDemandsCities]
			newCity.map((single_city, index) => {
				if (single_city.label === item.label) {
					single_city.value = false
				}
			})
			setValidJobDemandsCities(newCity)
		} else if (name === 'salary') {
			setMaxSalary(DEFAULT);
			setMinSalary(DEFAULT);
		}
		else if (name === 'job-posting-client') {
			let newClient = [...validJobPostingsClients];
			newClient.map((single_client, index) => {
				if (single_client.label === item.label) {
					single_client.value = false
				}
			})
			setValidJobPostingsClients(newClient)
		}
		else if (name === 'job-posting-city') {
			let newCity = [...validJobPostingsCities]
			newCity.map((single_city, index) => {
				if (single_city.label === item.label) {
					single_city.value = false
				}
			})
			setValidJobPostingsCities(newCity)
		}
		else if (name === 'date') {
			setStartDate(moment().subtract(2, 'M'));
			setEndDate(moment());
		}
	};

	const searchLocality = (e) => {
		if (e.target.value !== '') {
			const searchResult = originalLocalities.data.filter(ele => {
				return ele.locality.toLowerCase().includes(e.target.value.toLowerCase());
			})
			const search = {city: selectedLocalities.city, data: searchResult};
			setSelectedLocalities(search);
		} else {
			setSelectedLocalities(originalLocalities);
		}
	}

	const handleViewCreateWebLinks = (selected) => {
		setClient(selected.companyName);
		setSelectedClient(selected);
		getJobPostingByClientName(selected.companyName);
		getCreateJobPostingDropdown();
		setShowSideSheet(true);
	}
	const handleSalaryRange = (e) => {
		const salary = e.target.value;
		const tempSalaryArr = salary.split('-');
		setMinSalary(tempSalaryArr[0]);
		setMaxSalary(tempSalaryArr[1]);
		e.target.value = DEFAULT;
	}

	const handleTabSwitchtoJobLeads = (e) => {
		setJobPostingResponseById('');
		setJobLeadsTab(true);
		getJobPostingResponses(dateSelected);
		setStartDate(moment().add(-2, 'M'));
		history.push('/job-demands/job-leads')
	}

	const handleNameChange = (event) => {
		setName(event.target.value);
	};
	const handleNumberChange = (event) => {
		setPhoneNumber(event.target.value);
	};
	const getJobPostingImage = async () => {
		jobDemandsService.getJobPosterById(jobPostingId).then(data => {
			console.log('data :>> ', data);
		});
		return '';

	}
	const handleBackArrowClick = () => {
		setViewLocations(true);
		setJobLeadsTab(true);
		setBasicForm(false);
	};
	const handleReferLead = (jobPosting) => {
		console.log(jobPosting);
		setBasicInfo(jobPosting);
		setViewLocations(false);
		setJobLeadsTab(false);
		setBasicForm(true);
	};

	const handleDateRange = (dates, dateStrings) => {
		if (dates === null) {
			setStartDate(moment().subtract(1, 'd'));
			setEndDate(moment());
		}
		else {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	}

	const handleReferCandidateWithCityDetails = (client, category, city, locality) => {
		trackEvent(`click_refer_candidate_jobs_page`,{}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		if(locality.includes('&')) {
			locality = locality.replace('&', 'and')
		}
		if(client) {
			client = client.replace(/_3PL/g, ' With Mitra').replace(/_Salary/g, ' - Salary')
		}	
		const prams = `navigateTo=addSingleCandidate${city ? `&city=${city}` : ''}${category ? `&category=${category}` : ''}${client ? `&client=${client}` : ''}${locality ? `&locality=${locality}` : ''}`;
		history.push('/add-single-candidate?' + prams);
	}
	return (
		<>
		<Modal 
			style={{textAlign:'left'}}
			title={<img src={currentClient.logo} style={{width:'90px',height:'40px'}} alt="logo"/>} 
			visible={isValuePropModalVisible}
			onCancel={handleValuePropCancel}
			footer={
				[
					<Button key="1" className="ant-btn ant-btn-primary" style={{fontWeight:'700'}} onClick={handleReferCandidate}>Refer Candidate</Button>,
				]
				}
			>
				{setModalContent(currentClient.companyName)}
			</Modal>
			{joiningBonusModal && <Alert
					className="fullscreen-alert"
					visible={joiningBonusModal}
					message={
                    <div>
                      <h4 style={{paddingRight: '200px'}}>Joining Bonus Categorywise for:<b> {highlightedHubName}</b></h4>
                      {imageKeys.length > 0 ? (<div>
						<div>
							{imageKeys.map((key, index) => (
							<div key={key}>
								<p style={{paddingRight: '200px'}} >Category: <b>{key}</b></p>
								{imageElements[index]}
							</div>
							))}
						</div>
						</div>
						) : (
						<p>No images available.</p>
						)}
                    </div>
                  }
                  type="info"
                  closable
					scrollable
                  onClose={hideJoiningBonusModal}
                />}
			<div className="JobDemands mainContentZ">
			
				{
					jobLeadsTab ?
						<JobLeads 
						exportJobPostingResponseSheet={exportJobPostingResponseSheet}
						startDate={startDate}
						endDate={endDate}
						selectedCitiesOnJobPostingFilters={selectedCitiesOnJobPostingFilters}
						selectedClientsOnJobPostingFilters={selectedClientsOnJobPostingFilters}
						validJobPostingsClients={validJobPostingsClients}
						showClientFilterPopup={showClientFilterPopup}
						setClientShowFilterPopup={setClientShowFilterPopup}
						setValidJobPostingsClients={setValidJobPostingsClients}
						setShowBreadcrumb={setShowBreadcrumb}
						validJobPostingsCities={validJobPostingsCities}
						jobPostingResponseById={jobPostingResponseById}
						showCityFilterPopup={showCityFilterPopup}
						setCityShowFilterPopup={setCityShowFilterPopup}
						setValidJobPostingsCities={setValidJobPostingsCities}
						dateFormat={dateFormat}
						handleDateRange={handleDateRange}
						disabledDate={disabledDate}
						showDateChip={showDateChip}
						deleteChip={deleteChip}
						setShowDateChip={setShowDateChip}
						setJobLeadsTab={setJobLeadsTab}
						handleTabSwitchtoJobLeads={handleTabSwitchtoJobLeads}
						jobPostings={jobPostings}
						handleReferLead={handleReferLead}
						getJobDemandsOnFiltersChange={getJobDemandsOnFiltersChange}
						// setJobDemands={setJobDemands}
						jobDemands={jobDemands}
						setValidJobDemandsCities={setValidJobDemandsCities}
						validJobDemandsCities={validJobDemandsCities}
						/>
						: jobPostingId ?
							<JobPosterPage 
							setViewLocations={setViewLocations}
							setJobPostingId={setJobPostingId}
							jobPostingClientSelected={jobPostingClientSelected}
							jobPostingId={jobPostingId}
							setShowShareOnPlatform={setShowShareOnPlatform}
							jobPostingData={jobPostingData}
							showShareOnPlatform={showShareOnPlatform}
							nameError={nameError}
							register={register}
							handleNameChange={handleNameChange}
							phoneNumberError={phoneNumberError}
							handleNumberChange={handleNumberChange}
							createJobPostingResponse={createJobPostingResponse}
							phoneNumber={phoneNumber}
							showAPIError={showAPIError}
							APIError={APIError}
							showSubmitPopup={showSubmitPopup}
							setShowSubmitPopup={setShowSubmitPopup}
							submitText={submitText}
							name={name}
							// setJobDemands={setJobDemands}
							jobDemands={jobDemands}
							setValidJobDemandsCities={setValidJobDemandsCities}
							validJobDemandsCities={validJobDemandsCities}
							/>
							
							: viewLocations && !jobLeadsTab ?
								<JobRequirements
								jobDemands={jobDemands}
								showValuePropModal={showValuePropModal}
								setCurrentClient={setCurrentClient}
								isAdmin={isAdmin}
								handleViewCreateWebLinks={handleViewCreateWebLinks}
								JobsGAEventsTracker={JobsGAEventsTracker}
								handleViewLocations={handleViewLocations}
								client={client}
								viewCreateWebLink={viewCreateWebLink}
								setViewCreateWebLink={setViewCreateWebLink}
								setJobPostingId={setJobPostingId}
								setJobPostingCity={setJobPostingCity}
								setJobPostingClientSelected={setJobPostingClientSelected}
								createJobPostingDropdown={createJobPostingDropdown}
								setJobPostingData={setJobPostingData}
								validJobDemandsClients={validJobDemandsClients}
								showClientFilterPopup={showClientFilterPopup}
								setClientShowFilterPopup={setClientShowFilterPopup}
								setValidJobDemandsClients={setValidJobDemandsClients}
								validJobDemandsVerticals={validJobDemandsVerticals}
								showVerticalFilterPopup={showVerticalFilterPopup}
								setVerticalShowFilterPopup={setVerticalShowFilterPopup}
								setValidJobDemandsVerticals={setValidJobDemandsVerticals}
								setShowBreadcrumb={setShowBreadcrumb}
								handleSalaryRange={handleSalaryRange}
								deleteChip={deleteChip}
								maxSalary={maxSalary}
								minSalary={minSalary}
								handleTabSwitchtoJobLeads={handleTabSwitchtoJobLeads}
								setJobDemands={setJobDemands}
								setValidJobDemandsCities={setValidJobDemandsCities}
								validJobDemandsCities={validJobDemandsCities}
								mitra={mitra}
								getJobDemandsOnFiltersChange={getJobDemandsOnFiltersChange}
								viewLocations={viewLocations}
								/>
								:
								<JobLocations  // happens on default load of any client view locations
								showJoiningBonusModal={showJoiningBonusModal}
								showAlert={showAlert}
								setShowAlert={setShowAlert}
								imageElements={imageElements}
								setImageElements={setImageElements}
								imageKeys={imageKeys}
								setImageKeys={setImageKeys}
								setHighlightedHubName={setHighlightedHubName}
								viewLocations={viewLocations}
								moreLocalities={moreLocalities}
								jobLeadsTab={jobLeadsTab}
								basicForm={basicForm}
								validJobDemandsCities={validJobDemandsCities}
								showCityFilterPopup={showCityFilterPopup}
								setCityShowFilterPopup={setCityShowFilterPopup}
								setValidJobDemandsCities={setValidJobDemandsCities}
								setShowBreadcrumb={setShowBreadcrumb}
								setViewLocations={setViewLocations}
								setMoreLocalities={setMoreLocalities}
								searchLocality={searchLocality}
								deleteChip={deleteChip}
								selected={selected}
								setSelected={setSelected}
								handleReferCandidateWithCityDetails={handleReferCandidateWithCityDetails}
								handleMoreLocalities={handleMoreLocalities}
								client={client}
								selectedLocalities={selectedLocalities}
								handleBackArrowClick={handleBackArrowClick}
								mitra={mitra}
								basicInfo={basicInfo}
								/>
				}
			</div>
			{showSideSheet ?
				<>
					<JobPageSidebar 
					client={client}
					setShowSideSheet={setShowSideSheet}
					setViewCreateWebLink={setViewCreateWebLink}
					jobPostingsByClient={jobPostingsByClient}
					setJobPostingId={setJobPostingId}
					setJobPostingResponseById={setJobPostingResponseById}
					setJobPostingCity={setJobPostingCity}
					setJobPostingData={setJobPostingData}
					setJobPostingClientSelected={setJobPostingClientSelected}
					/>
				</> : null}
		</>
	);
};

export default JobDemandsPage;
