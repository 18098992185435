import React, {useEffect, useState,useContext} from 'react';
// import Modal from 'react-modal';
import './../../css/jobsTable.scss';
import * as jobDemandsService from './../../service/jobDemandsService';
import {Button,Modal} from 'antd';
import {GlobalOutlined, CloseOutlined} from '@ant-design/icons';
import {AppContext} from '../../App';


const DEFAULT = 'DEFAULT';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    padding: '4px 20px'
  },
};

const CreateJobPostPopUp = ({heading, visible, setShowFilterPopup, setCategory, setJobPostingCity, setJobPostingClientSelected, list, setJobPostingData, client}) => {
  const [stateList, setStateList] = useState(list)
  const [validJobPostingCitiesByClient, setValidJobPostingCitiesByClient] = useState([]);
  const [jobId, setJobId] = useState();
  const [language, setLanguage] = useState('');
  const [citySelected, setCitySelected] = useState('');
  const languages = ['English', 'Hindi', 'Telugu', 'Tamil', 'Kannada'];
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { setAlert} = useContext(AppContext);


  useEffect(() => {
    if (client) {
      handleJobPostingClientChange({target: {value: client.toLowerCase()}})
    }
  });
  const handleJobPostingClientChange = (e) => {
    const selected = e.target.value;
    const newClient = selected.charAt(0).toUpperCase() + selected.slice(1);
    setJobPostingClientSelected(newClient);
    list.map((client, index) => {
      if (client.name === selected) {
        // console.log(client.cities);
        setValidJobPostingCitiesByClient(client.cities);
      }
    })
  };

  const handlejobIdChange = (e) => {
    setJobId(e.target.value);
  };

  const createJobPosting = async (jobId) => {
    try {
      if (!jobId || !language) {
        setErrorMessage('Please select all fields.')
        return
      }
      setLoading(true)

      validJobPostingCitiesByClient.map(ele => {
        if (ele.id === jobId) {
          // setCitySelected(ele.city);
          setJobPostingCity(ele.city)
        }
      })
      setErrorMessage('')

      jobDemandsService.createJobPosting({jobId, language}).then((result) => {
        if (result && result.data.id) {
          setCategory(result.data.id);
          setJobPostingData(result.data.data)
          setShowFilterPopup(false);
          setLoading(false)
        }
      }).catch(error => {
        setAlert({
          display: true,
          text: 'Something Went wront ,Please try again.',
          type: 'error'
        })
        setLoading(false)

      });
    }
    catch (error) {
      console.log(error);
    }
  }
  const handleLanguageChange = async (e) => {
    setLanguage(e.target.value)
  }
  return (

    <Modal
      visible={visible}
      onCancel={() => {setShowFilterPopup(false);}}
      // shouldCloseOnOverlayClick={true}
      className="modalForCreatePosting"
      footer={[<Button className="createWebLinks" onClick={() => {createJobPosting(jobId)}} loading={loading}>
                <GlobalOutlined /> Create New Poster
               </Button>,errorMessage && <div className='error-message'>{errorMessage}</div> 
              ]}
    >
      <div className="filter-pop-up-container">
        {/* <div className="filter-popup-heading-container">
          <div className="header">{heading}</div>
          <div className="filter-popup-cross-icon">
          <CloseOutlined onClick={() => {setShowFilterPopup(false);}} />
          </div>
        </div> */}
        <div className="create-popup-page fieldsContainer">
          <h6 className="content">* Select Client</h6>
          <select disabled onChange={handleJobPostingClientChange} className="button Dropdown-control" defaultValue={client.toLowerCase()}>
            <option value={DEFAULT} selected disabled>Client</option>
            {list.map((client) => (
              <option key={client} className="Dropdown-option" value={client.name}>
                {client.name}
              </option>
            ))}
          </select>
          <h6 className="content">* Select City</h6>
          <select onChange={handlejobIdChange} className="button Dropdown-control">
            <option value={DEFAULT} selected disabled>City</option>
            {validJobPostingCitiesByClient.map((city) => (
              <option key={city} className="Dropdown-option" value={Array.isArray(city.id)&&city.id.length?city.id[0]:''}>
                {city.city}
              </option>
            ))}
          </select>
          <h6 className="content">* Select Language</h6>

          <select onChange={handleLanguageChange} className="button Dropdown-control" >
            <option value={DEFAULT} selected disabled>Language</option>
            {languages.map((language) => (
              <option key={language}  className="Dropdown-option" value={language}>
                {language}
              </option>
            ))}
          </select>
        </div>
        
      </div>
    </Modal>
  )
}
export default CreateJobPostPopUp;