import React, {useState, useEffect, useRef, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import trackingConstants from '../../constants/trackingConstants';
import '../../css/navigation.scss';
import {logEvent} from '../../service/tracking';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import {Tag, Typography,Badge,Menu} from 'antd';
import {LogoutOutlined, MenuOutlined, QuestionCircleOutlined, MenuFoldOutlined, SettingOutlined, MenuUnfoldOutlined, PieChartOutlined, DollarCircleOutlined, GroupOutlined, 
	UsergroupAddOutlined, HomeOutlined, PercentageOutlined, HddOutlined, IssuesCloseOutlined,BookOutlined,LeftOutlined,RightOutlined,BellOutlined} from '@ant-design/icons';
import {AppContext} from '../../App';
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import commonFunc from "../../utils/commonFunction"
import CreateTandCPopUp from '../createTandCPopUp';
import * as loginService from '../../service/loginService'
import {showPayoutDashboard}from './../../service/reportService'
import * as leadsConvertedService from './../../service/leadsConvertedService';
import { ROLES } from './roles';

const {Text} =Typography;
function getItem(label, key, icon, children, type,disabled) {
	return {
	key,
	icon,
	children,
	label,
	type,
	disabled
	};
  }
  
const NavigationTab = () => {
	let history = useHistory();
	const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const AccountGAEventsTracker = useGAEventsTracker("Accounts")
	const ReportGAEventsTracker = useGAEventsTracker("Reports")
	const JobsGAEventsTracker = useGAEventsTracker("jobs")

	// const [createMoengageUser, trackEvent] = useMoengage();

	const {mitraReducer, dispatchMitra,mitraDispatch, userRole} = useContext(AppContext);
	const [currentTab, setcurrentTab] = useState({})
	const [enabledNav, setenabledNav] = useState(true)
	const [enabledFAQs, setEnabledFAQs] = useState(true)
	const navigationTab = useRef(null)
	const insurance_manager = commonFunc.navigationCheck(mitraReducer?.mitraInfo?.role).insurance_manager;
	const insurance_team_member = commonFunc.navigationCheck(mitraReducer?.mitraInfo?.role).insurance_team_member
	const show_incentives = commonFunc.navigationCheck(mitraReducer?.mitraInfo?.role).incentive;
	const client_update = commonFunc.navigationCheck(mitraReducer?.mitraInfo?.role).client_update;
	const convertedLeads = leadsConvertedService.checkMitraEligibilityForConvertedLeads(mitraReducer?.mitraInfo?.id);
	const training_hub = commonFunc.navigationCheck('').training_Hub;
	const help = commonFunc.navigationCheck('').help;
	const [badgeCount, setBadgeCount] = useState('');
	const onClick = (e) => {
		Number(e.key) ? onNav(Number(e.key)) : onNav(e.key)
	};
	
	const items = [
		getItem(enabledNav ? <Text style={currentTab==='PERFORMANCE_DASH' || currentTab==='PAYOUT_DASH' ? {color:'#D34612'}:{color:'black'}}>Reports</Text>: '', 'sub1', <PieChartOutlined />, [
			getItem(<Text style={currentTab==='PERFORMANCE_DASH'? {color:'#D34612'}:null} id="performance_id_nav">Performance Dashboard</Text>, 'PERFORMANCE_DASH'),
			(showPayoutDashboard(mitraReducer?.mitraInfo)) ? getItem(<Text style={currentTab==='PAYOUT_DASH'? {color:'#D34612'}:null} id="payout_id_nav">Payout Dashboard</Text>, 'PAYOUT_DASH') : null,
		])
	];

	const [openKeys, setOpenKeys] = useState(['']);

	const onOpenChange = (e) => {
		if (!openKeys[0]) {
			setOpenKeys(['sub1']);
		} else {
			setOpenKeys(['']);
		}
	};

	const onNav = (tab) => {
		mitraDispatch({type: 'UTILITIES', value: {currentMenu: tab}});
		const tabInfo = CONSTANTS.NAVIGATION_TABS[tab];

		if (tab !== 'PERFORMANCE_DASH' && tab !== 13 && openKeys[0]) onOpenChange();
		setcurrentTab(tab);
		if (tab === 'HOME_PAGE') {
			trackEvent('home_page', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			history.push(tabInfo.link);
			logEvent('ClickHomeTab', trackingConstants.EVENT_GROUP_HOME, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		else if (tab === 'LEADS') {
			GAEventsTracker("OpenAllCandidatesTab")
			if(mitraReducer?.mitraInfo?.id === CONSTANTS.expectionMitrasForCandidatesPageReDirection || mitraReducer?.mitraInfo?.managerMitraID === CONSTANTS.expectionMitrasForCandidatesPageReDirection){
				history.push(tabInfo.optionalLink ? tabInfo.optionalLink : tabInfo.link);
			}
			else{
				history.push(tabInfo.link);
			}
			logEvent('ClickCandidatesTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		else if (tab === 'JOB_DEMANDS') {
			JobsGAEventsTracker("View Demand Hub")
			history.push(tabInfo.link);
			logEvent('ClickJobDemandsTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		else if (tab === 'PERFORMANCE_DASH') {
			ReportGAEventsTracker("ViewReportsMainPage")
			history.push(tabInfo.link);
		}
		else if (tab === 'FAQ') {
			trackEvent('faq_opened', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			window.open(tabInfo.link);
		}
		else if (tab === 'ACCOUNT') {
			AccountGAEventsTracker("View Account Section")
			logEvent('ClickAccountTab', trackingConstants.EVENT_GROUP_PROFILE, trackingConstants.EVENT_SCREEN_BOT_NAV);
			history.push(tabInfo.link);
			return;
		} else if (tab === 'V_CARE') {
			trackEvent('VAS_tab_click', {CTA: Date.now()},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			if (mitraReducer?.mitraInfo.role === 'admin' && process.env.REACT_APP_INSURANCE_SHOW_MANAGER == '1') {
				window.open(process.env.REACT_APP_INSURANCE_MANAGER_LINK)
			} else if (mitraReducer?.mitraInfo.role !== 'admin' && process.env.REACT_APP_INSURANCE_SHOW_TEAM_MEMBER == '1') {
				window.open(process.env.REACT_APP_INSURANCE_TEAM_MEMBER_LINK)
			} else {
				return;
			}
		} else if (tab === 'INCENTIVES') {
			trackEvent('SG_Bronze_incentive_navigation', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			history.push(tabInfo.link);
		} else if (tab === 'MORE') {
			history.push(tabInfo.link);
			logEvent('ClickMoreTab', trackingConstants.EVENT_GROUP_PROFILE, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		else if (tab == 'TRAINING_HUB') {
			history.push(tabInfo.link);
			trackEvent('trainingHubTabClick', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		} else if (tab === 'HELP') {
			trackEvent('helpTabClick', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			history.push(tabInfo.link);
		} else if (tab === 'CLIENT_UPDATES') {
			trackEvent('client_updates_viewed', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			window.open(tabInfo.link);
			logEvent('ClickMoreTab', trackingConstants.EVENT_GROUP_PROFILE, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}else if (tab === 'PAYOUT_DASH') {
			history.push(tabInfo.link);
		} else if(tab === 'NOTIFICATION') {
			history.push(tabInfo.link);
		} else if(tab === 'VAHAN_CONVERTED_USERS') {
			history.push(tabInfo.link);
		} else if(tab === 'REFER_AND_EARN') {
			history.push(tabInfo.link);
		}
		else if(tab === 'LOGOUT'){
			handleLogout();
		}
	};

	const checkReportDisplay = () => {
		return <div id="report-icon" style={{background: currentTab === 'PERFORMANCE_DASH' ? '#FFF7F4' : null,padding:0}} className="navigation-menu">
			<Menu
				onClick={onClick}
				mode={enabledNav ? "inline" :'vertical'}
				items={items}
				openKeys={openKeys}
				onOpenChange = {onOpenChange}
			/> 
		</div>
	}

	useEffect(() => {
		const path = history.location.pathname;

		Object.keys(CONSTANTS.NAVIGATION_TABS).map((key) => {
			if (CONSTANTS.NAVIGATION_TABS[key].link === path) {
				return onNav(key);
			}
		});
		
		switch (path) {
			case '/leads/action-needed':
				onNav(1)
				break;
			case '/upload-periscope-data':
				navigationTab.current.hidden = true;
				break;
			case '/upload-onboarding-data':
				navigationTab.current.hidden = true;
				break;
			case '/upload-marketing-data':
				navigationTab.current.hidden = true;
				break;
			case '/file-uploader':
				navigationTab.current.hidden = true;
				break;
			case '/terms-and-conditions':
				navigationTab.current.hidden = true;
				break;
			case '/	':
				onNav(11);
				break;
			default:
				break;
		}
	}, []);

	useEffect(() => {
		if (mitraReducer?.utilities?.currentMenu){
			setcurrentTab(mitraReducer?.utilities?.currentMenu);
		}
	}, [mitraReducer]);

	const handleLogout = () => {
		loginService
			.inValidateSession()
			.then((response) => {
                localStorage.clear()
				window.location.reload();
			})
			.catch((error) => {
				console.log('Error logging out: ', error);
			});
	};
	loginService.getConversationNotification(mitraReducer?.mitraInfo?.phoneNumber).then((response) => {
		const DateObj = new Date();
		let openTickets = response?.data?.results?.filter((ticket) => ticket.status === 6 &&
		((DateObj.getTime() - Date.parse(ticket.updated_at)) / (1000 * 3600 * 24) < 4));
		if (openTickets.length > 0) {
			setBadgeCount(openTickets.length);
		}
	})

	const generateTab = ({ tab }) => {
		const tabInfo = CONSTANTS.NAVIGATION_TABS[tab];
		
		if(tabInfo?.isVisible === false)	return null;

		if(tab === 'PERFORMANCE_DASH') {
			return checkReportDisplay();
		}

		let flagToCheck = false;

		switch (tab) {
			case 'INCENTIVES':
				flagToCheck = show_incentives;
				break;
			case 'VAHAN_CONVERTED_USERS':
				flagToCheck = convertedLeads;
				break;
			case 'CLIENT_UPDATES':
				flagToCheck = client_update;
				break;
			case 'V_CARE':
				flagToCheck = insurance_team_member || insurance_manager;
				break;
			case 'TRAINING_HUB':
				flagToCheck = training_hub;
				break;
			case 'REFER_AND_EARN':
				flagToCheck = !mitraReducer?.mitraInfo?.managerMitraID;
				break;
			case 'HELP':
				flagToCheck = help;
				break;
			case 'FAQ':
				flagToCheck = enabledFAQs;
				break;
			default:
				flagToCheck = true;
				break;
		}

		return (
			flagToCheck && <div id={tabInfo.id} style={{ background: currentTab === tab ? '#FFF7F4' : null, height: '47px' }} className="navigation-menu" onClick={(e) => onNav(tab)}>
				<div style={{ color: currentTab === tab ? '#D34612' : null, marginBottom: '7px'}}>
					{tabInfo.icon}
				</div>
				{enabledNav && <span style={{ color: currentTab === tab ? '#D34612' : null }} className={"navigation-text"}>
					{tabInfo.label}
					{tabInfo.key === 'help' && badgeCount > 0?
						<Badge count={badgeCount} offset={[10, 0]}/>
					: null}
				</span>}
			</div>
		);
	};

	const generateTabForMobile = ({ tab }) => {
		const tabInfo = CONSTANTS.NAVIGATION_TABS[tab];

		if(!tabInfo?.isMobileView)	return null;

		return (
			<div id={tabInfo.mobileId} className="navigation-menu" onClick={(e) => onNav(tab)}>
				<div className="nav-icon">
					<div style={{ color: currentTab === tab ? '#D34612' : null, marginBottom: '7px' }}>
						{tabInfo.icon}
					</div>
				</div>
				<div style={{ color: currentTab === tab ? '#D34612' : null }} className={"navigation-text"}>{tabInfo.mobileLabel ? tabInfo.mobileLabel : tabInfo.label}</div>
			</div>
		);
	}

	return (
		<>
			<CreateTandCPopUp mitraReducer={mitraReducer} dispatchMitra={mitraDispatch} isAdmin={mitraReducer?.mitraInfo?.role === 'admin'} termsVersion={mitraReducer?.termsVersion} mitraId ={mitraReducer?.mitraInfo?.id} />
			{ROLES.DC === userRole ? null : <div ref={navigationTab} className="nav-wrapper-desktop" style={!enabledNav ? {width: 'auto'} : null}>
				<Text
					className='arrow-fold-btn'
					onClick={() => setenabledNav(!enabledNav)} 
				>
					{enabledNav ? <LeftOutlined/> : <RightOutlined />}
				</Text>
				<div id="logo-icon" onClick={(e) => onNav('HOME_PAGE')} className="navigation-menu" >
					<span className="nav-icon">
						<img alt="Home Icon Inactive" src="/image/vahan-new-logo.png"/>
					</span>
					{enabledNav && <span className={"navigation-text"}>
						Vahan Leader
					</span>}
				</div>
				{
					Object.keys(CONSTANTS.NAVIGATION_TABS).map((tab) => {
						return generateTab({ tab });
					})
				}
				<div id="logout-icon" className="navigation-menu">
					<div onClick={(e) => onNav('LOGOUT')} className='logo-logoutText'>
						<LogoutOutlined />
						{enabledNav && <span className={"navigation-text"}>
							Logout
						</span>}
					</div>
				</div>
			</div>}

			{/* Mobile View */}
			<div className="navigation-wrapper">
				{
					Object.keys(CONSTANTS.NAVIGATION_TABS).map((tab) => {
						return generateTabForMobile({ tab });
					})
				}
			</div>
		</>
	);
};

export default NavigationTab;
