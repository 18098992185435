const reducer =(state,action) =>{
    switch (action.type) {
      case "LoginInfo":
        return {...state , mitraInfo: action.value}
      case "paramPass":
        return {...state , paramPass: action.value}
      case "navigationDisplay":
        return {...state , navigationDisplay: action.value }
      case "timeGauge":
        return {...state , timeGauge: action.value}
      case "reportParams":
        return {...state , reportParams: action.value}
      case "termsVersion":
      return {...state , termsVersion: action.value}
      case "KaleyraCallDetails":
        return {...state, kaleyraCallInformation: action.value}
      case "EXPERIMENTAL_FEATURES":
        return {...state, experimentalFeatures: action.value}
      case "UTILITIES":
        return {...state, utilities: action.value}
      // case "whiteListedURL":
      //   return {...state , publicURL:[]}
      default:
        return state;
    }
  }
export {reducer};
