import axios from 'axios';

export const getJobDemandsData = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobDemandsData`;
    return axios.get(url, {withCredentials: true});
};

export const getJobDemandsOnFiltersChange = async (minSalary, maxSalary, city, client, vertical) => {
    const data = {
        minSalary: minSalary,
        maxSalary: maxSalary,
        client: JSON.stringify(client),
        city: JSON.stringify(city),
        category: JSON.stringify(vertical)
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/getJobDemandsOnFiltersChange';
    return axios.get(url, {params: data, withCredentials: true});
};

// export const getJobDemandsOnCityChange = async (city) => {
//     const data = new FormData();
//     data.append('city', city);
//     let url = process.env.REACT_APP_BACKEND_URL + '/getJobDemandsOnCityChange';
//     return axios.post(url, data, { withCredentials: true });
// };

// export const getJobDemandsOnCategoryChange = async (category) => {
//     const data = new FormData();
//     data.append('category', category);
//     let url = process.env.REACT_APP_BACKEND_URL + '/getJobDemandsOnCategoryChange';
//     return axios.post(url, data, { withCredentials: true });
// };

export const getJobPostingByClientName = async (client) => {
    const data = {client: client}
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobPostingByClientName`;
    return axios.get(url, {params: data, withCredentials: true});
};

export const getCreateJobPostingDropdown = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getCreateJobPostingDropdown`;
    return axios.get(url, {withCredentials: true});
};

export const createJobPosting = async (data) => {
    let url = process.env.REACT_APP_BACKEND_URL + '/createJobPosting';
    return axios.post(url, data, {withCredentials: true});
};
export const createJobPostingResponse = async (jobPostingId, name, phoneNumber) => {
    const data = new FormData();
    data.append('jobPostingId', jobPostingId);
    data.append('name', name);
    data.append('phoneNumber', phoneNumber);
    let url = process.env.REACT_APP_BACKEND_URL + '/createJobPostingResponse';
    return axios.post(url, data, {withCredentials: true});
};

export const getJobPostingResponses = async (dateSelected) => {
    const data = {
        startDate: dateSelected + ' 00:00:01',
        endDate: dateSelected + ' 23:59:59'
    }
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobPostingResponses`;
    return axios.get(url, {params: data, withCredentials: true});
};
export const getJobPostingResponseById = async (jobPostingId) => {
    const data = {
        jobPostingId: jobPostingId
    }
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobPostingResponseById`;
    return axios.get(url, {params: data, withCredentials: true});
};
export const getJobPostingResponsesOnFilterChange = async (startDate, endDate, city, client) => {
    const data = {
        startDate: startDate,
        endDate: endDate,
        city: JSON.stringify(city),
        client: JSON.stringify(client)
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/getJobPostingResponsesOnFilterChange';
    return axios.get(url, {params: data, withCredentials: true});
};

export const exportJobPostingResponseSheetByFilters = async (startDate, endDate, city, client) => {
    const data = {
        startDate: startDate,
        endDate: endDate,
        city: JSON.stringify(city),
        client: JSON.stringify(client)
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/exportJobPostingResponseSheetByFilters';
    return axios.get(url, {params: data, withCredentials: true});
};

export const exportJobPostingResponseSheetById = async (jobPostingResponseById) => {
    const data = {
        jobPostingResponseById
    }
    let url = process.env.REACT_APP_BACKEND_URL + '/exportJobPostingResponseSheetById';
    return axios.get(url, {params: data, withCredentials: true});
};


export const getJobPosterById = async (jobPostingId) => {
    const data = {
        jobPostingId
    }
    console.log('data :>> ', data);
    let url = process.env.REACT_APP_BACKEND_URL + '/getJobPostingImage';
    return axios.get(url, {params: data, withCredentials: true});
};