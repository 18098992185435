import axios from "axios";
import CONSTANTS from "../constants/constants.js";


function getFDHeader() {
    return {
        headers:
        {
            'Authorization': 'Basic ' + CONSTANTS.FD_TOKEN,
            'Accept-Encoding': 'gzip',
            'Content-Type': 'application/json; charset=utf-8',
            'Access-Control-Allow-Origin': '*'
        }
    };
}

export const getCandidateData = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/help/search-candidate?search=${data}&limit=5`;
    return axios.get(url, { params: data, withCredentials: true });
}

export const checkDuplicateTicket = async (mitraInfo, candidateInfo) => {
    let url = `https://vahan-mitraleadersupport.freshdesk.com/api/v2/search/tickets?query="`;
    let clientName = candidateInfo?.extra?.companyName?.charAt(0)?.toUpperCase() + candidateInfo?.extra?.companyName?.slice(1);
    let customQuery = '('+'cf_your_registered_phone_number:' + mitraInfo.phoneNumber;
    customQuery += '%20OR%20cf_manager_phone_number:' + mitraInfo.managerMitraPhoneNumber+')';
    customQuery += '%20AND%20cf_candidate_phone_number:' + candidateInfo?.extra?.phoneNumber;
    customQuery += '%20AND%20cf_issue_type:' + `%27${candidateInfo.issueType}%27`;
    customQuery += '%20AND%20cf_client_name:' + `%27${clientName}%27`;
    url += customQuery + '"' ;
    return await axios.get(url, getFDHeader());
}

export const createTicket = async (mitraInfo, candidateInfo, attachments) => {
    try{
        const isDuplicateTicket = await checkDuplicateTicket(mitraInfo, candidateInfo);
        if (isDuplicateTicket && isDuplicateTicket?.data?.total) {
            return { status: 409, data: isDuplicateTicket?.data?.results?.[0] };
        }
        let url = `${process.env.REACT_APP_BACKEND_URL}/help/create-ticket`;
        let form = new FormData();
        form.append('mitraInfo', JSON.stringify(mitraInfo));
        form.append('candidateInfo', JSON.stringify(candidateInfo));
        if (attachments && attachments.length) {
            attachments.forEach(attachment => {
                console.log(attachment);
                form.append('attachments[]', attachment);
            });
        }
        const headers = {
            'Content-Type':'multipart/form-data; boundary=' + form._boundary
        }
        return axios.post(url,form, { ...{headers},withCredentials: true });
    }catch(e){
        console.log(e);
    }
    /*
    const url = `https://vahan-mitraleadersupport.freshdesk.com/api/v2/tickets`;

    const dataToFormat = {
        "name": mitraInfo.name,
        ...(mitraInfo.email ? { "email": mitraInfo.email } : { "phone": mitraInfo.phoneNumber }),
        // "email": "anuj.pundir@vahan.co",
        "group_id": CONSTANTS.FRESHDESK_GROUP_ID.MITRA_LEADER_ESCALATION,
        "subject": candidateInfo.issueType,
        "description": candidateInfo.issueDescription,
        "status": 2,
        "priority": 1,
        "source": 9,
        "custom_fields": {
            "cf_candidate_name": candidateInfo?.extra?.firstName + (candidateInfo?.extra?.lastName !== "Unknown" ? " " + candidateInfo?.extra?.lastName : ""),
            "cf_your_registered_phone_number": mitraInfo.phoneNumber,
            "cf_manager_phone_number": mitraInfo.managerMitraPhoneNumber,
            "cf_issue_type": candidateInfo.issueType,
            "cf_client_name": candidateInfo?.extra?.companyName?.charAt(0)?.toUpperCase() + candidateInfo?.extra?.companyName?.slice(1),
            "cf_candidate_phone_number": candidateInfo.extra.phoneNumber,
            // "cf_candidate_city": candidateInfo.extra.companyCity,
            "cf_candidate_city642809": candidateInfo.extra.companyCity,
            "cf_candidate_registered_name": candidateInfo.candidateRegisteredName,
            "cf_candidate_license_plate_number": candidateInfo.candidateDrivingLicenseNumber,
            "cf_rider_id": candidateInfo.riderId,
            "cf_vehicle_number": candidateInfo.vehicleNumber,
        }
    }

    const form = new FormData();

    Object.keys(dataToFormat).forEach(key => {
        if (key === 'custom_fields') {
            Object.keys(dataToFormat[key]).forEach(key1 => {
                form.append(`custom_fields[${key1}]`, dataToFormat[key][key1] ? dataToFormat[key][key1] : '');
            });
        } else {
            form.append(key, dataToFormat[key] ? dataToFormat[key] : '');
        }
    });

    if (attachments && attachments.length) {
        attachments.forEach(attachment => {
            form.append('attachments[]', attachment);
        });
    }

    const headers = getFDHeader();
    headers.headers['Content-Type'] = 'multipart/form-data; boundary=' + form._boundary;

    const response = axios.post(url, form, headers);
    return response;
    */
}