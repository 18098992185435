import React, { Component } from 'react';
import { Button } from '@rmwc/button';
import axios from 'axios';

class FileUploader extends Component {
    state = {
        uploders: ['FileComponent']
    };

    createUploder = () => {
        this.setState({ uploders: this.state.uploders.concat('FileComponent') });
    }
    render() {
        return (
            <div className="Home OnBoarding mainContentZ">
                <h2 className="heading_1 add-job">Vahan Jobs</h2>
                <h4 className="heading_1 form-sub-heading">File Uploader</h4>
                <div>
                    {this.state.uploders.map(block => <FileComponent />)}
                </div>
                {this.state.uploders.length < 5 ? <Button raised label="ADD More" type="button" className="addComponent"onClick={this.createUploder} /> : ""}
            </div>
        );
    }
};

class FileComponent extends Component {
    state = {
        selectedFile: null,
        fileUrl: null
    };

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
        if (event.target.files[0]) {
            this.onFileUpload(event.target.files[0]);
        } else {
            this.setState({ fileUrl: null, selectedFile: null });
        }
    };

    onFileUpload = (selectedFile) => {

        const formData = new FormData();
        let th = this;
        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        );
        // th.setState({ fileUrl: selectedFile.name });
        let url = process.env.REACT_APP_BACKEND_URL + '/fileUploader';
        axios.post(url, formData).then(function (response) {
            if (response.data.imageUrl) {
               th.setState({ fileUrl: response.data.imageUrl });
            }
            if (response.data.error) {
                alert(response.data.error)
             }
        })
        .catch(function (response) {
            console.log(response.data);
        });
    };



    copyToClipboard = () => {
        let dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = this.state.fileUrl;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }

    renderData = () => {
        if (this.state.fileUrl) {
            return (
                <div>
                    <span>
                        <input type="text" value={this.state.fileUrl} disabled/>

                    </span>
                    <span style={{'margin-left': '1%'}}>
                        {this.state.fileUrl ? <Button raised label="Copy" style={{'background-color': '#0F349A'}} className="copyButton" type="button" onClick={this.copyToClipboard} /> : ""}
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    <span style={{'margin-right': "3%"}}>
                        <input type="file" onChange={this.onFileChange} accept="image/*,.pdf" />
                    </span>
                </div>
            )
        }

    }
    render() {

        return (
            <div style={{'margin-bottom': "1%"}}>
                {this.renderData()}
            </div>

        );
    }
}

export default FileUploader;
