import React, { useCallback, useEffect, useState, useContext } from "react";
// import blitz from 'blitzllama-js';
import { Formio } from "react-formio"
// import * as loginService from '../../service/loginService';
import { useHistory } from "react-router-dom";
import { createApplication, getCandidateInfo, postAdditionalInfo, postCandidateDocuments, postCandidate, verifyOtp, postAdditionalInfoForRapido, submitApplication, sendOtp, getScheduledInterviewIdForStaffingClients, isAllRequiredDocumentsPresent, updateSiMetaData, getStoreShiftDetails,
sendZomatoOtp, verifyZomatoOtp, validateZomatoUser, getZomatoCitiesData, 
getZomatoDigiLockerVerificationUrl, getKycStatus, getZomatoZonesData, getZomatoVehicleData, getDocVerificationData,
updateZomatoCity, updateZomatoData } from "../../service/additionalInfoService";
import { uniquenessCheck } from './../../service/jobRecommendation';
import { AdditionalFormsURLEnum as URLEnum } from "./../../utils/utility"
import "./../../css/additionalInfo1.css"
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";
import { AppContext } from '../../App';
import commonFunc from "../../utils/commonFunction";
import { message, Card, Tag, notification, Modal, Button } from "antd";
import {RouterPrompt} from '../RouterPrompt/RouterPrompt'
import {getDisplayableClientName} from '../../utils/commonFunction'
import {rollOutStaffingMilestoneVisibility} from '../../constants/utils';
import { ROLES } from "../common/roles";

let docsSubmitCount = 0, verifyOtpCount = 0, isOtpVerified = false, startedOptCheck = false;
let citySubmitted = false;

const AdditionalInfo1 = props => {

    window.addEventListener("beforeunload", function (event) {
        event.preventDefault();
        console.log("refresh prevented");
        // event.returnValue = "Are you sure you want to leave?";
    });
    const { mitraReducer,setSpin, mitraDispatch, userRole } = useContext(AppContext);
    const [showPrompt, setShowPrompt] = useState(true);

    const history = useHistory()
    const [mitra, setMitra] = useState()
    const [isSubscribed, setisSubscribed] = useState(true)
    const [lead] = useState(commonFunc.getAllsearchParams(history.location.search))
    const [sub, setSub] = useState({
        data: {}
    })
    const [docFetch, setDocFetch] = useState(true)
    const [viaNext, setViaNext] = useState(false)
    const [viaPrev, setViaPrev] = useState(false)
    
    const [currentLeadScheduledInterviewId, setcurrentLeadScheduledInterviewId] = useState(null)
    const [isVendorReferringFormFilled, setIsVendorReferringFormFilled] = useState(false)
    //const [submissionData, setSubmissionData] = useState({})
    // const [createMoengageUser, trackEvent] = useMoengage();
    const [checkUniquenessForUber, setCheckUniquenessForUber] = useState(true)
    const [otpNotificationShowed, setOtpNotificationShowed] = useState(false)
    const [showEVDetails, setShowEVDetails] = useState(false)
    const [showDigiLockerContainer, setShowDigiLockerContainer] = useState(false)
    const [digiLockerUrl, setDigiLockerUrl] = useState(null)
    const [vehicleDetailsFilled, setVehicleDetailsFilled] = useState(false)


    const goToLeadsPage = () => {
        setTimeout(()=> {   // required for the state(showPrompt) to get updated at the caller method
            mitraDispatch({type: 'UTILITIES', value: {currentMenu: 'LEADS'}});
            if (localStorage.getItem('currentPhoneNumber')) {
                history.push({
                    pathname: '/search-page',
                    state: {
                        candidatePhoneNumber: localStorage.getItem('currentPhoneNumber'),
                    }
                })
            }
            else
                history.replace({ pathname: "/leads/all-candidates", state: JSON.stringify(lead) });
            setSpin({
                loading: false, //false or true when actived
                delay: null,
                tip: 'Uploading documents please wait ...'  //Loading copy here
              });
            if (localStorage.getItem('currentPhoneNumber')) {
                history.push({
                    pathname: '/search-page',
                    state: {
                        candidatePhoneNumber: localStorage.getItem('currentPhoneNumber'),
                    }
                })
            }
            else
                history.replace({ pathname: "/leads/all-candidates", state: JSON.stringify(lead) });
        }, 1000);
    };

    const updateOrInsertEntryForTCAccess = async (mitra, lead) => {
        const tcId = localStorage.getItem('suiteCrmId');

        if (!tcId || userRole !== ROLES.DC) return;

        let tcAccessLog = lead?.siMetaData?.tcAccessLog;
        if (tcAccessLog?.length > 0) {
            const isTcAlreadyAccessed = tcAccessLog.findIndex((item) => item.tcId === tcId);

            if (isTcAlreadyAccessed === -1) {
                tcAccessLog.push({
                    tcId: tcId,
                    tcAccessTime: new Date().getTime()
                });
            }
            else {
                tcAccessLog[isTcAlreadyAccessed].tcAccessTime = new Date().getTime();
            }
        }
        else {
            tcAccessLog = [{
                tcId: tcId,
                tcAccessTime: new Date().getTime()
            }]
        }

        await updateSiMetaData(
            {
                phoneNumber: lead.phoneNumber,
                scheduledInterviewId: lead.scheduledInterviewId,
                siMetaData: { tcAccessLog: tcAccessLog },
                mitraId: mitra.id
            },
            {
                updateSiMetaData: true
            }
        )
    }

    const clientBasedDisableApprovedDoc = (clientName, leadMeta) => {
        let disabledDocs = {};
        if (!clientName)
            return disabledDocs;

        if (clientName.toLowerCase().includes('rapido') && Array.isArray(leadMeta?.rapidoMilestone?.value)) {
            const metaDataValue = leadMeta?.rapidoMilestone?.value;
            for (let doc of metaDataValue) {
                const docType = doc.substring(0, doc.indexOf("-") - 1);
                if (doc.toLowerCase().includes('approved')) {
                    if (Array.isArray(CONSTANTS.RAPIDO_FORMIO_DOC_MAPPING[docType])) {
                        for (let item of CONSTANTS.RAPIDO_FORMIO_DOC_MAPPING[docType]) {
                            Object.assign(disabledDocs, { [item]: true });
                        }
                    }
                    else {
                        Object.assign(disabledDocs, { [CONSTANTS.RAPIDO_FORMIO_DOC_MAPPING[docType]]: true });
                    }
                }
            }
        }
        else if(clientName.toLowerCase().includes('uber') && leadMeta?.completedUberDocArray)
        {
            const approvedDocs = leadMeta?.completedUberDocArray;

            for(let doc of approvedDocs)
            {
                const name = doc.docName;
                if (Array.isArray(CONSTANTS.UBER_FORMIO_DOC_MAPPING[name])) {
                    for (let item of CONSTANTS.UBER_FORMIO_DOC_MAPPING[name]) {
                        Object.assign(disabledDocs, { [item]: true });
                    }
                }
                else {
                    Object.assign(disabledDocs, { [CONSTANTS.UBER_FORMIO_DOC_MAPPING[name]]: true });
                }
            }
        }

        return disabledDocs;
    }

    const options = {
        noAlerts: true,
        hooks: {
            beforeNext: async (currentPage, submission, next) => {
                console.log('before next---');
                await updateOrInsertEntryForTCAccess(mitra, lead);
                let errors = [], showMessage = false;
                if (lead.companyName.toLowerCase().indexOf('rapido') !== -1 && (!rollOutStaffingMilestoneVisibility(mitraReducer?.mitraInfo) ? lead?.companyName?.toLowerCase().indexOf('3pl') === -1 : true)) {
                    if (submission.data.uniqueness === 'createApplicationOnly') {
                        let errors = [];
                        try {
                            const createApplicationRes = await createApplication(submission.data, mitra, lead)
                            if (createApplicationRes.data.createApplication !== "200") {
                                errors.push(createApplicationRes.data.createApplication)
                            } else {
                                trackApplication(lead, mitra.id)
                                setShowPrompt(()=>false);
                                return goToLeadsPage();
                            }
                        } catch (error) {
                            if (error.response)
                                errors = error.response.data.message ? [error.response.data.message] : ["something went wrong"]
                        }
                    }
                    else {
                        if (!submission.data.verifyOtp || (submission.data.uniqueness !== 'createApplicationOnly' && !isOtpVerified)) {
                            message.warn('Next button clicked without verifying OTP', 5);
                            showMessage = true;
                        }
                        else {
                            await submitApplication(submission.data, lead, mitra, true);
                            if (submission?.data?.uniqueness === 'uniquenessCheckOnly')
                                return goToLeadsPage();
                        }
                    }
                }
                else if(lead?.companyName.toLowerCase().indexOf('zepto') !== -1){
                    // should be creating application
                    if(getPage() == 0){
                        let errors = [];
                            try {
                                const res = await postAdditionalInfo(submission.data, mitra, lead)
                                for (let key in res.data) {
                                    if (res.data[key] !== "200") {
                                        errors.push(`${key}: ${res.data[key]}`)
                                    }
                                }
                                const createApplicationRes = await createApplication(submission.data, mitra, lead)
                                if (createApplicationRes.data.createApplication !== "200") {
                                    errors.push(createApplicationRes.data.createApplication)
                                    
                                } else {
                                    trackApplication(lead, mitra.id)
                                    setIsVendorReferringFormFilled(true);
                                    //setShowPrompt(()=>false);
                                    //return goToLeadsPage();
                                }
                            } catch (error) {
                                if (error.response)
                                    errors = error.response.data.message ? [error.response.data.message] : ["something went wrong"]
                            }
                    }
                    else if(getPage() == 1) {
                        const response = await getScheduledInterviewIdForStaffingClients({"submissionData": submission.data,"lead": lead, "mitra": mitra})
                        const scheduledInterviewId = response?.data[0]?.id
                        setcurrentLeadScheduledInterviewId(scheduledInterviewId)
                        submission.data = {...submission.data, zeptoFormTwoCompleted: scheduledInterviewId}
                        try {
                            const res = await postAdditionalInfo(submission.data, mitra, lead)
                            for (let key in res.data) {
                                if (res.data[key] !== "200") {
                                    errors.push(`${key}: ${res.data[key]}`)
                                }
                            }
                        } catch (error) {
                            errors = error.response ? [error.response.data.message] : ["something went wrong"]
                        }
                    }
                    console.log("errors", errors)
                }
                else if(lead?.companyName.toLowerCase() === 'zomato') {
                    console.log('checking before next zomato')
                    // should be creating application
                    if(getPage() == 0) {
                        if (!submission.data.verifyZomatoOtp || !isOtpVerified) {
                            message.warn('Next button clicked without verifying OTP', 5);
                            showMessage = true;
                        }

                        let errors = [];
                            try {
                                delete submission.data.rawData;
                                const res = await postAdditionalInfo(submission.data, mitra, lead)
                                for (let key in res.data) {
                                    if (res.data[key] !== "200") {
                                        errors.push(`${key}: ${res.data[key]}`)
                                    }
                                }
                            } catch (error) {
                                if (error.response)
                                    errors = error.response.data.message ? [error.response.data.message] : ["something went wrong"]
                            }
                    } else if(getPage() == 1) {
                        // const response = await getScheduledInterviewIdForStaffingClients({"submissionData": submission.data,"lead": lead, "mitra": mitra})
                        // const scheduledInterviewId = response?.data[0]?.id
                        // setcurrentLeadScheduledInterviewId(scheduledInterviewId)
                        // submission.data = {...submission.data, zeptoFormTwoCompleted: scheduledInterviewId}

                        console.log(submission.data, 'page 1--submission');
                        try {
                            delete submission.data.rawData;
                            console.log(submission.data, '---VehicleType--submission');
                            delete submission.data.rawData;
                            const vehicleType = submission.data && submission.data.zomatoVehicleType;
                            const evType = submission.data && submission.data.zomatoEvType;
                            const evOwnershipType = submission.data && submission.data.zomatoEvOwnershipType;
                            const evProofUrl = submission.data && submission.data.evProof && submission.data.evProof.length &&
                            submission.data.evProof[0] && submission.data.evProof[0].url;
                            // update zomato
                            if(vehicleType) {
                                const response = await updateZomatoData({
                                    phoneNumber: lead.phoneNumber,
                                    dataToUpdate: {
                                        updateVehicleType: {
                                            vehicleType
                                        }
                                    }
                                });
                                console.log('update vehicleType res-----', response);
                            }
                            if (evType && evOwnershipType && evProofUrl) {
                                const response = await updateZomatoData({
                                    phoneNumber: lead.phoneNumber,
                                    dataToUpdate: {
                                        updateEvDetails: {
                                            ownershipType: evOwnershipType,
                                            vehicleType: evType,
                                            imageUrl: evProofUrl
                                        }
                                    }
                                });
                                console.log('update evDetails  res-----', response);
                            }
                            const submissionData = {
                                zomatoVehicleType: submission.data.zomatoVehicleType,
                                zomatoEvType: submission.data.zomatoEvType,
                                zomatoEvOwnershipType: submission.data.zomatoEvOwnershipType,
                            }
                            const res = await postAdditionalInfo(submissionData, mitra, lead);
                            for (let key in res.data) {
                                if (res.data[key] !== "200") {
                                    errors.push(`${key}: ${res.data[key]}`)
                                }
                            }
                        } catch (error) {
                            errors = error.response ? [error.response.data.message] : ["something went wrong"]
                        }
                    } else if(getPage() == 2) {
                        try {
                            console.log(submission.data, '---city--submission');
                            delete submission.data.rawData;
                            const cityRaw = submission.data && submission.data.zomatoWorkCity;
                            let city = cityRaw ? cityRaw.substring(cityRaw.indexOf('-') + 1) : null;
                            let cityId = cityRaw ? cityRaw.substring(0,cityRaw.indexOf('-')) : null;
                            console.log({city, cityId});
                            const submissionData = {
                                zomatoWorkCity: city
                            }
                            // update zomato
                            if (cityId) {
                                const response = await updateZomatoData({
                                    phoneNumber: lead.phoneNumber,
                                    dataToUpdate: {
                                        updateCity: {
                                            cityId: cityId
                                        }
                                    }
                                });
                                
                                console.log('final update  city res-----', response);
                            }

                            const res = await postAdditionalInfo(submissionData, mitra, lead);
                            for (let key in res.data) {
                                if (res.data[key] !== "200") {
                                    errors.push(`${key}: ${res.data[key]}`)
                                }
                            }

                        } catch (error) {
                            errors = error.response ? [error.response.data.message] : ["something went wrong"]
                        }
                    } else if(getPage() == 3) {
                        try {
                            console.log(submission.data, '---Zone--submission');
                            delete submission.data.rawData;
                            const zoneRaw = submission.data && submission.data.zomatoWorkZone;
                            console.log({zoneRaw}, '---from--ui----');
                            let zone = zoneRaw ? zoneRaw.substring(zoneRaw.indexOf('=') + 1) : null;
                            let zoneId = zoneRaw ? zoneRaw.substring(0,zoneRaw.indexOf('=')) : null;
                            console.log({zone, zoneId});
                            const submissionData = {
                                zomatoWorkZone: zone
                            }
                            // update zomato
                            if (zoneId) {
                            const response = await updateZomatoData({
                                phoneNumber: lead.phoneNumber,
                                dataToUpdate: {
                                    updateZone: {
                                        zoneId
                                    }
                                }
                            });
                            
                            console.log('final update Zone res-----', response);
                            }
                            const res = await postAdditionalInfo(submissionData, mitra, lead);
                            for (let key in res.data) {
                                if (res.data[key] !== "200") {
                                    errors.push(`${key}: ${res.data[key]}`)
                                }
                            }

                        } catch (error) {
                            errors = error.response ? [error.response.data.message] : ["something went wrong"]
                        }
                    } else if(getPage() == 4) {
                        try {
                            console.log(submission.data, '---Doc-Verification--submission');
                            delete submission.data.rawData;
                            const submissionData = {
                                panNumber: submission.data.panNumber,
                                gender: submission.data.gender,
                                fathersName: submission.data.fathersName,
                                permanentAddressHouseNumber: submission.data.permanentAddressHouseNumber,
                                permanentAddressStreet: submission.data.permanentAddressStreet,
                                permanentAddressCity: submission.data.permanentAddressCity,
                                permanentAddressState: submission.data.permanentAddressState,
                                permanentAddressPincode: submission.data.permanentAddressPincode,
                                permanentAddressLandmark: submission.data.permanentAddressLandmark,
                                zomatoAddressProofType: submission.data.zomatoAddressProofType,
                                zomatoVerificationType: submission.data.zomatoVerificationType,
                            }
                            
                            const panUrl = submission.data && submission.data.panCard && submission.data.panCard.length &&
                            submission.data.panCard[0] && submission.data.panCard[0].url;

                            if (submissionData.zomatoVerificationType === 'Document Upload') {
                                // update zomato
                                if (submissionData.panNumber && submissionData.fathersName && submissionData.gender && panUrl) {
                                    const response = await updateZomatoData({
                                        phoneNumber: lead.phoneNumber,
                                        dataToUpdate: {
                                            updatePanCardDetails: {
                                                panNumber: submissionData.panNumber,
                                                fathersName: submissionData.fathersName,
                                                gender: submissionData.gender,
                                                imageUrl: panUrl,
                                                verifyPan: true
                                            }
                                        }
                                    });
                                    console.log('final update pan res-----', response);

                                }

                                let frontImageUrl = null;
                                let backImageUrl = null;
                                if (submission.data.zomatoAddressProofType === 'DL') {
                                    frontImageUrl = submission.data && submission.data.drivingLicense && submission.data.drivingLicense.length &&
                                        submission.data.drivingLicense[0] && submission.data.drivingLicense[0].url;
                                    backImageUrl = submission.data && submission.data.drivingLicenseBack && submission.data.drivingLicenseBack.length &&
                                        submission.data.drivingLicenseBack[0] && submission.data.drivingLicenseBack[0].url;
                                } else {
                                    frontImageUrl = submission.data && submission.data.aadhaarCard && submission.data.aadhaarCard.length &&
                                        submission.data.aadhaarCard[0] && submission.data.aadhaarCard[0].url;
                                    backImageUrl = submission.data && submission.data.aadhaarCardBack && submission.data.aadhaarCardBack.length &&
                                        submission.data.aadhaarCardBack[0] && submission.data.aadhaarCardBack[0].url;
                                }
                                
                                if (submissionData.permanentAddressHouseNumber && submissionData.permanentAddressStreet &&
                                        submissionData.permanentAddressCity && submissionData.permanentAddressState &&
                                        submissionData.permanentAddressPincode && submissionData.permanentAddressLandmark &&
                                        frontImageUrl && frontImageUrl) {
                                    const response = await updateZomatoData({
                                        phoneNumber: lead.phoneNumber,
                                        dataToUpdate: {
                                            updateAddressDetails: {
                                                houseNumber: submissionData.permanentAddressHouseNumber,
                                                street: submissionData.permanentAddressStreet,
                                                city: submissionData.permanentAddressCity,
                                                state: submissionData.permanentAddressState,
                                                pincode: submissionData.permanentAddressPincode ,
                                                landmark: submissionData.permanentAddressLandmark,
                                                frontImageUrl,
                                                backImageUrl
                                            }
                                        }
                                    });
                                    console.log('final update address res-----', response);
                                }
                            } else {
                                console.log(submission.data.kycStatus, 'submission.data.kycStatus');
                                if(submission.data.kycStatus && submission.data.kycStatus === 'Not Verified') {
                                    message.warn('Complete the verification', 5);
                                    showMessage = true;
                                }
                            }



                            const res = await postAdditionalInfo(submissionData, mitra, lead);
                            for (let key in res.data) {
                                if (res.data[key] !== "200") {
                                    errors.push(`${key}: ${res.data[key]}`)
                                }
                            }

                        } catch (error) {
                            errors = error.response ? [error.response.data.message] : ["something went wrong"]
                        }
                    }
                    console.log("errors", errors)
                } else {
                    try {
                        console.log('before next--else-');

                        const res = await postAdditionalInfo(submission.data, mitra, lead)
                        for (let key in res.data) {
                            if (res.data[key] !== "200" && !['sameAsAboveOfCurrentAddress'].includes(key)) {
                                errors.push(`${key}: ${res.data[key]}`)
                            }
                        }
                    } catch (error) {
                        errors = error.response ? [error.response.data.message] : ["something went wrong"]
                    }
                }


                if (errors.length <= 0) {
                    console.log(showMessage, "showMessage")
                    if (!showMessage) {
                        await fetchInfo()
                        console.log('----seting via next---');
                        setViaNext(true);
                        // setViaPrev(false);
                        console.log('---- next page---');
                        next()
                    }else{
                        next([]);
                    }
                } else {
                    alert(errors.join("\n"))
                    if(lead?.companyName.toLowerCase().indexOf('zepto') == -1){
                        next([])
                    }
                }
            },
            // beforeSubmit: async (submission, next) => {
            //      if(lead.companyName.toLowerCase().indexOf('zepto_3pl') !== -1){
            //         let errors = [];
            //         try {
            //             // const lead = JSON.parse(localStorage.getItem('lead'))
            //             const createApplicationRes = await createApplication(submission.data, mitra, lead)
            //             if (createApplicationRes.data.createApplication !== "200") {
            //                 errors.push(createApplicationRes.data.createApplication)
            //             } else {
            //                 //await uniquenessCheck(lead);
            //                 trackApplication(lead, mitra.id)
            //                 setShowPrompt(val=>false);
            //                 return goToLeadsPage();
            //             }
            //         } catch (error) {
            //             if (error.response)
            //                 errors = error.response.data.message ? [error.response.data.message] : ["something went wrong"]
            //         }

            //         if (errors.length > 0) {
            //             alert(errors.join("\n"))
            //         }
            //         next()
            //     }
            //     next()
                //     let errors = [];
                //     try {
                //         const route = props.location.route
                //         const res = await postCandidateDocuments(submission.data, mitra, lead, route)
                //         console.log(res)
                //         for(let key in res.data){
                //             if(res.data[key]!== "200")
                //                 errors.push({message: res.data[key]})
                //         }
                //     } catch (error) {
                //         // console.log(error)
                //         errors = error.response ? [{message: error.response.data.message}]: [{message: "something went wrong"}]
                //     }

                //     if(errors.length > 0 ){
                //         next(errors)
                //     }
                //     else{
                //         history.replace("/leads/all-candidates")
                //     }

            // },

            beforeCancel: () => {
                if (window.confirm('Are you sure you want to cancel this form?')) {
                    goToLeadsPage();
                }
            }
        },
        breadcrumbSettings: {
            clickable: false
        }
    }

    const trackApplication = (obj, id) => {
        // blitz.triggerEvent('single_application_created');
        trackEvent('single_application_created', {
            client_name: obj.clientPreference,
            application_city_name: obj.cityPreference,
            source_id: id
        }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }
    function getPage() {
        return parseInt(localStorage.getItem('formioPage'), 10) || 0;
    }
    function setPage(page) {
        localStorage.setItem('formioPage', page);
    }
    const fetchForm = useCallback(async (formURL) => {
        Formio.createForm(document.getElementById("form-io"), formURL, options)
            .then(async function (form) {
                let verificationPanel, submitButton;
                if(lead && lead.companyName.toLowerCase().indexOf('uber') !== -1){
                    if(lead && lead.scheduledInterviewId && lead.scheduledInterviewId.toLowerCase() !== 'null'){
                        let DlNumberPanel = document.getElementsByClassName("form-control")[0];
                        DlNumberPanel.disabled = true;
                    }
                }

                if (lead && lead.companyName.toLowerCase().indexOf('rapido_3pl') !== -1 && rollOutStaffingMilestoneVisibility(mitraReducer?.mitraInfo)) {
                    if (lead && lead.scheduledInterviewId && lead?.meta) {
                        if (!lead?.meta?.rapidoMilestone?.captainId && lead?.meta?.mitraAppActivated) {
                            setTimeout(() => {
                                const sendOtpPanel = document.getElementsByClassName("formio-component-sendOtpAndCheckUniqueness")?.[0];
                                if (sendOtpPanel)
                                    sendOtpPanel.style.display = 'none';
                            }, 500);
                        }
                        else
                        {
                            if (!otpNotificationShowed && !lead?.meta?.mitraAppActivated) {
                                notification['info']({
                                    message: 'OTP Verification will be unlocked once the Vahan Activation is done.',
                                    duration: 6
                                });
                                setOtpNotificationShowed(true);
                            }
                            await form.setPage(1);
                        }
                    }
                    else {
                        notification.destroy();
                        if (!otpNotificationShowed && !lead?.meta?.mitraAppActivated) {
                            notification['info']({
                                message: 'OTP Verification will be unlocked once the Vahan Activation is done.',
                                duration: 6
                            });
                            setOtpNotificationShowed(true);
                        }
                        await form.setPage(1);               
                    }
                }

                if(lead && lead.companyName.toLowerCase().indexOf('rapido') !== -1 && lead?.companyName?.toLowerCase().indexOf('3pl') === -1)
                {
                    if(lead && lead.scheduledInterviewId && lead?.meta)
                    {
                        if(!lead?.meta?.rapidoMilestone?.captainId)
                        {
                            const uploadDocumentOptions = document.getElementsByClassName("form-check-input");
                            if (uploadDocumentOptions && uploadDocumentOptions.length) {
                                for (let radioButton = 0; radioButton < 2; radioButton++) {
                                    uploadDocumentOptions[radioButton].disabled = true;
                                }                             
                            }    
                            setTimeout(() => {
                                const sendOtpPanel = document.getElementsByClassName("formio-component-sendOtpAndCheckUniqueness")?.[0];
                                if (sendOtpPanel)
                                    sendOtpPanel.style.display = 'none';
                            }, 500);    
                        }
                        else
                            await form.setPage(1);
                    }
                    else
                    {
                        verificationPanel = document.getElementsByClassName("formio-component-otpVerification")[0];
                        // FIXME: to be removed after upload document implementation
                        submitButton = document.getElementsByClassName("btn-wizard-nav-submit")[0];
                        if (verificationPanel)
                            verificationPanel.style.display = 'none';
                        if (submitButton)
                            submitButton.style.display = 'none';
                    }
                }
                //console.log("lead:>>", lead)
                //console.log("currentLeadScheduledInterviewId:>>", currentLeadScheduledInterviewId)
                if(lead && lead.companyName.toLowerCase().indexOf('zepto') !== -1){
                    if(lead && lead.scheduledInterviewId && lead.scheduledInterviewId.toLowerCase() !== 'null' && currentLeadScheduledInterviewId == null){ // case 1: Opened from edit view Details
                        if(!lead?.meta?.zeptoMilestone?.isVendorReferringFormFilled && !isVendorReferringFormFilled) {
                            console.log('---taking to form 1----');
                            await form.setPage(0);
                            setPage(0);
                        }
                        else if(lead?.meta?.formTwoCompleted != null){ // if form 2 already filled.
                            await form.setPage(1);
                            setPage(1);
                        }
                    }
                    else if(currentLeadScheduledInterviewId !== null){ // case 2: Continue to doc upload on page 3
                        await form.setPage(2);
                        setPage(2);
                    }
                    else { // case 3: New application or Incomplete application (SI not created yet)
                        await form.setPage(0);
                        setPage(0);
                    }
                }

                if(lead && lead.companyName.toLowerCase() === 'zomato'){

                    console.log('check zomato fetch form --', lead);
                    // if(lead && lead.scheduledInterviewId && lead.scheduledInterviewId.toLowerCase() !== 'null') {
                        if(lead) {
                        console.log('check zomato if--', isOtpVerified);
                        // @todo --check for active token
                        // startedOptCheck = isOtpVerified ? true : startedOptCheck;
                        console.log(getPage(), 'fetching form current page firstLoad', isOtpVerified, startedOptCheck);
                        
                        if (!isOtpVerified && !startedOptCheck) {
                            startedOptCheck = true;
                            const response = await validateZomatoUser(lead);
                            console.log('zomato--status--res--firstLoad',response);
                            if (response.data && response.data.statusCode == 200) {
                                    isOtpVerified = true;
                                    await setPage(1);
                                    await fetchInfo();
                                    // await form.setPage(1);
                                message.success("Earlier Entered OTP is valid", 4)

                            } else {
                                message.warn("OTP Not Verified. Please complete OTP verification", 4)
                                await setPage(0);
                                // await form.setPage(0);
                            }
                            console.log(getPage(), 'page set')
                        }

                        if (getPage() === 2) {
                            console.log('---in--verification--page');
                            const verifiedNameField = document.getElementsByName("data[kycStatus]")?.[0];
                            console.log({verifiedNameField});
                            console.log({data: form.data}, '---in page');

                        }



                        // else if (true) {
                        //     console.log(getPage(), 'new set Page');
                        //     await form.setPage(5);
                        //     setPage(5);
                        //     // console.log(getPage(), 'new set Page');
                        // }
                    }
                }


                form.on("sendOtp", async (submission) => {
                    const sendOtpPanel = document.getElementsByClassName("formio-component-sendOtpAndCheckUniqueness")?.[0];
                    const uploadDocumentOptions = document.getElementsByClassName("form-check-input");
                    const sendOtpButton = document.getElementsByName("data[sendOtp]")?.[0];
                    if (sendOtpButton) {
                        sendOtpButton.disabled = true;
                        // sendOtpButton.innerHTML = "OTP Sent";
                        sendOtpButton.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh fa-spin button-icon-right"></i>`)
                    }
                    if (uploadDocumentOptions && uploadDocumentOptions.length) {
                        for (let radioButton = 0; radioButton < 2; radioButton++) {
                            uploadDocumentOptions[radioButton].disabled = true;
                        }
                    }
                    let errors = [];
                    try {
                        // const checkAlreadyReferred = await checkForScheduledInterview(lead);
                        // if(checkAlreadyReferred.data.checkForScheduledInterview === "200"){
                        if (lead && lead.siMetaData) {
                            alert("You have already referred the candidate. Please move to the upload document step. (Press Next)")
                            // sendOtpButton.innerHTML = "OTP previously verified";
                        }
                        else {
                            // const res = await postAdditionalInfoForRapido(submission.data, mitra, lead)
                            // for (let key in res.data) {
                            //     if (res.data[key] !== "200")
                            //     {
                            //         errors.push(`${key}: ${res.data[key]}`)
                            //         if((lead.companyName).toLowerCase().indexOf('rapido') !== -1)
                            //         {
                            //             history.replace("/leads/all-candidates");
                            //         }
                            //     }
                            // }
                            const createApplicationRes = await createApplication(submission, mitra, lead)
                            if (createApplicationRes.data.createApplication !== "200") {
                                errors.push(createApplicationRes.data.createApplication)
                            }
                            if (createApplicationRes.data.uniqueness !== "200") {
                                alert('Your Candidate is not Unique!!');
                                setShowPrompt(()=>false);
                                goToLeadsPage();
                            }
                            else {
                                if (sendOtpButton) {
                                    sendOtpButton.removeChild(sendOtpButton.lastChild)
                                }
                                trackApplication(lead, mitra.id)
                                sendOtpButton.innerHTML = "OTP Sent";
                                if (verificationPanel) {
                                    verificationPanel.style.display = 'block';
                                    sendOtpPanel.style.display = 'none';
                                }
                                // alert("Please Enter The OTP sent to Candidate's Phone Number.");    
                                message.success("Please Enter The OTP sent to Candidate's Phone Number.", 6);
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        errors = error.response ? [error.response.data.message] : ["something went wrong"]
                    }
                    if (errors.length > 0)
                        alert(errors.join("\n"))
                })
                form.on("verifyOtp", async (submission) => {
                    console.log(submission, '---submission---');
                    const verifyOtpButton = document.getElementsByName("data[verifyZomatoOtp]")?.[0]
                    if (verifyOtpButton) {
                        verifyOtpButton.disabled = true;
                        verifyOtpButton.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh fa-spin button-icon-right"></i>`)
                    }
                    try {
                        if(verifyOtpCount >=3)
                        {
                            message.warn("You have exceeded the maximum number of attempts.",6);
                            setShowPrompt(()=>false);
                            goToLeadsPage();
                        }
                        else if((!submission.sendOtp || submission.sendOtp === false) && !lead?.meta)
                        {
                            // alert("Please send the OTP first");
                            message.warn("Please send the OTP first", 4);
                            verifyOtpButton.disabled = false;
                        }
                        else if (!submission.otp || submission.otp.toString().indexOf('_') !== -1) {
                            message.warn("Please enter the OTP first", 4);
                            verifyOtpButton.disabled = false;
                        }
                        else {
                            const response = await verifyOtp(submission, mitra, lead);
                            if (response.data.verifyOtp === "200") {                                // await submitApplication(basicDetails, additionalInfo, mitra);
                                // verifyOtpButton.innerHTML = "OTP Verified"
                                // @todo --correct class name
                                submitButton = document.getElementsByClassName("btn-wizard-nav-submit");
                                message.success("Your Candidate has been verified. Please Submit form to attribute the candidate.", 8);
                                isOtpVerified = true;
                                const resendOtpButton = document.getElementsByName("data[resendOtp]")?.[0];
                                if(resendOtpButton)
                                    resendOtpButton.style.display = 'none';
                                if (submitButton)
                                    submitButton.style.display = 'block';
                            }
                            else
                            {
                                message.error('Please Enter correct OTP',4);
                                verifyOtpButton.disabled = true;
                                verifyOtpCount++;
                            }
                        }
                        verifyOtpButton.removeChild(verifyOtpButton.lastChild)
                    } catch (error) {
                        console.log(error);
                        // errors = error.response ? [error.response.data.message] : ["something went wrong"]
                    }
                })
                form.on("resendOtp", async (submission) => {
                    const sendOtpButton = document.getElementsByName("data[resendOtp]")?.[0];
                    if (sendOtpButton) {
                        sendOtpButton.disabled = true;
                        sendOtpButton.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh fa-spin button-icon-right"></i>`)
                    }
                    try {
                        const response = await sendOtp(submission, mitra, lead);
                        if (response.status == "200") {
                            message.success("OTP Resent Successfully", 4);
                        }
                        else {
                            notification['error']({
                                message: 'Candidate is not Unique!',
                            });
                            setShowPrompt(()=>false);
                            goToLeadsPage();
                        }
                    } catch (error) {
                        notification['error']({
                            message: 'Candidate is not Unique!',
                        });
                        setShowPrompt(()=>false);
                        goToLeadsPage();
                    }
                    if (sendOtpButton) {
                        sendOtpButton.removeChild(sendOtpButton.lastChild)
                        sendOtpButton.disabled = false;
                    }
                })    
                form.on("change", async (submission) => {
                    const key = submission.changed ? submission.changed.component.key : undefined
                    if (key && submission.data[key] && Array.isArray(submission.data[key]) && submission.data[key].length > 0 && submission.data[key][0].data) {
                        submission.data[key][0].url = submission.data[key][0].data.imageUrl
                        form.submission = {
                            data: submission.data
                        }
                        let dummyData = { onBoardingPreference: submission.data['onBoardingPreference'] }
                        dummyData[key] = submission.data[key];
                        if (document.querySelector('.btn-wizard-nav-submit'))
                            document.querySelector('.btn-wizard-nav-submit').disabled = true;
                        let errorStatus = await validateFile({ data: dummyData });
                        if (errorStatus) {
                            const div = document.querySelector('.formio-component-' + key);
                            div.querySelector('.fa-remove').click()
                        }
                        if (document.querySelector('.btn-wizard-nav-submit'))
                            document.querySelector('.btn-wizard-nav-submit').disabled = false;
                    }
                    if(lead && lead.companyName.toLowerCase().indexOf('zepto') !== -1){
                        submitButton = document.getElementsByClassName("btn-wizard-nav-submit")[0];
                        if (submitButton && submitButton.style){
                                setPage(2);
                                if(docFetch){
                                    setDocFetch(false);
                                    await fetchInfo();
                                }
                                submitButton.style.display = 'none';
                        }
                        
                    }
                })
                form.on("submit", async (submission) => {
                    await updateOrInsertEntryForTCAccess(mitra, lead);
                    console.log("TRIGGERED FORM.ON SUBMIT")
                    setSpin({
                        loading: true, //false or true when actived
                        delay: null,
                        tip: 'Uploading documents please wait ...'  //Loading copy here
                      });
                    const submitButtomEl = document.querySelector(".btn-wizard-nav-submit")
                    if (submitButtomEl) {
                        submitButtomEl.disabled = true;
                        submitButtomEl.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh fa-spin button-icon-right"></i>`)

                    }
                    let errors = [];
                    // remove validation

                    // try {
                    //     const res = await postCandidateDocuments(submission.data, mitra, lead)
                    //     for(let key in res.data){
                    //         if(res.data[key]!== "200"){
                    //             let errMsg = res.data[key].split("Error: ").join("").split("500: ").join("")
                    //             const attemptsIndex = errMsg.indexOf("Attempt: ")

                    //             if(attemptsIndex > -1){
                    //                 errMsg = errMsg.split("Attempt: ").join("")
                    //                 const attempts = errMsg.split(" ")[0]
                    //                 if(!isNaN(attempts * 1) && attempts > 3 && docsSubmitCount >=3){}
                    //                 else{
                    //                     errMsg = errMsg.slice(errMsg.indexOf(" ") + 1)
                    //                     errors.push(`${key}: ${errMsg}`)
                    //                 }
                    //             }
                    //             else{
                    //                 errors.push(`${key}: ${errMsg}`)
                    //             }
                    //         }
                    //     }
                    // } catch (error) {
                    //     console.log(error)
                    //     if(error.response)
                    //         errors = error.response.data.message ? [error.response.data.message]: ["something went wrong"]
                    //     else
                    //         errors = ["something went wrong"]
                    // }
                    if (errors.length <= 0) {
                        if (lead.companyName.toLowerCase().indexOf('rapido') !== -1 && (lead.companyName.toLowerCase().indexOf('3pl') === -1 || lead?.meta?.rapidoMilestone?.captainId || (rollOutStaffingMilestoneVisibility(mitraReducer?.mitraInfo) && lead?.meta?.mitraAppActivated))) {
                            await postAdditionalInfoForRapido(submission.data, mitra, lead);
                            await submitApplication(submission.data, lead, mitra);
                            setShowPrompt(()=>false);
                            return goToLeadsPage();
                        }  
                        const route = props.location.route
                        console.log("posting submission data to backend DB")
                        if(lead && lead.companyName.toLowerCase().indexOf('zepto') !== -1){
                            const response = await getScheduledInterviewIdForStaffingClients({"submissionData": submission.data,"lead": lead, "mitra": mitra})
                            const scheduledInterviewId = response?.data[0]?.id
                            if(scheduledInterviewId){
                                //const response = await isAllRequiredDocumentsPresent(scheduledInterviewId);
                            }
                            submission.data = {...submission.data, zeptoFormThreeCompleted: scheduledInterviewId}
                        }


                        if(lead && lead.companyName.toLowerCase() === 'zomato') {
                            // upload selfie submission
                            try {
                                delete submission.data.rawData;
                                console.log(submission.data, '---selfie--submission');
                                delete submission.data.rawData;
                                const selfieUrl = submission.data && submission.data.passportPhoto && submission.data.passportPhoto.length &&
                                submission.data.passportPhoto[0] && submission.data.passportPhoto[0].url;
                                // update zomato
                                if(selfieUrl) {
                                    const response = await updateZomatoData({
                                        phoneNumber: lead.phoneNumber,
                                        dataToUpdate: {
                                            uploadSelfie: {
                                                imageUrl: selfieUrl
                                            }
                                        }
                                    });
                                    console.log('update selfie res-----', response);
                                }
                                console.log('-------ZOMATO APPLICATION SUBMITTED---------');
                            } catch (error) {
                                errors = error.response ? [error.response.data.message] : ["something went wrong"]
                            }
                        }

                        await postCandidate(submission.data, mitra, lead)
                        if (!(route && route === "jobRecommendationPage")) {
                            if (lead && ((checkUniquenessForUber && lead.companyName.toLowerCase().indexOf('uber') !== -1) || lead.companyName.toLowerCase().indexOf('shadowfax') !== -1)) {
                                const jobDetails = {
                                    companyName: lead.companyName,
                                    jobid: lead.jobId,
                                    jobdemandid: lead.jobDemandId,
                                    ...((lead.companyName).toLowerCase().includes('shadowfax') ? {
                                        aadhaarNumber: submission.data.aadhaarNumber,
                                        bankAccountNumber: submission.data.bankAccountNumber,
                                        bankName: submission.data.bankName,
                                        bankIfscCode: submission.data.bankIfscCode,
                                        cityPreference: lead.companyCity,
                                        uploadShadowfaxDocs: submission.data.uploadShadowfaxDocs
                                    } : {})
                                }
                                const basicDetails = {
                                    name: lead.name,
                                    phoneNumber: lead.phoneNumber,
                                    cityPreference: lead.companyCity,
                                }
                                await uniquenessCheck(jobDetails, basicDetails, mitra);
                            }
                            setShowPrompt(()=>false);
                            return goToLeadsPage();
                        }          
                        try {
                            // const lead = JSON.parse(localStorage.getItem('lead'))
                            if(lead?.companyName.toLowerCase().indexOf('zepto') !== -1){
                                console.log("Submit triggered!, not creating application for zepto")
                                setShowPrompt(()=>false);
                                return goToLeadsPage();
                                //submission.data = {...submission.data, vehicleType: submissionData.vehicleType, aadhaarNumber: submissionData.aadhaarNumber}
                            }

                            const createApplicationRes = await createApplication(submission.data, mitra, lead)
                            if (createApplicationRes.data.createApplication !== "200") {
                                errors.push(createApplicationRes.data.createApplication)
                            } else {
                                //await uniquenessCheck(lead);
                                trackApplication(lead, mitra.id)
                                setShowPrompt(()=>false);
                                return goToLeadsPage();
                            }
                        } catch (error) {
                            if (error.response)
                                errors = error.response.data.message ? [error.response.data.message] : ["something went wrong"]
                        }
                    }
                    docsSubmitCount++
                    alert(errors.join("\n"))
                    if (submitButtomEl) {
                        submitButtomEl.disabled = false;
                        submitButtomEl.removeChild(submitButtomEl.lastChild)
                    }


                })

                form.on("sendZomatoOtp", async (submission) => {
                    console.log('sendZomatoOtp');
                    const sendOtpPanel = document.getElementsByClassName("formio-component-sendZomatoOtpContainer")?.[0];
                    const verificationPanel = document.getElementsByClassName("formio-component-otpVerification")?.[0];
                    const sendOtpButton = document.getElementsByName("data[sendZomatoOtp]")?.[0];
                    if (sendOtpButton) {
                        sendOtpButton.disabled = true;
                        // sendOtpButton.innerHTML = "OTP Sent";
                        sendOtpButton.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh fa-spin button-icon-right"></i>`)
                    }

                    let errors = [];
                    try {
                        const response = await sendZomatoOtp(submission, mitra, lead);
                        console.log(response, '-----res---', response.status === 200);
                        if (response.status === 200) {
                            if (sendOtpButton) {
                                sendOtpButton.removeChild(sendOtpButton.lastChild)
                            }
                            trackApplication(lead, mitra.id)
                            sendOtpButton.innerHTML = "OTP Sent";
                            if (verificationPanel) {
                                verificationPanel.type = '';
                                verificationPanel.style.display = 'block';
                                sendOtpPanel.style.display = 'none';
                            }
                            // alert("Please Enter The OTP sent to Candidate's Phone Number.");    
                            message.success("Please Enter The OTP sent to Candidate's Phone Number.", 6);
                        }
                    } catch (error) {
                        console.log(error);
                        errors = error.response ? [error.response.data.message] : ["something went wrong"]
                    }
                    if (errors.length > 0)
                        alert(errors.join("\n"))
                })

                form.on("verifyZomatoOtp", async (submission) => {
                    console.log(submission, '---submission---');
                    const verifyOtpButton = document.getElementsByName("data[verifyZomatoOtp]")?.[0]
                    if (verifyOtpButton) {
                        verifyOtpButton.disabled = true;
                        verifyOtpButton.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh fa-spin button-icon-right"></i>`)
                    }
                    try {
                        if(verifyOtpCount >=3)
                        {
                            message.warn("You have exceeded the maximum number of attempts.",6);
                            setShowPrompt(()=>false);
                            goToLeadsPage();
                        }
                        else if((!submission.sendZomatoOtp || submission.sendZomatoOtp === false) && !lead?.meta)
                        {
                            // alert("Please send the OTP first");
                            message.warn("Please send the OTP first", 4);
                            verifyOtpButton.disabled = false;
                        }
                        else if (!submission.otp || submission.otp.toString().indexOf('_') !== -1) {
                            message.warn("Please enter the OTP first", 4);
                            verifyOtpButton.disabled = false;
                        }
                        else {
                            const response = await verifyZomatoOtp(submission, mitra, lead);
                            console.log(response.data && response.data.status , response.data.status.code == "200", 'verify--otp--response--');
                            if (response.data && response.data.status && response.data.status.code == "200") {                                // await submitApplication(basicDetails, additionalInfo, mitra);
                                // verifyOtpButton.innerHTML = "OTP Verified"
                                message.success("OTP has been verified.", 8);
                                isOtpVerified = true;
                                const resendOtpButton = document.getElementsByName("data[resendZomatoOtp]")?.[0];
                                if(resendOtpButton)
                                    resendOtpButton.style.display = 'none';
                                if (submitButton)
                                    submitButton.style.display = 'block';
                            } else if (response.data && response.data.status && response.data.status.code === 400 &&
                                response.data.status.message) {
                                message.error(response.data.status.message,4);
                                verifyOtpButton.disabled = true;
                                verifyOtpCount++;
                            }else {
                                message.error('OTP Not Verified. Something Went Wrong',4);
                                verifyOtpButton.disabled = true;
                                verifyOtpCount++;
                            }
                        }
                        verifyOtpButton.removeChild(verifyOtpButton.lastChild)
                    } catch (error) {
                        console.log(error);
                        // errors = error.response ? [error.response.data.message] : ["something went wrong"]
                    }
                })

                
                form.on("digiLockerVerification", async (submission) => {
                    console.log( '---create digiLockerUrl---');
                    try {
                        const response = await getZomatoDigiLockerVerificationUrl(lead);
                        if(response && response.data && response.data.statusCode == '200' && response.data.redirectUrl); {
                            console.log('ready to redirect---');
                            // showDigiLockerContainer = true;
                            // setShowDigiLockerContainer(true);
                            // setDigiLockerUrl(response.data.redirectUrl);
                            // console.log(`${response.data.redirectUrl}`, 'url');
                            const getUrlBtn = document.getElementsByName("data[gotoDigiLockerVerification]")?.[0];
                            const getCheckStatusBtn = document.getElementsByName("data[checkVerificationStatus]")?.[0];
                            getCheckStatusBtn.innerHTML = `Check Verification Status`;
                            getUrlBtn.style.display = 'none';
                            window.open(`${response.data.redirectUrl}`, "_blank");
                        }

                    } catch (error) {
                        console.log(error);
                        // errors = error.response ? [error.response.data.message] : ["something went wrong"]
                    }

                })
                
                form.on("deleteDigiLockerVerification", async (submission) => {
                    try {
                        const response = await getZomatoDigiLockerVerificationUrl(lead);
                        if(response && response.data && response.data.statusCode == '200' && response.data.redirectUrl); {
                            console.log('ready to redirect---');
                            // showDigiLockerContainer = true;
                            // setShowDigiLockerContainer(true);
                            // setDigiLockerUrl(response.data.redirectUrl);
                            // console.log(`${response.data.redirectUrl}`, 'url');
                            const getCheckStatusBtn = document.getElementsByName("data[checkVerificationStatus]")?.[0];
                            const deleteBtn = document.getElementsByClassName("formio-component-deleteKycVerification")?.[0];
                            const verifiedNameField = document.getElementsByName("data[kycStatus]")?.[0];
                            console.log(getCheckStatusBtn, 'getCheckStatusBtn-----------');
                            getCheckStatusBtn.innerHTML = `Check Verification Status`;

                            getCheckStatusBtn.style.display = 'block';
                            deleteBtn.style.display = 'none';
                            if (verifiedNameField) {
                                const verificationString = `Not Verified`;
                                verifiedNameField.value = verificationString;
                                submission.kycStatus = verificationString;
                                verifiedNameField.value = 'Not Verified';
                            }
                            window.open(`${response.data.redirectUrl}`, "_blank");
                        }

                    } catch (error) {
                        console.log(error);
                        // errors = error.response ? [error.response.data.message] : ["something went wrong"]
                    }

                })
                form.on("getKycStatus", async (submission) => {
                    try {
                        const getUrlBtn = document.getElementsByClassName("formio-component-gotoDigiLockerVerification")?.[0];
                        const getCheckStatusBtn = document.getElementsByClassName("formio-component-checkVerificationStatus")?.[0];
                        const verifiedNameField = document.getElementsByName("data[kycStatus]")?.[0];
                        const deleteBtn = document.getElementsByName("data[deleteKycVerification]")?.[0];
                        console.log(verifiedNameField, 'verifiedNameField-----');
                        const statusBtn = document.getElementsByName("data[checkVerificationStatus]")?.[0];
                        if (verifiedNameField.value === 'Not Verified') {
                            const response = await getKycStatus(lead);
                            if(response && response.data && response.data.statusCode == '200' && response.data.aadharStatus === 'COMPLETED') {
                                // verifiedNameField.show = true;
                                // verifiedNameField2.display = 'block';
                                
                                // getCheckStatusBtn.style.disabled = true;
                                const verificationString = `Verified Aadhar -> ${response.data.aadharCardName} | ${response.data.maskedAadharNumber}`;
                                verifiedNameField.value = verificationString;
                                submission.kycStatus = verificationString;
                                
                                if(statusBtn) {
                                    statusBtn.innerHTML = `Aadhar Verification Completed`;
                                    statusBtn.insertAdjacentHTML("beforeend", `<i class="fa fa-check button-icon-right"></i>`)

                                }
                                if (deleteBtn) {
                                    deleteBtn.style.display = 'block';
                                }
    
                                getCheckStatusBtn.style.display  = 'block';
                                message.success("KYC is Completed.", 8)
                            } else {
                                statusBtn.innerHTML = `KYC Not Completed - Re-Check`;
                                statusBtn.insertAdjacentHTML("beforeend", `<i class="fa fa-refresh button-icon-right"></i>`)
                                message.warn("KYC Not Completed", 8)
                            }
                        } else {
                            statusBtn.innerHTML = `Aadhar Verification Completed`;
                            statusBtn.insertAdjacentHTML("beforeend", `<i class="fa fa-check  button-icon-right"></i>`)
                            message.success("Already Verified", 8)

                        }

                        // if (response.data.verifyOtp === "200") {                                // await submitApplication(basicDetails, additionalInfo, mitra);
                        //     // verifyOtpButton.innerHTML = "OTP Verified"
                        //     // @todo --correct class name
                        //     submitButton = document.getElementsByClassName("btn-wizard-nav-submit");
                        //     message.success("Your Candidate has been verified. Please Submit form to attribute the candidate.", 8);
                        //     isOtpVerified = true;
                        //     const resendOtpButton = document.getElementsByName("data[resendOtp]")?.[0];
                        //     if(resendOtpButton)
                        //         resendOtpButton.style.display = 'none';
                        //     if (submitButton)
                        //         submitButton.style.display = 'block';
                        // }
                        // else
                        // {
                        //     message.error('Please Enter correct OTP',4);
                        //     verifyOtpButton.disabled = true;
                        //     verifyOtpCount++;
                        // }
                    } catch (error) {
                        console.log(error);
                        // errors = error.response ? [error.response.data.message] : ["something went wrong"]
                    }

                })

                form.submission = sub
                setTimeout(() => {
                    if (viaNext && lead?.companyName.toLowerCase().indexOf('zepto') !== -1){ // add changes only for zepto
                        console.log("current page before moving:", getPage());
                        console.log("moving to next page, (just after form.on('submit'))")
                        if(getPage() == 0){
                            form.setPage(1);
                            setPage(1);
                        }
                        else if(getPage() == 1){
                            form.setPage(2);
                            // setPage(2);

                        }
                    } else if ( lead?.companyName.toLowerCase() == 'zomato') { // add changes only for zomato
                        console.log('---inside setTimeout zomato', {isOtpVerified, citySubmitted});
                        if(viaNext) {
                            if (!isOtpVerified) {
                                setPage(0);  
                                form.setPage(0);
                            } else if (!citySubmitted) {
                                const currentPage = Number(getPage());
                                form.setPage(currentPage + 1);
                                setPage(currentPage + 1);
                            }
                        } else if (getPage() === 1) {
                            form.setPage(1);
                        }
                        
                    }
                    else if(viaNext){ // keep this for other companies as before
                        form.setPage(1);
                    }
                    console.log('---inside setTimeout zomato End ', {isOtpVerified, citySubmitted});
                }, 10)

                setTimeout(() => {
                    document.querySelectorAll("img").forEach(el => {
                        el.addEventListener("click", (e) => {
                            window.open(e.target.getAttribute("src"))
                        })
                    })
                }, 1000)
            })
    }, [options, props, history, lead, mitra, viaNext])

    const fetchInfo = async () => {
        const populatedRes = await getCandidateInfo(lead.phoneNumber)

        console.log('fetching info-----', populatedRes);

        const populatedData = {}
        let zomatoCityData = [];
        let zomatoZoneData = [];
        
        let verificationStatus = null;
        populatedData.rawData = {};
        populatedData.rawData.phoneNumber = lead.phoneNumber;
        populatedRes.data.forEach(el => {
            try {
                const parsedValue = JSON.parse(el.value)
                if (typeof parsedValue === "number")
                    populatedData[el.type] = `${parsedValue}`
                else
                    populatedData[el.type] = [{ ...parsedValue, originalName: parsedValue.url, storage: "url" }]
            } catch (error) {
                populatedData[el.type] = el.value
            }
        })

        try {
            if(lead?.companyName?.toLowerCase() === 'zomato') {
                console.log(getPage(), '--fetch--info--');
                if (getPage() === 1) {
                    const zomatoVehicleData = await getZomatoVehicleData({phoneNumber: lead.phoneNumber});
                    console.log(zomatoVehicleData, 'zomatoVehicleData------');
                    if (zomatoVehicleData && zomatoVehicleData.data.status === 200) {
                        const vehicleType = zomatoVehicleData.data.vehicleTypeData ? zomatoVehicleData.data.vehicleTypeData : null;
                        const evDetails = zomatoVehicleData.data.evDetails ? zomatoVehicleData.data.evDetails : {};
                        if (vehicleType && vehicleType.value) {
                            populatedData.zomatoVehicleType = vehicleType.value
                        }
                        if (populatedData.zomatoVehicleType === 'Electric scooter' && evDetails) {
                            populatedData.zomatoEvType = evDetails.vehicleType ? evDetails.vehicleType : populatedData.zomatoEvType;
                            populatedData.zomatoEvOwnershipType = evDetails.ownershipType ? evDetails.ownershipType : populatedData.zomatoEvOwnershipType;
                            // populatedData.evProof = evDetails.vehicleType ? evDetails.vehicleType : populatedData.zomatoEvType;
                        } 
                    }


                    console.log('get--zomato--cities---');
                    const response = await getZomatoCitiesData({phoneNumber: lead.phoneNumber});
                    zomatoCityData = response && response.data && response.data || [];
                    console.log('ZomatoCities', zomatoCityData);
                    if (zomatoCityData && zomatoCityData.selected) {
                        populatedData.zomatoWorkCity = `${zomatoCityData.selected.id}-${zomatoCityData.selected.value}`;
                    }
                    populatedData.rawData.citiesRawData = zomatoCityData;
                }
                if (getPage() === 2) {
                    const reqData = {phoneNumber: lead.phoneNumber};
                    const savedData = populatedRes.data.find((item) => {return item.type === 'zomatoWorkCity'});
                    if (savedData && savedData.value) {
                        reqData.city = savedData.value
                    }
                    console.log({reqData}, '---fetch--zones');
                    const response = await getZomatoZonesData(reqData);
                    zomatoZoneData = response && response.data && response.data.status && response.data.status.code === 200 ? response.data : [];

                    const savedZoneData = populatedRes.data.find((item) => {return item.type === 'zomatoWorkZone'});
                    const savedZone = savedZoneData && savedZoneData.value ? savedZoneData.value : null;

                    console.log({savedZone}, '---savedZone--db');

                    const zomatoZoneMappedObj = savedZone && savedZone.allZones ? zomatoZoneData.allZones.find((zone) => {return zone.zone === savedZone}): null;
                    console.log({zomatoZoneMappedObj}, '---zomatoZoneMappedObj--db');

                    if(zomatoZoneMappedObj) {
                        populatedData.zomatoWorkZone = `${zomatoZoneMappedObj.zone} - ${zomatoZoneMappedObj.locality_list.map(zone=> zone.locality).join(', ')}`;
                    }
                    console.log(populatedData.zomatoWorkZone, '---zomatoWorkZone--db');


                    console.log('ZomatoZones', zomatoZoneData);
                    populatedData.rawData.zonesRawData = zomatoZoneData;
                    console.log({ rawZones: populatedData.rawData.zonesRawData}, '---fetch--zones---raw');

                }
                if (getPage() === 3) {
                    console.log('get--verification status---');
                    const docVerificationData = await getDocVerificationData({phoneNumber: lead.phoneNumber});
                    console.log(docVerificationData, 'docVerificationData------');
                    if (docVerificationData && docVerificationData.data.status === 200) {
                        const kycStatus = docVerificationData.data.kycStatus ? docVerificationData.data.kycStatus : {};
                        verificationStatus = kycStatus && kycStatus && kycStatus.aadharStatus === 'COMPLETED' || null;
                        populatedData.rawData.verificationStatus = verificationStatus;
                        if (verificationStatus) {
                            populatedData.zomatoVerificationType = 'DigiLocker Verification';
                            populatedData.kycStatus = `Verified Aadhar -> ${kycStatus.aadharCardName} | ${kycStatus.maskedAadharNumber}`;
                        } else {
                            populatedData.zomatoVerificationType = 'Document Upload';
                            populatedData.kycStatus = `Not Verified`
                        }
                        const panDetails = docVerificationData.data.panDetails ? docVerificationData.data.panDetails && docVerificationData.data.panDetails.data: {};
                        populatedData.panNumber = panDetails.panNumber ? panDetails.panNumber : populatedData.panNumber;
                        populatedData.fathersName = panDetails.fathersName ? panDetails.fathersName : populatedData.fathersName;
                        populatedData.gender = panDetails.gender ? panDetails.gender : populatedData.gender;

                        const addressDetails = docVerificationData.data.addressDetails ? docVerificationData.data.addressDetails && docVerificationData.data.addressDetails.data : {};
                        populatedData.permanentAddressHouseNumber = addressDetails.houseNumber ? addressDetails.houseNumber : populatedData.permanentAddressHouseNumber;
                        populatedData.permanentAddressStreet = addressDetails.street ? addressDetails.street : populatedData.permanentAddressStreet;
                        populatedData.permanentAddressCity = addressDetails.city ? addressDetails.city : populatedData.permanentAddressCity;
                        populatedData.permanentAddressState = addressDetails.state ? addressDetails.state : populatedData.permanentAddressState;
                        populatedData.permanentAddressPincode = addressDetails.pincode ? addressDetails.pincode : populatedData.permanentAddressPincode;
                        populatedData.permanentAddressLandmark = addressDetails.landmark ? addressDetails.landmark : populatedData.permanentAddressLandmark;

                        // todo -- backup populate from constants
                        populatedData.rawData.stateList = addressDetails.stateList ? addressDetails.stateList : [];
                    }
                    


                }
            }

        } catch (error) {
            console.log(error);
        }
        // populatedData["storeShiftRaw"] = storeAndShiftDetails;
        
        // let storeAndShiftDetails = {};
        // try {
        //     storeAndShiftDetails = await getStoreShiftDetails(populatedData?.firstLocalityPreference?.[0]);
        //     storeAndShiftDetails = storeAndShiftDetails?.data?.response;
        // } catch (error) {
        //     console.log(error);
        // }
        // populatedData["storeShiftRaw"] = {};

        if(lead?.companyName?.toLowerCase().indexOf('rapido') !== -1 && rollOutStaffingMilestoneVisibility(mitraReducer?.mitraInfo) ? true : lead.companyName.toLowerCase().indexOf('3pl') === -1  && (lead?.meta && !lead?.meta?.rapidoMilestone?.captainId)){
            populatedData["uniqueness"] = 'fullOnboarding'
        }
        if(lead?.companyName?.toLowerCase().indexOf('rapido') !== -1)
        {
            populatedData["vehicleNumber"] = populatedData["vehicleNumber"] ? populatedData["vehicleNumber"] : populatedData["rcNumber"]
        }

        if (populatedData?.drivingLicense?.length > 0) {
            setCheckUniquenessForUber(false);
        }
        if (populatedData["aadhaarCard"] && populatedData["aadhaarCard"][0].otherSideImageUrl)
            populatedData["aadhaarCard1"] = [{ originalName: populatedData["aadhaarCard"][0].otherSideImageUrl, size: populatedData["aadhaarCard"][0].size, url: populatedData["aadhaarCard"][0].otherSideImageUrl, storage: "url" }]

        if (populatedData["drivingLicense"] && populatedData["drivingLicense"][0].otherSideImageUrl)
            populatedData["drivingLicense1"] = [{ originalName: populatedData["drivingLicense"][0].otherSideImageUrl, size: populatedData["drivingLicense"][0].size, url: populatedData["drivingLicense"][0].otherSideImageUrl, storage: "url" }]

        if (populatedData["vehicleRc"] && populatedData["vehicleRc"][0].otherSideImageUrl)
            populatedData["vehicleRc1"] = [{ originalName: populatedData["vehicleRc"][0].otherSideImageUrl, size: populatedData["vehicleRc"][0].size, url: populatedData["vehicleRc"][0].otherSideImageUrl, storage: "url" }]
        if (lead?.companyCity.toLowerCase().indexOf('delhi') !== -1)
            populatedData['cityForBookkeeping'] = 'delhi'
        else
            populatedData['cityForBookkeeping'] = lead?.companyCity.toLowerCase();
            populatedData['selectedVehicle'] = 'Electric Vehicle';
            populatedData['phoneNumber'] = lead.phoneNumber;
        console.log(populatedData, 'populatedData-----');
        setSub({ data: populatedData })
    }

    // useEffect(() => {
    //     verifyOtpCount = 0;
    //     isOtpVerified = false;
    //     docsSubmitCount = 0;
    //     setTimeout(() => {
    //         fetchInfo()
    //     }, 800);
    useEffect(() => {
        verifyOtpCount = 0;
        isOtpVerified = false;
        docsSubmitCount = 0;
        setTimeout(() => {
            fetchInfo()
        }, 100);

        if (lead?.meta) {
            lead.meta = JSON.parse(lead.meta);
        }      
        setPage(0);
        if (lead?.companyName.toLowerCase().indexOf('rapido') !== -1 && (lead?.companyName.toLowerCase().indexOf('3pl') !== -1 && rollOutStaffingMilestoneVisibility(mitraReducer?.mitraInfo))) {
            window.onbeforeunload = function (event) {
                return event.returnValue = "Are you sure you want to exit?";
            };
            return () => {
                window.onbeforeunload = null;
            }
        }
        const companyName = (lead && lead.companyName) || '';
        trackEvent(`viewed_additional_form_page_${companyName}`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }, [])

 
    useEffect(() => {
        if (!lead || !URLEnum[lead.companyName.toLowerCase()]) {
            setShowPrompt(()=>false);
            goToLeadsPage();
        }
        else
            // if(lead.companyName !== "Dunzo" || (lead.companyCity !== "Bangalore" && lead.companyCity !== "Bengaluru" && lead.companyCity !== "Mumbai" && lead.companyCity !== "Chennai") )
            //     fetchForm(URLEnum[lead.companyName])
            // else {
            //     fetchForm(URLEnum["Dunzo_Bangalore_Mumbai"])
            // }
            options.disabled = clientBasedDisableApprovedDoc(lead.companyName,lead?.meta);
            fetchForm(URLEnum[lead.companyName?.toLowerCase().indexOf('rapido_3pl')!==-1 && rollOutStaffingMilestoneVisibility(mitraReducer?.mitraInfo)? `${lead.companyName?.toLowerCase()}_staffing` : lead.companyName?.toLowerCase()])
            setMitra(mitraReducer?.mitraInfo)
        // loginService
        //     .validateSession()
        //     .then((response) => {
        //         const loggedInMitra = response.data.mitra;
        //         // console.log(loggedInMitra)
        //         if (isSubscribed)
        //             setMitra(loggedInMitra)
        //     })
        //     .catch((error) => {
        //         history.push({
        //             pathname: '/',
        //             state: {
        //                 redirectTo: '/leads/all-candidates'
        //             }
        //         })
        //     });

        return () => { setisSubscribed(false); }

    }, [fetchForm, history, isSubscribed, lead])
    

    const askPermissionForReupload = async (errMsg, key, data) => {
        const fileName = key ? key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, function(match) { return match.toUpperCase(); }) : null;
        const reuploadFile = window.confirm(`${fileName ? fileName : ''} : Uh - Oh! High chances of rejection from client - Please upload the correct document to activate rider\n\nDo you want to proceed with the upload?`);
        if (reuploadFile) {
            await trackEvent(`Warning_document_proceeded_by_Recruiter`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            await validateFile(data, true);
        }
        else {
            const findIndex = Object.keys(data?.data).findIndex(ele => Array.isArray(data?.data[ele]));
            const div = document?.querySelector('.formio-component-' + Object.keys(data?.data)?.[findIndex]);
            if (div)
                div.querySelector('.fa-remove').click()
        }
    }

    const validateFile = async (data, allowDocUpload = false) => {
        const res = await postCandidateDocuments(data.data, mitra, lead, allowDocUpload);
        let error = false;
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                let errMsg = res.data[key].split("Error: ").join("").split("500: ").join("")
                const attemptsIndex = errMsg.indexOf("Attempt: ")
                if (attemptsIndex > -1) {
                    errMsg = errMsg.split("Attempt: ").join("")
                    const attempts = errMsg.split(" ")[0]
                    if (!(!isNaN(attempts * 1) && attempts > 3 && docsSubmitCount >= 3)) {
                        errMsg = errMsg.slice(errMsg.indexOf(" ") + 1)
                        if (lead.companyName?.toLowerCase().indexOf('zepto_3pl') !== -1)
                        {
                            await trackEvent(`Document_Rejected_Warning_Shown`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                            await askPermissionForReupload(errMsg, key, data)
                        }
                        else {
                            alert(`${key}: ${errMsg}`);
                            error = true;
                        }
                    }
                }
                else {
                    if (lead.companyName?.toLowerCase().indexOf('zepto_3pl') !== -1)
                    {
                        await trackEvent(`Document_Rejected_Warning_Shown`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
                        await askPermissionForReupload(errMsg, key, data)
                    }
                    else {
                        alert(`${key}: ${errMsg}`);
                        error = true;
                    }
                }
            }
        }
        return error;
    }

    return (
        <>
                <div className="AdditionalInfo1 mainContentZ">
            {
            //     <Prompt
            //     message={(location, action) => {
            //         return location.pathname.startsWith("/") && action !== 'REPLACE'
            //             ? `Are you sure you want to exit this page as the lead has not been attributed to you as yet?`
            //             : true
            //     }}
            // />
                <RouterPrompt
                    when={showPrompt}
                    title="Leave this page"
                    cancelText="No"
                    okText="Yes"
                    onOK={() => true}
                    onCancel={() => false}
                    promptMessage= {(lead?.companyName?.toLowerCase()?.indexOf('3pl')!==-1 || CONSTANTS.APPLICATION_CREATION_STAFFING_CLIENTS.includes(lead?.companyName?.toLowerCase()))? "Are you sure you want to leave the form?" : "The lead has not yet been referred. Are you sure you want to leave the form?"}
                />
            }

            <h2 className="heading_1 add-job">
                {lead && getDisplayableClientName(lead.companyName)} Additional Form
            </h2>
            {
                lead.companyName.toLowerCase().indexOf('uber') !== -1 && CONSTANTS.UBER_DL_CHECKER_CITIES.indexOf(lead.companyCity.toLowerCase()) === -1 ?
                    <h6 style={{textAlign: 'right', color: 'red', marginRight: '2rem'}}><br></br><b>There is no uniqueness check for the city you have chosen.<br></br>Please ask lead to proceed with next steps without uniqueness.</b></h6>
                : null
            }
            {
                lead.meta && lead.meta.uberMilestone && (Array.isArray(lead.meta.failedUberDocArray) || Array.isArray(lead.meta.notUploadedUberDocArray)) ? <Card title="Rejected Documents">
                    {lead.meta.failedUberDocArray.map(ele => {
                        return <> <Tag color="#ed1d0e" style={{
                            marginTop: '5px',
                            padding: '10px',
                            fontSize: '15px',
                            borderRadius: '10px'
                        }}>{ele.docName} - {ele.status}{(ele.docRejectReason)? ` : ${ele.docRejectReason}`:""}</Tag></> //+" - Rejected"+(ele.docRejectReason)? " : ele.docRejectReason":""
                    }
                    )}
                </Card> : null
            }
            {
                lead.meta && (lead.meta.googleFormFillErrorReason || lead.meta.zeptoRejectionReason) ? <Card title="Rejection/Error Reason">
                        {lead.meta.googleFormFillErrorReason ? <Tag color="#ed1d0e" style={{
                            marginTop: '5px',
                            padding: '10px',
                            fontSize: '15px',
                            borderRadius: '10px'
                        }}>{lead.meta.googleFormFillErrorReason}</Tag> : null}

                        {lead.meta.zeptoRejectionReason ? <Tag color="#ed1d0e" style={{
                            marginTop: '5px',
                            padding: '10px',
                            fontSize: '15px',
                            borderRadius: '10px'
                        }}>{lead.meta.zeptoRejectionReason}</Tag> : null}
                        
                </Card> : null
            }
            {
                lead.meta && lead.meta.rapidoMilestone && Array.isArray(lead.meta.rapidoMilestone.value) ? <Card title="Rejected Documents">
                    {lead.meta.rapidoMilestone.value.map(ele => {
                        if (ele.toLowerCase().includes('rejected') || ele.toLowerCase().includes('not uploaded')) return <> <Tag color="#ed1d0e" style={{
                            marginTop: '5px',
                            padding: '10px',
                            fontSize: '15px',
                            borderRadius: '10px'
                        }}>{ele}</Tag></>
                    }
                    )}
                </Card> : null
            }
            {
                lead.meta && lead.meta.shadowFaxMilestone && lead.meta.shadowFaxMilestone.value ? <Card title="Status of Previous Document Uploads">


                    {lead.meta.shadowFaxMilestone.value.map(ele => 

                       {if(!ele.toLowerCase().includes('success') )return<> <Tag color="#f50" style={{marginTop:'5px',
                       padding: '10px',
                       fontSize: '13px',
                       borderRadius: '10px'
                   }}>{ele}</Tag></>}

                    )}
                </Card> : null
            }
            {mitra && <div id="form-io"></div>}
            
            {/* {
            showDigiLockerContainer ?
            <div id="digiLocker"> <h1>Hello</h1> 
            </div> : null
            } */}
    
        </div>

        </>
    )
}


export default AdditionalInfo1;
