import React , {useState} from "react";
import { Input, Tooltip  } from 'antd';
import '../../css/siderInput.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

const SiderInput = (props,ref) => {    
    const {disabled, required,placeholder,imgsrc,labelname,suffix,value,readOnly,selector,onChange}=props;
    const [option, setoption] = useState(selector && selector[0].value)


    const onChangeOption =(valueInput)=>{
        onChange(valueInput)
        setoption(valueInput)
    }
    const selectAfter = (
        <select className="selection-value" ref={ref} onChange={onChangeOption} defaultValue={value || option}>
            {
                selector?.map(item=>{
                    return <option key={item.value}  value={item.value}>{item.label}</option>
                })
            }
        </select>
      );
      
    return (
        <React.Fragment>
            <div className="inputLabel">
                <div>
                    {required && <span className="requiredField">*</span>}
                    <label>{labelname}</label>
                </div>
                <div className="inputImg">
                    <Input
                        disabled={disabled}
                        placeholder={placeholder}
                        addonAfter={selector ? selectAfter : null} 
                        prefix={<img src={`/image/${imgsrc}`}/>}
                        defaultValue={value}
                        readOnly={readOnly}
                        ref={selector ? null : ref }
                        onChange={onChange}
                        suffix={ suffix &&
                        <Tooltip title={suffix.title}>
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>}
                    />
                </div>
           </div>
           
        </React.Fragment>
    )
}
const forwardedRef = React.forwardRef(SiderInput);

export default  forwardedRef;