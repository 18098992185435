import React from "react";
import { Button, DatePicker} from 'antd';
import { DownOutlined, InfoCircleOutlined, CloseOutlined, UserOutlined, DownloadOutlined} from '@ant-design/icons';
import FilterPopup from "../leads/Filter-popup.component";
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";
import moment from "moment";
import Moment from 'react-moment';
import useGAEventsTracker from "../../service/useGSEventsTracker";
import {useHistory} from 'react-router-dom';

const JobLeads = (props) => {
    const {RangePicker} = DatePicker;
    const {
        exportJobPostingResponseSheet,
        startDate, 
        endDate, 
        selectedCitiesOnJobPostingFilters, 
        selectedClientsOnJobPostingFilters,
        validJobPostingsClients,
        showClientFilterPopup,
        setClientShowFilterPopup,
        setValidJobPostingsClients,
        setShowBreadcrumb,
        validJobPostingsCities,
        jobPostingResponseById,
        showCityFilterPopup,
        setCityShowFilterPopup,
        setValidJobPostingsCities,
        dateFormat,
        handleDateRange,
        disabledDate,
        showDateChip,
        deleteChip,
        setShowDateChip,
        setJobLeadsTab,
        handleTabSwitchtoJobLeads,
        jobPostings,
        handleReferLead
    } = props;

    const JobsGAEventsTracker = useGAEventsTracker("jobs");
    const history = useHistory();

    return (
        <div className="jobs-page">
            <div style={{float: 'right', margin: '1%'}}>
                <Button icon={<DownloadOutlined />} danger style={{display: 'flex', alignItems: 'center'}} onClick={() => {exportJobPostingResponseSheet(startDate, endDate, selectedCitiesOnJobPostingFilters, selectedClientsOnJobPostingFilters); trackEvent('job_leads_downloaded', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);}}>
                    Download File
                </Button>
            </div>
            <h4>Jobs</h4>
            <div className="bottom-detail-wrapper">
                {
                    validJobPostingsClients.length > 0 ?
                        <FilterPopup key="f-client-validJobPostingsClients" heading="Select Client" visible={showClientFilterPopup} setShowFilterPopup={setClientShowFilterPopup} setCategory={setValidJobPostingsClients} list={validJobPostingsClients} setShowBreadcrumb={setShowBreadcrumb} />
                    : null
                }
                {
                    validJobPostingsCities.length > 0 ?
                        <FilterPopup key="f-city-validJobPostingsCities" heading="Select City" visible={showCityFilterPopup} setShowFilterPopup={setCityShowFilterPopup} setCategory={setValidJobPostingsCities} list={validJobPostingsCities} setShowBreadcrumb={setShowBreadcrumb} />
                    : null
                }
            </div>
            {jobPostingResponseById ? '' :
                <div style={{background: '#F0F2F5', padding: '1rem', textAlign: 'left'}}>
                    <div id="status-filter">
                        <RangePicker
                            defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                            value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                            format={dateFormat}
                            onChange={handleDateRange}
                            ranges={{
                                Today: [moment().startOf('day'), moment().endOf('day')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            disabledDate={disabledDate}
                            numberOfMonths={1}
                            allowClear={false}
                        />
                        <div onClick={() => {
                            setClientShowFilterPopup(true);
                            JobsGAEventsTracker("ClickOnJobsClientFilter")
                        }}
                            className="Dropdown-control filter-label">
                            <div style={{margin: 'auto 0px'}}>Client</div>
                            <DownOutlined style={{lineHeight: 'inherit', margin: 'auto 0px'}} />
                        </div>
                        <div onClick={() => {
                            setCityShowFilterPopup(true);
                            JobsGAEventsTracker("ClickOnJobsCityFilter")
                        }}
                            className="Dropdown-control filter-label">
                            <div style={{margin: 'auto 0px'}}>City</div>
                            <DownOutlined style={{lineHeight: 'inherit', margin: 'auto 0px'}} />
                        </div>
                    </div>
                    <div className="Chips-container">
                        {
                            (showDateChip) ?
                                <div className="single-chip">
                                    <span className="chip-text">
                                        <Moment format="D MMM" withTitle>{startDate}</Moment>
                                        {" - "}
                                        <Moment format="D MMM" withTitle>{endDate}</Moment>
                                    </span>
                                    <span className="chip-cross" onClick={() => {deleteChip('date'); setShowDateChip(false)}}> <CloseOutlined /> </span>
                                </div>
                                : null
                        }
                        {
                            validJobPostingsClients.map((single_client, index) => {
                                if (single_client.value) {
                                    return <div key={index} className="single-chip">
                                        <span className="chip-text">{single_client.label}</span>
                                        <span className="chip-cross" onClick={() => {deleteChip('job-posting-client', single_client)}}> <CloseOutlined /> </span>
                                    </div>
                                }
                            })
                        }

                        {
                            validJobPostingsCities.map((single_city,index) => {
                                if (single_city.value) {
                                    return <div key={index} className="single-chip">
                                        <span className="chip-text">{single_city.label}</span>
                                        <span className="chip-cross" onClick={() => {deleteChip('job-posting-city', single_city)}}> <CloseOutlined /> </span>
                                    </div>
                                }
                            })
                        }
                    </div>
                </div>
            }
            <div className="tabs-container">
                <button id="allCandidates"
                    className="tab" onClick={(e) => {setJobLeadsTab(false); history.push('/job-demands/job-requirements')}}>
                    Job Requirements
                </button>
                <button id="atRiskApplication" className="active-tab tab" onClick={(e) => {handleTabSwitchtoJobLeads();}}>
                    <InfoCircleOutlined style={{color: '#D34612', verticalAlign: 'baseline'}} />&nbsp;
                    Job Leads
                </button>
            </div>

            <div className="jobs-page job-posting-sidesheet">
                <div className="detail-element-body">
                    {/* <h6 className="content" style={{textAlign: 'left'}}><b>on {moment(dateSelected).format("DD MMM YYYY")}</b></h6> */}
                    {jobPostings.map((JobPosting, index) => (

                        <div className="card-container" style={{display: 'inline-block', textAlign: 'left'}} key={index}>
                            <div className="row">
                                <div className="contact-details col-6">
                                    <div className="lead-name">{JobPosting.name}</div>
                                    <div className="phoneNumber">{JobPosting.phoneNumber}</div>
                                </div>
                                <div className="col-6">
                                    <a
                                        rel="noopener noreferrer"
                                        href={`https://api.whatsapp.com/send?phone=+91${JobPosting.phoneNumber}&text=Dear%20${JobPosting.name}`}
                                        target="_blank"
                                    >
                                        <button>
                                            <i className="fab fa-whatsapp whatsapp-phone-icon"></i>
                                        </button>
                                    </a>
                                    &nbsp;
                                    <a
                                        href={`tel:+91-${JobPosting.phoneNumber}`}
                                    >
                                        <button>
                                            <i className="fas fa-phone whatsapp-phone-icon"></i>
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="client-logo col-6">
                                    <img className="imgResponse" alt={JobPosting.companyName} src={JobPosting.logo} />
                                    Location <br></br>
                                    <span style={{color: '#D34612', fontWeight: '600', marginLeft: '96px'}}>
                                        {JobPosting.city}
                                    </span>
                                </div>
                                <div className="col-6">
                                    <button className="viewLocations" onClick={(e) => {handleReferLead(JobPosting);}}>
                                        <UserOutlined />
                                        &nbsp;&nbsp;Refer Lead
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default JobLeads;
