import {TeamOutlined, TableOutlined, DashboardOutlined, BookOutlined, IssuesCloseOutlined,PercentageOutlined, LogoutOutlined, MenuOutlined, QuestionCircleOutlined, MenuFoldOutlined, SettingOutlined, MenuUnfoldOutlined, PieChartOutlined, DollarCircleOutlined, GroupOutlined, 
	UsergroupAddOutlined, HomeOutlined, HddOutlined,LeftOutlined,RightOutlined,BellOutlined} from '@ant-design/icons';
import React from 'react';

const CONSTANTS = {
    SALARY_FILTER_TAGS: [
        {
            "key": "0-10000",
            "label":"0-10000",
            "value":false
        },
        {
            "key": "10000-25000",
            "label":"10000-25000",
            "value":false
        },
        {
            "key": "25000-50000",
            "label":"25000-50000",
            "value":false
        },
        {
            "key": "50000-100000",
            "label":"50000-100000",
            "value":false
        },
        
    ],
    INBOX_FILTER_TAGS: [
        {
            "id": 0,
            "name": 'All Messages'
        },
        {
            "id": 1,
            "name": 'Incentives'
        },
        {
            "id": 3,
            "name": 'Payouts'
        },
        {
            "id": 4,
            "name": 'Client Process Update'
        },
        {
            "id": 5,
            "name": 'New Client Launch'
        },
        {
            "id": 6,
            "name": 'Client Demand Update'
        }, 
        {
            "id": 7,
            "name": 'New Feature Launch'
        }
    ],
    HELP_FILTER_TAGS: [
        {
            "id": 7,
            "name": 'All Tickets',
            "statusId": null
        },
        {
            "id": 0,
            "name": 'Open',
            "statusId": 2
        },
        {
            "id": 1,
            "name": 'Pending',
            "statusId": 3
        },
        {
            "id": 3,
            "name": 'Resolved',
            "statusId": 4
        },
        {
            "id": 4,
            "name": 'Closed',
            "statusId": 5
        },
        {
            "id": 5,
            "name": 'Waiting for your reply',
            "statusId": 6
        },
        {
            "id": 6,
            "name": 'Raised to Client',
            "statusId": 7
        }
    ],
    ENGAGEMENT_TYPES: {
        BLITZLLAMA: 'BLITZLLAMA',
        MOENGAGE: 'MOENGAGE',
        ALL_ENGAGEMENTS: 'ALL_ENGAGEMENTS'
    },
    VALUE_PROPOSITION: {
        "uber": [
            {
                id:0,
                value:"Upfront destination information in advance.",
            },
            {
                id:1,
                value:"Long Pick up Fee.",
            },
            {
                id:2,
                value:"Night Time pricing.",
            },
            {
                id:3,
                value:"Surge pricing.",
            },
            {
                id:4,
                value:"City wise joining bonus.",
            },
            {
                id:5,
                value:"Peak hours and high demand areas.",
            },
            {
                id:6,
                value: "Zero registration fee.",
            },
            {
                id:7,
                value:"Accidental Insurance upto 5L (For Full time drivers).",
            },
            {
                id: 8,
                value:"Daily Payment."
            }       
        ],
        "sfx": [
            {
                id: 0,
                value:"D/L is not a mandatory document.",
            },
            {
                id: 1,
                value:"Daily Slab Based MG (Minimum Guarantee) earning + Weekly Incentive Milestone.",
            },
            {
                id: 2,
                value:"Accidental Insurance upto 8L + Accidental hospitalization cover up to 2L for a minimum of 24h hospital admission cases.",
            },
            {
                id: 3,
                value:"Zero upfront fees.",
            },
            {
                id: 4,
                value:"Own cost effective EV at Rs 150 rental per day ( Rs 2500 one time deposit).",
            },
            {
                id: 5,
                value:"Joining Bonus.",
            }
        ],
        "rapido": [
            {
                id: 0,
                value:"Demand across T-1 ,T-2 and T-3 Cities. ",
            },
            {
                id: 1,
                value:"Food delivery and Parcel delivery options are available apart from Bike taxi.",
            },
            {
                id: 2,
                value:"No Onboarding fees.",
            },
            {
                id: 3,
                value:"100% Remote onboarding.",
            },
            {
                id: 4,
                value:"Real time activations.",
            },
            {
                id: 5,
                value:"Earnings of upto Rs.30,000 per month for the riders working full time and upto Rs.18,000 per month for part time riders. ",
            },
            {
                id: 6,
                value:"Earnings can be transferred from Rapido wallet to your bank account at your convenience.",
            },
            {
                id: 7,
                value:"Surge during peak hours.",
            },
            {
                id: 8,
                value:"High demand areas visible on the home screen,helps riders to get maximum trips in respective areas.",
            },
            {
                id: 9,
                value:"Accidental coverage and medical benefits covered up to Rs 5 Lakh for Captain & family.",
            },
          ],
        "zomato": [
            {
                id: 0,
                value:"Monthly earning potential upto 35,000.",
            },
            {
                id: 1,
                value:"Flexible timings via the gig based ecosystem.",
            },
            {
                id: 2,
                value:"Ability to book gigs upto 3 days in advance.",
            },
            {
                id: 3,
                value:"Following Insurances",
                hasChild: [
                    {
                        id: 'a',
                        value:"5K OPD for you and your family.",
                    },
                    {
                        id: 'b',
                        value:"1 Lakh for hospitalization for you only.",
                    },
                    {
                        id: 'c',
                        value:"10 Lacs for accidental death for you only.",
                    },
                    {
                        id: 'd',
                        value:"Donation for delivery rider’s kids education, upto 50k annually, for riders who have been with them for 5 years.",
                    },
                ]
            },
            {
                id: 4,
                value:"Joining bonus amounts after hitting a certain milestone.",
            },
            {
                id: 5,
                value:"PAN India demand for Vahans to contribute.",
            },
            {
                id: 6,
                value:"100% remote onboarding.",
            },
            {
                id: 7,
                value:"No need to go to a hub to collect t-shirt bag. It gets delivered to riders doorstep.",
            },
            {
                id: 8,
                value:"Stars marked next to a few gig slots helping riders identify high demand time and locations.",
            },
        ],
        "zepto": [
            {
                id: 0,
                value: "Short distance delivery."
            },
            {
                id: 1,
                value: "Proper sitting areas at the store. Riders have washroom accessibility,drinking water etc."
            },
            {
                id: 2,
                value: "High demand: 20-40 orders/day."
            },
            {
                id: 3,
                value: "Insurance coverage upto Rs.2 Lac."
            },
            {
                id: 4,
                value: "Attractive weekly and monthly incentives along with minimum guarantee."
            },
            {
                id: 5,
                value: "Earnings upto 30-40k/month."
            },
            {
                id: 6,
                value: "No waiting time at stores."
            },
            {
                id: 7,
                value: "Zepto provides it’s own cycle free of cost."
            },
        ],
        "amflex": [
            {
                id: 0,
                value:"No Upfront/OB fee. Free Bag+Tshirt."
            },
            {
                id: 1,
                value: "Courier delivery with multiple orders on the same route."
            },
            {
                id:2,
                value:"Payment is made on Every Wednesday per week."
            },
            {
                id:3,
                value:"Earning Potential: Amazon Flex candidates earn on a per hour basis. Depending upon the city and time, they can earn anywhere between 120 to 140 Rs* per hour."
            },
            {
                id: 4,
                value:"Flexibility in work timings as the slot booking is done by candidates themselves."
            },
            {
                id: 5,
                value: "Candidates can work for a maximum of 54 hours per week, hence better personal life balance."
            },
            {
                id: 6,
                value: "No Mandatory Login Hours - Very useful to manage your time in doing other activities like studying, playing a sport, learning a new skill etc."
            },
            {
                id: 7,
                value: "Work available a week ahead so that slots can be planned and blocked."
            },
            {
                id: 8,
                value: "Insurance Benefits upto Rs. 5,00,000."
            },
        ],
        "swiggy": [
            {
                id: 0,
                value: "Flexibility of work and time - Join Swiggy as: Swiggy Full Time Job, Swiggy Part-Time Job or Swiggy Temporary Job."
            },
            {
                id: 1,
                value: "Flexibility in choosing shift timings."
            },
            {
                id: 2,
                value: "Minimum income guarantee. Earn as much as you can which also includes attractive incentives."
            },
            {
                id: 3,
                value: "No qualification asked - The best part about this job is that anyone can join it & no education is required except basic knowledge in English."
            },
            {
                id: 4,
                value: "Weekly Payout: Their salary is given on a weekly basis."
            },
            {
                id: 5,
                value: "Immediate Joining - The joining process of Swiggy for DE is very simple and easy to complete."
            },
            {
                id: 6,
                value: "Growth & Promotion opportunities - Swiggy provides different growth opportunities to partners."
            },
            {
                id: 7,
                value: "Insurance Benefits for DE and their family members."
            },
        ],
        "grab": [
            {
                id: 0,
                value : "Ability to earn and redeem money daily (For Rider)"
            },
            {
                id: 1,
                value : "Opportunity to work with the biggest E-comm company Flipkart (For Rider)"
            },
            {
                id: 2,
                value : "Help with escalations/activations real time (For Rider)"
            },
            {
                id: 3,
                value : "Earn up to 2k in incentives just on the number of days and order numbers no bar here (For Vahan Leaders)"
            },
            {
                id: 4,
                value : "Direct support from our AMs for every real time escalation/activation (For Vahan Leaders)"
            },
            {
                id: 5,
                value : "Complete support on data cadence on a daily basis (For Vahan Leaders)"
            },
            {
                id: 6,
                value : "Support for Vahan exclusively (For Vahan Leaders)"
            }
        ],
        "uber_3pl": [],
        "rapido_3pl": [
            {
                id: 0,
                value:"Earning Details and Benefits:",
                hasChild: [
                    {
                        id: 'a',
                        value:"Earn upto Rs 30,000 per month.",
                    },
                    {
                        id: 'b',
                        value:"Zero Registration or Onboarding Fee.",
                    },
                    {
                        id: 'c',
                        value:"Accidental coverage and medical benefits covered up to Rs 5 Lakh for Captain.",
                    },
                    {
                        id: 'd',
                        value:"Get onboarded from your home, no need to visit Rapido Office.",
                    },
                    {
                        id: 'e',
                        value:"Earnings can be transferred from Vahan wallet to your bank account at your convenience.",
                    }
                ]
            },
            {
                id: 1,
                value:"Joining Requirements:",
                hasChild: [
                    {
                        id: 'a',
                        value:"Educational Requirements - None.",
                    },
                    {
                        id: 'b',
                        value:"Need your own vehicle and phone.",
                    },
                    {
                        id: 'c',
                        value:"Vehicles Allowed - Bike (Geared or Ungeared), E-Bike.",
                    },
                    {
                        id: 'd',
                        value:"Documents - Aadhar, PAN, DL, RC.",
                    },
                ]
            },
            {
                id: 2,
                value:"Timings:",
                hasChild: [
                    {
                        id: 'a',
                        value:"Flexible Timings.",
                    },
                ]
            },
        ],
        "dunzo": [
            {
                id:0,
                value: "No upfront onboarding fees.",
            },
            {
                id:1,
                value: "Earnings of upto Rs.35,000 per month for the riders working full time and upto Rs.20,000 per month for part time riders.",
            },
            {
                id:2,
                value: "Surge during peak hours.",
            },
            {
                id:3,
                value: "Flexible working hours.",
            },
            {
                id:4,
                value: "Joining Bonus and Weekly incentives.",
            },
            {
                id:5,
                value: "Onboarding fee of Rs.1200 will be deducted in 2 installments in the first 2 weeks of earnings.",
            },
        ],
        "delhivery_3pl": [
            {
                id:0,
                value: "Flexibility of work and time - Full time and part time options available Two shifts available : 8AM - 1PM and 1PM to 6PM (Flexibility to choose)",
            },
            {
                id:1,
                value: "Short distance delivery",
            },
            {
                id:2,
                value: "High Demand : Deliver upto 50-70 shipments per day",
            },
            {
                id:3,
                value: "Pick multiple packets in one go",
            },
            {
                id:4,
                value: "Earning potential - Upto 40k",
            },
            {
                id:5,
                value: "No waiting time at stores",
            },
            {
                id:6,
                value: "Accidental insurance of 3 lacs and medical insurance of 1 lac",
            },
            {
                id:7,
                value: "Easy onboarding process",
            }
        ],
    },
    CANDIDATE_POST_REFERRAL_STEPS:[
        {
            message : 'Share App Download Link with the lead.',
            clients : ['uber','rapido','dunzo','zomato','swiggy']
        },
        {
            message : 'Share ID & Password setup link with the lead.',
            clients : ['uber']
        },
        {
            message : 'Share list of nearest OB centre with the lead.',
            clients : ['dunzo','swiggy']
        },
        {
            message : 'Ask lead to upload missing/rejected documents.',
            clients : ['uber','rapido','dunzo','zomato','swiggy']
        },
        {
            message : 'Ask lead to pay OB fee.',
            clients : ['zomato','swiggy']
        },
        {
            message : 'Download the Vahan App : https://mitraapp.page.link/download-ma',
            clients : ['zepto_3pl'],
            hasClickableUrl: true
        },
        {
            message : 'Ask rider to upload documents - Profile Picture, Aadhar card front, PAN card, Driving license front, bank account passbook/cheque',
            clients : ['zepto_3pl']
        },
        {
            message : 'Ask rider to download Oust app and complete training : https://play.google.com/store/apps/details?id=com.oustme.oustapp',
            clients : ['zepto_3pl'],
            hasClickableUrl: true
        },
        {
            message : 'Ask Unique candidate to download the Vahan App : http://dwnld.vahan.co/',
            clients : ['rapido_3pl', 'bigbasket_3pl'],
            hasClickableUrl: true
        },
        {
            message : 'Ask rider to upload documents - Profile Picture, Aadhar card front, PAN card, Driving license front, bank, account passbook/cheque, RC front, RC back',
            clients : ['rapido_3pl', 'bigbasket_3pl']
        },
        {
            message : 'Please ask the candidate to download the Vahan App : https://mitraapp.page.link/download-ma',
            clients : ['uber', 'uber_3pl', 'rapido', 'rapido', 'zomato', 'dunzo', 'swiggy', 'zepto', 'zepto_3pl', 'zomato', 'blinkit', 'flipkart'],
        },
        {
            message : "Please fill out the provided form if you haven't already. https://360.idfy.com/signup/37646013-0184-42e9-8998-45ef9cf606c5/18e0cf22-81ea-40b3-88a1-b656086d5928",
            clients : ['dominos'],
            hasClickableUrl: true
        }
    ],
    CALL_TAGS: {
        CONNECTED_TAGS: [
            {
            id: 0,
            value: 'Already Working',
            isChecked: false
            },
            {
            id: 1,
            value: 'Not Started',
            isChecked: false
            },
            {
            id: 2,
            value: 'Personal Issue',
            isChecked: false
            },
            {
            id: 3,
            value: 'App Issue',
            isChecked: false
            },
            {
            id: 4,
            value: 'Call Me Later',
            isChecked: false
            },
            {
            id: 5,
            value: 'Interested',
            isChecked: false
            },
            {
            id: 6,
            value: 'Not Interested',
            isChecked: false
            },
            {
            id: 7,
            value: 'Not Eligible',
            isChecked: false
            }
        ],
        NOT_CONNECTED_TAGS: [
            {
            id: 0,
            value: 'Ringing but not receiving',
            isChecked: false
            },
            {
            id: 1,
            value: 'Switch-off',
            isChecked: false
            },
            {
            id: 2,
            value: 'Busy',
            isChecked: false
            },
            {
            id: 3,
            value: 'Out of service',
            isChecked: false
            }
        ]
    },
    RECRUITER_DASHBOARD_CALL_STATUS_TAGS: [
        {
            key: 'connected',
            label: 'Connected',
            value: false
        },
        {
            key: 'notConnected',
            label: 'Not Connected',
            value: false
        },
        {
            key: 'alreadyWorking',
            label: 'Already Working',
            value: false
        },
        {
            key: 'notStarted',
            label: 'Not Started',
            value: false
        },
        {
            key: 'personalIssue',
            label: 'Personal Issue',
            value: false
        },
        {
            key: 'appIssue',
            label: 'App Issue',
            value: false
        },
        {
            key: 'callMeLater',
            label: 'Call Me Later',
            value: false
        },
        {
            key: 'ringingButNotReceiving',
            label: 'Ringing but not receiving',
            value: false
        },
        {
            key: 'switchOff',
            label: 'Switch-off',
            value: false
        },
        {
            key: 'busy',
            label: 'Busy',
            value: false
        },
        {
            key: 'outOfService',
            label: 'Out of service',
            value: false
        },
        {
            key: 'interested',
            label: 'Interested',
            value: false
        },
        {
            key: 'notInterested',
            label: 'Not Interested',
            value: false
        },
        {
            key: 'notEligible',
            label: 'Not Eligible',
            value: false
        }
    ],
    COLUMNS_FOR_CONVERTED_LEADS: [
        {
          title: 'Candidate Name',
          dataIndex: 'candidateName',
          key: 'candidateName',
        },
        {
          title: 'Candidate Phone Number',
          dataIndex: 'candidatePhoneNumber',
          key: 'candidatePhoneNumber',
        },
        {
          title: 'Client',
          dataIndex: 'client',
          key: 'client',
        },
        {
          title: 'Referral Date',
          dataIndex: 'referralDate',
          key: 'referralDate',
        },
        {
          title: 'First Date of work,',
          dataIndex: 'first_date_of_work',
          key: 'first_date_of_work',
        },
        {
          title: '10th Order date',
          dataIndex: 'tenth_date_of_work',
          key: 'tenth_date_of_work',
        },
        {
          title: '25th Order date',
          dataIndex: 'twentyFifth_date_of_work',
          key: 'twentyFifth_date_of_work',
        },
        {
          title: 'Candidate Lifetime Orders/trips',
          dataIndex: 'tripCount',
          key: 'tripCount',
        }
    ],
    RAPIDO_FORMIO_DOC_MAPPING : {
        'DL': [
            'drivingLicense',
            'drivingLicenseBack'
        ],
        'PAN': 'panCard',
        'RC': [
            'vehicleRc',
            'vehicleRcBack',
            'vehicleNumber'
        ],
        'Profile Photo': 'passportPhoto'
    },
    UBER_FORMIO_DOC_MAPPING : {
        'DL Image': ['drivingLicense', 'drivingLicenseBack'],
        'PAN Image': 'panCard',
        'Aadhaar Image': ['aadhaarCard', 'aadhaarCardBack'],
        'Bank Passbook or Cancelled Cheque Image': 'bankPassbookOrCancelledCheque',
        'RC Image': 'vehicleRc',
        'Selfie': 'userSelfie'
    },
    ZEPTO_3PL_DOC_MISSING_ARRAY: [
        'userSelfie',
        'drivingLicense',
        'vehicleRc',
    ],
    UBER_DL_CHECKER_CITIES : [
        'hyderabad',
        'mumbai',
        'guwahati',
        'ahmedabad',
        'kolkata' ,
        'pune',
        'bangalore',
        'bengaluru',
        'mysore',
        'chandigarh',
        'jaipur',
        'raipur',
        'chennai',
        'madurai',
        'trichy',
        'coimbatore',
        'salem',
        'delhi',
        'new delhi',
        'gurgaon',
        'noida',
        'ghaziabad',
        'faridabad',
        'kochi',
        'thiruvananthapuram',
        'thrissur',
        'kozhikode',
        'bhopal',
        'indore',
        'bhubaneswar'
    ],
    RECRUITER_DASHBOARD_CALL_TYPE_TAGS: [
        {
            key: 'incoming',
            label: 'Incoming',
            value: false
        },
        {
            key: 'outgoing',
            label: 'Outgoing',
            value: false
        },
    ],
    RECRUITER_DASHBOARD_MILESTONE_TAGS: [
        {
            key: 'activated',
            label: 'Activated',
            value: false
        },
        {
            key: 'completed1stTrip',
            label: 'F1 ~ First Trip',
            value: false
        },
        {
            key: 'completed10thTrip',
            label: 'F10 ~ First 10 Trips',
            value: false
        }, 
    ],
    RECRUITER_DASHBOARD_CANDIDATE_STATUS_TAGS:[
        {
            key: 'followUpAgain',
            label: 'Follow Up Again',
            value: false
        },
    ],
    FD_TOKEN: 'R1pJSXdhczMyMVV4RThvUG56Olg=',
    HOME_PAGE_QUICK_LINKS: [
		{currentMenu: 'LEADS', key: 'candidate_list', heading: 'Candidate List', link: '/recruiter-candidates', icon: <TeamOutlined style={{fontSize: '30px'}} />, visible:'allUsers'},
		{currentMenu: 'JOB_DEMANDS', key: 'client_list_with_demand', heading: 'Client List with Demand', link: '/job-demands/job-requirements', icon: <TableOutlined style={{fontSize: '30px'}} />,visible:'allUsers'},
		{currentMenu: 'LEADS', key: 'action_needed', heading: 'Action Needed', link: '/leads/action-needed', icon: <TeamOutlined style={{fontSize: '30px'}} />,visible:'teamMembers'},
        {currentMenu: 'INCENTIVES', key: 'incentives', heading: 'Incentives', link: '/incentives', icon: <PercentageOutlined style={{fontSize: '30px'}} />,visible:'managers'},
		{currentMenu: 'PERFORMANCE_DASH', key: 'performance_dashboard', heading: 'Performance Dashboard', link: '/report/performance-dashboard', icon: <DashboardOutlined style={{fontSize: '30px'}}/>,visible:'allUsers'},
		{currentMenu: 'TRAINING_HUB', key: 'training_section', heading: 'Training Section', link: '/training-hub', icon: <BookOutlined style={{fontSize: '30px'}} />,visible:'allUsers'},
		{currentMenu: 'HELP', key: 'help_and_support', heading: 'Help & Support', link: '/help', icon: <IssuesCloseOutlined style={{fontSize: '30px'}} />,visible:'allUsers'}
	],
    REFER_AND_EARN_TNC: [
        {
            id: 0,
            value: 'Payout will not be made for any fraud trips.'
        },
        {
            id: 1,
            value: 'Your friend should complete 5 valid FTs in his/her first month of registration.'
        },
        {
            id: 2,
            value: 'Vahan has all the rights to decide on payouts going to the Vahan Leader on the referral program.'
        }
    ],
    CLIENT_AND_JOB_CATEGORY_MAPPING: {
        'uber': 'Driver',
        'uber_3pl': 'Driver',
        'rapido': 'Driver',
        'rapido_3pl': 'Driver',
        'shadowfax': 'Delivery',
        'zomato': 'Delivery',
        'dunzo': 'Delivery',
        'swiggy': 'Delivery',
        'zepto': 'Delivery',
        'zepto_3pl': 'Delivery',
        'grab': 'Delivery',
    },
    NAVIGATION_TABS: {
        'HOME_PAGE': {
            key: 'home',
            label: 'Home',
            link: '/home',
            icon: <HomeOutlined  />,
            id: 'home-icon',
            isMobileView: true,
            mobileId: 'home-footer-icon',
        },
        'LEADS': {
            key: 'leads',
            label: 'Candidates',
            link: '/recruiter-candidates',
            optionalLink: '/leads/all-candidate',
            icon: <UsergroupAddOutlined  />,
            id: 'candidates-icon',
            isMobileView: true,
            mobileId: 'candidates-footer-icon'
        },
        'JOB_DEMANDS': {
            key: 'job-demands',
            label: 'Jobs',
            link: '/job-demands/job-requirements',
            icon: <GroupOutlined />,
            id: 'demand-hub-icon',
            isMobileView: true,
            mobileId: 'demand-hub-footer-icon'
        },
        'PERFORMANCE_DASH': {
            key: 'performance-dashboard',
            label: 'Performance Dashboard',
            link: '/report/performance-dashboard',
            isMobileView: true,
            icon: <PieChartOutlined />,
            mobileId: 'report-footer-icon',
            mobileLabel: 'Report',
        },
        'PAYOUT_DASH': {
            key: 'payout-dashboard',
            label: 'Payout Dashboard',
            link: '/report/payout-dashboard',
            isVisible: false
        },
        'INCENTIVES': {
            key: 'incentives',
            label: 'Incentives',
            link: '/incentives',
            icon: <PercentageOutlined  />,
            id: 'incentive-hub-icon'
        },
        'NOTIFICATION': {
            key: 'notification',
            label: 'Inbox',
            link: '/notification',
            icon: <BellOutlined  />,
            id: 'notification-footer-icon'
        },
        'VAHAN_CONVERTED_USERS': {
            key: 'vahan-converted-users',
            label: 'Vahan Converted Users',
            link: '/converted-leads',
            icon: <UsergroupAddOutlined  />,
            id: 'candidates-icon'
        },
        'CLIENT_UPDATES': {
            key: 'client-updates',
            label: 'Client Updates',
            link: process.env.REACT_APP_CLIENT_UPDATES_LINK,
            icon: <HddOutlined  />,
            id: 'client-update-icon'
        },
        'V_CARE': {
            key: 'v-care',
            label: 'V Care',
            link: '',
            icon: <DollarCircleOutlined  />,
            id: 'v-care-icon'
        },
        'TRAINING_HUB': {
            key: 'training-hub',
            label: 'Training',
            link: '/training-hub',
            icon: <BookOutlined  />,
            id: 'training-icon'
        },
        'REFER_AND_EARN': {
            key: 'refer-and-earn',
            label: 'Refer & Earn',
            link: '/refer-and-earn',
            icon: <DollarCircleOutlined  />,
            id: 'refer-icon'
        },
        'ACCOUNT': {
            key: 'account',
            label: 'Account',
            link: '/account',
            icon: <SettingOutlined  />,
            id: 'account-icon'
        },
        'HELP': {
            key: 'help',
            label: 'Help',
            link: '/help',
            icon: <IssuesCloseOutlined  />,
            id: 'help-icon'
        },
        'FAQ': {
            key: 'profile',
            label: 'FAQs',
            link: process.env.REACT_APP_FAQ_LINK,
            icon: <QuestionCircleOutlined  />,
            id: 'faqs-icon'
        },
        'LOGOUT': {
            key: 'logout',
            label: 'Logout',
            link: '',
            icon: <LogoutOutlined  />,
            id: 'logout-icon',
            isVisible: false
        },
        'MORE': {
            key: 'more',
            label: 'More',
            link: '/more',
            icon: <MenuOutlined  />,
            id: 'more-footer-icon',
            isVisible: false,
            isMobileView: true,
            mobileId: 'more-footer-icon'
        }  
    },
    RAISE_ISSUE_TYPES: [
        'Client Activation Issue',
        'Document Issue',
        'Data Missing from the trackers',
        'Rider login Issue',
        'Rider Payout Issue',
        'Vahan Payout Issue',
        'Orders/Trips related',
        'Vahan App Issue',
        'Client App Issue',
        'BGC Issue',
        'Slot/Vertical Issue',
        'Location/Store/Shift Change',
        'Account Manager related',
        'Others'
    ],
    FRESHDESK_GROUP_ID: {
        "MITRA_LEADER_ESCALATION": 84000268346,
    },
    REFRESH_DOCUMENT_STATUS_MENU: [
        {
            key: 'rapido',
            label: 'Rapido ( Last 24 hours )'
        },
        {
            key: 'rapido_3pl',
            label: 'Rapido_3PL ( Last 24 hours )'
        }
    ],
    SIGNUP_PAGE_LOGO_CLIENTS: [
        "Dunzo",
        "Grab",
        "Rapido",
        "Swiggy",
        "Uber",
        "Zepto_3PL",
        "Zomato",
        "Delhivery_3PL"
    ],
    LOU_TOURS:{
        voiceNote: '028108098972'
    },
    APPLICATION_CREATION_STAFFING_CLIENTS: [
        'bigbasket_3pl',
        'rapido_3pl',
        'delhivery_3pl',
        'zomato_3pl',
    ],
    statusToMilestoneMaping: {
        "referral_date_si": "Completed referrals",
        "activation_date": "Activations",
        "first_date_of_work": "F1 ~ First Trip",
        "10th_order_date": "F10 ~ First 10 Trips",
        "15th_order_date": "F15 ~ First 15 Trips",
        "20th_order_date": "F20 ~ First 20 Trips",
        "25th_order_date": "F25 ~ First 25 Trips",
        "30th_order_date": "F30 ~ First 30 Trips",
        "35th_order_date": "F35 ~ First 35 Trips",
        "40th_order_date": "F40 ~ First 40 Trips",
        "45th_order_date": "F45 ~ First 45 Trips",
        "50th_order_date": "F50 ~ First 50 Trips",
        "60th_order_date": "F60 ~ First 60 Trips",
        "75th_order_date": "F75 ~ First 75 Trips",
        "80th_order_date": "F80 ~ First 80 Trips",
        "100th_order_date": "F100 ~ First 100 Trips",
        "200th_order_date": "F200 ~ First 200 Trips",
        "mitra_app_downloads_before_onboarding": "Pre Onboarding",
        "mitra_app_downloads_after_onboarding": "Post Onboarding",
    },
    zeptoMandatoryDocToElementMapping: {
        'bankPassbookOrCancelledCheque' : '2d9b11b9-43a6-4fc1-b025-3f1f3c7085b8',
        'aadhaarCard': '11f733df-554e-48f1-8b2f-d0ef868d20f3',
        'panCard': 'd3ef8e38-5f43-44b2-b75b-4105a155eae7',
        'userSelfie': 'dd1b1952-8f0e-4d40-8ee4-81fdc1063db0',
        'drivingLicense': '7bfc0c27-6eb2-4347-89f5-516b9dcd1a5f'
    },
    crmExceptionMitras: [
        'd8d6f51e-83ff-45a2-863f-acc633b638c2',
        '2dbfebb5-0756-40c9-a41f-86b49858a084',
        '9dbb09bb-a198-42fc-a923-3666ca4fe8d4',
        '5884c908-572b-4887-ab95-574c718d32c9',
        '36e3e751-1b3c-4abf-8551-ee250055c015'  // naman's account id
    ],
    zomatoAdditionalFormEligibleMlIds: [
        'd91b67cf-5dab-4031-9600-50e9f5ed56e7', //gaurav's ml id
        '9dbb09bb-a198-42fc-a923-3666ca4fe8d4',
        'c220dcf1-a262-48aa-8ff3-05218a74138e'
    ],
    expectionMitrasForCandidatesPageReDirection: [
        '8fa5708a-ea7e-45be-8601-7ed22399213a' // My Smart Buy - 9902316557
    ]
};

export default CONSTANTS;
