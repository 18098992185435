import axios from 'axios';

export const getCandidatesData = async (data) => {
  console.log(data);
  let url = `${process.env.REACT_APP_BACKEND_URL}/getAllCandidatesList`;
    return axios.get(url, {params: data, withCredentials: true});
} 

export const getUniquenessStatusForScheduledInterviewIds = async (data) => {
  // const config = {
  //   method: 'post',
  //   url: `${process.env.REACT_APP_BACKEND_URL}/getUniquenessStatusForScheduledInterviewIds`,
  //   data,
  // };
    // return axios(config);
    return axios.post(process.env.REACT_APP_BACKEND_URL + '/getUniquenessStatusForScheduledInterviewIds', data, {withCredentials: true});
} 

export const downloadCandidatesPageData = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/download/geAllCandidatesList`;
    return axios.get(url, {params: data, withCredentials: true});
} 

export const getViewDetailsData = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/getViewDetailsPopupData`;
    return axios.get(url, {params: data, withCredentials: true});
} 

export const getAtRiskApplications = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/getAtRiskApplications`;
    return axios.get(url, {params: data, withCredentials: true});
} 

export const downloadAtRiskApplications = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/download/AtRiskApplications`;
    return axios.get(url, {params: data, withCredentials: true});
} 

export const recruiterDashboardCandidateListDownload = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/download/recruiterDashboardCandidateList`;
    return axios.get(url, {params: data, withCredentials: true});
} 
export const recruiterDashboardCandidateList= async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/recruiterDashboardCandidateList`;
    return axios.get(url, {params: data, withCredentials: true});
} 

export const displayexpiryDateChangedBanner= async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/displayexpiryDateChangedBanner`;
    return axios.get(url, {params: data, withCredentials: true});
}
export const showHotLeadsPopUpBackend= async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/showHotLeadsPopUp`;
    return axios.get(url, {params: data, withCredentials: true});
}
export const updateLastSeenStatus= async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/updateUserFeatureLastSeenStatus`
    return axios.post(url, data, {withCredentials: true});
}
