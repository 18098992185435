import axios from 'axios';

export const getProgressBarTimelineInformation = () => {
    const url = process.env.REACT_APP_BACKEND_URL + '/getTierTimeline';
    return axios.get(url, { withCredentials: true });
};

export const trackEventForProgressBar =  (eventName) => {
    const data = new FormData();
    data.append('eventName', eventName);
    const url = process.env.REACT_APP_BACKEND_URL + '/trackEvent';
    return axios.post(url, data, { withCredentials: true });
};
