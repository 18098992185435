import React, { useState, useEffect , useContext } from 'react';
import blitz from 'blitzllama-js';
import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';
import axios from 'axios';
import Home from '../Home';
import '../../css/login.scss';
import * as loginService from '../../service/loginService';
import { logEvent } from '../../service/tracking';
import ClipLoader from 'react-spinners/ClipLoader';
import trackingConstants from '../../constants/trackingConstants';
import { NavLink, Redirect, useHistory, useLocation } from "react-router-dom";
// import useMoengage from '../../hooks/useMoengage';
import { createMoengageUser } from '../../service/engagementMonitoringService';
import { AppContext } from "../../App";
import {Skeleton, notification} from 'antd';
import * as queryString from 'query-string';
import { ROLES } from '../common/roles';

const LogInPage = (props) => {
	const { mitraDispatch, setUserRole } = useContext(AppContext);
	const [ phoneNumber, setPhoneNumber ] = useState('');
	const [ phoneNumberVerified, setPhoneNumberVerified ] = useState(null);
	const [ otpSent, setOtpSent ] = useState(null);
	const [ otpVerified, setOtpVerified ] = useState(null);
	const [ otp, setOtp ] = useState('');
	const [ mitra, setMitra ] = useState(null);
	const [ validSession, setValidSession ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ phoneNumberTextErrorMsg, setPhoneNumberTextErrorMsg ] = useState(null);
	const [ otpTextErrorMsg, setOtpTextErrorMsg ] = useState(null);
	const history = useHistory();
	const location = useLocation();

	const goToOnboardingQuestionsScreen = () => {
		history.push('/onboarding-questions');
	}

	useEffect(() => {
		const parsedUrl = queryString.parse(window.location.search);
		if(parsedUrl && parsedUrl.accountManagerAccessToken) {
			loginService.validateAccountManagerLogin(parsedUrl.accountManagerAccessToken)
			.then((response) => {
				setLoading(false);
				localStorage.setItem("payoutAccessToken", parsedUrl.accountManagerPayoutAccessToken)
				notification['warning']({
					message: 'This is a READ ONLY access !!'
				});
				history.push('/home');
			})
			.catch((error) => setLoading(false));
		} 
		if(parsedUrl && parsedUrl.dCAccessToken) {
			loginService.validateDCLogin(parsedUrl.dCAccessToken)
			.then((response) => {
				setLoading(false);
				localStorage.setItem("isFromSuiteCrm", true);
				setUserRole(ROLES.DC);
				localStorage.setItem('currentPhoneNumber', response?.data?.user?.phoneNumber);
				localStorage.setItem('suiteCrmId', response?.data?.suiteCrmId);
				notification['warning']({
					message: 'This is a Candidate specific access !!'
				});
				history.push({
					pathname: '/search-page',
					state: {
						candidatePhoneNumber: response?.data?.user?.phoneNumber,
					}
				})
			})
			.catch((error) => setLoading(false));
		} 
		logEvent('OpenLoginScreen', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		loginService
			.validateSession()
			.then((response) => {
				setMitra(response.data.mitra);
				setValidSession(true);
				setLoading(false);
				if (!response.data.mitra.isFeedBackCompleted) goToOnboardingQuestionsScreen();
			})
			.catch((error) => setLoading(false));
   
		//Added to show and hide nav bar
		// mitraDispatch({type:'navigationDisplay',value:false});
		// return () => {
		// 	mitraDispatch({type:'navigationDisplay',value:true}); // Show when component is unmounted
		// };
	}, []);
 
	
	// const [createMoengageUser, trackEvent] = useMoengage();

	const initializeThirdPartyEngagements = (response) => {
		// MoEngage
		createMoengageUser(response.data.mitra);

		// Blitzllama
		blitz.createUser(response.data.mitra.phoneNumber);
		blitz.setUserProperties({
			mitraPhoneNumber: response.data.mitra.phoneNumber,
			mitraName: response.data.mitra.name,
			mitraId: response.data.mitra.mitraId,
			role: response.data.mitra.role
		}); 
		blitz.triggerEvent('LoginSuccessful');

		// LOU Assist
		window.LOU.identify(response.data.mitra.id, {
			mitraPhoneNumber: response.data.mitra.phoneNumber,
			mitraName: response.data.mitra.name,
			city: response.data.mitra.homeCity,
			managerMitraID: response.data.mitra.managerMitraID,
			email: response.data.mitra.email,
		});
	};

	const verifyOTP = (e) => {
		logEvent('LoginSuccessful', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		loginService
			.verifyOTP(phoneNumber, otp)
			.then((response) => {
				if (response.data.otpVerified) {
					console.log('mitraOTPLogin',response.data.mitra )
					setMitra(response.data.mitra);

					initializeThirdPartyEngagements(response);
					
					setOtpVerified(true);
					if (location.state && location.state.redirectTo) {
						history.push(location.state.redirectTo);
					}
				} else if (!response.data.otpVerified) {
                    setOtpTextErrorMsg('Wrong or Expired OTP');
                } else if (response.status === 404) {
                    setOtpTextErrorMsg('Service Unavailable');
                } else {
                    setOtpTextErrorMsg('OTP could not be verified');
                }
			})
			.catch((error) => {
                setOtpVerified(false);
                setOtpTextErrorMsg('Service Unavailable');
				console.log('Error verifying OTP for: ', phoneNumber, otp, error);
			});
	};

	const handleOTPChange = (event) => {
		setOtp(event.target.value);
	};

	const sendOTP = async (phoneNumber, sendOTPtoEmail = false) => {
        setPhoneNumberTextErrorMsg(null);
		if (phoneNumber.length === 10) {
			try {
				const data = new FormData();
				data.append('phoneNumber', phoneNumber);
				data.append('sendOTPtoEmail', sendOTPtoEmail);
				let url = process.env.REACT_APP_BACKEND_URL + '/sendOtp';
                const response = await axios.post(url, data);
                if (response.status === 404) {
                    setPhoneNumberTextErrorMsg('Service Unavailable');
                } else if (response.status === 210) {
                    setOtpSent(true);
					setPhoneNumberVerified(true);
                } else {
                    setPhoneNumberTextErrorMsg('OTP could not be sent');
                }
			} catch (error) {
                setPhoneNumberTextErrorMsg('Service Unavailable');
				console.log('Error sending OTP for: ', phoneNumber, error);
			}
		}
	};

	const handleResendOTP = () => {
		sendOTP(phoneNumber);
	};

	const handleBackArrowClick = () => {
		setOtpSent(false);
		setOtp('');
		setOtpVerified(null);
	};

	const handleNumberChange = (event) => {
		setPhoneNumber(event.target.value);
	};

	const validateMitraPhoneNumber = async (phoneNumber) => {
		try {
            setPhoneNumberTextErrorMsg(null);
			let url = `${process.env.REACT_APP_BACKEND_URL}/validate?phoneNumber=${encodeURIComponent(phoneNumber)}`;
			const response = await axios.get(url);
			if (response.data.validMitra) {
				return true;
			}  else if (!response.data.validMitra) {
                setPhoneNumberTextErrorMsg('This number is not registered');
            } else if (response.status === 404) {
                setPhoneNumberTextErrorMsg('Service Unavailable');
            } else {
                setPhoneNumberTextErrorMsg('Number could not be verified');
            }
		} catch (error) {
            setPhoneNumberTextErrorMsg('Service Unavailable');
			console.log(error);
		}
		return false;
	};

	const handleGetOTPClick = async () => {
		logEvent('RequestLoginOTP', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		const validMitraPhoneNumer = await validateMitraPhoneNumber(phoneNumber);
		if (validMitraPhoneNumer) {
			let otpToMail = false;
			if(phoneNumber.length === 10 && trackingConstants.EmailOTPPhoneNumbers.includes(phoneNumber)) {
				otpToMail = true;
			}
			await sendOTP(phoneNumber, otpToMail);
		} else {
			setPhoneNumberVerified(false);
			logEvent('LoginNumberNotRegistered', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		}
	};
	return loading ? (
		<Skeleton active />
	) : validSession || otpVerified ? (
		<Redirect to='/home'/>
	) : (
		<div className="Login mainContentZ">
			{/* <img className="vahan-logo" alt="vahan" style={{ marginTop: '2.5rem' }} src="/image/mitra-logo.png" />
			<h3 className="subheading">by</h3> */}
			<img className="vahan" alt="vahan" src="/image/vahan-final-logo-black.png" />
			{otpSent ? (
				<div>
					<Button className="arrow-back" icon={require('../../arrow_back.svg')} onClick={handleBackArrowClick} />
					<div className="main-login-container">
						<div className="cards-elements-surface-filled">
							{otpVerified === false ? (
								<h5 className="OtpMessage marginTopClass">OTP SMSed to {phoneNumber}</h5>
							) : (
								<h5 className="OtpMessage">OTP SMSed to {phoneNumber} {trackingConstants.EmailOTPPhoneNumbers.includes(phoneNumber)? "and sent to registered e-mail":"" }  </h5>
							)}
							<div>
								<TextField
									id= "enter-otp"
									label="Enter OTP"
									outlined
									placeholder="Enter OTP"
									minLength={4}
									maxLength={4}
									pattern="[0-9]*"
									value={otp}
									onChange={handleOTPChange}
								/>
								<h5 className="errorMsg">{otpTextErrorMsg}</h5>
                                <div className="buttons-contained-enabled-copy">
									<Button id="log-in" raised label="LOG IN" onClick={(e) => verifyOTP(e)} />
								</div>
							</div>
						</div>
					</div>
					<h5 style={{ margin: '0' }} className="OtpMessage">
						Didn&apos;t get SMS?
						<Button id="resend-otp" className="btn_tertiary" label="Resend OTP" onClick={handleResendOTP} />{' '}
					</h5>
				</div>
			) : (
				<div className="main-login-container">
					<div className="cards-elements-surface-filled">
						{phoneNumberVerified === false ? <h3 className="marginTopClass login-font">LOG IN</h3> : <h3 className="login-font">LOG IN</h3>}
						<div>
							<TextField
								id= "enter-mobile"
								label="Enter Mobile Number"
								outlined
								className="surface"
								placeholder="+91"
								minLength={10}
								maxLength={10}
								pattern="[0-9]*"
								value={phoneNumber}
								onChange={handleNumberChange}
							/>
							<h5 className="errorMsg">{phoneNumberTextErrorMsg}</h5>
							<div className="buttons-contained-enabled-copy">
								<Button id= "get-otp" raised label="Get OTP" onClick={handleGetOTPClick} />
							</div>
							<br/>
							<p>Do not have an account? <div onClick={()=>history.push('/signup')} className="signup_link">SIGN UP</div></p>
						</div>
					</div>
				</div>
			)}
			{/* <img alt="vahan" style={otpSent ? { marginTop: '0rem' } : { marginTop: '4.5rem' }} src="/image/g16.png" /> */}
			<div className="illustration" />
		</div>
	);
};

export default LogInPage;
