import React, {useContext, useEffect, useState} from "react";
import { Typography, Radio, Space,Input, Checkbox, Divider,Tag, Button, notification } from 'antd';
import {CheckCircleFilled,InfoCircleFilled} from '@ant-design/icons';
import './callStatus.scss';
import PageHeaderComponent from "../common/PageHeader";
import CONSTANTS from "../../constants/constants";
import * as queryString from 'query-string';
import { addCallRemark, getCallStatus } from "../../service/kaleyraCallService";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../App";
import moment from "moment";
import { RouterPrompt } from "../RouterPrompt/RouterPrompt";

const { Text} = Typography;
const { TextArea } = Input;

const CallStatusScreen = () => {
    const [breadcrumb] = useState([{link: '/call-status', label: 'Call Status'}]);
    const [callConnectedTags, setCallConnectedTags] = useState(CONSTANTS.CALL_TAGS.CONNECTED_TAGS);
    const [callNotConnectedTags, setCallNotConnectedTags] = useState(CONSTANTS.CALL_TAGS.NOT_CONNECTED_TAGS);
    const [ifCallConnected, setIfCallConnected] = useState(true);
    const [checkboxTicked, setCheckboxTicked] = useState(false);
    const [comment, setComment] = useState('');
    const [callId, setCallId] = useState('');
    const history = useHistory();
    const {mitraReducer} = useContext(AppContext);
    const [callStatus, setCallStatus] = useState({});
    const [loading, setLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [showPrompt, setShowPrompt] = useState(true);

    useEffect(()=>{
        // Adding these two lines to drop previous component values.
        setCallConnectedTags([...CONSTANTS.CALL_TAGS.CONNECTED_TAGS]);
        setCallNotConnectedTags([...CONSTANTS.CALL_TAGS.NOT_CONNECTED_TAGS]);

        const parsedUrl = queryString.parse(window.location.search);

        if (!mitraReducer && !mitraReducer?.kaleyraCallInformation && !parsedUrl) {
            // if user refreshes the status page
            setShowPrompt(false);
            setTimeout(()=> {
                history.push('/recruiter');
            }, 1000);
        } else {
        setIfCallConnected(JSON.parse(parsedUrl.callAnswered));
        setCallId(parsedUrl.callId);
        getCallStatus(parsedUrl.callId).then((response) => {
            setCallStatus(response.data);
        }).catch((error)=> console.log(error));
    }

    return () => {
        for (let i=0;i<callConnectedTags.length;i++) {
            if (callConnectedTags[i].isChecked) callConnectedTags[i].isChecked=false;
        }
        for (let i=0;i<callNotConnectedTags.length;i++) {
            if (callNotConnectedTags[i].isChecked) callNotConnectedTags[i].isChecked=false;
        }
        setCallConnectedTags([...callConnectedTags]);
        setCallConnectedTags([...callNotConnectedTags]);
    }
    }, []);

    const onTagChange = (tagList, tagId, tagType) => {
        setDisableSubmit(false);
        for (let i=0;i<tagList.length; i++) {
            if (tagList[i].id === tagId) {
                tagList[i].isChecked = true;
            } else {
                tagList[i].isChecked = false;
            }
        }
        if (tagType === 'CONNECTED') {
            setCallConnectedTags([...tagList])
            const newTagValue = [...callNotConnectedTags];
            for (let i=0;i<newTagValue.length; i++) {
                newTagValue[i].isChecked = false;
            }
        } else {
            setCallNotConnectedTags([...tagList]);
            const newTagValue = [...callConnectedTags];
            for (let i=0;i<newTagValue.length; i++) {
                newTagValue[i].isChecked = false;
            }
        }
    };

    const onRadioChange = (event) => {
        setDisableSubmit(true);
        setCheckboxTicked(false);
        setComment('');
       setIfCallConnected(!ifCallConnected);
    };

    const onCheckboxValueChange = () => {
        setCheckboxTicked(!checkboxTicked);
    }

    const onCommentChange = (event) => {
        setComment(event.target.value)
    }

    const openNotification = () => {
        const key = `open${Date.now()}`;
        // const btn = (
        //   <Button 
        //   type="primary" 
        //   size="small" 
        //   onClick={() => history.push(`/connecting-call`)}>
        //     Call Again
        //   </Button>
        // );
        const placement= 'bottomRight';
        notification.open({
          message: `Call log successfully recorded for: ${mitraReducer.kaleyraCallInformation.receiverNumber}`,
          key,
          duration: 5,
          placement,
          icon: <CheckCircleFilled style={{ color: '#52C41A' }} /> ,
          description: <Button type="primary" size="small" onClick={() => history.push(`/connecting-call`)}>Call Again</Button>
        });
      };

    const submitRemarks = () => {
        setLoading(true);
        const tagGroup = ifCallConnected ? callConnectedTags: callNotConnectedTags;
        const selectedTag = tagGroup.filter((tag) => tag.isChecked);
        if (!selectedTag.length) {
            setLoading(false);
            notification['error']({
                message: 'Please select a status tag to continue !!'
              });
            return;
        }
        // call the api
        const apiParams = {
            isCallConnected: ifCallConnected,
            isFollowUpNeeded: checkboxTicked,
            tag: selectedTag?.[0]?.value || "",
            comment: comment,
            callId: callId
        };
        addCallRemark(apiParams).then((response) => {
            setLoading(false);
            setShowPrompt(false);
            openNotification();
            setTimeout(()=> {
                history.push('/recruiter');
            }, 1000);
        }).catch((error)=> {
            setLoading(false);
            console.log(error);
        });
    }


    return (
        <div>
            <RouterPrompt
                    when={showPrompt}
                    title="Leave this page"
                    cancelText="No"
                    okText="Yes"
                    onOK={() => true}
                    onCancel={() => false}
                    promptMessage="Remark not submitted. Are you sure you want to leave this page?"
                    onOKCallback={submitRemarks}
                />
            <div className="header">
                <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb}></PageHeaderComponent>
            </div>
            <div className="call-status-main-container">

                <div className="call-status">
                    <Space direction="vertical" size={8} style={{background:'#F6FFED', width:"100%", padding:'20px'}}>
                        {/* <CheckCircleFilled style={{color:'#52C41A', fontSize:'55px'}}/> */}
                        {
                            ifCallConnected ? 
                            <CheckCircleFilled style={{color:'#52C41A', fontSize:'55px'}}/> : 
                            <InfoCircleFilled  style={{color:'#F5222D', fontSize:'55px'}}/>
                        }
                        
                        <Text className="contact-information">{ifCallConnected ? 'Connected':'Not Connected'}, <b>
                            {mitraReducer?.kaleyraCallInformation?.receiverNumber || ''}
                            </b></Text>
                        <Text className="call-metadata">
                            {moment(callStatus.startedAt).format('DD MMM hh:mm A')}
                            {parseInt(callStatus.billedDuration) ? `, Duration: ${callStatus.billedDuration} sec`:null}
                            </Text>
                        <Text className="contact-name">
                            {mitraReducer?.kaleyraCallInformation ? 
                            mitraReducer.kaleyraCallInformation.currentLead.firstName+ ' '+
                            ((mitraReducer.kaleyraCallInformation.currentLead.lastName).includes('Unknown') ? '':mitraReducer.kaleyraCallInformation.currentLead.lastName)
                            : ''}
                            </Text>
                    </Space>
                </div>

                <div className="call-feedback">
                    <Space direction="vertical" size={24} style={{background:'#ffffff', width:"100%", padding:'20px'}}>
                        <Text className="feedback-header">How was your call?</Text>
                        <Divider/>
                        <Radio.Group onChange={onRadioChange} value={ifCallConnected}>
                            <Space direction="vertical" size={24}>
                                <div className="radio-section">
                                    <Radio value={true}  className="radio-text">Connected</Radio>
                                    {
                                        ifCallConnected && <Checkbox onChange={onCheckboxValueChange} checked={checkboxTicked}>Follow up again</Checkbox>
                                    }
                                </div>
                                {
                                    ifCallConnected && (
                                        <>
                                        <div className="tag-container">
                                        <Text style={{fontSize:'1rem',fontWeight:'700', display:'flex'}}>Select status (required) :</Text>
                                            <div className="tag-wrapper-container-connected">
                                                {
                                                    callConnectedTags.map((tag)=> {
                                                        return (
                                                            <Tag.CheckableTag 
                                                            key={tag.id} 
                                                            className={!tag.isChecked ? 'tags':'tags tag-active'}
                                                            checked={tag.isChecked}
                                                            onChange={()=> onTagChange(callConnectedTags, tag.id, 'CONNECTED')}
                                                            >{tag.value}</Tag.CheckableTag>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <Divider></Divider>
                                        <TextArea
                                            placeholder="Add Comment"
                                            autoSize={{ minRows: 2, maxRows: 6 }}
                                            onChange={onCommentChange}
                                            value={comment}
                                        />
                                        </>
                                    )
                                }
                                

                                <div className="radio-section">
                                    <Radio value={false} className="radio-text">Not Connected</Radio>
                                    {
                                        !ifCallConnected && <Checkbox onChange={onCheckboxValueChange} checked={checkboxTicked}>Follow up again</Checkbox>
                                    }
                                </div>
                                {
                                    !ifCallConnected && (
                                        <>
                                        <div className="tag-container">
                                        <Text style={{fontSize:'1rem',fontWeight:'700', display:'flex'}}>Select status (required) :</Text>
                                            <div className="tag-wrapper-container-not-connected">
                                            {
                                                    callNotConnectedTags.map((tag)=> {
                                                        return (
                                                            <Tag.CheckableTag 
                                                            key={tag.id} 
                                                            className={!tag.isChecked ? 'tags':'tags tag-active'}
                                                            checked={tag.isChecked}
                                                            onChange={()=> onTagChange(callNotConnectedTags, tag.id, 'NOT_CONNECTED')}
                                                            >{tag.value}</Tag.CheckableTag>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <Divider></Divider>
                                        <TextArea
                                            placeholder="Add Comment"
                                            autoSize={{ minRows: 2, maxRows: 6 }}
                                            onChange={onCommentChange}
                                            value={comment}
                                        />
                                        </>
                                    )
                                }
                            
                            </Space>
                        </Radio.Group>
                        <Button 
                        style={{color: '#fff',background: ifCallConnected ? '#52C41A': '#F5222D'}} 
                        className="custom-submit-button"
                        loading={loading}
                        onClick={submitRemarks}>Submit</Button>
                    </Space>
                </div>
                
            </div>	
        </div>
    );
};

export default CallStatusScreen
